/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useRef, useState, useEffect } from 'react';

import { Container, SubMenu, Arrow } from './styles';

interface ULProps {
  label: string;
  visibleClose: boolean;
  // eslint-disable-next-line no-unused-vars
  toggleClose: (visible: boolean) => void;
}

const SubMenuUL: React.FC<ULProps> = ({
  label,
  visibleClose,
  toggleClose,
  children,
}) => {
  const ulRef = useRef<HTMLDivElement>(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (visibleClose === true) {
      setVisible(false);
    }
  }, [toggleClose, visibleClose]);

  const toggleVisible = useCallback(() => {
    if (visible === false) {
      toggleClose(true);
      setTimeout(() => {
        setVisible(true);
      }, 200);
    } else {
      setVisible(false);
    }
  }, [toggleClose, visible]);

  return (
    <Container visible={visible} ref={ulRef}>
      <a
        href="#"
        onClick={() => {
          toggleVisible();
        }}
      >
        <label>
          <span>{label}</span>
          <Arrow visible={visible} />
        </label>
      </a>
      <SubMenu visible={visible}>{children}</SubMenu>
    </Container>
  );
};

export default SubMenuUL;
