/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable camelcase */
import React from 'react';

import { Scope } from '@unform/core';

import Input from '../Input';
import Checkbox from '../Checkbox';

import { ContainerPermissions, ContentPermissions } from './styles';

interface PropMenu {
  propListMenus: Array<MenuDTO>;
}

interface MenuDTO {
  id: number;
  group_menu: number;
  label: string;
  route: string;
  action: string | null;
  is_submenu: boolean;
  submenus?: Array<MenuDTO>;
  permission?: {
    read: boolean;
    create: boolean;
    update: boolean;
    delete: boolean;
    // cancel: boolean;
  };
}

const MenuPermissions: React.FC<PropMenu> = ({
  propListMenus = [] as Array<MenuDTO>,
}) => {
  /**
   * Estas variáveis de controle de index são obrigatórias pois
   * o scope do unform cria outro array ao submit. Se utilizar
   * o index propListMenus e propListMenus.submenu, correr o risco
   * de itens não serem enviados ao submit, pois existem index
   * iguais em propListMenus.submenu. Exemplo:
   *
   * Menu principal Produto no index 0 de propListMenus com os subMenus:
   * 0: {id: 5, group_menu: 4, label: "Gerenciar", route: "products", action: "/list", …}
   * 1: {id: 6, group_menu: 4, label: "Importar", route: "products/import", action: "/list", …}
   * 2: {id: 7, group_menu: 4, label: "Entrada", route: "products/input", action: "/list", …}
   * 3: {id: 8, group_menu: 4, label: "Entrada NFe", route: "products/nfe", action: "/list", …}
   * 4: {id: 9, group_menu: 4, label: "Estoque Inicial", route: "products/stock-start
   *
   * Menu principal Caixa no index 1 de propListMenus com os subMenus:
   * 0: {id: 11, group_menu: 5, label: "Contas Financeira", route: "cash/financial-accounts", action: "/list", …}
   * 1: {id: 12, group_menu: 5, label: "Formas de Pagamento", route: "cash/payment-methods", action: "/list", …}
   *
   * Note que os submenus 0 e 1 de Produtos serão substituídos pelos submenus 0 e 1 de Caixa, dentro do Scope usersHasSubMenus[],
   * fazendo com que os valores dos items 0 e 1 do submenu de produtos não sejam enviados.
   *
   * usersHasSubMenus é enviado apenas ao submit. Os campos são exibidos normalmente.
   *
   * A controlIndexMain para menus principais que não tem submenu e a controlIndexMainWithSubItem menus principais que tem submenu
   * impedem que sejam criados posições vazias nos Scope usersHasMainMenus e usersHasMainSubMenus.
   *
   */
  let controlIndexMain = 0;
  let controlIndexMainWithSubItem = 0;
  let controlIndexSubItem = 0;

  return (
    <ContainerPermissions>
      <h1>Permissões</h1>
      {(propListMenus.length < 1 && (
        <div>Nenhuma permissão encontrada!</div>
      )) || (
        <div>
          {propListMenus.map((item, index) => (
            <details key={index.toString()}>
              <summary>{item.label}</summary>
              {(propListMenus[index].submenus!.length > 0 && (
                <>
                  <Scope
                    path={`usersHasMainSubMenus[${controlIndexMainWithSubItem++}]`}
                  >
                    <Input
                      type="hidden"
                      name="menus_id"
                      value={item.id}
                      isHidden
                    />
                    <Input type="hidden" name="read" value="true" isHidden />
                  </Scope>
                  {propListMenus[index].submenus!.map((subItem, subIndex) => (
                    <Scope
                      key={subIndex.toString()}
                      path={`usersHasSubMenus[${controlIndexSubItem++}]`}
                    >
                      <ContentPermissions>
                        <strong>{subItem.label}</strong>
                        <Input
                          type="hidden"
                          name="usersHasMainSubMenus_id"
                          value={item.id}
                          isHidden
                        />
                        <Input
                          type="hidden"
                          name="menus_id"
                          value={subItem.id}
                          isHidden
                        />
                        <Checkbox
                          name="read"
                          propChecked={subItem.permission!.read}
                        >
                          Visualizar
                        </Checkbox>

                        <Checkbox
                          name="create"
                          propChecked={subItem.permission!.create}
                        >
                          Incluir
                        </Checkbox>

                        <Checkbox
                          name="update"
                          propChecked={subItem.permission!.update}
                        >
                          Alterar
                        </Checkbox>

                        <Checkbox
                          name="delete"
                          propChecked={subItem.permission!.delete}
                        >
                          Excluir
                        </Checkbox>
                      </ContentPermissions>
                    </Scope>
                  ))}
                </>
              )) || (
                <Scope path={`usersHasMainMenus[${controlIndexMain++}]`}>
                  <ContentPermissions>
                    <strong>{item.label}</strong>
                    <Input
                      type="hidden"
                      name="menus_id"
                      value={item.id}
                      isHidden
                    />
                    <Checkbox name="read" propChecked={item.permission!.read}>
                      Visualizar
                    </Checkbox>

                    <Checkbox
                      name="create"
                      propChecked={item.permission!.create}
                    >
                      Incluir
                    </Checkbox>

                    <Checkbox
                      name="update"
                      propChecked={item.permission!.update}
                    >
                      Alterar
                    </Checkbox>

                    <Checkbox
                      name="delete"
                      propChecked={item.permission!.delete}
                    >
                      Excluir
                    </Checkbox>
                  </ContentPermissions>
                </Scope>
              )}
            </details>
          ))}
        </div>
      )}
    </ContainerPermissions>
  );
};

export default MenuPermissions;
