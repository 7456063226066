import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

interface ComplexCreateAndUpdateTemplateProps {
  title: string;
}

const ComplexCreateAndUpdateTemplate: React.FC<ComplexCreateAndUpdateTemplateProps> = ({
  title,
  children,
}) => {
  return (
    <Col
      md={12}
      className="d-flex flex-column h-100
      overflow-auto bg-branco-puro-5bits shadow p-2"
    >
      <Row noGutters>
        <Col md={12}>
          <h1>{title}</h1>
        </Col>
      </Row>
      <Row noGutters className="d-block h-75 w-100">
        <Col className="h-100 p-2">{children}</Col>
      </Row>
    </Col>
  );
};

export default ComplexCreateAndUpdateTemplate;
