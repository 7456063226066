/* eslint-disable camelcase */
import React, { useState, useEffect, useCallback } from 'react';
// import { Link } from 'react-router-dom';

import Table from 'react-bootstrap/Table';

import ListingTemplate from '../../../components/Template/ListingTemplate';
import Loader from '../../../components/Loader';

import Tooltip from '../../../components/Tooltip';
import api from '../../../services/api';
import convertDateWithHoursToBr from '../../../utils/convertDateWithHoursToBr';

import { useToast } from '../../../context/ToastContext';

import { IPermission } from '../../../interfaces/IPermission';

interface PermissionDTO {
  permissions: IPermission;
}

interface CancelSaleFormDataDTO {
  id: number;
  client_id: string;
  employee_id: number;
  amount: string;
  discount: string;
  addition: string;
  dependent_name: string;
  observation: string;
  is_canceled: boolean;
  canceled_date: string;
  canceled_user: string;
  created_at: string;
  updated_at: string;
  client: {
    id: number;
    name: string;
  };
  employee: {
    id: number;
    name: string;
    nickname: string;
  };
}

/**
 * [X] Adicionar botão Editar
 * [ ] Corrigir a quebra de linha para texto muito grande
 */

const ReadsCancelSale: React.FC<PermissionDTO> = ({ permissions }) => {
  const [loading, setLoading] = useState(false);
  const [listCancelSale, setListCancelSale] = useState<CancelSaleFormDataDTO[]>(
    [],
  );
  const [
    listCancelSalePaginatedItems,
    setListCancelSalePaginatedItems,
  ] = useState<CancelSaleFormDataDTO[]>([]);
  const { addToast } = useToast();

  // const { permission } = useAuth();
  // let CancelSalePermissions: IPermission | undefined = {};
  // if (permission.length > 0) {
  //   CancelSalePermissions = permission.find((item: any) => item.menus_id === 2); // menus_id CancelSale == 2
  // }

  useEffect(() => {
    try {
      const getListCancelSale = async (): Promise<void> => {
        try {
          setLoading(true);
          const response = await api.get('sales/cancel');
          setListCancelSale(response.data);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          // eslint-disable-next-line no-shadow
          if (error.response) {
            const { data } = error.response; // Error vindo do back está em data dentro de response
            addToast({
              type: 'error',
              title: data.message,
            });
            return;
          }

          addToast({
            type: 'error',
            title: 'Ocorreu um erro interno',
          });
        }
      };
      getListCancelSale();
    } catch (error) {
      setLoading(false);
      // eslint-disable-next-line no-shadow
      if (error.response.data) {
        const { data } = error.response; // Error vindo do back está em data dentro de response
        addToast({
          type: 'error',
          title: data.message,
        });
        return;
      }

      addToast({
        type: 'error',
        title: 'Ocorreu um erro interno',
      });
    }
  }, [addToast]);

  const handleListCancelSalePaginatedItems = useCallback(newList => {
    setListCancelSalePaginatedItems(newList);
  }, []);

  return (
    <ListingTemplate
      title="Vendas Canceladas"
      module="sales/cancel"
      listItemsProps={listCancelSale}
      handleListItemsPaginatedItems={handleListCancelSalePaginatedItems}
      displaySearchArea={false}
      placeHolderProp="Cód. da venda"
      permissions={permissions}
    >
      {(loading && <Loader />) || (
        <>
          <Table striped bordered hover size="sm" responsive="md">
            <thead className="text-center text-primary text-uppercase">
              <tr>
                <th className="font-weight-normal py-3" scope="col">
                  ID
                </th>
                <th className="font-weight-normal py-3" scope="col">
                  Cliente
                </th>
                <th className="font-weight-normal py-3" scope="col">
                  Dependente
                </th>
                <th className="font-weight-normal py-3" scope="col">
                  Total
                </th>
                <th className="font-weight-normal py-3" scope="col">
                  Vendedor
                </th>
                <th className="font-weight-normal py-3" scope="col">
                  Data Cancelamento
                </th>
                {/* <th className="font-weight-normal py-3" scope="col">
              Ações
            </th> */}
              </tr>
            </thead>
            <tbody className="text-center">
              {listCancelSalePaginatedItems.map((item, index) => (
                <tr key={index.toString()}>
                  <th scope="row">{item.id}</th>
                  <td className="text-left">{item.client.name}</td>
                  <td className="text-left">{item.dependent_name}</td>
                  <td>{item.amount}</td>
                  <td className="text-left">{item.employee.nickname}</td>
                  <td>
                    <Tooltip
                      style={{
                        textTransform: 'capitalize',
                      }}
                      className="position-relative text-primary"
                      classNameSpan="bg-primary border-primary"
                      colorBefore="#0F4C82"
                      title={`Cancelado por: ${item.canceled_user}`}
                    >
                      <div
                        style={{
                          cursor: 'pointer',
                        }}
                      >
                        {convertDateWithHoursToBr(item.canceled_date)}
                      </div>
                    </Tooltip>
                  </td>
                  {/* <td>
                {(permissions.update && (
                  <Link to={`/CancelSale/read/${item.id}`}>Alterar</Link>
                )) || <span className="text-cinza-51-5bits">Alterar</span>}
              </td> */}
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
    </ListingTemplate>
  );
};

export default ReadsCancelSale;
