import React from 'react';
import { Switch } from 'react-router-dom';

import PrivateRoute from './PrivateRoutes';

import { useAuth } from '../context/AuthContext';
import findPermission from '../utils/findPermission';

import NotAuthorizedPage from '../pages/NotAuthorizedPage';

import ImportProducts from '../pages/Products/importProducts';
import EntradaNFe from '../pages/Products/inputNFe';
import EntradaProdutos from '../pages/Products/inputProducts';
import EntradaEstoqueInicial from '../pages/Products/inputInitialStock';
import CreateProducts from '../pages/Products/createProducts';
import ListProducts from '../pages/Products/listProducts';
import UpdateProduct from '../pages/Products/updateProduct';

const ProductsRoutes: React.FC = () => {
  const { permission } = useAuth();
  const productsPermissions = findPermission('products', permission); // menus_id products = 5
  const productsImportPermissions = findPermission(
    'products/import',
    permission,
  ); // menus_id products/import = 6
  const productsInputPermissions = findPermission('products/input', permission); // menus_id products/input = 7
  const productsNfePermissions = findPermission('products/nfe', permission); // menus_id products = 8
  const productsStockStartPermissions = findPermission(
    'products/stock-start',
    permission,
  ); // menus_id products/stock-start = 9

  return (
    <Switch>
      <PrivateRoute
        path="*/import/list"
        render={props => (
          <ImportProducts {...props} permissions={productsImportPermissions} />
        )}
      />
      <PrivateRoute
        path="*/nfe/list"
        render={props => (
          <EntradaNFe {...props} permissions={productsNfePermissions} />
        )}
      />
      <PrivateRoute
        path="*/input/list"
        render={props => (
          <EntradaProdutos {...props} permissions={productsInputPermissions} />
        )}
      />
      <PrivateRoute
        path="*/products/stock-start/list"
        render={props => (
          <EntradaEstoqueInicial
            {...props}
            permissions={productsStockStartPermissions}
          />
        )}
      />

      <PrivateRoute
        path="*/create"
        component={
          Object.keys(productsPermissions).length !== 0 &&
          productsPermissions.create
            ? CreateProducts
            : NotAuthorizedPage
        }
      />
      <PrivateRoute
        path="*/list"
        render={props => (
          <ListProducts {...props} permissions={productsPermissions} />
        )}
      />
      <PrivateRoute
        path="*/read/:id"
        component={
          Object.keys(productsPermissions).length !== 0 &&
          productsPermissions.update
            ? UpdateProduct
            : NotAuthorizedPage
        }
      />
    </Switch>
  );
};

export default ProductsRoutes;
