/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { createContext, useContext, useCallback, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import ToastContainer from '../components/ToastContainer';
/*
Interface para as messages. Segue o mesmo tipo da
criada em ToastContext porém aqui tem o id pois como
será um array para um mesmo tipo de elemento é sempre
bom ter um identificador único para ele. O id neste caso
será usada na key no map.
Esta tipagem será reutilizada no componente ToastContainer
por isso está sendo exportada.
*/
export interface ToastMessage {
  id: string;
  type?: 'success' | 'error' | 'info';
  title: string;
  description?: string;
}

/*
Interface com as tipagens, neste caso
serão os dois métodos para add e remove o
toast
*/
interface ToastContextData {
  // eslint-disable-next-line no-unused-vars
  addToast(message: Omit<ToastMessage, 'id'>): void;
  // eslint-disable-next-line no-unused-vars
  removeToast(id: string): void;
}

const ToastContext = createContext<ToastContextData>({} as ToastContextData);

const ToastProvider: React.FC = ({ children }) => {
  /*
  O state começão como um array vazio, é array porque
  poderá ter várias mensagens de toast exibidas ao mesmo tempo.
  O tipo do state será um array de ToastMessage
  O messages tem que ser passado no ToastContainer em return
  pois é ele quem mostra as mensagens.
  */
  const [messages, setMessages] = useState<ToastMessage[]>([]);

  /*
  A tipagem de message são todos os parâmetros da interface
  ToastMessage menos o id. Para omitir o id (pois este será criado
  pela aplicação e não enviado pelo usuário), usa o helper Omit do typescript
  */
  const addToast = useCallback(
    ({ type, title, description }: Omit<ToastMessage, 'id'>) => {
      const id = uuidv4();

      const toast = {
        id,
        type,
        title,
        description,
      };

      /*
    Aqui recebe todas as mensagens que já existem e adiciona as novas
    */
      // eslint-disable-next-line no-unused-vars
      setMessages(oldMessages => [...messages, toast]);
    },
    /**
     * Não colocar a messages para serem supervisionadas, senão
     * aparecem mensagens em um loop
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  /*
  Aqui remove o toast informado pelo id.
  Faz um setMessages com o estado atual do state sem o toast
  do id informado. Para isso usa o filter que retorna
  todas as message menos a do id informado.
  */
  const removeToast = useCallback((id: string) => {
    setMessages(state => state.filter(message => message.id !== id));
  }, []);

  return (
    <ToastContext.Provider value={{ addToast, removeToast }}>
      {children}
      <ToastContainer messages={messages} />
    </ToastContext.Provider>
  );
};

/*
Aqui cria o hook que retorna os dados contidos dentro
contexto de toast por isso tem que usar o useContext do react.
*/

function useToast(): ToastContextData {
  const context = useContext(ToastContext);

  /*
  Irá disparar um error se o desenvolvedor não colocar o
  ToastContext.Provider no App.tsx, pois se não colocar quer dizar que
  o contexto não existe:
   <ToastContext.Provider>
        <SignIn />
    </ToastContext.Provider>
  Se encontrar retorna o context.
  */
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { ToastProvider, useToast };
