/* eslint-disable camelcase */
import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';

import Table from 'react-bootstrap/Table';

import ListingTemplate from '../../components/Template/ListingTemplate';
import Loader from '../../components/Loader';
// import convertNumberFloatToBr from '../../utils/convertNumberFloatToBr';

import api from '../../services/api';

import { useToast } from '../../context/ToastContext';

import { IPermission } from '../../interfaces/IPermission';

interface PermissionDTO {
  permissions: IPermission;
}

interface ProductFormDataDTO {
  id: string;
  name: string;
  price: string;
  size: string;
  color: string;
  stock: string;
  min_stock: string;
  is_active: boolean;
  barcode: string;
}

/**
 * [X] Adicionar botão Editar
 * [ ] Corrigir a quebra de linha para texto muito grande
 */

const ReadsProducts: React.FC<PermissionDTO> = ({ permissions }) => {
  const [loading, setLoading] = useState(false);
  const [listProducts, setListProducts] = useState<ProductFormDataDTO[]>([]);
  const [listProductsPaginatedItems, setListProductsPaginatedItems] = useState<
    any[]
  >([]);
  const { addToast } = useToast();

  useEffect(() => {
    try {
      const getListProducts = async (): Promise<void> => {
        try {
          setLoading(true);
          const response = await api.get('products');
          setListProducts(response.data);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          // eslint-disable-next-line no-shadow
          if (error.response) {
            const { data } = error.response; // Error vindo do back está em data dentro de response
            addToast({
              type: 'error',
              title: data.message,
            });
            return;
          }
          addToast({
            type: 'error',
            title: 'Ocorreu um erro interno',
          });
        }
      };
      getListProducts();
    } catch (error) {
      setLoading(false);
      // eslint-disable-next-line no-shadow
      if (error.response.data) {
        const { data } = error.response; // Error vindo do back está em data dentro de response
        addToast({
          type: 'error',
          title: data.message,
        });
        return;
      }

      addToast({
        type: 'error',
        title: 'Ocorreu um erro interno',
      });
    }
  }, [addToast]);

  const handleListProductsPaginatedItems = useCallback(newList => {
    setListProductsPaginatedItems(newList);
  }, []);

  return (
    <ListingTemplate
      title="Produtos"
      module="products"
      listItemsProps={listProducts}
      handleListItemsPaginatedItems={handleListProductsPaginatedItems}
      placeHolderProp="Cód. do Produto"
      permissions={permissions}
    >
      {(loading && <Loader />) || (
        <>
          <div className="text-primary text-uppercase font-weight-bold">
            Produtos Cadastrados:
            <span className="text-cinza-51-5bits text-uppercase ml-2 font-weight-bold">
              {listProducts.length}
            </span>
          </div>
          <Table striped bordered hover size="sm" responsive="md">
            <thead className="text-center text-primary text-uppercase">
              <tr>
                <th className="font-weight-normal py-3" scope="col">
                  ID
                </th>
                <th className="font-weight-normal py-3" scope="col">
                  Nome do produto
                </th>
                <th className="font-weight-normal py-3" scope="col">
                  Preço
                </th>
                <th className="font-weight-normal py-3" scope="col">
                  Tamanho
                </th>
                <th className="font-weight-normal py-3" scope="col">
                  Cor
                </th>
                <th className="font-weight-normal py-3" scope="col">
                  Estoque
                </th>
                <th className="font-weight-normal py-3" scope="col">
                  Status
                </th>
                <th className="font-weight-normal py-3" scope="col">
                  Ações
                </th>
              </tr>
            </thead>
            <tbody className="text-center">
              {listProductsPaginatedItems.map((item, index) => (
                <tr key={index.toString()}>
                  <th scope="row">{item.id}</th>
                  <td className="text-left">{item.name}</td>
                  <td className="text-right pr-3">{item.price}</td>
                  <td>{item.size}</td>
                  <td>{item.color}</td>
                  <td>{item.stock}</td>
                  <td>{(item.is_active === true && ' Ativo') || ' Inativo'}</td>
                  <td>
                    {(permissions.update && (
                      <Link to={`/products/read/${item.id}`}>Editar</Link>
                    )) || <span className="text-cinza-51-5bits">Editar</span>}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
    </ListingTemplate>
  );
};

export default ReadsProducts;
