/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import React, { useCallback, useRef, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { FiUser, FiLock } from 'react-icons/fi';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ComplexCreateAndUpdateTemplate from '../../components/Template/ComplexCreateAndUpdateTemplate';

import { useAuth } from '../../context/AuthContext';

import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';
import {
  handleListMenus,
  joinMenusWithPermission,
} from '../../utils/handleListMenus';
import { useToast } from '../../context/ToastContext';

import Input from '../../components/Input';
import Checkbox from '../../components/Checkbox';
import Button from '../../components/Button';
import MenuPermissions from '../../components/MenuPermissions';

interface MenuDTO {
  id: number;
  group_menu: number;
  label: string;
  route: string;
  action: string | null;
  is_submenu: boolean;
  submenus: Array<MenuDTO>;
  permission: {
    menus_id?: number;
    action?: string | null;
    read: boolean;
    create: boolean;
    update: boolean;
    delete: boolean;
    // cancel: boolean;
  };
}

interface UserFormDataDTO {
  id: number;
  name: string;
  nickname: string;
  username: string;
  is_adminText?: string;
  is_activeText?: string;
  is_admin: boolean;
  is_active: boolean;
  password?: string;
  usersHasMenus?: Array<Object>;
  usersHasMainMenus?: Array<Object>;
  usersHasMainSubMenus?: Array<Object>;
  usersHasSubMenus?: Array<Object>;
}

const UpdateUser: React.FC<UserFormDataDTO> = () => {
  const userLogged: any = useAuth().user;
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { id }: any = useParams();
  const [user, setUser] = useState<UserFormDataDTO>({} as UserFormDataDTO);
  const [listMenus, setListMenus] = useState<MenuDTO[]>([]);
  const [isVisiblePassword, setIsVisiblePassword] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  const sessionStorageUser: any = sessionStorage.getItem('@Stock5b:user');
  const currentUserLogged = JSON.parse(sessionStorageUser);

  useEffect(() => {
    const getUser = async (): Promise<void> => {
      try {
        const response = await api.get(`users/${id}`);
        const responseMenus = await api.get('menus');

        setUser(response.data);

        setListMenus(
          joinMenusWithPermission(
            responseMenus.data,
            response.data.usersHasMenus,
          ),
        );
      } catch (error) {
        // eslint-disable-next-line no-shadow
        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: data.message,
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Ocorreu um erro interno',
        });
      }
    };
    getUser();
  }, [addToast, id, user.name]);

  // handleSubmit
  const handleSubmit = useCallback(
    async (dataForm: UserFormDataDTO, { reset }) => {
      try {
        setDisableButton(true);
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          nickname: Yup.string().required('Cognome obrigatório'),
          password: Yup.string().min(6, 'No mínimo 6 dígitos'),
        });

        await schema.validate(dataForm, {
          abortEarly: false,
        });

        /**
         * handleListMenus monta o array de objetos com os
         * menus e permissões
         */
        const usersHasMenus = handleListMenus(dataForm);

        /**
         * Cria um novo array com o is_admin com o
         * valor em booleano que será salvo no bd
         */
        let isAdminBoolean;
        let isActiveBoolean;

        if (currentUserLogged.is_admin) {
          isAdminBoolean = dataForm.is_adminText === 'true';
          isActiveBoolean = dataForm.is_activeText === 'true';
        } else {
          isActiveBoolean = user.is_admin;
          isActiveBoolean = user.is_active;
        }

        const newData = {
          ...dataForm,
          is_admin: isAdminBoolean,
          is_active: isActiveBoolean,
          usersHasMenus,
        };

        /**
         * Remove os campos e as listas que não são enviadas para o banco de dados
         */
        delete newData.is_adminText;
        delete newData.is_activeText;
        delete newData.usersHasMainMenus;
        delete newData.usersHasMainSubMenus;
        delete newData.usersHasSubMenus;

        await api.put('users', newData);

        addToast({
          type: 'success',
          title: 'Alteração realizada com sucesso',
        });

        setDisableButton(false);

        if (!userLogged.is_admin) {
          reset();
          history.push('/home');
        }
      } catch (error) {
        setDisableButton(false);

        // Verifica se o error é do Yup ou seja dos campos
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);

          return;
        }
        // eslint-disable-next-line no-shadow
        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: 'Erro na alteração do cadastro',
            description: data.message,
          });
          formRef.current?.getFieldRef(data.field).focus(); // Foca o campo que deu erro de acordo com o field retornado do back

          return;
        }
        addToast({
          type: 'error',
          title: 'Ocorreu um erro interno',
        });
      }
    },
    [addToast, history, currentUserLogged.is_admin, user, userLogged.is_admin],
  );
  // Fim handleSubmit

  const handleVisiblePassword = useCallback(() => {
    setIsVisiblePassword(!isVisiblePassword);
  }, [isVisiblePassword]);

  return (
    <ComplexCreateAndUpdateTemplate title="Atualização de Usuário">
      <Form
        ref={formRef}
        initialData={user}
        onSubmit={handleSubmit}
        className="h-100"
      >
        <Row noGutters className="w-100 h-100">
          <Col md={6} className=" h-100 overflow-auto">
            <Input type="hidden" name="id" isHidden />
            <Input
              type="text"
              name="name"
              label="Nome Completo"
              icon={FiUser}
              placeholder="Nome Completo"
              maxLength={45}
              className="mb-2"
            />

            <Input
              type="text"
              name="nickname"
              label="Cognome"
              icon={FiUser}
              placeholder="Cognome"
              maxLength={15}
              className="mb-2"
            />
            {currentUserLogged.is_admin === true && (
              <>
                <Checkbox
                  name="is_adminText"
                  className="mb-2"
                  propChecked={user?.is_admin}
                >
                  Nível Administrador
                </Checkbox>

                <Checkbox
                  name="is_activeText"
                  className="mb-2"
                  propChecked={user?.is_active}
                >
                  Usuário Ativo
                </Checkbox>
              </>
            )}

            {(isVisiblePassword && (
              <Input
                type="password"
                name="password"
                label="Senha"
                icon={FiLock}
                placeholder="Senha"
                maxLength={60}
                autoComplete="new-password"
              />
            )) || (
              <Button onClick={handleVisiblePassword}>Alterar Senha</Button>
            )}
          </Col>
          <Col md={6} className="overflow-auto h-100 pl-3">
            {listMenus.length > 0 && (
              <MenuPermissions propListMenus={listMenus} />
            )}
          </Col>
          <Col md={6} className="mt-5 ml-auto mr-auto">
            <Button name="submit" type="submit" disabled={disableButton}>
              Salvar
            </Button>
          </Col>
        </Row>
      </Form>
    </ComplexCreateAndUpdateTemplate>
  );
};

export default UpdateUser;
