/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useState, useEffect, useCallback } from 'react';
import ReactSelect, {
  OptionTypeBase,
  Props as SelectProps,
} from 'react-select';
import { useField } from '@unform/core';
import InputGroup from 'react-bootstrap/InputGroup';
// Importa todas as propriedades que um ícone pode ter
import { IconBaseProps } from 'react-icons';
// Icone de erro que irá no lugar da menssagem de erro dentro do container
import { FiAlertCircle } from 'react-icons/fi';
import api from '../../services/api';
import { useToast } from '../../context/ToastContext';

// O error é um container que contém a imagem e o tooltip com a mensagem ao passar o mouse
import { Container, ErrorStyle } from './styles';

interface Props extends SelectProps<OptionTypeBase> {
  name: string;
  module: string;
  label?: string;
  fontSize?: string;
  sizeIcon?: number;
  className?: string;
  classNameInputGroupTextAndFormControl?: string;
  icon?: React.ComponentType<IconBaseProps>;
}

const SelectSimple: React.FC<Props> = ({
  name,
  module,
  label,
  fontSize,
  sizeIcon = 20,
  className,
  classNameInputGroupTextAndFormControl = '',
  icon: Icon,
  ...rest
}) => {
  const selectRef = useRef(null);

  const {
    fieldName,
    defaultValue,
    error,
    clearError,
    registerField,
  } = useField(name);

  const { addToast } = useToast();
  const [listsItems, setListItems] = useState<any[]>([]);

  /*
  Armazena no state se o campo está com focus ou não.
  O isFilled armazena se o campo está preenchido ou não.
  */
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref: any) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value.map((option: OptionTypeBase) => option.value);
        }
        if (!ref.state.value) {
          return '';
        }
        return ref.state.value.value;
      },
      setValue: (ref: any, value: any) => {
        ref.select.setValue(value);
      },
    });
  }, [fieldName, registerField, rest.isMulti]);
  // Carrega Lista de contas financeiras para o select

  useEffect(() => {
    try {
      const getListItems = async (): Promise<void> => {
        try {
          const response = await api.get(module);

          const options: any = [];
          response.data.map((item: any, index: any) => {
            options[index] = {
              value: item.id,
              label: item.name,
            };
            // Retorno da Arrow function de map
            return null;
          });

          setListItems(options);
        } catch (erro) {
          // eslint-disable-next-line no-shadow
          if (erro.response) {
            const { data } = erro.response; // Error vindo do back está em data dentro de response
            addToast({
              type: 'error',
              title: data.message,
            });
            return;
          }

          addToast({
            type: 'error',
            title: 'Ocorreu um erro interno',
          });
        }
      };
      getListItems();
    } catch (erro) {
      // eslint-disable-next-line no-shadow
      if (erro.response.data) {
        const { data } = erro.response; // Error vindo do back está em data dentro de response
        addToast({
          type: 'error',
          title: data.message,
        });
        return;
      }

      addToast({
        type: 'error',
        title: 'Ocorreu um erro interno',
      });
    }
  }, [addToast, module]);
  // Fim Carrega Lista de contas financeiras para o select

  const handleInputFocus = useCallback((): void => {
    setIsFocused(true);
    // Função do unform para limpar os erros do campo
    clearError();
  }, [clearError]);

  const handleInputBlur = useCallback((): void => {
    setIsFocused(false);

    const valueSelect: any = selectRef.current;
    setIsFilled(!!valueSelect.state.value);
  }, []);

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      minHeight: 1,
      width: 200,
      letterSpacing: '0,09em',
      fontSize: `${fontSize}`,
    }),
    menu: (provided: any) => ({
      ...provided,
      width: 'auto',
      minWidth: 300,
      letterSpacing: '0,09em',
      fontSize: `${fontSize}`,
    }),
  };

  return (
    <Container
      isErrored={!!error}
      isFilled={isFilled}
      isFocused={isFocused}
      className={className}
    >
      {label && (
        <label
          htmlFor={name}
          style={{
            textTransform: 'uppercase',
            fontSize: `${fontSize}`,
            letterSpacing: '0,09em',
          }}
        >
          {label}
        </label>
      )}
      <InputGroup className="pt-0">
        {Icon && (
          <InputGroup.Prepend>
            <InputGroup.Text
              id="basic-addon1"
              className={classNameInputGroupTextAndFormControl}
            >
              <Icon size={sizeIcon} />
            </InputGroup.Text>
          </InputGroup.Prepend>
        )}
        <ReactSelect
          style={{ letterSpacing: '0,09em' }}
          defaultValue={
            defaultValue &&
            listsItems.find(option => option.value === defaultValue)
          }
          ref={selectRef}
          options={listsItems}
          className="d-flex flex-fill border border-primary rounded text-uppercase"
          classNamePrefix="react-select"
          aria-describedby="basic-addon1"
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          menuPlacement="auto"
          styles={customStyles}
          maxMenuHeight={150}
          menuPosition="fixed"
          loadingMessage={() => 'Carregando...'}
          {...rest}
        />
        {error && (
          <ErrorStyle title={error}>
            <FiAlertCircle size={20} />
          </ErrorStyle>
        )}
      </InputGroup>
    </Container>
  );
};

export default SelectSimple;
