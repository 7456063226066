import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 100%;
  padding: 10px;
  border-radius: 100px 100px 100px 100px;
  border: none;
  background: transparent;

  label {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin: 0;
    cursor: pointer;
  }
`;
