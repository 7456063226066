/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/require-default-props */
import React, { useRef, useState, useEffect, useCallback } from 'react';
import ReactDatePicker, {
  ReactDatePickerProps,
  registerLocale,
  setDefaultLocale,
} from 'react-datepicker';
import pt from 'date-fns/locale/pt';

import { useField } from '@unform/core';

import 'react-datepicker/dist/react-datepicker.css';

import { FiAlertCircle } from 'react-icons/fi';

import { IconBaseProps } from 'react-icons';

import InputGroup from 'react-bootstrap/InputGroup';

import { Container, ErrorStyle } from './styles';

interface Props extends Omit<ReactDatePickerProps, 'onChange'> {
  name: string;
  label?: string;
  className?: string;
  classNameInputGroupTextAndFormControl?: string;
  inputClassName?: string;
  wrapperClassName?: string;
  icon?: React.ComponentType<IconBaseProps>;
}

registerLocale('pt', pt);
setDefaultLocale('pt');
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export default function DatePicker({
  name,
  label,
  className,
  classNameInputGroupTextAndFormControl = '',
  inputClassName = '',
  wrapperClassName = '',
  icon: Icon,
  ...rest
}: Props) {
  const datepickerRef = useRef(null);
  // eslint-disable-next-line no-unused-vars
  const {
    fieldName,
    registerField,
    defaultValue,
    error,
    clearError,
  } = useField(name);
  // eslint-disable-next-line no-unused-vars
  const [isFocused, setIsFocused] = useState(false);

  const [date, setDate] = useState(defaultValue || null);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: datepickerRef.current,
      path: 'props.selected',
      setValue: (ref: any, value) => {
        setDate(value);
      },
      clearValue: (ref: any) => {
        ref.clear();
      },
    });
  }, [fieldName, registerField]);

  const handleInputFocus = useCallback((): void => {
    setIsFocused(true);
    // Função do unform para limpar os erros do campo
    clearError();
  }, [clearError]);

  // const handleInputBlur = useCallback((): void => {
  //   setIsFocused(false);

  //   setIsFilled(!!datepickerRef.current?.value);
  // }, []);

  return (
    <Container isErrored={!!error} isFocused={isFocused} className={className}>
      {label && (
        <label htmlFor={name} style={{ textTransform: 'uppercase' }}>
          {label}
        </label>
      )}
      <InputGroup>
        {Icon && (
          <InputGroup.Prepend>
            <InputGroup.Text
              id="basic-addon1"
              className={classNameInputGroupTextAndFormControl}
            >
              <Icon size={20} />
            </InputGroup.Text>
          </InputGroup.Prepend>
        )}
        <ReactDatePicker
          ref={datepickerRef}
          dateFormat="dd/MM/yyyy"
          selected={date}
          onChange={setDate}
          onFocus={handleInputFocus}
          locale="pt"
          className={`form-control ${inputClassName}`}
          wrapperClassName={`${wrapperClassName}`}
          {...rest}
        />
        {error && (
          <ErrorStyle title={error}>
            <FiAlertCircle size={20} />
          </ErrorStyle>
        )}
      </InputGroup>
    </Container>
  );
}
