import React from 'react';
import { Switch } from 'react-router-dom';

import PrivateRoute from './PrivateRoutes';
import { useAuth } from '../context/AuthContext';
import findPermission from '../utils/findPermission';

import NotAuthorizedPage from '../pages/NotAuthorizedPage';
import CreateEmployees from '../pages/Employees/createEmployees';
import ListEmployees from '../pages/Employees/listEmployees';
import UpdateEmployee from '../pages/Employees/updateEmployee';

const EmployeesRoutes: React.FC = () => {
  const { permission } = useAuth();
  const employeePermissions = findPermission('employees', permission); // menus_id employee = 2

  return (
    <Switch>
      <PrivateRoute
        path="*/create"
        component={
          Object.keys(employeePermissions).length !== 0 &&
          employeePermissions.create
            ? CreateEmployees
            : NotAuthorizedPage
        }
      />
      <PrivateRoute
        path="*/list"
        render={props => (
          <ListEmployees {...props} permissions={employeePermissions} />
        )}
      />
      <PrivateRoute
        path="*/read/:id"
        component={
          Object.keys(employeePermissions).length !== 0 &&
          employeePermissions.update
            ? UpdateEmployee
            : NotAuthorizedPage
        }
      />
    </Switch>
  );
};

export default EmployeesRoutes;
