import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
    /*Scroll on firefox*/
    /* scrollbar-width: thin;
    scrollbar-color: #F7F7F7 orange;
    scrollbar-arrow-color: orange; */
  }

  /*Scroll - Works on Chrome, Edge, and Safari */
  /* *::-webkit-scrollbar {
    width: 12px;
  }

  *::-webkit-scrollbar-track {
    background: #F7F7F7;
  }
  *::-webkit-scrollbar-button{

  }
  *::-webkit-scrollbar-thumb {
    background-color: blue;
    border-radius: 20px;
    border: 3px solid #F7F7F7;
  } */

  /* Buttons */
/* ::-webkit-scrollbar-button:single-button {
  background-color: #bbbbbb;
  display: block;
  border-style: solid;
  height: 13px;
  width: 16px;
} */
/* Up */
/* ::-webkit-scrollbar-button:single-button:vertical:decrement {
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent #555555 transparent;
}

::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
  border-color: transparent transparent #777777 transparent;
} */
/* Down */
/* ::-webkit-scrollbar-button:single-button:vertical:increment {
  border-width: 8px 8px 0 8px;
  border-color: #555555 transparent transparent transparent;
}

::-webkit-scrollbar-button:vertical:single-button:increment:hover {
  border-color: #777777 transparent transparent transparent;
} */
/*End Scroll - Works on Chrome, Edge, and Safari */

  body {
    background: #F7F7F7;
    color: #0F4C82;
    -webkit-font-smoothing: antialiased;
  }

  a {
    color: #0F4C82;
  }

  body, input, button, h1 {
    font-family: 'Red Hat Display', Verdana, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
  }

  h1 {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    text-align: center;
  }

  table {
    text-transform: uppercase;
  }


  @media only screen and (max-width: 767px) {
    body {
      font-family: 'Red Hat Display', Verdana, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      text-transform: uppercase;
    }

    h1 {
      font-family: 'Red Hat Display', Verdana, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 21px;
      letter-spacing: 0.02em;
      text-transform: uppercase;

      color: #0F4C82;
    }
  }


  button {
    cursor: pointer;
  }

  :root {

    --azul-5bits: #0F4C82;
    --laranja-5bits: #FF8441;
    --cinza-51-5bits: #515151;
    --branco-gelo-5bits: #F7F7F7;
    --branco-puro-5bits: #FFFFFF;

    --input-text-color-5bits: #888888;

    --header-bg-color: #232129;
    --header-border-color: #232129;


    --input-bg-color: #232129;
    --input-border-color: #232129;
    --input-text-color: #fff;
    --input-placeholder-color: #666360;

    --on-focus-input-border-color: var(--laranja-5bits);
    --on-focus-input-text-color: var(--laranja-5bits);

    --on-filled-input-text-color: var(--laranja-5bits);

    --on-errored-input-border-color: #c53030;


    --on-errored-message-bg-color: #c53030;
    --on-errored-message-border-color: #c53030;
    --on-errored-message-text-color: #fff;

    --on-errored-icon-color: #c53030;


    --button-bg-color: var(--laranja-5bits);
    --button-text-color: #F7F7F7;


    --buttonlink-bg-color: var(--laranja-5bits);
    --buttonlink-text-color: #F7F7F7;
    --size-buttonlink-height: 56px;
    --size-buttonlink-width: 200px;

    --checkbox-bg-color: #232129;
    --checkbox-text-color: #666360;
    --before-checkbox-border-color: #666360;
    --ischecked-checkbox-text-color: var(--laranja-5bits);
    --ischecked-before-checkbox-border-color: var(--laranja-5bits);
    --ischecked-after-checkbox-border-color: var(--laranja-5bits);

    --message-toast-info-bg-color: #ebf8ff;
    --message-toast-info-text-color: #0F4C82;

    --message-toast-success-bg-color: #e6fffa;
    --message-toast-success-text-color: #2e656a;

    --message-toast-error-bg-color: #fddede;
    --message-toast-error-text-color: #c53030;


    --message-tooltip-span-bg-color: var(--laranja-5bits);
    --message-tooltip-span-text-color: #F7F7F7;
    --message-tooltip-span-before-border-color: var(--laranja-5bits);


    --modal-bg-color: #666360;

    --pages-link-text-color: #fff;

    --pages-listusers-div-border-color: var(--laranja-5bits);
  }

`;
