import React, { useState, useCallback, useEffect, useMemo } from 'react';

import Col from 'react-bootstrap/Col';
import Button from '../Button';

interface PaginationProps {
  listItems: Array<any>;
  // eslint-disable-next-line no-unused-vars
  onChangeListPaginatedItems: (newList: Array<any>) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  listItems,
  onChangeListPaginatedItems,
}) => {
  const [pageActual, setPageActual] = useState(1);
  const [buttons, setButtons] = useState<number[]>([]);

  const perPage = 50;

  const state = useMemo(
    () => ({
      perPage,
      totalPages: Math.ceil(listItems.length / perPage), // Arredonda para cima
      maxVisibleButtons: 5,
    }),
    [listItems.length],
  );

  useEffect(() => {
    setPageActual(1);
  }, [listItems]);

  // updateListPaginatedItems
  const updateListPaginatedItems = useCallback((): Array<any> => {
    const page = pageActual - 1;
    const start = page * state.perPage;
    const end = start + state.perPage;

    const paginatedItems = listItems.slice(start, end);
    return paginatedItems;
  }, [listItems, pageActual, state.perPage]);
  // Fim updateListPaginatedItems

  // calculateMaxVisibleButtonsPagination
  const calculateMaxVisibleButtonsPagination = useCallback(() => {
    const { maxVisibleButtons } = state;
    let maxLeft = pageActual - Math.floor(maxVisibleButtons / 2);
    let maxRight = pageActual + Math.floor(maxVisibleButtons / 2);

    if (maxLeft < 1) {
      maxLeft = 1;
      maxRight = maxVisibleButtons;
    }

    if (maxRight > state.totalPages) {
      maxLeft = state.totalPages - (maxVisibleButtons - 1);
      maxRight = state.totalPages;

      if (maxLeft < 1) maxLeft = 1;
    }

    return { maxLeft, maxRight };
  }, [pageActual, state]);
  // Fim calculateMaxVisibleButtonsPagination

  // updateButtonsPagination
  const updateButtonsPagination = useCallback((): Array<any> => {
    const newButtons = [];
    const { maxLeft, maxRight } = calculateMaxVisibleButtonsPagination();

    for (let page = maxLeft; page <= maxRight; page += 1) {
      newButtons.push(page);
    }
    return newButtons;
  }, [calculateMaxVisibleButtonsPagination]);
  // Fim updateButtonsPagination

  // update
  const update = useCallback(() => {
    setButtons(updateButtonsPagination());
  }, [updateButtonsPagination]);
  // Fim update

  // Next items
  const next = useCallback(() => {
    if (pageActual + 1 > state.totalPages) {
      setPageActual(pageActual);
    } else {
      setPageActual(pageActual + 1);
    }
    update();
  }, [pageActual, state.totalPages, update]);
  // End Next Items

  // Previous items
  const prev = useCallback(() => {
    if (pageActual - 1 < 1) {
      setPageActual(pageActual);
    } else {
      setPageActual(pageActual - 1);
    }
    update();
  }, [pageActual, update]);
  // End Previous Items

  // GoTo items
  const goTo = useCallback(
    (page: number) => {
      if (page < 1) {
        // eslint-disable-next-line no-param-reassign
        page = 1;
      }

      setPageActual(page);

      if (page > state.totalPages) {
        setPageActual(state.totalPages);
      }
      update();
    },
    [state.totalPages, update],
  );
  // End GoTo items

  useEffect(() => {
    setButtons(updateButtonsPagination());
    onChangeListPaginatedItems(updateListPaginatedItems());
  }, [
    listItems,
    pageActual,
    onChangeListPaginatedItems,
    updateListPaginatedItems,
    updateButtonsPagination,
  ]);

  return (
    <>
      <Col className="mt-4">
        <nav className="position-relative" aria-label="Page navigation">
          <ul className="pagination justify-content-center mb-0">
            <li className="page-item bg-branco-puro-5bits mx-1">
              <Button onClick={() => goTo(1)}>
                <span aria-hidden="true">&laquo;</span>
              </Button>
            </li>
            <li className="page-item bg-branco-puro-5bits mx-1">
              <Button onClick={prev}>
                <span aria-hidden="true">&lt;</span>
              </Button>
            </li>

            {buttons.map((item, index) => (
              <li
                key={index.toString()}
                className="page-item bg-branco-puro-5bits mx-2"
              >
                <Button
                  isPagination
                  isSelected={`${
                    +item === pageActual
                      ? 'bg-laranja-5bits border-laranja-5bits text-branco-puro-5bits'
                      : ''
                  }`}
                  onClick={() => goTo(item)}
                >
                  {item}
                </Button>
              </li>
            ))}

            <li className="page-item bg-branco-puro-5bits mx-1">
              <Button onClick={next}>
                <span aria-hidden="true">&gt;</span>
              </Button>
            </li>
            <li className="page-item bg-branco-puro-5bits mx-1">
              <Button onClick={() => goTo(state.totalPages)}>
                <span aria-hidden="true">&raquo;</span>
              </Button>
            </li>
          </ul>
        </nav>
      </Col>
    </>
  );
};

export default Pagination;
