import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;

  > a {
    margin-top: 30px;
    text-decoration: none;
  }
`;

export const Content = styled.div``;
