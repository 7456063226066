/* eslint-disable camelcase */
import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';

import ListingTemplate from '../../../components/Template/ListingTemplate';
import Loader from '../../../components/Loader';

import api from '../../../services/api';

import ModalConditional from './Modal/modalPrintConditional';
import ModalCloseConditional from './Modal/modalCloseConditional';

import { useToast } from '../../../context/ToastContext';
import convertDateToBr from '../../../utils/convertDateToBr';

import { IPermission } from '../../../interfaces/IPermission';

interface PermissionDTO {
  permissions: IPermission;
}

interface EmployeeFormDataDTO {
  id: number;
  name: string;
  nickname: string;
  dt_birth: string;
  is_active: boolean | string;
}

interface SalesHeaderFormDTO {
  id: number;
  payment_method_id: number;
  employee_id: number;
  client_name: string;
  dependent_name: string;
  phone: string;
  address: string;
  neighborhood: string;
  discount: number;
  observation: string;
  date_close: string;
  is_open: boolean;
  created_at: string;
  // conditional_body: Array<Product>;
}

const ReadsConditional: React.FC<PermissionDTO> = ({ permissions }) => {
  const [loading, setLoading] = useState(false);
  const [listSalesConditional, setListSalesConditional] = useState<
    SalesHeaderFormDTO[]
  >([]);
  const [listEmployees, setListEmployees] = useState<EmployeeFormDataDTO[]>([]);
  const [
    listSalesConditionalPaginatedItems,
    setListSalesConditionalPaginatedItems,
  ] = useState<SalesHeaderFormDTO[]>([]);

  const [loadModalPrint, setLoadModalPrint] = useState(false);
  const [loadModalCloseConditional, setLoadModalCloseConditional] = useState(
    false,
  );
  const [idConditionalModal, setIdConditionalModal] = useState(0);
  const { addToast } = useToast();

  const handleListSalesConditional = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);
      const response = await api.get('sales/conditional/open');
      setListSalesConditional(response.data);

      const responseEmployees = await api.get('employees');
      setListEmployees(responseEmployees.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      // eslint-disable-next-line no-shadow
      if (error.response) {
        const { data } = error.response; // Error vindo do back está em data dentro de response
        addToast({
          type: 'error',
          title: data.message,
        });
        return;
      }
      addToast({
        type: 'error',
        title: 'Ocorreu um erro interno',
      });
    }
  }, [addToast]);

  useEffect(() => {
    handleListSalesConditional();
  }, [handleListSalesConditional]);

  const toggleLoadModalPrint = useCallback(() => {
    setLoadModalPrint(!loadModalPrint);
  }, [loadModalPrint]);

  const closeAndClearModalPrint = useCallback(() => {
    setLoadModalPrint(false);
  }, []);

  const handleListSalesConditionalPaginatedItems = useCallback(newList => {
    setListSalesConditionalPaginatedItems(newList);
  }, []);

  const handleEmployee = useCallback(
    (employeeId: number): string => {
      if (listEmployees) {
        const employee = listEmployees.find(
          element => element.id === employeeId,
        );
        if (employee !== undefined) return employee.name;
      }
      return '';
    },
    [listEmployees],
  );

  return (
    <>
      <ListingTemplate
        title="Vendas Condicionais"
        module="sales/conditional/open"
        moduleButtonNew="sales/conditional"
        searchTheLocalObject
        searchPerId
        listItemsProps={listSalesConditional}
        handleListItemsPaginatedItems={handleListSalesConditionalPaginatedItems}
        placeHolderProp="Cód. Venda Condicional"
        permissions={permissions}
      >
        {(loading && <Loader />) || (
          <>
            <div className="text-primary text-uppercase font-weight-bold">
              Vendas Cadastradas:
              <span className="text-cinza-51-5bits text-uppercase ml-2 font-weight-bold">
                {listSalesConditional.length}
              </span>
            </div>
            <Table
              striped
              bordered
              hover
              size="sm"
              responsive="md"
              style={{ fontSize: '12px' }}
            >
              <thead className="text-center text-primary text-uppercase">
                <tr>
                  <th className="font-weight-normal py-3" scope="col">
                    ID
                  </th>
                  <th className="font-weight-normal py-3" scope="col">
                    Cliente
                  </th>
                  <th className="font-weight-normal py-3" scope="col">
                    Dependente
                  </th>
                  <th className="font-weight-normal py-3" scope="col">
                    Vendedor(a)
                  </th>
                  <th className="font-weight-normal py-3" scope="col">
                    Data Abertura
                  </th>

                  <th className="font-weight-normal py-3" scope="col">
                    Ação
                  </th>
                </tr>
              </thead>
              <tbody className="text-center">
                {listSalesConditionalPaginatedItems.map(
                  (item: SalesHeaderFormDTO, index) => (
                    <tr key={index.toString()}>
                      <th scope="row">{item.id}</th>
                      <td className="text-left">{item.client_name}</td>
                      <td className="text-left">{item.dependent_name}</td>
                      <td>{handleEmployee(item.employee_id)}</td>
                      <td>{convertDateToBr(item.created_at)}</td>
                      <td>
                        {(permissions.read && (
                          <Link
                            to="/"
                            onClick={e => {
                              e.preventDefault();
                              toggleLoadModalPrint();
                              setIdConditionalModal(item.id);
                            }}
                          >
                            Imprimir
                          </Link>
                        )) || (
                          <span className="text-cinza-51-5bits">Imprimir</span>
                        )}
                        {' | '}
                        {(permissions.update && (
                          <Link
                            to="/"
                            onClick={e => {
                              e.preventDefault();
                              setLoadModalCloseConditional(true);
                              setIdConditionalModal(item.id);
                            }}
                          >
                            Baixar
                          </Link>
                        )) || (
                          <span className="text-cinza-51-5bits">Baixar</span>
                        )}
                      </td>
                    </tr>
                  ),
                )}
              </tbody>
            </Table>
          </>
        )}
      </ListingTemplate>

      <ModalConditional
        title="Imprimir Venda Condicional"
        saleConditionalIdProp={idConditionalModal}
        loadModalProp={loadModalPrint}
        toggleLoadModalPrint={toggleLoadModalPrint}
        closeAndClearModalPrint={closeAndClearModalPrint}
      />

      <ModalCloseConditional
        title="Baixar Esta Venda Condicional?"
        saleConditionalIdProp={idConditionalModal}
        loadModalProp={loadModalCloseConditional}
        handleListSalesConditionalModal={() => {
          handleListSalesConditional();
          setLoadModalCloseConditional(false);
        }}
      />
    </>
  );
};

export default ReadsConditional;
