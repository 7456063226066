import styled from 'styled-components';

export const ModalBackground = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 15000;
  background-color: rgba(0, 0, 0, 0.7);
`;

export const ModalArea = styled.div`
  background-color: var(--modal-bg-color);
  padding: 10px;
  border-radius: 10px;
  max-height: 90vh;
  overflow: hide;
`;
