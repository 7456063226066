/* eslint-disable @typescript-eslint/ban-types */
import { IPermission } from '../interfaces/IPermission';

export default function findPermission(
  route: string,
  permission: Array<object>,
): IPermission {
  let findPermissions: any = {};

  // Verifica se tem action pois se não tiver é um menu principal com submenus
  if (permission.length > 0) {
    findPermissions = permission.find(
      (item: any) => item.route === route && item.action !== null,
    );
  }
  const permissions: IPermission =
    findPermissions !== undefined
      ? findPermissions
      : ({
          create: false,
          read: false,
          update: false,
          delete: false,
        } as IPermission);
  return permissions;
}
