/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useCallback, useState, useEffect } from 'react';
import fincalc from 'fincalc';
// import { useHistory } from 'react-router-dom';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import ComplexCreateAndUpdateTemplate from '../../../components/Template/ComplexCreateAndUpdateTemplate';
import Modal from '../../../components/Modal';

import ModalConditional from './Modal/modalPrintConditional';

import api from '../../../services/api';
import getValidationErrors from '../../../utils/getValidationErrors';
import { useToast } from '../../../context/ToastContext';

import HeaderConditional from './Partials/HeaderConditional/headerConditional';
import BodyConditional from './Partials/BodyConditional/bodyConditional';
import FooterConditional from './Partials/FooterConditional/footerConditional';
import convertNumberFloatToDB from '../../../utils/convertNumberFloatToDB';

interface Product {
  product_id: number;
  price: number;
  discount: number;
}

interface SalesHeaderFormDTO {
  payment_method_id_select: any;
  employee_id_select: any;
  client_id_select: any;
  dependent_name: string;
  phone: string;
  address: string;
  neighborhood: string;
  observation: string;
  conditional_body: Array<Product>;
}

interface SalesConditional {
  id: number;
  payment_method_id: number;
  employee_id: number;
  client_name: string;
  dependent_name: string;
  phone: string;
  address: string;
  neighborhood: string;
  observation: string;
  is_open: boolean;
  conditional_body: Array<Product>;
}

const CreateConditional: React.FC = () => {
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  // const history = useHistory();

  const [paymentMethods, setPaymentMethods] = useState<any[]>([]);
  const [listClients, setListClients] = useState<any[]>([]);
  const [listEmployees, setListEmployees] = useState<any[]>([]);
  const [listProducts, setListProducts] = useState<any[]>([]);

  const [
    saleConditionalReturn,
    setListSaleConditionalReturn,
  ] = useState<SalesConditional>({} as SalesConditional);
  const [loadModalReturn, setLoadModalReturn] = useState(false);
  const [loadModalConfirmationSale, setLoadModalConfirmationSale] = useState(
    false,
  );
  const [loadModalLoadingNewSale, setLoadModalLoadingNewSale] = useState(false);

  const toggleLoadModalSale = useCallback(() => {
    setLoadModalReturn(!loadModalReturn);
  }, [loadModalReturn]);

  const closeAndClearModalSale = useCallback(() => {
    setLoadModalReturn(false);
    setLoadModalLoadingNewSale(true);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }, []);

  useEffect(() => {
    try {
      const getListsAllSales = async (): Promise<void> => {
        try {
          const responseClients = await api.get('clients/active');
          setListClients(responseClients.data);

          const responseEmployees = await api.get('employees/active');
          setListEmployees(responseEmployees.data);

          const responseProducts = await api.get('products/with-stock');
          setListProducts(responseProducts.data);
          const responsePaymentMethods = await api.get(
            'cash/payment-methods/active',
          );
          setPaymentMethods(responsePaymentMethods.data);
        } catch (erro) {
          // eslint-disable-next-line no-shadow
          if (erro.response) {
            const { data } = erro.response; // Error vindo do back está em data dentro de response
            addToast({
              type: 'error',
              title: data.message,
            });
            return;
          }

          addToast({
            type: 'error',
            title: 'Ocorreu um erro interno',
          });
        }
      };
      getListsAllSales();
    } catch (erro) {
      // eslint-disable-next-line no-shadow
      if (erro.response.data) {
        const { data } = erro.response; // Error vindo do back está em data dentro de response
        addToast({
          type: 'error',
          title: data.message,
        });
        return;
      }

      addToast({
        type: 'error',
        title: 'Ocorreu um erro interno',
      });
    }
  }, [addToast]);

  // handleSubmit
  const handleSubmit = useCallback(
    async (dataForm: SalesHeaderFormDTO) => {
      try {
        formRef.current?.setErrors({});
        setLoadModalConfirmationSale(true);

        /** A função mista do Yup pode corresponder a todos os tipos */
        const schema = Yup.object().shape({
          client_id_select: Yup.object()
            .shape({
              value: Yup.string().required('Cliente é obrigatório'),
            })
            .nullable()
            .required('Cliente é obrigatório'),
          employee_id_select: Yup.object().shape({
            id: Yup.string().required('Vendedor é obrigatório'),
          }),
          sales_body: Yup.array().of(
            Yup.object().shape({
              product_idItem: Yup.object()
                .shape({
                  id: Yup.string().required('Produto é obrigatório'),
                })
                .nullable()
                .required('Produto é obrigatório'),
            }),
          ),
          payment_method_id_select: Yup.object()
            .shape({
              id: Yup.string().required('Forma de pagamento obrigatório'),
            })
            .nullable()
            .required('Forma de pagamento obrigatório'),
        });

        await schema.validate(dataForm, {
          abortEarly: false,
        });

        const conditionalBody: Array<Product> = dataForm.conditional_body.map(
          (item: any) => {
            const newItem: Product = {
              product_id: parseInt(item.id, 10),
              price: fincalc(
                convertNumberFloatToDB(item.price) -
                  convertNumberFloatToDB(item.discount),
              ),
              discount: convertNumberFloatToDB(item.discount),
            };

            return newItem;
          },
        );

        const newDataForm: Omit<SalesConditional, 'id' | 'is_open'> = {
          payment_method_id: dataForm.payment_method_id_select.id,
          employee_id: dataForm.employee_id_select.id,
          client_name: dataForm.client_id_select.label,
          dependent_name: dataForm.dependent_name,
          phone: dataForm.phone,
          address: dataForm.address,
          neighborhood: dataForm.neighborhood,
          observation: dataForm.observation,
          conditional_body: conditionalBody,
        };

        const response = await api.post('sales/conditional', newDataForm);
        setListSaleConditionalReturn(response.data);

        addToast({
          type: 'success',
          title: 'Cadastro realizado com sucesso',
        });

        setLoadModalConfirmationSale(false);

        toggleLoadModalSale();

        // reset();

        // history.push('/sales/Conditional/create');
      } catch (error) {
        setLoadModalConfirmationSale(false);

        // Verifica se o error é do Yup ou seja dos campos
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);

          return;
        }
        // eslint-disable-next-line no-shadow
        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: 'Erro no cadastro',
            description: data.message,
          });
          if (formRef.current?.getFieldRef(data.field)) {
            formRef.current?.getFieldRef(data.field).focus(); // Foca o campo que deu erro de acordo com o field retornado do back
          }

          return;
        }

        addToast({
          type: 'error',
          title: 'Ocorreu um erro interno',
          description: error,
        });
      }
    },
    [addToast, toggleLoadModalSale],
  );
  // Fim handleSubmit

  return (
    <ComplexCreateAndUpdateTemplate title="Cadastro de Venda Condicional">
      <Form ref={formRef} onSubmit={handleSubmit} className="h-100">
        <Row noGutters className="w-100 h-100">
          <HeaderConditional
            listClients={listClients}
            listEmployees={listEmployees}
            listPaymentMethods={paymentMethods}
            formRef={formRef}
          />

          <BodyConditional formRefProps={formRef} listProducts={listProducts} />

          <FooterConditional />
        </Row>
      </Form>

      <ModalConditional
        title="Imprimir Venda Condicional"
        saleConditionalIdProp={saleConditionalReturn.id}
        loadModalProp={loadModalReturn}
        toggleLoadModalPrint={toggleLoadModalSale}
        closeAndClearModalPrint={closeAndClearModalSale}
      />

      <Modal visibleProp={loadModalConfirmationSale}>
        <div className="d-flex flex-column align-items-center bg-branco-puro-5bits p-4">
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          <strong>Salvando a venda condicional</strong>
        </div>
      </Modal>
      <Modal visibleProp={loadModalLoadingNewSale}>
        <div className="d-flex flex-column align-items-center bg-branco-puro-5bits p-4">
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          <strong>Preparando para nova a venda condicional</strong>
        </div>
      </Modal>
    </ComplexCreateAndUpdateTemplate>
  );
};

export default CreateConditional;
