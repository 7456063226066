/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/ban-types */
// createContext cria o contexto
// useContext utiliza o contexto
import React, { createContext, useContext, useCallback, useState } from 'react';
import api from '../services/api';

interface AuthState {
  token: string;
  configuration: object;
  user: object;
  menus: Array<any>;
  permission: Array<object>;
}

interface SignInCredentials {
  username: string;
  password: string;
}

// Para tipagem do contexto
interface AuthContextData {
  configuration: object;
  user: object;
  menus: Array<any>;
  permission: Array<object>;
  // eslint-disable-next-line no-unused-vars
  updateLastReadImportProducts(newDate: string): void;
  // eslint-disable-next-line no-unused-vars
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
}

/**
 * [ ] nome da aplicação para o token
 */

// Cria o contexto com a tipagem acima.
// createContext não pode iniciar sem valor então {} as AuthContextData
const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  // state
  const [data, setData] = useState<AuthState>(() => {
    const token = sessionStorage.getItem('@Stock5b:token');
    const configuration = sessionStorage.getItem('@Stock5b:configuration');
    const user = sessionStorage.getItem('@Stock5b:user');
    const menus = sessionStorage.getItem('@Stock5b:menus');
    const permission = sessionStorage.getItem('@Stock5b:permission');

    if (token && configuration && user && menus && permission) {
      return {
        token,
        configuration: JSON.parse(configuration),
        user: JSON.parse(user),
        menus: JSON.parse(menus),
        permission: JSON.parse(permission),
      };
    }

    return {} as AuthState;
  });
  // Fim state

  // updateLastReadImportProducts
  const updateLastReadImportProducts = useCallback(
    (newDate: string): void => {
      const newConfiguration = {
        ...data.configuration,
        last_read_import_products: newDate,
      };
      setData({ ...data, configuration: newConfiguration });
    },
    [data],
  );

  // sign in
  const signIn = useCallback(async ({ username, password }) => {
    const response = await api.post('/sessions', { username, password });

    const { token, configuration, user, menus, permission } = response.data;

    sessionStorage.setItem('@Stock5b:token', token);
    sessionStorage.setItem(
      '@Stock5b:configuration',
      JSON.stringify(configuration),
    );
    sessionStorage.setItem('@Stock5b:user', JSON.stringify(user));
    sessionStorage.setItem('@Stock5b:menus', JSON.stringify(menus));
    sessionStorage.setItem('@Stock5b:permission', JSON.stringify(permission));

    setData({ token, configuration, user, menus, permission });
  }, []);
  // Fim sign in

  // signOut
  const signOut = useCallback(() => {
    sessionStorage.removeItem('@Stock5b:token');
    sessionStorage.removeItem('@Stock5b:configuration');
    sessionStorage.removeItem('@Stock5b:user');
    sessionStorage.removeItem('@Stock5b:menus');
    sessionStorage.removeItem('@Stock5b:permission');

    setData({} as AuthState);
  }, []);
  // Fim signOut

  return (
    <AuthContext.Provider
      value={{
        configuration: data.configuration,
        user: data.user,
        menus: data.menus,
        permission: data.permission,
        updateLastReadImportProducts,
        signIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

/*
Aqui cria o hook para ficar mais fácil o uso do context
em outros componentes. Pois assim diminui o código nos outros
componentes já que eles não terão que importar o useContext, o
AuthContext para usá-lo no useContext, setar a variável com o context
entre outras coisas.
*/
function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  /*
  Irá disparar um error se o desenvolvedor não colocar o
  AuthProvider no App.tsx, pois se não colocar quer dizer que
  o contexto não existe:
   <AuthProvider>
        <SignIn />
    </AuthProvider>
  Se encontrar retorna o context.
  */
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

/*
O AuthContext não precisa ser exportado pois está no hook useAuth.
O AuthProvider tem que ser exportado pois será usado no App.tsx.
*/
export { AuthProvider, useAuth };
