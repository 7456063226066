/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useState, useEffect } from 'react';

import { FormHandles, Scope } from '@unform/core';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FiTrash2 } from 'react-icons/fi';
import { RiAddFill } from 'react-icons/ri';

import fincalc from 'fincalc';
import Input from '../../../../../components/Input';
import CurrencyInput from '../../../../../components/Input/InputCurrency';
import SelectWithListLocal from '../../../../../components/SelectWithListLocal';
import Button from '../../../../../components/Button';

import convertNumberFloatToDB from '../../../../../utils/convertNumberFloatToDB';

interface HeaderSalesFormDTO {
  formRefProps: any | FormHandles;
  listProducts: Array<any>[any];
  // eslint-disable-next-line no-unused-vars
  onValueChangeAmountPrimaryOriginal: (value: number) => void;
}

interface ItemsFormDTO {
  id: string;
  idItem: string;
  name: string;
  size: string;
  color: string;
  price: string;
  quantity: string | number;
  discount: string | number;
  amount: string | number;
}

const BodySell: React.FC<HeaderSalesFormDTO> = ({
  formRefProps,
  listProducts,
  onValueChangeAmountPrimaryOriginal,
}) => {
  const [listItemsSale, setListItemsSale] = useState<ItemsFormDTO[]>([
    {} as ItemsFormDTO,
  ]);

  // handleAmountAllItems
  const handleAmountAllItems = useCallback(() => {
    const dataForm = formRefProps.current.getData();
    const salesBodyScope = dataForm.sales_body;
    const paymentMethods: any = dataForm.payment_methods;

    let calcAmountItems = 0;
    let discount = 0;
    let addition = 0;
    let amount = 0;

    if (salesBodyScope) {
      calcAmountItems = fincalc(
        salesBodyScope.reduce((sum: number, item: any) => {
          return sum + convertNumberFloatToDB(item.amount);
        }, 0),
      );

      if (paymentMethods) {
        paymentMethods.map((item: any) => {
          discount += convertNumberFloatToDB(item.discount);
          addition += convertNumberFloatToDB(item.addition);
          return null;
        });
      }

      // Se houver cupom de desconto
      if (
        dataForm &&
        dataForm.amountCoupon &&
        convertNumberFloatToDB(dataForm.amountCoupon) > 0
      ) {
        const amountDiscountCoupon: number = convertNumberFloatToDB(
          dataForm.amountCoupon,
        );

        amount = calcAmountItems - (discount + amountDiscountCoupon);
        amount += addition;
        amount = fincalc(amount);
      } else {
        amount = calcAmountItems - discount;
        amount += addition;
        amount = fincalc(amount);
      }

      formRefProps.current?.setFieldValue(
        'amount',
        amount.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
        }),
      );

      if (dataForm.secondPaymentMethods === 'true') {
        const amountReceivedPrimary = convertNumberFloatToDB(
          formRefProps.current?.getFieldValue(
            'payment_methods[0].amount_received',
          ),
        );
        const amountReceivedSecond = convertNumberFloatToDB(
          formRefProps.current?.getFieldValue(
            'payment_methods[1].amount_received',
          ),
        );

        const dif = amount - amountReceivedPrimary;

        let amountReceivedPrimaryFinally =
          amountReceivedPrimary + dif - amountReceivedSecond;

        amountReceivedPrimaryFinally = fincalc(amountReceivedPrimaryFinally);

        onValueChangeAmountPrimaryOriginal(amountReceivedPrimaryFinally);

        formRefProps.current?.setFieldValue(
          `payment_methods[0].amount_received`,
          amountReceivedPrimaryFinally.toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
          }),
        );
      } else {
        formRefProps.current?.setFieldValue(
          `payment_methods[0].amount_received`,
          amount.toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
          }),
        );
      }
    }
  }, [formRefProps, onValueChangeAmountPrimaryOriginal]);
  // Fim handleAmountAllItems

  // handleAddItem
  const handleAddItem = useCallback(() => {
    /*
        O hook useState aceita uma função que recebe o estado anterior.
        Sempre que for manipular uma state que dependa do valor anterior (neste
        caso precisa dos itens da lista para adicionar mais um) faça com a função
        callback do state. Outro exemplo é a soma: setSum((prev: any) => prev + 1);
        */
    setListItemsSale((prev: any) => [...prev, [{} as ItemsFormDTO]]);
  }, []);
  // Fim handleAddItem

  // calcItem
  const calcItem = useCallback(
    (price: string, discount: string, quantity: string): string => {
      let amount = 0;

      const priceStr: number = convertNumberFloatToDB(price);
      const discountStr: number = convertNumberFloatToDB(discount);
      const quantityStr: number = convertNumberFloatToDB(quantity);

      amount = fincalc(quantityStr * (priceStr - discountStr));
      // amount = Number.parseInt(amount, 10);
      // const amountFinal = amount / 100;

      return amount.toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
      });
    },
    [],
  );
  // Fim calcItem

  // handleInputChange: O inputValue é o objeto inteiro do produto selecionado
  const handleInputChange = useCallback(
    (inputValue: any, index) => {
      if (inputValue) {
        formRefProps.current?.setFieldValue(
          `sales_body[${index}].id`,
          inputValue.id,
        );
        formRefProps.current?.setFieldValue(
          `sales_body[${index}].size`,
          inputValue.size,
        );
        formRefProps.current?.setFieldValue(
          `sales_body[${index}].color`,
          inputValue.color,
        );
        formRefProps.current?.setFieldValue(
          `sales_body[${index}].price`,
          convertNumberFloatToDB(inputValue.price).toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
          }),
        );
        formRefProps.current?.setFieldValue(`sales_body[${index}].quantity`, 1);
        formRefProps.current?.setFieldValue(`sales_body[${index}].discount`, 0);
        formRefProps.current?.setFieldValue(
          `sales_body[${index}].amount`,
          convertNumberFloatToDB(inputValue.price).toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
          }),
        );

        const salesBody = formRefProps.current?.getData().sales_body;
        setListItemsSale(salesBody);
      }
      // handleAmountAllItems();
    },
    [formRefProps],
  );
  // Fim handleInputChange

  // handleAmountItem Calcula valor total do item
  const handleAmountItem = useCallback(
    (index: number) => {
      const priceItem = formRefProps.current?.getFieldValue(
        `sales_body[${index}].price`,
      );

      const discountItem = formRefProps.current?.getFieldValue(
        `sales_body[${index}].discount`,
      );
      const quantityItem = formRefProps.current?.getFieldValue(
        `sales_body[${index}].quantity`,
      );

      const newAmount = calcItem(priceItem, discountItem, quantityItem);

      formRefProps.current?.setFieldValue(
        `sales_body[${index}].amount`,
        newAmount,
      );

      const salesBody = formRefProps.current?.getData().sales_body;
      setListItemsSale(salesBody);
      // Soma todos os totais de todos os items
      // handleAmountAllItems();
    },
    [formRefProps, calcItem],
  );
  // Fim handleAmountItem

  const handleRemoveItemSale = useCallback(
    (index: number) => {
      if (listItemsSale.length > 1) {
        const newListItemsSale = formRefProps.current.getData().sales_body;
        newListItemsSale.splice(index, 1);
        newListItemsSale.map((item: any, indexMap: number) => {
          formRefProps.current.setFieldValue(
            `sales_body[${indexMap}].product_idItem`,
            item.product_idItem,
          );
          formRefProps.current.setFieldValue(
            `sales_body[${indexMap}].id`,
            item.id,
          );
          formRefProps.current.setFieldValue(
            `sales_body[${indexMap}].size`,
            item.size,
          );
          formRefProps.current.setFieldValue(
            `sales_body[${indexMap}].color`,
            item.color,
          );
          formRefProps.current.setFieldValue(
            `sales_body[${indexMap}].price`,
            item.price,
          );
          formRefProps.current.setFieldValue(
            `sales_body[${indexMap}].quantity`,
            item.quantity,
          );
          formRefProps.current.setFieldValue(
            `sales_body[${indexMap}].discount`,
            item.discount,
          );
          formRefProps.current.setFieldValue(
            `sales_body[${indexMap}].amount`,
            item.amount,
          );

          return null;
        });

        setListItemsSale(newListItemsSale);
      } else {
        formRefProps.current.clearField('sales_body[0].product_idItem');
        formRefProps.current.clearField('sales_body[0].id');
        formRefProps.current.clearField('sales_body[0].size');
        formRefProps.current.clearField('sales_body[0].color');
        formRefProps.current.clearField('sales_body[0].price');
        formRefProps.current.clearField('sales_body[0].quantity');
        formRefProps.current.clearField('sales_body[0].discount');
        formRefProps.current.clearField('sales_body[0].amount');

        const newListItemsSale = [{} as ItemsFormDTO];
        setListItemsSale(newListItemsSale);
      }
    },
    [listItemsSale, formRefProps],
  );

  useEffect(() => {
    handleAmountAllItems();
  }, [listItemsSale, formRefProps, handleAmountAllItems]);

  return (
    <>
      <Col
        md={12}
        className="h-75 mt-2 mb-2 border border-right-0 border-left-0 border-cinza-claro-5bits overflow-auto"
      >
        <Row
          noGutters
          className="w-100 h-100 justify-content-center row no-gutters overflow-auto"
        >
          <Col md={12} className="p-md-2">
            {listItemsSale && (
              <>
                {listItemsSale.map((item, index) => (
                  <Row key={index.toString()} noGutters className="w-100">
                    <Scope path={`sales_body[${index}]`}>
                      <Col md={4} className="d-flex align-items-end py-md-2">
                        <SelectWithListLocal
                          name="product_idItem"
                          label={`${(index === 0 && 'Nome do Produto') || ''}`}
                          placeholder="Nome do Produto"
                          fontSize="12px"
                          sizeIcon={12}
                          options={listProducts}
                          getOptionLabel={option => option.name}
                          getOptionValue={option => option.id}
                          aria-label="Nome do Produto"
                          maxLength={45}
                          className="mb-1"
                          onChange={(e: any) => handleInputChange(e, index)}
                        />
                      </Col>
                      <Col md={4} className="d-flex flex-row p-md-2">
                        <Input
                          type="text"
                          name="id"
                          label={`${(index === 0 && 'Código') || ''}`}
                          className="mb-1 mr-2"
                          fontSizeInput="12px"
                          fontSizeLabel="12px"
                          disabled
                        />
                        <Input
                          type="text"
                          name="size"
                          label={`${(index === 0 && 'Tamanho') || ''}`}
                          className="mb-1 mr-2"
                          fontSizeInput="12px"
                          fontSizeLabel="12px"
                          disabled
                        />
                        <Input
                          type="text"
                          name="color"
                          label={`${(index === 0 && 'Cor') || ''}`}
                          className="mb-1 mr-2"
                          fontSizeInput="12px"
                          fontSizeLabel="12px"
                          disabled
                        />
                        <Input
                          type="text"
                          name="price"
                          label={`${(index === 0 && 'Preço UN') || ''}`}
                          className="mb-1"
                          classNameInputGroupTextAndFormControl="p-1 font-weight-bold"
                          fontSizeInput="12px"
                          fontSizeLabel="12px"
                          disabled
                        />
                      </Col>
                      <Col
                        md={4}
                        className="d-flex flex-row align-items-center p-md-2"
                      >
                        <Input
                          type="number"
                          name="quantity"
                          label={`${(index === 0 && 'Quantidade') || ''}`}
                          className="mb-1 mr-2"
                          classNameInputGroupTextAndFormControl="bg-branco-puro-5bits"
                          onChange={() => handleAmountItem(index)}
                          fontSizeInput="12px"
                          fontSizeLabel="12px"
                          min={1}
                          defaultValue={1}
                        />
                        <CurrencyInput
                          name="discount"
                          label={`${(index === 0 && 'Desconto') || ''}`}
                          className="mb-1 mr-2"
                          classNameInputGroupTextAndFormControl="bg-branco-puro-5bits"
                          onKeyUp={() => handleAmountItem(index)}
                          fontSizeInput="12px"
                          fontSizeLabel="12px"
                        />
                        <Input
                          type="text"
                          name="amount"
                          label={`${(index === 0 && 'Total') || ''}`}
                          className="mb-1 mr-2"
                          classNameInputGroupTextAndFormControl="p-1 font-weight-bold"
                          fontSizeInput="12px"
                          fontSizeLabel="12px"
                          disabled
                        />
                        <div className="d-flex flex-row align-items-end h-50">
                          <FiTrash2
                            size={16}
                            onClick={() => {
                              handleRemoveItemSale(index);
                            }}
                          />
                        </div>
                      </Col>
                    </Scope>
                  </Row>
                ))}
              </>
            )}

            <Row noGutters className="w-100 h-auto">
              <Col md={3} className="d-flex justify-content-start">
                <Button
                  name="addProduct"
                  type="button"
                  className="w-50"
                  fontSizeButton="12px"
                  onClick={handleAddItem}
                >
                  <RiAddFill size={24} />
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default BodySell;
