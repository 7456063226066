import styled from 'styled-components';
import loader from '../../assets/loader.gif';

type IProp = {
  sizeProp: string;
};

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const Content = styled.div<IProp>`
  height: ${prop => prop.sizeProp};
  width: ${prop => prop.sizeProp};
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${loader});
`;
