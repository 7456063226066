import styled, { css } from 'styled-components';
import MenuMobile from '../../assets/Menu-Mobile.svg';
import Vector from '../../assets/Vector.svg';

interface MenuProps {
  menuOpen: boolean;
}

export const Container = styled.div<MenuProps>`
  z-index: 5000;
  background: var(--branco-puro-5bits);

  a,
  label {
    color: #346795;
  }

  @media only screen and (max-width: 767px) {
    width: 100%;
    transform: translateX(-100%);
    ${props =>
      props.menuOpen &&
      css`
        transform: translateX(0);
      `}

    transition: transform 500ms linear;
  }
`;

export const NavCustom = styled.nav`
  text-transform: none;
  z-index: 5002;

  font-size: 16px;
  font-weight: bold;
  line-height: 21px;

  a {
    text-decoration: none;
  }
`;

export const Menu = styled.ul<MenuProps>`
  z-index: 5003;
  position: absolute;
  width: 100%;
  height: 66vh;
  max-height: 66vh;

  overflow-y: auto;
  padding-bottom: 20px;
  padding: 0 14px 0 14px;
  list-style: none;
  background: var(--branco-puro-5bits);

  margin-bottom: 0;

  li a span {
    transition: 0.4s;
  }

  li {
    margin-top: 12px;

    a {
      width: 100%;
    }

    label {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      padding: 5px;

      &:hover {
        cursor: pointer;
        font-weight: bold;
        color: var(--laranja-5bits);
      }

      span::before {
        content: url(${Vector});
        margin-right: 9px;
        color: var(--laranja-5bits);
      }
    }
  }

  label {
    width: 100%;
    margin: 0;
  }

  // X-Large devices (large desktops, less than 1400px)
  @media only screen and (max-width: 1399.98px) {
  }

  // Large devices (desktops, less than 1200px)
  @media only screen and (max-width: 1199.98px) {
  }

  // Medium devices (tablets, less than 992px)
  @media only screen and (max-width: 991.98px) {
  }

  // Small devices (landscape phones, less than 768px)
  @media only screen and (max-width: 767.98px) {
    height: 81vh;
    max-height: 81vh;
  }

  // X-Small devices (portrait phones, less than 576px)
  @media only screen and (max-width: 575.98px) {
    height: 81vh;
    max-height: 81vh;
  }

  @media only screen and (max-height: 755.98px) {
    height: 81vh;
    max-height: 81vh;
  }
  @media only screen and (min-height: 767.98px) {
    > li:first-child {
      margin-top: 50px;
    }
  }
`;

export const BtnMenuOpen = styled.a`
  display: none;

  @media only screen and (max-width: 767px) {
    display: block;
    position: absolute;
    top: 5%;
    left: 20px;
    z-index: 5000;
    background-image: url(${MenuMobile});
    background-repeat: no-repeat;
    padding: 12px 16px;

    @media only screen and (max-height: 575.98px) {
      top: 3%;
    }
  }
`;
