import React from 'react';
import { Switch } from 'react-router-dom';

import PrivateRoute from './PrivateRoutes';
import { useAuth } from '../context/AuthContext';
import findPermission from '../utils/findPermission';

import NotAuthorizedPage from '../pages/NotAuthorizedPage';

import CreateSell from '../pages/Sales/Sell/createSell';
import ListSell from '../pages/Sales/Sell/listSell';
import UpdateSell from '../pages/Sales/Sell/updateSell';

import CreateReceive from '../pages/Sales/Receive/createReceive';
import ListReceive from '../pages/Sales/Receive/listReceive';
import UpdateReceive from '../pages/Sales/Receive/updateReceive';

import CreateConditional from '../pages/Sales/Conditional/createConditional';
import ListConditional from '../pages/Sales/Conditional/listConditional';

import CreateCancelSale from '../pages/Sales/CancelSales/createCancelSale';
import ListCancelSale from '../pages/Sales/CancelSales/listCancelSale';

const SalesRoutes: React.FC = () => {
  const { permission } = useAuth();
  const salesSellPermissions = findPermission('sales/sell', permission); // menus_id sales/sell = 14
  const salesReceivePermissions = findPermission('sales/receive', permission); // menus_id sales/receive = 15
  const salesConditionalPermissions = findPermission(
    'sales/conditional',
    permission,
  ); // menus_id sales/conditional = 18
  const salesCancelPermissions = findPermission('sales/cancel', permission);

  return (
    <Switch>
      <PrivateRoute
        path="*/sell/create"
        component={
          Object.keys(salesSellPermissions).length !== 0 &&
          salesSellPermissions.create
            ? CreateSell
            : NotAuthorizedPage
        }
      />
      <PrivateRoute
        path="*/sell/list"
        render={props => (
          <ListSell {...props} permissions={salesSellPermissions} />
        )}
      />
      <PrivateRoute
        path="*/sell/read/:id"
        component={
          Object.keys(salesSellPermissions).length !== 0 &&
          salesSellPermissions.update
            ? UpdateSell
            : NotAuthorizedPage
        }
      />

      <PrivateRoute
        path="*/receive/create"
        component={
          Object.keys(salesReceivePermissions).length !== 0 &&
          salesReceivePermissions.create
            ? CreateReceive
            : NotAuthorizedPage
        }
      />
      <PrivateRoute
        path="*/receive/list"
        render={props => (
          <ListReceive {...props} permissions={salesReceivePermissions} />
        )}
      />
      <PrivateRoute
        path="*/receive/read/:id"
        component={
          Object.keys(salesReceivePermissions).length !== 0 &&
          salesReceivePermissions.update
            ? UpdateReceive
            : NotAuthorizedPage
        }
      />

      <PrivateRoute
        path="*/conditional/create"
        component={
          Object.keys(salesConditionalPermissions).length !== 0 &&
          salesConditionalPermissions.create
            ? CreateConditional
            : NotAuthorizedPage
        }
      />
      <PrivateRoute
        path="*/conditional/list"
        render={props => (
          <ListConditional
            {...props}
            permissions={salesConditionalPermissions}
          />
        )}
      />

      <PrivateRoute
        path="*/cancel/create"
        component={
          Object.keys(salesCancelPermissions).length !== 0 &&
          salesCancelPermissions.create
            ? CreateCancelSale
            : NotAuthorizedPage
        }
      />
      <PrivateRoute
        path="*/cancel/list"
        render={props => (
          <ListCancelSale {...props} permissions={salesCancelPermissions} />
        )}
      />
    </Switch>
  );
};

export default SalesRoutes;
