import styled from 'styled-components';

export const TR = styled.tr`
  :hover {
    td:last-child {
      > div {
        span {
          opacity: 1;
          visibility: visible;
          left: -43vw;
        }
      }
    }
  }
`;
