/* eslint-disable @typescript-eslint/ban-types */
import React, { useState, useEffect } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Pagination from '../../Pagination';

interface CreateWithListingTemplateProps {
  title: string;
  numberColContent?: number;
  listItemsProps: Array<object>;
  // eslint-disable-next-line no-unused-vars
  handleListItemsPaginatedItems: (newList: Array<any>) => void;
}

const CreateWithListingTemplate: React.FC<CreateWithListingTemplateProps> = ({
  title,
  numberColContent = 5,
  listItemsProps,
  handleListItemsPaginatedItems,
  children,
}) => {
  const [listAllItems, setListAllItems] = useState<any[]>([]);

  useEffect(() => {
    const getListAllItems = (): void => {
      setListAllItems(listItemsProps);
    };
    getListAllItems();
  }, [listItemsProps]);

  return (
    <>
      <Col
        md={12}
        className="d-flex md-justify-content-center flex-column h-100
      overflow-auto bg-branco-puro-5bits p-4"
      >
        <Row className="no-gutters">
          <Col md={12}>
            <h1 className="m-0">{title}</h1>
          </Col>
        </Row>
        <Row className="overflow-auto justify-content-center no-gutters">
          <Col md={numberColContent}>{children}</Col>
        </Row>

        <Pagination
          listItems={listAllItems}
          onChangeListPaginatedItems={handleListItemsPaginatedItems}
        />
      </Col>
    </>
  );
};

export default CreateWithListingTemplate;
