import React from 'react';
import * as Styles from './styles';

type IProp = {
  size?: string;
};

export const Loader: React.FC<IProp> = ({ size = '104px' }) => {
  return (
    <Styles.Container>
      <Styles.Content sizeProp={size} />
    </Styles.Container>
  );
};

export default Loader;
