import styled, { css } from 'styled-components';

type IShowPagination = {
  showPagination: boolean;
};

export const TogglePaginationAndPayOff = styled.div<IShowPagination>`
  width: 100%;
  /* overflow: hidden; */
  height: 70px;
  ${props =>
    (props.showPagination &&
      css`
        > div {
          opacity: 1;
          visibility: visible;
          transition: all 0.5s linear;
          transform: translateY(0%) scaleY(1);
        }

        > form {
          opacity: 0;
          visibility: hidden;
          pointer-events: none;
          transition: all 0.5s linear;
          transform: translateY(-32px) scaleY(0);
        }
      `) ||
    css`
      > div {
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        transition: all 0.5s linear;
        transform: translateY(-100%) scaleY(0);
      }

      > form {
        opacity: 1;
        visibility: visible;
        transition: all 0.5s linear;
        transform: translateY(-62px) scaleY(1);
      }
    `}
`;
