/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  InputHTMLAttributes,
  useState,
  useRef,
  useCallback,
  useEffect,
} from 'react';
import { useField } from '@unform/core';

import Form from 'react-bootstrap/Form';

import { Container } from './styles';

interface CheckBoxProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  propChecked: boolean;
  className?: string;
  fontSizeText?: string;
}

const Checkbox: React.FC<CheckBoxProps> = ({
  children,
  name,
  propChecked = false,
  className,
  fontSizeText = '',
  ...rest
}) => {
  const checkboxRef = useRef<HTMLInputElement>(null);
  const [isChecked, setIsChecked] = useState(propChecked);

  const { fieldName, registerField } = useField(name);

  useEffect(() => {
    setIsChecked(propChecked);
    registerField({
      name: fieldName,
      ref: checkboxRef.current,
      path: 'value',
    });
  }, [checkboxRef, fieldName, propChecked, registerField]);

  // handleIsChecked
  const handleIsChecked = useCallback(() => {
    setIsChecked(!isChecked);
  }, [isChecked]);
  // Fim handleIsChecked

  return (
    <Container
      className={className}
      style={{
        fontSize: `${fontSizeText}`,
      }}
    >
      <Form.Check
        type={'checkbox' as any}
        name={name}
        value={`${isChecked}`}
        ref={checkboxRef}
        className="text-uppercase"
        onChange={handleIsChecked}
        checked={isChecked}
        label={children}
        {...rest}
      />
    </Container>
  );
};

export default Checkbox;
