import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  background: var(--azul-5bits);
  height: 100%;

  div:first-child {
    margin-right: 45px;
    span {
      font-size: 12px;
      line-height: 16px;
      color: var(--branco-puro-5bits);
    }
  }

  div {
    svg {
      margin-right: 45px;
      color: var(--branco-puro-5bits);
      width: 24px;
      height: 24px;
      &:hover {
        cursor: pointer;
      }
    }
    svg:last-child {
      margin-right: 20px;
    }
  }

  @media only screen and (max-width: 767px) {
    justify-content: space-between;

    div:first-child {
      display: flex;
      flex-direction: column;
      margin-left: 75px;

      span:nth-child(2) {
        display: none;
      }
    }

    div {
      svg {
        margin-right: 8px;
        color: var(--branco-puro-5bits);
        width: 16px;
        height: 17px;
        &:hover {
          cursor: pointer;
        }
      }
      svg:last-child {
        margin-right: 20px;
      }
    }
  }
`;
