/* eslint-disable camelcase */
import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import fincalc from 'fincalc';

import Table from 'react-bootstrap/Table';

import { compareAsc, parseISO } from 'date-fns';

import ListingTemplate from './ListingTemplate';
import Loader from '../../../components/Loader';
import Tooltip from '../../../components/Tooltip';
import ButtonLink from '../../../components/ButtonLink';
import Button from '../../../components/Button';

import convertDateToBr from '../../../utils/convertDateToBr';
import convertNumberFloatToBr from '../../../utils/convertNumberFloatToBr';
import returnDateNow from '../../../utils/returnDateNow';

import api from '../../../services/api';

import { useToast } from '../../../context/ToastContext';

import { IPermission } from '../../../interfaces/IPermission';
import { IReceive } from '../../../interfaces/IReceive';

import ModalDetailsReceive from './Modal/modalDetailsReceive';
import ModalConfirmReceiveList from './components/Modal/ModalConfirmReceiveList/modalConfirmReceiveList';
import ModalResponseReceiveList from './components/Modal/ModalResponseReceiveList/modalResponseReceiveList';

interface PermissionDTO {
  permissions: IPermission;
}

type IPaymentMethodSelected = {
  id: number;
  name: string;
};

interface IReceiveItemForListReceives {
  id: number;
  amount_received: string;
  payment_method_id: number;
}

const ReadsReceive: React.FC<PermissionDTO> = ({ permissions }) => {
  const [loading, setLoading] = useState(false);
  // const history = useHistory();
  const [listReceive, setListReceive] = useState<IReceive[]>([]);
  const [listReceivePaginatedItems, setListReceivePaginatedItems] = useState<
    IReceive[]
  >([]);
  const { addToast } = useToast();

  useEffect(() => {
    try {
      const getListReceive = async (): Promise<void> => {
        try {
          setLoading(true);
          const response: any = await api.get('sales/receive');
          setListReceive(response.data);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          // eslint-disable-next-line no-shadow
          if (error.response) {
            const { data } = error.response; // Error vindo do back está em data dentro de response
            addToast({
              type: 'error',
              title: data.message,
            });
            return;
          }
          addToast({
            type: 'error',
            title: 'Ocorreu um erro interno',
          });
        }
      };
      getListReceive();
    } catch (error) {
      setLoading(false);
      // eslint-disable-next-line no-shadow
      if (error.response.data) {
        const { data } = error.response; // Error vindo do back está em data dentro de response
        addToast({
          type: 'error',
          title: data.message,
        });
        return;
      }

      addToast({
        type: 'error',
        title: 'Ocorreu um erro interno',
      });
    }
  }, [addToast]);

  const [idReceive, setIdReceive] = useState<number>(0);
  const [loadModalReturn, setLoadModalReturn] = useState(false);

  const toggleLoadModalDetailsReceive = useCallback(() => {
    setLoadModalReturn(!loadModalReturn);
  }, [loadModalReturn]);

  const handleIdReceive = useCallback(
    (id: number) => {
      setIdReceive(id);
      toggleLoadModalDetailsReceive();
    },
    [toggleLoadModalDetailsReceive],
  );

  const [
    selectedDebtAmountToBePaidOff,
    setSelectedDebtAmountToBePaidOff,
  ] = useState(0);

  const sumOfTheOutstandingAmountToBePaid = useCallback(
    (selectedDebtorAmount: number) => {
      setSelectedDebtAmountToBePaidOff(prev =>
        fincalc(prev + selectedDebtorAmount),
      );
    },
    [],
  );
  const subtractOfTheOutstandingAmountToBePaid = useCallback(
    (selectedDebtorAmount: number) => {
      setSelectedDebtAmountToBePaidOff(prev =>
        fincalc(prev - selectedDebtorAmount),
      );
    },
    [],
  );

  const [receiveListForSubmit, setReceiveListForSubmit] = useState<
    Array<IReceive>
  >([]);

  const addItemInReceiveListForSubmit = useCallback(
    (receiveItemSelected: IReceive) => {
      setReceiveListForSubmit((prev: any) => [...prev, receiveItemSelected]);
    },
    [],
  );

  const removeItemInReceiveListForSubmit = useCallback(
    (receiveItemSelected: IReceive) => {
      setReceiveListForSubmit((prev: Array<IReceive>) =>
        prev.filter(itemFilter => itemFilter.id !== receiveItemSelected.id),
      );
    },
    [],
  );

  // Show and hide Modal Receives list
  const [loadModalReceiveList, setLoadModalReceiveList] = useState(false);
  const [
    paymentMethodSelectedInReceiveList,
    setPaymentMethodSelectedInReceiveList,
  ] = useState<IPaymentMethodSelected>({} as IPaymentMethodSelected);

  const handleShowModalReceivesList = useCallback(
    (paymentMethodSelected: IPaymentMethodSelected): void => {
      // setReceiveItemShowInModal(receiveListForSubmit);
      setPaymentMethodSelectedInReceiveList(paymentMethodSelected);
      setLoadModalReceiveList(!loadModalReceiveList);
    },
    [loadModalReceiveList],
  );
  // End show and hide Modal Receives list

  const [
    loadModalResponseReceiveList,
    setLoadModalResponseReceiveList,
  ] = useState(false);

  const [
    hiddenButtonsInModalConfirmWhileSubmitOfTheReceives,
    setHiddenButtonsInModalConfirmWhileSubmitOfTheReceives,
  ] = useState(false);

  const [responseReceiveListText, setResponseReceiveListText] = useState('');

  const closeModalResponseReceive = useCallback(() => {
    setLoadModalResponseReceiveList(false);

    window.location.reload();
  }, []);

  // handleSubmit
  const handleSubmitListReceives = useCallback(async () => {
    try {
      setHiddenButtonsInModalConfirmWhileSubmitOfTheReceives(true);
      const receiveItemsFormattedForSubmit: IReceiveItemForListReceives[] = receiveListForSubmit.map(
        item => ({
          id: item.id,
          amount_received: convertNumberFloatToBr(
            fincalc(parseFloat(item.amount) - parseFloat(item.amount_received)),
          ),
          payment_method_id: paymentMethodSelectedInReceiveList.id,
        }),
      );

      const response = await api.post(
        'sales/receive/pay/',
        receiveItemsFormattedForSubmit,
      );

      setResponseReceiveListText(response.data);
      addToast({
        type: 'success',
        title: 'Cadastro realizado com sucesso',
      });

      setLoadModalReceiveList(false);
      setHiddenButtonsInModalConfirmWhileSubmitOfTheReceives(false);
      setLoadModalResponseReceiveList(true);
      // history.push('/sales/receive/list');
    } catch (error) {
      setLoadModalReceiveList(false);
      setHiddenButtonsInModalConfirmWhileSubmitOfTheReceives(false);
      setLoadModalResponseReceiveList(false);
      // eslint-disable-next-line no-shadow
      if (error.response) {
        const { data } = error.response; // Error vindo do back está em data dentro de response
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: data.message,
        });

        return;
      }

      addToast({
        type: 'error',
        title: `Ocorreu um erro interno${error}`,
      });
    }
  }, [addToast, paymentMethodSelectedInReceiveList.id, receiveListForSubmit]);
  // Fim handleSubmit

  const closeAndClearModalDetailsReceive = useCallback(() => {
    setLoadModalReturn(false);
    setIdReceive(0);
  }, []);

  const handleListReceivePaginatedItems = useCallback(newList => {
    setListReceivePaginatedItems(newList);
  }, []);

  return (
    <>
      <ListingTemplate
        title="Contas a Receber"
        module="sales/receive/search"
        moduleSearchPerDate="sales/receive/search"
        listItemsProps={listReceive}
        handleListItemsPaginatedItems={handleListReceivePaginatedItems}
        selectedDebtAmountToBePaidOffProp={selectedDebtAmountToBePaidOff}
        handleShowModalReceivesListProp={handleShowModalReceivesList}
        displayButtonNew={false}
        placeHolderProp="Nome do Cliente"
        permissions={permissions}
      >
        {(loading && <Loader />) || (
          <>
            <div className="d-flex justify-content-between text-primary text-uppercase font-weight-bold">
              <div className="d-flex align-items-end w-50">
                Contas a Receber Cadastradas:
                <span className="text-cinza-51-5bits text-uppercase ml-2 font-weight-bold">
                  {listReceivePaginatedItems.length}
                </span>
              </div>
              <div className="d-flex justify-content-end w-50 mb-2">
                {(permissions.create && (
                  <ButtonLink className="w-20" to="/sales/receive/create">
                    Novo
                  </ButtonLink>
                )) || (
                  <Button className="w-20" disabled>
                    Novo
                  </Button>
                )}
              </div>
            </div>
            <Table
              style={{ fontSize: '12px' }}
              striped
              bordered
              hover
              size="sm"
              responsive="md"
            >
              <thead className="text-center text-primary text-uppercase">
                <tr>
                  <th className="font-weight-normal py-3" scope="col">
                    Cliente
                  </th>
                  <th className="font-weight-normal py-3" scope="col">
                    Dependente
                  </th>
                  <th className="font-weight-normal py-3" scope="col">
                    Venda
                  </th>
                  <th className="font-weight-normal py-3" scope="col">
                    Valor
                  </th>
                  <th className="font-weight-normal py-3" scope="col">
                    Recebido
                  </th>
                  <th className="font-weight-normal py-3" scope="col">
                    Devedor
                  </th>
                  <th className="font-weight-normal py-3" scope="col">
                    Vencimento
                  </th>
                  <th className="font-weight-normal py-3" scope="col">
                    Tipo
                  </th>
                  <th className="font-weight-normal py-3" scope="col">
                    Status
                  </th>
                  <th className="font-weight-normal py-3" scope="col">
                    Ação
                  </th>
                </tr>
              </thead>
              <tbody className="text-center">
                {listReceivePaginatedItems.map((item: any, index) => (
                  <tr key={index.toString()}>
                    <th scope="row" className="text-left">
                      {item.client.name}
                    </th>
                    <td className="text-left">
                      {item.sale_header.dependent_name}
                    </td>
                    <td>
                      {(item.sale_header.id === 1 && (
                        <Tooltip
                          style={{
                            textTransform: 'capitalize',
                          }}
                          className="position-relative text-primary"
                          classNameSpan="bg-primary border-primary"
                          colorBefore="#0F4C82"
                          title="Lançamento Avulso (lançado sem venda)"
                        >
                          <div
                            style={{
                              cursor: 'pointer',
                            }}
                          >
                            {item.sale_header.id}
                          </div>
                        </Tooltip>
                      )) || (
                        <Link to={`/sales/sell/read/${item.sale_header.id}`}>
                          {item.sale_header.id}
                        </Link>
                      )}
                    </td>
                    <td className="text-right pr-3">
                      {convertNumberFloatToBr(item.amount)}
                    </td>
                    <td className="text-right pr-3">
                      {convertNumberFloatToBr(item.amount_received)}
                    </td>
                    <td className="text-right text-nowrap pr-3">
                      {convertNumberFloatToBr(
                        fincalc(
                          parseFloat(item.amount) -
                            parseFloat(item.amount_received),
                        ),
                      )}
                      {item.is_paid === false && permissions.update && (
                        <input
                          type="checkbox"
                          name="dv"
                          id="de"
                          className="ml-2"
                          value={fincalc(
                            parseFloat(item.amount) -
                              parseFloat(item.amount_received),
                          )}
                          onClick={e => {
                            if (e.currentTarget.checked) {
                              sumOfTheOutstandingAmountToBePaid(
                                parseFloat(e.currentTarget.value),
                              );
                              addItemInReceiveListForSubmit(item);
                              return;
                            }
                            subtractOfTheOutstandingAmountToBePaid(
                              parseFloat(e.currentTarget.value),
                            );
                            removeItemInReceiveListForSubmit(item);
                          }}
                        />
                      )}
                    </td>

                    {(compareAsc(
                      parseISO(returnDateNow('yyyy-MM-dd')),
                      parseISO(item.due_date),
                    ) === 1 &&
                      item.is_paid === false && (
                        <td className="bg-vermelho-suave-5bits font-weight-bold">
                          {convertDateToBr(item.due_date)}
                        </td>
                      )) || <td>{convertDateToBr(item.due_date)}</td>}

                    <td>{item.payment_method.name}</td>

                    <td className="text-success font-weight-bold">
                      {item.is_paid && 'Pago'}
                    </td>
                    <td>
                      {(permissions.update && (
                        <>
                          {(item.is_paid === false && (
                            <Link to={`/sales/receive/read/${item.id}`}>
                              Baixar
                            </Link>
                          )) || (
                            <Button
                              onClick={() => handleIdReceive(item.id)}
                              fontSizeButton="12px"
                              classNameButton="p-0 border-0"
                            >
                              Detalhes
                            </Button>
                          )}
                        </>
                      )) || (
                        <>
                          {(item.is_paid === false && (
                            <span className="text-cinza-51-5bits">Baixar</span>
                          )) || (
                            <span className="text-cinza-51-5bits">
                              Detalhes
                            </span>
                          )}
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        )}
      </ListingTemplate>

      <ModalDetailsReceive
        title="Detalhes da Baixa Conta a Receber"
        idReceiveProp={idReceive}
        loadModalProp={loadModalReturn}
        toggleLoadModalDetailsReceive={toggleLoadModalDetailsReceive}
        closeAndClearModalDetailsReceive={closeAndClearModalDetailsReceive}
      />

      <ModalConfirmReceiveList
        title="Confirmar Baixa"
        listReceivesProp={receiveListForSubmit}
        paymentMethodSelectedInReceiveListProp={
          paymentMethodSelectedInReceiveList
        }
        loadModalReceiveListProp={loadModalReceiveList}
        hiddenButtonsWhileSubmitOfTheReceives={
          hiddenButtonsInModalConfirmWhileSubmitOfTheReceives
        }
        permissions={permissions}
        handleSubmitListReceivesProp={handleSubmitListReceives}
        closeModalReceive={() => setLoadModalReceiveList(false)}
      />

      <ModalResponseReceiveList
        title={responseReceiveListText}
        loadModalResponseReceiveListProp={loadModalResponseReceiveList}
        closeModalResponseReceiveProp={closeModalResponseReceive}
      />
    </>
  );
};

export default ReadsReceive;
