import styled from 'styled-components';
// import { shade } from 'polished';

export const Container = styled.div`
  width: 100%;

  a {
    text-decoration: none;
    width: 100%;
  }
`;
