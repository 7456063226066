/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { useCallback, useEffect, useState } from 'react';

import api from '../../../../../services/api';
import { useToast } from '../../../../../context/ToastContext';

import SelectWithListLocal from '../../../../../components/SelectWithListLocal';
import Loader from '../../../../../components/Loader';

import convertNumberFloatToBr from '../../../../../utils/convertNumberFloatToBr';
import * as Styles from './styles';

type IPaymentMethodComplete = {
  id: number;
  name: string;
  days_compensation: number;
  allow_installments: boolean;
  fee: string;
  print_booklet_format: boolean;
  is_active: boolean;
};

type IPaymentMethodSelected = {
  id: number;
  name: string;
};

type IProps = {
  selectedDebtAmountToBePaidOffProp: number;
  handleShowModalReceivesListProp: (
    paymentMethodSelected: IPaymentMethodSelected,
  ) => void;
};

const PayOff: React.FC<IProps> = ({
  selectedDebtAmountToBePaidOffProp,
  handleShowModalReceivesListProp,
}) => {
  const { addToast } = useToast();
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [paymentMethodsComplete, setPaymentMethodsComplete] = useState<
    IPaymentMethodComplete[]
  >([]);

  useEffect(() => {
    try {
      if (selectedDebtAmountToBePaidOffProp <= 0) {
        setButtonDisabled(true);
      } else if (
        selectedDebtAmountToBePaidOffProp > 0 &&
        paymentMethodsComplete.length < 1
      ) {
        const getListPaymentsMethods = async (): Promise<void> => {
          try {
            const responsePaymentMethodsComplete = await api.get(
              'cash/payment-methods/active',
            );
            setPaymentMethodsComplete(responsePaymentMethodsComplete.data);
          } catch (erro) {
            // eslint-disable-next-line no-shadow
            if (erro.response) {
              const { data } = erro.response; // Error vindo do back está em data dentro de response
              addToast({
                type: 'error',
                title: data.message,
              });
              return;
            }

            addToast({
              type: 'error',
              title: 'Ocorreu um erro interno',
            });
          }
        };
        getListPaymentsMethods();
      }
    } catch (erro) {
      // eslint-disable-next-line no-shadow
      if (erro.response.data) {
        const { data } = erro.response; // Error vindo do back está em data dentro de response
        addToast({
          type: 'error',
          title: data.message,
        });
        return;
      }

      addToast({
        type: 'error',
        title: 'Ocorreu um erro interno',
      });
    }
  }, [
    addToast,
    paymentMethodsComplete.length,
    selectedDebtAmountToBePaidOffProp,
  ]);

  const [
    paymentMethodSelected,
    setPaymentMethodSelected,
  ] = useState<IPaymentMethodSelected>();

  const handlePaymentMethod = (
    paymentMethodSelectedComplete: IPaymentMethodComplete,
  ): void => {
    if (paymentMethodSelectedComplete) {
      setPaymentMethodSelected({
        id: paymentMethodSelectedComplete.id,
        name: paymentMethodSelectedComplete.name,
      });
      setButtonDisabled(false);
    }
  };

  const handlePayOff = useCallback((): void => {
    if (paymentMethodSelected) {
      handleShowModalReceivesListProp(paymentMethodSelected);
    }
  }, [handleShowModalReceivesListProp, paymentMethodSelected]);

  return (
    <Styles.Container>
      <Styles.Content>
        <Styles.SelectContainer>
          {/* {(paymentMethodsComplete.length < 0 && <Loader size="38px" />) || ( */}
          <SelectWithListLocal
            name="payment_method_id_select"
            placeholder="Forma de Pagamento"
            fontSize="12px"
            sizeIcon={12}
            options={paymentMethodsComplete}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            aria-label="Forma de Pagamento"
            maxLength={45}
            menuPlacement="top"
            menuPosition="absolute"
            onChange={option =>
              handlePaymentMethod(option as IPaymentMethodComplete)
            }
            clearInputProp={selectedDebtAmountToBePaidOffProp <= 0}
          />
          {/* )} */}
        </Styles.SelectContainer>

        <Styles.PayOffAmountText>
          <span>
            Total À Quitar:{' '}
            <strong>
              R$ {convertNumberFloatToBr(selectedDebtAmountToBePaidOffProp)}
            </strong>
          </span>
        </Styles.PayOffAmountText>

        <Styles.ButtonContainer buttonDisabledProp={buttonDisabled}>
          {(buttonDisabled && (
            <button type="button" disabled>
              QUITAR
            </button>
          )) || (
            <button type="button" onClick={handlePayOff}>
              QUITAR
            </button>
          )}
        </Styles.ButtonContainer>
      </Styles.Content>
    </Styles.Container>
  );
};

export default PayOff;
