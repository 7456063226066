import styled from 'styled-components';

/**
 * styles da readsUsers
 */
export const DependentsContent = styled.div`
  display: flex;
  flex-direction: column;
  place-content: center;
  align-items: center;
  width: 100%;

  > div {
    margin-bottom: 10px;
  }
`;

export const DependentsContainerActions = styled.div`
  display: flex;
  place-content: center;
  padding: 10px;
  cursor: pointer;

  > div {
    &:first-child {
      margin-right: 10px;
    }
    button {
      width: 116px;
      height: 41px;
    }
  }
`;

export const ListDependents = styled.div`
  display: flex;
  flex-direction: column;
  place-content: center;
  justify-content: space-around;
  align-items: center;

  width: 100%;

  border: 3px solid var(--pages-listusers-div-border-color);
  border-radius: 8px;

  padding: 5px 5px 5px 5px;

  margin-top: 20px;

  > div {
    margin-bottom: 10px;
  }

  div:last-child {
    margin-bottom: 0;
  }
`;
