/* eslint-disable camelcase */
import React from 'react';

import SimpleCreateAndUpdateTemplate from '../../components/Template/SimpleCreateAndUpdateTemplate';
import ButtonLink from '../../components/ButtonLink';

/**
 * [ ] Ver o history com o Daniel após cadastrar employee
 * [ ] Ver campo sold_amount
 */

const NotAuthorizedPage: React.FC = () => {
  return (
    <SimpleCreateAndUpdateTemplate title="Sem permissão para acessar esta página!">
      <div className="mt-5">
        <ButtonLink to="/home" className="mt-5 w-auto">
          Home
        </ButtonLink>
      </div>
    </SimpleCreateAndUpdateTemplate>
  );
};

export default NotAuthorizedPage;
