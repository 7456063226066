/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useCallback, useState } from 'react';
import { compareAsc, parseISO } from 'date-fns';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { RiCake2Line } from 'react-icons/ri';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import CreateWithListingTemplate from '../../components/Template/CreateWithListingTemplate';

import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';
import convertDataToBr from '../../utils/convertDateToBr';
import convertDateToDB from '../../utils/convertDateToDB';
import { useToast } from '../../context/ToastContext';
import { useAuth } from '../../context/AuthContext';

import Input from '../../components/Input';
import InputFile from '../../components/Input/InputFile';
import Button from '../../components/Button';

import Modal from '../../components/Modal';
import ConvertPriceBRL from '../../utils/convertPriceBRL';

import { IPermission } from '../../interfaces/IPermission';

interface PermissionDTO {
  permissions: IPermission;
}

interface ImportProductsDTO {
  file: any;
  startDate: string;
}

const ImportProducts: React.FC<PermissionDTO> = ({ permissions }) => {
  const { configuration, updateLastReadImportProducts }: any = useAuth();
  const [startDateObj, setStartDateObj] = useState<ImportProductsDTO>({
    file: '',
    startDate: configuration.last_read_import_products
      ? convertDataToBr(configuration.last_read_import_products)
      : '',
  });

  // Para paginação
  const [listResponse, setListResponse] = useState<any[]>([]);
  const [listProductsPaginatedItems, setListProductsPaginatedItems] = useState<
    any[]
  >([]);
  // Fim Para paginação

  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [disableButton, setDisableButton] = useState(false);
  const [load, setLoad] = useState(false);
  const [percentCompleted, setPercentCompleted] = useState(0);

  const compareDate = useCallback(
    strDate => {
      if (startDateObj.startDate) {
        return (
          compareAsc(
            parseISO(convertDateToDB(strDate)),
            parseISO(convertDateToDB(startDateObj.startDate)),
          ) === 1
        );
      }
      return true;
    },
    [startDateObj.startDate],
  );

  // handleSubmit
  const handleSubmit = useCallback(
    async (dataForm: ImportProductsDTO, { reset }) => {
      try {
        setDisableButton(true);
        setLoad(true);

        formRef.current?.setErrors({});

        /** A função mista do Yup pode corresponder a todos os tipos */
        const schema = Yup.object().shape({
          file: Yup.mixed().test('file', 'Arquivo obrigatório!', () => {
            if (dataForm.file) {
              return true;
            }
            return false;
          }),
          startDate: Yup.string()
            .test(
              'startDate',
              `Data Inicial não pode ser igual ou anterior à ${startDateObj.startDate}`,
              () => {
                return compareDate(dataForm.startDate);
              },
            )
            .required('Data obrigatório'),
        });

        await schema.validate(dataForm, {
          abortEarly: false,
        });

        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress(progressEvent: any) {
            // Indica a porcentagem já enviada
            setPercentCompleted(
              Math.round((progressEvent.loaded * 100) / progressEvent.total),
            );
          },
        };

        const formData = new FormData();
        formData.append('file', dataForm.file);
        formData.append('startDate', dataForm.startDate);

        const response = await api.post('products/import', formData, config);

        // Para Paginação
        setListResponse(response.data);
        // Fim Para Paginação

        addToast({
          type: 'success',
          title: 'Importação realizada com sucesso',
        });

        /*
        Convert para formato americano pois estava dando erro quando a data mudava e voltava no método
        convertDateToBr com a data já no formato correto.
        */

        updateLastReadImportProducts(convertDateToDB(dataForm.startDate));

        setStartDateObj({
          file: '',
          startDate: dataForm.startDate,
        });

        reset();
        setDisableButton(false);
        setPercentCompleted(0);
        setLoad(false);

        // history.push('/home');
      } catch (error) {
        setDisableButton(false);
        setPercentCompleted(0);
        setLoad(false);

        // Verifica se o error é do Yup ou seja dos campos
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);

          return;
        }
        // eslint-disable-next-line no-shadow
        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: 'Erro na importação',
            description: data.message,
          });
          if (formRef.current?.getFieldRef(data.field)) {
            formRef.current?.getFieldRef(data.field).focus(); // Foca o campo que deu erro de acordo com o field retornado do back
          }

          return;
        }

        addToast({
          type: 'error',
          title: 'Ocorreu um erro interno',
        });
      }
    },
    [addToast, compareDate, startDateObj, updateLastReadImportProducts],
  );
  // Fim handleSubmit

  const handleListProductsPaginatedItems = useCallback(newList => {
    setListProductsPaginatedItems(newList);
  }, []);

  return (
    <>
      <CreateWithListingTemplate
        title="Importar Produtos"
        numberColContent={12}
        listItemsProps={listResponse}
        handleListItemsPaginatedItems={handleListProductsPaginatedItems}
      >
        {(load && (
          <Modal visibleProp={load}>
            <div className="w-100 bg-branco-puro-5bits p-4">
              <h1>Atualizando produtos</h1>
              <ProgressBar
                animated
                now={percentCompleted}
                label={`${percentCompleted}%`}
              />
            </div>
          </Modal>
        )) || (
          <Form
            ref={formRef}
            initialData={startDateObj}
            className="mt-2 mb-4 d-md-flex flex-md-row align-items-md-end justify-content-md-center"
            onSubmit={handleSubmit}
          >
            {(permissions.create && (
              <>
                <InputFile name="file" className="mb-4 mb-md-0" />

                <Input
                  type="text"
                  name="startDate"
                  label="Data inicial"
                  icon={RiCake2Line}
                  placeholder="Data inicial"
                  aria-label="Data inicial"
                  mask="99/99/9999"
                  className="mb-4 mb-md-0 mx-md-3"
                />

                <Button
                  name="submit"
                  type="submit"
                  className="mt-5 mt-md-0 w-auto"
                  disabled={disableButton}
                >
                  Importar
                </Button>
              </>
            )) || (
              <>
                <InputFile name="file" className="mb-4 mb-md-0" disabled />

                <Input
                  type="text"
                  name="startDisabled"
                  label="Data inicial"
                  icon={RiCake2Line}
                  placeholder="Data inicial"
                  aria-label="Data inicial"
                  mask="99/99/9999"
                  className="mb-4 mb-md-0 mx-md-3"
                  disabled
                />

                <Button
                  name="btDisabled"
                  type="button"
                  onClick={e => e.preventDefault()}
                  className="mt-5 mt-md-0 w-auto"
                  disabled
                >
                  Importar
                </Button>
              </>
            )}
          </Form>
        )}
        {listResponse && (
          <Row className="h-auto justify-content-center no-gutters overflow-hide">
            <Col className="h-100 overflow-auto" md={12}>
              <div className="text-primary text-uppercase font-weight-bold">
                Produtos Carregados:
                <span className="text-cinza-51-5bits text-uppercase ml-2 font-weight-bold">
                  {listResponse.length}
                </span>
              </div>
              <Table striped bordered hover size="sm" responsive="md">
                <thead className="text-center text-primary text-uppercase">
                  <tr>
                    <th className="font-weight-normal py-3" scope="col">
                      ID
                    </th>
                    <th className="font-weight-normal py-3" scope="col">
                      Nome do produto
                    </th>
                    <th className="font-weight-normal py-3" scope="col">
                      Preço
                    </th>
                    <th className="font-weight-normal py-3" scope="col">
                      Tamanho
                    </th>
                    <th className="font-weight-normal py-3" scope="col">
                      Cor
                    </th>
                    <th className="font-weight-normal py-3" scope="col">
                      Estoque
                    </th>
                    <th className="font-weight-normal py-3" scope="col">
                      Status
                    </th>
                    <th className="font-weight-normal py-3" scope="col">
                      Ações
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {permissions.read && (
                    <>
                      {listProductsPaginatedItems.map((item, index) => (
                        <tr key={index.toString()}>
                          <th scope="row">{item.id}</th>
                          <td className="text-left">{item.name}</td>
                          <td className="text-right pr-3">
                            {ConvertPriceBRL(item.price)}
                          </td>
                          <td>{item.size}</td>
                          <td>{item.color}</td>
                          <td>{item.stock}</td>
                          <td>
                            {(item.is_active === true && ' Ativo') ||
                              ' Inativo'}
                          </td>
                          <td>
                            {(permissions.update && (
                              <Link to={`/products/read/${item.id}`}>
                                Editar
                              </Link>
                            )) || (
                              <span className="text-cinza-51-5bits">
                                Editar
                              </span>
                            )}
                          </td>
                        </tr>
                      ))}
                    </>
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
        )}
      </CreateWithListingTemplate>
    </>
  );
};

export default ImportProducts;
