/* eslint-disable func-names */
import axios, { AxiosRequestConfig } from 'axios';
// import signOutIfTokenExpired from '../utils/signOutIfTokenExpired';
/**
 * [x] criar um arquivo externo com as configurações
 * [ ] nome da aplicação para o token
 */

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use(async (config = {} as AxiosRequestConfig) => {
  const headersAuthorization = config;
  const token = sessionStorage.getItem('@Stock5b:token');

  if (
    token &&
    headersAuthorization &&
    Object.keys(headersAuthorization).length > 0
  ) {
    if (headersAuthorization.headers) {
      headersAuthorization.headers.Authorization = `Bearer ${token}`;
    }
  }

  return headersAuthorization;
});

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const statusCodTokenExpired = 302;
    if (error.response.status === statusCodTokenExpired) {
      setTimeout(() => {
        sessionStorage.clear();
        window.location.href = '/';
      }, 5500);
    }

    return Promise.reject(error);
  },
);

export default api;
