/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import React, { useCallback, useRef, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { FiUser, FiEdit } from 'react-icons/fi';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ComplexCreateAndUpdateTemplate from '../../components/Template/ComplexCreateAndUpdateTemplate';

import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';
import convertToNumber from '../../utils/convertToNumber';
import validationCPF from '../../utils/validationCPF';
import { useToast } from '../../context/ToastContext';

import Input from '../../components/Input';
import Checkbox from '../../components/Checkbox';
import Button from '../../components/Button';

import DependentsUpdate from './updateDependents';

interface DependentsFormDataDTO {
  id?: number | string;
  name: string;
  phone: string;
  is_active?: boolean | string;
}

interface ClientFormDataDTO {
  id: number;
  name: string;
  cpf: string;
  dt_birth: string;
  address: string;
  phone: string;
  email: string;
  is_activeText?: string;
  is_active: boolean;

  dependents?: Array<DependentsFormDataDTO>;
  // Estes dois campos são retirados do objeto no submit
  nameDependentFormAdd?: string;
  phoneDependentFormAdd?: string;
}

/**
 * [ ] Ver o history com o Daniel após alterar user
 */
const UpdateClient: React.FC<ClientFormDataDTO> = () => {
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { id }: any = useParams();
  const [client, setClient] = useState<ClientFormDataDTO>(
    {} as ClientFormDataDTO,
  );

  const [disableButton, setDisableButton] = useState(false);
  /*
  const currentUserLogged = JSON.parse(
    sessionStorage.getItem('@Stock5b:user')!,
  );
  */

  // eslint-disable-next-line no-bitwise

  useEffect(() => {
    const getClient = async (): Promise<void> => {
      try {
        const response = await api.get(`clients/${id}`);
        setClient({
          ...response.data,
          dt_birth: response.data.dt_birth,
        });

        /**
         * Preenche os campos do formulário. Sem esta linha os inputs com
         * máscara não são preenchidos.
         */
        formRef.current?.setData({
          ...response.data,
          dt_birth: response.data.dt_birth,
        });
        // setIsVisibleFormDependents(response.data.dependents.length > 0);
      } catch (error) {
        // eslint-disable-next-line no-shadow
        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: data.message,
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Ocorreu um erro interno',
          description: `${error}`,
        });
      }
    };
    getClient();
  }, [addToast, id]);

  // handleSubmitOutsideTheForm
  /**
   * Função chamada no botão Salvar fora do form de clientes.
   * É o último botão depois de todos os forms.
   */
  const handleSubmitOutsideTheForm = useCallback(() => {
    formRef.current?.submitForm();
  }, []);
  // Fim handleSubmitOutsideTheForm

  // handleSubmit
  const handleSubmit = useCallback(
    async (dataForm: ClientFormDataDTO) => {
      try {
        setDisableButton(true);

        // Remove os campos que não devem ir para o banco de dados
        delete dataForm.nameDependentFormAdd;
        delete dataForm.phoneDependentFormAdd;

        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          cpf: Yup.string()
            .min(11)
            .test('cpf', 'CPF inválido', () => {
              return validationCPF(dataForm.cpf);
            })
            .required('CPF obrigatório'),
          phone: Yup.string().test('phone', 'Telefone inválido', () => {
            return (
              convertToNumber(dataForm.phone).length === 11 ||
              convertToNumber(dataForm.phone).length === 10
            );
          }),
          dependents: Yup.array().of(
            Yup.object().shape({
              name: Yup.string().required('Nome obrigatório'),
              phone: Yup.string()
                .test('phone', 'Telefone inválido', v => {
                  return (
                    convertToNumber(v!).length === 11 ||
                    convertToNumber(v!).length === 10
                  );
                })
                .required('Telefone obrigatório'),
            }),
          ),
        });

        await schema.validate(dataForm, {
          abortEarly: false,
        });

        let newData = {} as ClientFormDataDTO;

        if (dataForm.dependents) {
          const newDependents: any = [];
          // eslint-disable-next-line array-callback-return
          dataForm.dependents?.map((item, index) => {
            if (!item.id) {
              delete item.id;
            }
            if (item.is_active === 'true') {
              newDependents[index] = {
                ...item,
                is_active: true,
              };
            } else {
              newDependents[index] = {
                ...item,
                is_active: false,
              };
            }
          });

          newData = {
            ...dataForm,
            is_active: dataForm.is_activeText === 'true',
            dependents: newDependents,
          };

          delete newData.is_activeText;
        } else {
          newData = {
            ...dataForm,
            is_active: dataForm.is_activeText === 'true',
          };

          delete newData.is_activeText;
        }

        await api.put('clients', newData);

        addToast({
          type: 'success',
          title: 'Alteração realizada com sucesso',
        });

        setDisableButton(false);

        history.push('/clients/list');
      } catch (error) {
        setDisableButton(false);

        // Verifica se o error é do Yup ou seja dos campos
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);

          return;
        }
        // eslint-disable-next-line no-shadow
        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response

          addToast({
            type: 'error',
            title: 'Erro na alteração do cadastro',
            description: data.message,
          });
          if (
            formRef.current?.getFieldRef(data.field) &&
            data.field.toString().indexOf('_id') === -1
          ) {
            formRef.current?.getFieldRef(data.field).focus(); // Foca o campo que deu erro de acordo com o field retornado do back
          }

          return;
        }
        addToast({
          type: 'error',
          title: 'Ocorreu um erro interno',
        });
      }
    },
    [addToast, history],
  );
  // Fim handleSubmit

  /**
   * Sem o initialData o id não é preenchido
   */
  return (
    <ComplexCreateAndUpdateTemplate title="Atualização de Clientes">
      <Form
        ref={formRef}
        initialData={client}
        onSubmit={handleSubmit}
        className="h-100"
      >
        <Row noGutters className="w-100 h-100">
          <Col md={6} className=" h-100 overflow-auto">
            <Input type="hidden" name="id" isHidden />
            <Input
              type="text"
              name="name"
              label="Nome Completo"
              icon={FiUser}
              placeholder="Nome Completo"
              maxLength={45}
              className="mb-2"
            />

            <Input
              type="text"
              name="cpf"
              label="CPF"
              icon={FiEdit}
              placeholder="CPF"
              mask="999.999.999-99"
              disabled
              className="mb-2"
            />

            <Input
              type="text"
              name="dt_birth"
              label="Data Aniversário"
              icon={FiEdit}
              placeholder="Data Aniversário"
              mask="99/99/9999"
              className="mb-2"
            />
            <Input
              type="text"
              name="address"
              label="Endereço"
              icon={FiEdit}
              placeholder="Endereço"
              maxLength={150}
              className="mb-2"
            />
            <Input
              type="text"
              name="neighborhood"
              label="Bairro"
              icon={FiEdit}
              placeholder="Bairro"
              maxLength={150}
              className="mb-2"
            />
            <Input
              type="text"
              name="phone"
              label="Telefone"
              icon={FiEdit}
              placeholder="Telefone"
              mask="(99) 9 9999-9999"
              className="mb-2"
            />
            <Input
              type="text"
              name="email"
              label="Email"
              icon={FiEdit}
              placeholder="Email"
              maxLength={255}
              className="mb-2"
            />

            <Checkbox
              name="is_activeText"
              propChecked={client?.is_active}
              className="mb-2"
            >
              Cliente Ativo
            </Checkbox>
          </Col>
          <Col md={6} className="overflow-auto h-100 pl-3">
            <DependentsUpdate
              refProps={formRef}
              dependentsProps={client?.dependents!}
            />
          </Col>
        </Row>
      </Form>
      <Row noGutters className="w-100 mt-5">
        <Col md={6} className="m-auto">
          <Button
            name="submit"
            type="button"
            onClick={handleSubmitOutsideTheForm}
            disabled={disableButton}
          >
            Salvar
          </Button>
        </Col>
      </Row>
    </ComplexCreateAndUpdateTemplate>
  );
};

export default UpdateClient;
