/* eslint-disable camelcase */
import React, { useEffect, useState, useCallback } from 'react';
import Table from 'react-bootstrap/Table';

import api from '../../../services/api';

import ListingTemplate from '../../../components/Template/ListingTemplate';
import Loader from '../../../components/Loader';
import { useToast } from '../../../context/ToastContext';
import convertDateToBr from '../../../utils/convertDateToBr';
import convertNumberFloatToBr from '../../../utils/convertNumberFloatToBr';

import { IPermission } from '../../../interfaces/IPermission';

interface TransferFormDataDTO {
  id: number;
  amount: string;
  created_at: string;
  observation: string;
  outgoingFinancialAccount: {
    id: number;
    name: string;
  };
  incomingFinancialAccount: {
    id: number;
    name: string;
  };
  user: {
    id: number;
    nickname: string;
  };
}

interface PermissionDTO {
  permissions: IPermission;
}

const ListTransfer: React.FC<PermissionDTO> = ({ permissions }) => {
  const [loading, setLoading] = useState(false);
  const [listTransfers, setListTransfers] = useState<
    Array<TransferFormDataDTO>
  >([]);
  const [
    listTransfersPaginatedItems,
    setListTransfersPaginatedItems,
  ] = useState<any[]>([]);
  const { addToast } = useToast();

  useEffect(() => {
    try {
      const getListTransfers = async (): Promise<void> => {
        try {
          setLoading(true);
          const response = await api.get('cash/financial-transfers');
          setListTransfers(response.data);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          // eslint-disable-next-line no-shadow
          if (error.response) {
            const { data } = error.response; // Error vindo do back está em data dentro de response
            addToast({
              type: 'error',
              title: data.message,
            });
            return;
          }

          addToast({
            type: 'error',
            title: 'Ocorreu um erro interno',
          });
        }
      };
      getListTransfers();
    } catch (error) {
      setLoading(false);
      // eslint-disable-next-line no-shadow
      if (error.response.data) {
        const { data } = error.response; // Error vindo do back está em data dentro de response
        addToast({
          type: 'error',
          title: data.message,
        });
        return;
      }

      addToast({
        type: 'error',
        title: 'Ocorreu um erro interno',
      });
    }
  }, [addToast]);

  const handleListTransfersPaginatedItems = useCallback(newList => {
    setListTransfersPaginatedItems(newList);
  }, []);

  return (
    <ListingTemplate
      title="Transferências entre contas"
      module="cash/financial-transfers"
      moduleForSelectFind="cash/financial-accounts"
      listItemsProps={listTransfers}
      handleListItemsPaginatedItems={handleListTransfersPaginatedItems}
      placeHolderProp="Nome da Conta Financeira"
      permissions={permissions}
    >
      {(loading && <Loader />) || (
        <>
          <Table striped bordered hover size="sm" responsive="md">
            <thead className="text-center text-primary text-uppercase">
              <tr>
                <th className="font-weight-normal py-3" scope="col">
                  Saída
                </th>
                <th className="font-weight-normal py-3" scope="col">
                  Entrada
                </th>
                <th className="font-weight-normal py-3" scope="col">
                  Valor
                </th>
                <th className="font-weight-normal py-3" scope="col">
                  Data
                </th>
                <th className="font-weight-normal py-3" scope="col">
                  Usuário
                </th>
                <th className="font-weight-normal py-3" scope="col">
                  Observação
                </th>
              </tr>
            </thead>
            <tbody className="text-center">
              {listTransfersPaginatedItems.map((item, index) => (
                <tr key={index.toString()}>
                  <th scope="row" className="text-left">
                    {item.outgoingFinancialAccount.name}
                  </th>
                  <th scope="row" className="text-left">
                    {item.incomingFinancialAccount.name}
                  </th>
                  <td className="text-right pr-3">
                    {convertNumberFloatToBr(item.amount)}
                  </td>
                  <td>{convertDateToBr(item.created_at)}</td>
                  <td>{item.user.nickname}</td>
                  <td className="text-left">{item.observation}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
    </ListingTemplate>
  );
};

export default ListTransfer;
