/* eslint-disable camelcase */
import React, { useCallback, useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Cookie from 'js-cookie';

import SimpleCreateAndUpdateTemplate from '../../../components/Template/SimpleCreateAndUpdateTemplate';
import api from '../../../services/api';
import getValidationErrors from '../../../utils/getValidationErrors';
import convertDateToDB from '../../../utils/convertDateToDB';
import convertNumberFloatToDB from '../../../utils/convertNumberFloatToDB';
import removeMaskCPFCNPJ from '../../../utils/removeMaskCPFCNPJ';
import { useToast } from '../../../context/ToastContext';

import Input from '../../../components/Input';
import Button from '../../../components/Button';
import SelectAsync from '../../../components/SelectAsync';
import SelectWithListLocal from '../../../components/SelectWithListLocal';
import InputCurrency from '../../../components/Input/InputCurrency';
import DatePicker from '../../../components/DatePicker';

import { useAuth } from '../../../context/AuthContext';

interface ReceiveFormDataDTO {
  sale_header_id: string;
  analytical_account_id: string;
  payment_method_id: any;
  client_id: any;
  amount: string;
  due_date: string;
}

const CreateReceive: React.FC = () => {
  const { configuration }: any = useAuth();
  const history = useHistory();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [disableButton, setDisableButton] = useState(false);
  const cookieName = `cookieFirstItemSelectPaymentMethods5Bits${removeMaskCPFCNPJ(
    configuration.cnpj,
  )}`;
  const cookieFirstItemSelectPaymentMethods = Cookie.get(cookieName);
  const [paymentMethods, setPaymentMethods] = useState<Array<any>>([]);
  const [listClients, setListClients] = useState<Array<any>>([]);

  useEffect(() => {
    try {
      const getLists = async (): Promise<void> => {
        try {
          const responsePaymentMethods: any = await api.get(
            'cash/payment-methods/active',
          );

          setPaymentMethods(responsePaymentMethods.data);

          if (cookieFirstItemSelectPaymentMethods) {
            formRef.current?.setFieldValue(
              'payment_method_id',
              responsePaymentMethods.data.find(
                (item: any) =>
                  item.id ===
                  parseInt(`${cookieFirstItemSelectPaymentMethods}`, 10),
              ),
            );
          }

          const responseListClients: any = await api.get('clients');
          setListClients(responseListClients.data);
        } catch (error) {
          if (error.response) {
            const { data } = error.response; // Error vindo do back está em data dentro de response
            addToast({
              type: 'error',
              title: data.message,
            });
            return;
          }

          addToast({
            type: 'error',
            title: 'Ocorreu um erro interno',
          });
        }
      };

      getLists();
    } catch (erro) {
      // eslint-disable-next-line no-shadow
      if (erro.response.data) {
        const { data } = erro.response; // Error vindo do back está em data dentro de response
        addToast({
          type: 'error',
          title: data.message,
        });
        return;
      }

      addToast({
        type: 'error',
        title: 'Ocorreu um erro interno',
      });
    }
  }, [addToast, cookieFirstItemSelectPaymentMethods]);

  // handleSubmit
  const handleSubmit = useCallback(
    async (dataForm: ReceiveFormDataDTO, { reset }) => {
      try {
        setDisableButton(true);

        if (cookieFirstItemSelectPaymentMethods !== undefined) {
          Cookie.remove(cookieName);
        }
        Cookie.set(cookieName, dataForm.payment_method_id.id, { expires: 365 });

        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          sale_header_id: Yup.string().required('Cód. da venda obrigatório'),
          analytical_account_id: Yup.string().required(
            'Cód. da conta analítica obrigatório',
          ),
          payment_method_id: Yup.object()
            .shape({
              id: Yup.string().required('Forma de pagamento obrigatório'),
            })
            .nullable()
            .required('Forma de pagamento obrigatório'),
          client_id: Yup.object()
            .shape({
              id: Yup.string().required('Cliente é obrigatório'),
            })
            .nullable()
            .required('Cliente é obrigatório'),
          amount: Yup.string().required('Valor obrigatório'),
          due_date: Yup.string()
            .required('Data de vencimento obrigatório')
            .nullable(),
        });

        await schema.validate(dataForm, {
          abortEarly: false,
        });

        const newData = [
          {
            sale_header_id: parseInt(dataForm.sale_header_id, 10),
            analytical_account_id: parseInt(dataForm.analytical_account_id, 10),
            payment_method_id: parseInt(dataForm.payment_method_id.id, 10),
            client_id: parseInt(dataForm.client_id.id, 10),
            amount: convertNumberFloatToDB(dataForm.amount),
            due_date: convertDateToDB(dataForm.due_date),
          },
        ];

        await api.post('sales/receive', newData);

        addToast({
          type: 'success',
          title: 'Cadastro realizado com sucesso',
        });

        reset();
        setDisableButton(false);
        history.push('/sales/receive/list');
      } catch (error) {
        setDisableButton(false);

        // Verifica se o error é do Yup ou seja dos campos
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);

          return;
        }
        // eslint-disable-next-line no-shadow
        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: 'Erro no cadastro',
            description: data.message,
          });
          if (formRef.current?.getFieldRef(data.field)) {
            formRef.current?.getFieldRef(data.field).focus(); // Foca o campo que deu erro de acordo com o field retornado do back
          }

          return;
        }

        addToast({
          type: 'error',
          title: `Ocorreu um erro interno`,
        });
      }
    },
    [addToast, cookieFirstItemSelectPaymentMethods, cookieName, history],
  );
  // Fim handleSubmit

  const handleLoadingClient = useCallback(
    async (inputValue: any): Promise<any> => {
      try {
        if (inputValue) {
          const response = await api.get(`clients/search/${inputValue}`);

          return response.data;
        }
        return [];
      } catch (erro) {
        if (erro.response) {
          const { data } = erro.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: data.message,
          });
          return false;
        }

        addToast({
          type: 'error',
          title: 'Ocorreu um erro interno',
        });
      }
      return false;
    },
    [addToast],
  );

  const loadOptions = async (inputValue: any, callback: any): Promise<any> => {
    if (!inputValue) return false;
    const response = await handleLoadingClient(inputValue);

    return callback(response);
  };

  return (
    <SimpleCreateAndUpdateTemplate title="Cadastro de contas a receber">
      <Form ref={formRef} className="mt-5" onSubmit={handleSubmit}>
        <Input type="hidden" name="sale_header_id" value={1} />
        <Input type="hidden" name="analytical_account_id" value={2} />
        <SelectWithListLocal
          name="payment_method_id"
          label="Forma de Pagamento"
          placeholder="Forma de Pagamento"
          options={paymentMethods}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          aria-label="Forma de Pagamento"
          maxLength={45}
          className="mr-md-2 mb-2"
        />
        <SelectAsync
          name="client_id"
          label="Nome do Cliente"
          defaultValue={
            listClients.length > 1
              ? listClients.filter((item: any) => item.id === 1)
              : listClients
          }
          placeholder="Nome do Cliente"
          aria-label="Nome do Cliente"
          defaultOptions={listClients}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          loadOptions={loadOptions}
          noOptionsMessage={() => 'Nenhum cliente encontrado'}
          maxLength={45}
          className="mb-2"
          isClearable
        />
        <div className="d-flex justify-content-between mb-2">
          <InputCurrency
            name="amount"
            label="Valor"
            aria-label="Valor"
            className="mr-4"
          />

          <DatePicker
            name="due_date"
            label="Data de Vencimento"
            aria-label="Data de Vencimento"
          />
        </div>
        <Button
          name="submit"
          type="submit"
          className="mt-5 w-auto"
          disabled={disableButton}
        >
          Cadastrar
        </Button>
      </Form>
    </SimpleCreateAndUpdateTemplate>
  );
};

export default CreateReceive;
