/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useCallback, useMemo } from 'react';

import Table from 'react-bootstrap/Table';
import Col from 'react-bootstrap/Col';

import { compareAsc, parseISO } from 'date-fns';

import ConvertPriceBRL from '../../../../../utils/convertPriceBRL';
import convertDateToDB from '../../../../../utils/convertDateToDB';
import returnDateNow from '../../../../../utils/returnDateNow';

interface HeaderSalesFormDTO {
  paymentMethodsComplete: Array<any>[any];
  saleId: number;
}

const FooterSellUpdate: React.FC<HeaderSalesFormDTO> = ({
  paymentMethodsComplete,
}) => {
  const [listPaymentMethods, setListPaymentMethods] = useState<Array<any>>([]);
  const listSecondPaymentMethod: any = useMemo(() => [], []);

  const filterListPaymentMethods = useCallback(
    obj => {
      if (
        paymentMethodsComplete &&
        obj.payment_method_id === paymentMethodsComplete[0].payment_method_id
      ) {
        return true;
      }
      listSecondPaymentMethod.push(obj);
      return false;
    },
    [paymentMethodsComplete, listSecondPaymentMethod],
  );

  useEffect(() => {
    if (paymentMethodsComplete && paymentMethodsComplete.length > 0) {
      const newListPaymentMethods = [
        paymentMethodsComplete.filter(filterListPaymentMethods),
        listSecondPaymentMethod,
      ];
      setListPaymentMethods(newListPaymentMethods);
    }
  }, [paymentMethodsComplete]);

  return (
    <Col className="overflow-auto">
      <Table
        style={{ fontSize: '12px' }}
        striped
        bordered
        hover
        size="sm"
        responsive="md"
      >
        <thead className="text-center text-primary text-uppercase">
          <tr>
            <th className="font-weight-normal py-3" scope="col">
              Forma de Pagamento
            </th>
            <th className="font-weight-normal py-3" scope="col">
              Desconto
            </th>
            <th className="font-weight-normal py-3" scope="col">
              Acréscimo
            </th>
            <th className="font-weight-normal py-3" scope="col">
              Valor
            </th>
            <th className="font-weight-normal py-3" scope="col">
              Vencimento
            </th>
            <th className="font-weight-normal py-3" scope="col">
              Status
            </th>
          </tr>
        </thead>
        <tbody className="text-center">
          {listPaymentMethods.map(item => {
            return item.map((subItem: any, indexSubItem: number) => (
              <tr key={indexSubItem.toString()}>
                <th scope="row">{subItem.name}</th>
                <td>{ConvertPriceBRL(subItem.discount)}</td>
                <td>{ConvertPriceBRL(subItem.addition)}</td>
                <td>{ConvertPriceBRL(subItem.amount)}</td>
                {(compareAsc(
                  parseISO(returnDateNow('yyyy-MM-dd')),
                  parseISO(convertDateToDB(subItem.due_date)),
                ) === 1 &&
                  subItem.is_paid === false && (
                    <td className="bg-vermelho-suave-5bits font-weight-bold">
                      {subItem.due_date}
                    </td>
                  )) || <td>{subItem.due_date}</td>}

                <td className="text-success font-weight-bold">
                  {subItem.is_paid && 'Pago'}
                </td>
              </tr>
            ));
          })}
        </tbody>
      </Table>
    </Col>
  );
};

export default FooterSellUpdate;
