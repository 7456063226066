/* eslint-disable camelcase */
import React, { useCallback, useState, useRef } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { FiEdit } from 'react-icons/fi';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import SimpleCreateAndUpdateTemplate from '../../components/Template/SimpleCreateAndUpdateTemplate';
import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';
import convertNumberFloatToDB from '../../utils/convertNumberFloatToDB';
import { useToast } from '../../context/ToastContext';

import Input from '../../components/Input';
import CurrencyInput from '../../components/Input/InputCurrency';
import Button from '../../components/Button';

interface ProductFormDataDTO {
  id: string;
  name: string;
  price: string | number;
  size: string;
  color: string;
  min_stock: string;
  is_active: boolean;
  barcode: string;
}

/**
 * [ ] Ver o history com o Daniel após cadastrar product
 * [ ] Ver campo sold_amount
 */

const Product: React.FC = () => {
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [disableButton, setDisableButton] = useState(false);

  // handleSubmit
  const handleSubmit = useCallback(
    async (dataForm: ProductFormDataDTO, { reset }) => {
      try {
        setDisableButton(true);
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          price: Yup.string()
            .test('price', 'Preço obrigatório', () => {
              return convertNumberFloatToDB(dataForm.price.toString()) > 0;
            })
            .required('Preço obrigatório'),
          size: Yup.string().required('Tamanho obrigatório'),
          color: Yup.string().required('Cor obrigatório'),
        });

        await schema.validate(dataForm, {
          abortEarly: false,
        });

        const newData = {
          ...dataForm,
          price: convertNumberFloatToDB(dataForm.price.toString()),
        } as ProductFormDataDTO;

        await api.post('products', newData);

        addToast({
          type: 'success',
          title: 'Cadastro realizado com sucesso',
        });

        reset();
        setDisableButton(false);
        // history.push('/home');
      } catch (error) {
        setDisableButton(false);

        // Verifica se o error é do Yup ou seja dos campos
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);

          return;
        }
        // eslint-disable-next-line no-shadow
        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: 'Erro no cadastro',
            description: data.message,
          });
          if (formRef.current?.getFieldRef(data.field)) {
            formRef.current?.getFieldRef(data.field).focus(); // Foca o campo que deu erro de acordo com o field retornado do back
          }

          return;
        }

        addToast({
          type: 'error',
          title: 'Ocorreu um erro interno',
        });
      }
    },
    [addToast],
  );
  // Fim handleSubmit

  return (
    <SimpleCreateAndUpdateTemplate
      title="Cadastro de Produtos"
      numberColContent={12}
    >
      <Form ref={formRef} className="h-100 mt-5" onSubmit={handleSubmit}>
        <Row className="flex-row overflow-auto m-0">
          <Col md={6}>
            <Input
              type="text"
              name="name"
              label="Nome"
              icon={FiEdit}
              placeholder="Nome"
              maxLength={60}
              className="mb-2"
            />
          </Col>
          <Col md={6}>
            <CurrencyInput
              type="text"
              name="price"
              label="Preço"
              icon="R$"
              placeholder="Preço"
              className="mb-2"
            />
          </Col>
          <Col md={6}>
            <Input
              type="text"
              name="size"
              label="Tamanho"
              icon={FiEdit}
              placeholder="Tamanho"
              maxLength={45}
              className="mb-2"
            />
          </Col>
          <Col md={6}>
            <Input
              type="text"
              name="color"
              label="Cor"
              icon={FiEdit}
              placeholder="Cor"
              maxLength={30}
              className="mb-2"
            />
          </Col>
          <Col md={6}>
            <Input
              type="number"
              name="min_stock"
              label="Estoque Mínimo"
              icon={FiEdit}
              placeholder="Estoque Mínimo"
              className="mb-2"
            />
          </Col>
          <Col md={6}>
            <Input
              type="text"
              name="barcode"
              label="Código de Barras"
              icon={FiEdit}
              placeholder="Código de Barras"
              maxLength={60}
              className="mb-2"
            />
          </Col>
        </Row>
        <Row className="m-0">
          <Col md={6} className="m-auto">
            <Button
              name="submit"
              type="submit"
              className="mt-5 w-auto"
              disabled={disableButton}
            >
              Cadastrar
            </Button>
          </Col>
        </Row>
      </Form>
    </SimpleCreateAndUpdateTemplate>
  );
};

export default Product;
