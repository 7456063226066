import React, { useState, useEffect, useCallback, useMemo } from 'react';

import Table from 'react-bootstrap/Table';

import ConvertPriceBRL from '../../../../utils/convertPriceBRL';

import Button from '../../../../components/Button';

import Modal from '../../../../components/Modal';

interface ModalDTO {
  title: string;
  loadModalProp: boolean;
  listSaleProp: Array<any>;
  toggleLoadModalSale: () => void;
}

const ModalSimulateSell: React.FC<ModalDTO> = ({
  title,
  loadModalProp = false,
  listSaleProp,
  toggleLoadModalSale,
}) => {
  const [listSale, setListSale] = useState<Array<any>>([]);
  const listSaleSecondPaymentMethod: any = useMemo(() => [], []);

  const filterListSale = useCallback(
    obj => {
      if (
        listSaleProp &&
        obj.payment_method.id === listSaleProp[0].payment_method.id
      ) {
        return true;
      }

      listSaleSecondPaymentMethod.push(obj);
      return false;
    },
    [listSaleProp, listSaleSecondPaymentMethod],
  );

  useEffect(() => {
    // setLoadModal(loadModalProp);
    if (loadModalProp === true) {
      const newListSale = [
        listSaleProp.filter(filterListSale),
        listSaleSecondPaymentMethod,
      ];

      setListSale(newListSale);
    }
  }, [
    loadModalProp,
    listSaleProp,
    filterListSale,
    listSaleSecondPaymentMethod,
  ]);

  const clearListSale = useCallback(() => {
    setListSale([]);
  }, []);

  return (
    <Modal visibleProp={loadModalProp}>
      <div
        style={{ maxHeight: '70vh' }}
        className="d-flex flex-column align-items-center w-100 bg-branco-puro-5bits p-4"
      >
        <h1>{title}</h1>
        <div className="overflow-auto">
          <Table striped bordered hover size="sm" responsive="md">
            <thead className="text-center text-primary text-uppercase">
              <tr>
                <th className="font-weight-normal py-3" scope="col">
                  Forma de Pagamento
                </th>
                <th className="font-weight-normal py-3" scope="col">
                  Desconto
                </th>
                <th className="font-weight-normal py-3" scope="col">
                  Acréscimo
                </th>
                <th className="font-weight-normal py-3" scope="col">
                  Valor
                </th>
                <th className="font-weight-normal py-3" scope="col">
                  Vencimento
                </th>
              </tr>
            </thead>
            <tbody className="text-center">
              {listSale.map(item => {
                return item.map((subItem: any, indexSubItem: number) => (
                  <tr key={indexSubItem.toString()}>
                    <th scope="row">{subItem.payment_method.name}</th>
                    <td className="text-right pr-3">
                      {ConvertPriceBRL(subItem.discount)}
                    </td>
                    <td className="text-right pr-3">
                      {ConvertPriceBRL(subItem.addition)}
                    </td>
                    <td className="text-right pr-3">
                      {ConvertPriceBRL(subItem.amount)}
                    </td>
                    <td>{subItem.due_date}</td>
                  </tr>
                ));
              })}
            </tbody>
          </Table>
        </div>

        <div className="d-flex flex-row justify-content-around mt-3 w-50">
          <Button
            name="closeModal"
            type="button"
            className="w-50 mr-4"
            fontSizeButton="12px"
            onClick={() => {
              toggleLoadModalSale();
              clearListSale();
            }}
          >
            Fechar
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalSimulateSell;
