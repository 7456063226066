/*
ValidationError são os erros retornados do yup pois é a
biblioteca de validação utlizada
*/
import { ValidationError } from 'yup';
/*
interface de tipos dos erros. Tanto a chave
quanto o conteúdo dos erros são strings.
Não se sabe quantos erros e serão já que esta função
será utilizada em outras parte do app. Mas como tudo é
string pode fazer do jeito abaixo.
[key: string] no lugar do key poderia ser qualquer nome
*/
interface Errors {
  [key: string]: string;
}

/*
err contém o objeto com os erros capturados do yup
*/
export default function getValidationErrors(err: ValidationError): Errors {
  const validationErrors: Errors = {};
  /*
Os erros estão dentro de inner no objeto do yup
O error é cada item encontrado.
error.path é o caminho do erro, no caso o campo que ocorreu.
error.message é a mensagem de error.
*/

  err.inner.forEach((error: any) => {
    validationErrors[error.path] = error.message;
  });

  return validationErrors;
}
