/* eslint-disable camelcase */
import React, { useCallback, useState, useRef, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { parseISO } from 'date-fns';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { FiEdit, FiPrinter } from 'react-icons/fi';
import Spinner from 'react-bootstrap/Spinner';

import { Row, Col } from 'react-bootstrap';
import SimpleCreateAndUpdateTemplate from '../../components/Template/SimpleCreateAndUpdateTemplate';
import api from '../../services/api';
import convertNumberFloatToBr from '../../utils/convertNumberFloatToBr';
// import convertDateToBr from '../../utils/convertDateToBr';
import getValidationErrors from '../../utils/getValidationErrors';
import { useToast } from '../../context/ToastContext';

import Button from '../../components/Button';
import Input from '../../components/Input';
import CurrencyInput from '../../components/Input/InputCurrency';
import Checkbox from '../../components/Checkbox';
import DatePicker from '../../components/DatePicker';

import convertDateToDB from '../../utils/convertDateToDB';

interface DiscountCouponFormDataDTO {
  id: number;
  expiration_date: string;
  amount: string;
  client_name: string;
  is_activeText: string;
  is_active: boolean;
}

const UpdateDiscountCoupon: React.FC = () => {
  const { addToast } = useToast();
  const { id }: any = useParams();
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [disableButton, setDisableButton] = useState(false);
  const [loadingPDF, setLoadingPDF] = useState(false);
  const [
    discountCoupon,
    setDiscountCoupon,
  ] = useState<DiscountCouponFormDataDTO>({} as DiscountCouponFormDataDTO);

  const sessionStorageUser: any = sessionStorage.getItem('@Stock5b:user');
  const currentUserLogged = JSON.parse(sessionStorageUser);

  useEffect(() => {
    const getDiscountCoupon = async (): Promise<void> => {
      try {
        const response = await api.get(`discount-coupons/${id}`);

        setDiscountCoupon({
          ...response.data,
          expiration_date: response.data.expiration_date
            ? parseISO(response.data.expiration_date)
            : null,
          amount: convertNumberFloatToBr(response.data.amount),
          client_name: response.data.client_name,
        });
        /**
         * Preenche os campos do formulário. Sem esta linha os inputs com
         * máscara não são preenchidos.
         */
        formRef.current?.setData({
          ...response.data,
          expiration_date: response.data.expiration_date
            ? parseISO(response.data.expiration_date)
            : null,
          amount: convertNumberFloatToBr(response.data.amount),
          client_name: response.data.client_name,
        });
      } catch (error) {
        // eslint-disable-next-line no-shadow
        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: data.message,
          });
          return;
        }

        addToast({
          type: 'error',
          title: `Ocorreu um erro interno`,
        });
      }
    };
    getDiscountCoupon();
  }, [addToast, id]);

  // handleSubmit
  const handleSubmit = useCallback(
    async (dataForm: DiscountCouponFormDataDTO, { reset }) => {
      try {
        setDisableButton(true);
        formRef.current?.setErrors({});
        // const schema = Yup.object().shape({
        //   is_active: Yup.string().required('Cognome obrigatório'),
        // });

        // await schema.validate(dataForm, {
        //   abortEarly: false,
        // });

        let isActiveBoolean;

        if (currentUserLogged.is_admin) {
          isActiveBoolean = dataForm.is_activeText === 'true';
        } else {
          isActiveBoolean = discountCoupon.is_active;
        }

        const newData = {
          id: discountCoupon.id,
          expiration_date: convertDateToDB(dataForm.expiration_date),
          is_active: isActiveBoolean,
        };

        await api.put('discount-coupons', newData);

        // setIdCouponModal(response.data.id);
        // setLoadModalPrint(true);

        addToast({
          type: 'success',
          title: 'Alteração realizada com sucesso',
        });

        reset();
        setDisableButton(false);
        history.push('/discount-coupons/list');
      } catch (error) {
        // setLoadModalPrint(false);
        // setIdCouponModal(0);
        setDisableButton(false);

        // Verifica se o error é do Yup ou seja dos campos
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);

          return;
        }
        // eslint-disable-next-line no-shadow
        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: 'Erro na alteração do cadastro',
            description: data.message,
          });
          if (formRef.current?.getFieldRef(data.field)) {
            formRef.current?.getFieldRef(data.field).focus(); // Foca o campo que deu erro de acordo com o field retornado do back
          }

          return;
        }

        addToast({
          type: 'error',
          title: 'Ocorreu um erro interno',
        });
      }
    },
    [
      addToast,
      currentUserLogged.is_admin,
      discountCoupon.id,
      discountCoupon.is_active,
      history,
    ],
  );
  // Fim handleSubmit

  // handlePrintPDF
  const handlePrintPDF = useCallback(async (): Promise<void> => {
    try {
      setLoadingPDF(true);

      const response = await api.get(
        `discount-coupons/document-pdf/${discountCoupon.id}`,
        {
          responseType: 'arraybuffer',
        },
      );

      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: 'application/pdf' }),
      );
      setLoadingPDF(false);
      window.open(url);
    } catch (error) {
      setLoadingPDF(false);
      // eslint-disable-next-line no-shadow
      if (error.response) {
        const { data } = error.response; // Error vindo do back está em data dentro de response
        addToast({
          type: 'error',
          title: data.message,
        });
        return;
      }

      addToast({
        type: 'error',
        title: `Ocorreu um erro interno`,
        description: `${error}`,
      });
    }
  }, [addToast, discountCoupon.id]);
  // Fim handlePrintPDF

  return (
    <SimpleCreateAndUpdateTemplate title="Detalhes do Cupom de Desconto">
      <Form
        ref={formRef}
        className="mt-5"
        initialData={discountCoupon}
        onSubmit={handleSubmit}
      >
        <Row noGutters>
          <Col md={12} className="mb-3">
            <div className="text-primary text-uppercase font-weight-bold">
              Cód do Cupom:
              <span className="text-cinza-51-5bits text-uppercase ml-2 font-weight-bold">
                {discountCoupon && discountCoupon.id}
              </span>
            </div>
          </Col>
          <Col md={6}>
            <DatePicker
              name="expiration_date"
              label="Data de Expiração"
              icon={FiEdit}
              className="mb-2"
              wrapperClassName="w-70"
            />
          </Col>
          <Col md={6}>
            <CurrencyInput
              type="text"
              name="amount"
              label="Valor"
              icon="R$"
              placeholder="Valor"
              className="mb-4"
              disabled
            />
          </Col>
          <Col
            md={12}
            className="d-flex flex-column justify-content-center align-items-center"
          >
            <Input
              type="text"
              name="client_name"
              label="Nome do Cliente"
              icon={FiEdit}
              placeholder="Nome do Cliente"
              maxLength={45}
              className="mb-2"
              disabled
            />
            {currentUserLogged.is_admin === true &&
              discountCoupon.is_active === true && (
                <Checkbox
                  name="is_activeText"
                  propChecked={discountCoupon.is_active}
                >
                  Cupom Ativo
                </Checkbox>
              )}

            {(loadingPDF && (
              <Spinner animation="border" variant="primary" className="mt-5" />
            )) || (
              <Button
                name="print"
                type="button"
                fontSizeButton="12px"
                className="mt-5 w-50"
                onClick={e => {
                  e.preventDefault();
                  handlePrintPDF();
                }}
              >
                <FiPrinter size={26} />
              </Button>
            )}

            <Button
              name="submit"
              type="submit"
              className="mt-5 w-auto"
              disabled={disableButton}
            >
              Salvar
            </Button>
          </Col>
        </Row>
      </Form>
    </SimpleCreateAndUpdateTemplate>
  );
};

export default UpdateDiscountCoupon;
