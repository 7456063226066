/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useState } from 'react';
import { FormHandles } from '@unform/core';

import Col from 'react-bootstrap/Col';
import Input from '../../../../../components/Input';
import SelectWithListLocal from '../../../../../components/SelectWithListLocal';
import SelectAsync from '../../../../../components/SelectAsync';
import { useToast } from '../../../../../context/ToastContext';
import api from '../../../../../services/api';
// import { handleListMenus } from '../../../../../utils/handleListMenus';

interface HeaderSalesFormDTO {
  listClients: Array<any>[];
  listEmployees: Array<any>[];
  formRef: any | FormHandles;
}

const HeaderSell: React.FC<HeaderSalesFormDTO> = ({
  listClients,
  listEmployees,
  formRef,
}) => {
  const [listDependents, setListDependents] = useState<any[]>([]);
  const [clientId, setClientId] = useState('');
  const [dependentName, setDependentName] = useState('');
  const [employeeId, setEmployeeId] = useState('');
  const { addToast } = useToast();

  const handleInputChangeClient = useCallback(
    // eslint-disable-next-line consistent-return
    async (inputValue: any) => {
      try {
        // /Limpa o campo hidden e o select de dependente
        formRef.current.clearField('dependent_id');
        formRef.current.clearField('dependent_name');
        if (inputValue) {
          const response = await api.get(`clients/active/${inputValue.id}`);

          setListDependents(response.data.dependents);
          setClientId(response.data.id);
        } else {
          setClientId('');
        }
      } catch (erro) {
        // eslint-disable-next-line no-shadow
        if (erro.response) {
          const { data } = erro.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: data.message,
          });
          // eslint-disable-next-line consistent-return
          return;
        }

        addToast({
          type: 'error',
          title: 'Ocorreu um erro interno',
        });
      }
    },
    [addToast, formRef],
  );

  const handleLoadingClient = useCallback(
    // eslint-disable-next-line consistent-return
    async (inputValue: any) => {
      try {
        if (inputValue) {
          const response = await api.get(`clients/search/active/${inputValue}`);

          return response.data;
        }
        return [];
      } catch (erro) {
        // eslint-disable-next-line no-shadow
        if (erro.response) {
          const { data } = erro.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: data.message,
          });
          // eslint-disable-next-line consistent-return
          return;
        }

        addToast({
          type: 'error',
          title: 'Ocorreu um erro interno',
        });
      }
    },
    [addToast],
  );

  const handleInputChangeDependent = useCallback((inputValue: any) => {
    if (inputValue) {
      setDependentName(inputValue.name);
    }
  }, []);

  const handleInputChangeEmployee = useCallback((inputValue: any) => {
    if (inputValue) {
      setEmployeeId(inputValue.id);
    }
  }, []);

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const loadOptions = async (inputValue: any, callback: any) => {
    if (!inputValue) return;
    const response = await handleLoadingClient(inputValue);

    // eslint-disable-next-line consistent-return
    return callback(response);
  };

  return (
    <Col>
      <Col md={12} className="d-md-flex flex-row p-0">
        <Input
          type="text"
          name="client_id"
          label="CÓD."
          placeholder="CÓD."
          value={clientId}
          fontSizeInput="12px"
          fontSizeLabel="12px"
          className="mr-md-2 mb-2 w-25"
          disabled
        />
        <Input type="hidden" value={employeeId} name="employee_id" isHidden />
        <SelectAsync
          name="client_id_select"
          label="Nome do Cliente"
          placeholder="Nome do Cliente"
          fontSize="12px"
          defaultOptions={listClients}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          loadOptions={loadOptions}
          noOptionsMessage={() => 'Nenhum cliente encontrado'}
          maxLength={45}
          className="mb-2"
          onChange={handleInputChangeClient}
          isClearable
        />
        <Input
          type="hidden"
          value={dependentName}
          name="dependent_name"
          isHidden
        />

        <SelectWithListLocal
          name="dependent_id"
          label="Nome do Dependente"
          placeholder="Nome do Dependente"
          fontSize="12px"
          sizeIcon={12}
          options={listDependents}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          aria-label="Nome do Dependente"
          maxLength={45}
          className="ml-md-2 mb-2"
          onChange={handleInputChangeDependent}
        />
        <SelectWithListLocal
          name="employee_id_select"
          label="Nome do Vendedor(A)"
          placeholder="Nome do Vendedor(A)"
          fontSize="12px"
          sizeIcon={12}
          options={listEmployees}
          getOptionLabel={option => option.nickname}
          getOptionValue={option => option.id}
          aria-label="Nome do Vendedor(A)"
          maxLength={45}
          className="ml-md-2 mb-2 w-60"
          onChange={handleInputChangeEmployee}
        />
      </Col>
    </Col>
  );
};

export default HeaderSell;
