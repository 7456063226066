/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useState, useEffect } from 'react';
import { FormHandles } from '@unform/core';

import Col from 'react-bootstrap/Col';
import Input from '../../../../../components/Input';
import SelectWithListLocal from '../../../../../components/SelectWithListLocal';
// import SelectAsync from '../../../../../components/SelectAsync';
import SelectAsyncCreatable from '../../../../../components/SelectAsyncCreatable';
import { useToast } from '../../../../../context/ToastContext';
import api from '../../../../../services/api';
// import { handleListMenus } from '../../../../../utils/handleListMenus';

interface HeaderSalesFormDTO {
  listClients: Array<any>[];
  listEmployees: Array<any>[];
  listPaymentMethods: Array<any>[];
  formRef: any | FormHandles;
}

const HeaderConditional: React.FC<HeaderSalesFormDTO> = ({
  listClients,
  listEmployees,
  listPaymentMethods,
  formRef,
}) => {
  const [listClientsSelect, setListClientsSelect] = useState<any[]>([]);
  const [listDependents, setListDependents] = useState<any[]>([]);
  const [dependentName, setDependentName] = useState('');
  const [employeeId, setEmployeeId] = useState('');
  const [disabledDependentsSelect, setDisabledDependentsSelect] = useState(
    false,
  );
  const { addToast } = useToast();

  useEffect(() => {
    const getListClient = (): void => {
      const newListClientForSelect = listClients.map((item: any) => {
        return { label: item.name, value: item.id };
      });
      setListClientsSelect(newListClientForSelect);
    };
    getListClient();
  }, [listClients]);

  const handleInputChangeClient = useCallback(
    // eslint-disable-next-line consistent-return
    async (inputValue: any) => {
      try {
        // /Limpa o campo hidden e o select de dependente
        formRef.current.clearField('dependent_id');
        formRef.current.clearField('dependent_name');
        if (inputValue && inputValue.value) {
          const response = await api.get(`clients/active/${inputValue.value}`);
          setDisabledDependentsSelect(false);
          setListDependents(response.data.dependents);
          // setClientId(response.data.id);
          formRef.current.setFieldValue('address', response.data.address);
          formRef.current.setFieldValue(
            'neighborhood',
            response.data.neighborhood,
          );
          formRef.current.setFieldValue('phone', response.data.phone);
        } else {
          setListDependents([]);
          setDisabledDependentsSelect(true);
          formRef.current.setFieldValue('address', '');
          formRef.current.setFieldValue('neighborhood', '');
          formRef.current.setFieldValue('phone', '');
        }
      } catch (erro) {
        // eslint-disable-next-line no-shadow
        if (erro.response) {
          const { data } = erro.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: data.message,
          });
          // eslint-disable-next-line consistent-return
          return;
        }

        addToast({
          type: 'error',
          title: 'Ocorreu um erro interno',
        });
      }
    },
    [addToast, formRef],
  );

  // handleLoadingClient
  const handleLoadingClient = useCallback(
    // eslint-disable-next-line consistent-return
    async (inputValue: any) => {
      try {
        if (inputValue) {
          const response = await api.get(`clients/search/active/${inputValue}`);

          const responseForSelect = response.data.map((item: any) => {
            return { label: item.name, value: item.id };
          });

          return responseForSelect;
        }

        return [];
      } catch (erro) {
        // eslint-disable-next-line no-shadow
        if (erro.response) {
          const { data } = erro.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: data.message,
          });
          // eslint-disable-next-line consistent-return
          return;
        }

        addToast({
          type: 'error',
          title: 'Ocorreu um erro interno',
        });
      }
    },
    [addToast],
  );
  // Fim handleLoadingClient

  const handleInputChangeDependent = useCallback((inputValue: any) => {
    if (inputValue) {
      setDependentName(inputValue.name);
    }
  }, []);

  const handleInputChangeEmployee = useCallback((inputValue: any) => {
    if (inputValue) {
      setEmployeeId(inputValue.id);
    }
  }, []);

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const loadOptions = async (inputValue: any, callback: any) => {
    if (!inputValue) return;
    const response = await handleLoadingClient(inputValue);
    // eslint-disable-next-line consistent-return
    return callback(response);
  };

  return (
    <Col>
      <Col md={12} className="d-md-flex flex-row p-0">
        <Input type="hidden" value={employeeId} name="employee_id" isHidden />
        <SelectAsyncCreatable
          name="client_id_select"
          label="Nome do Cliente"
          placeholder="Nome do Cliente"
          fontSize="12px"
          defaultOptions={listClientsSelect}
          loadOptions={loadOptions}
          noOptionsMessage={() => 'Nenhum cliente encontrado'}
          allowCreateWhileLoading
          maxLength={45}
          className="mb-2"
          onChange={handleInputChangeClient}
          isClearable
        />
        <Input
          type="hidden"
          value={dependentName}
          name="dependent_name"
          isHidden
        />

        <SelectWithListLocal
          name="dependent_id"
          label="Nome do Dependente"
          placeholder="Nome do Dependente"
          fontSize="12px"
          sizeIcon={12}
          options={listDependents}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          aria-label="Nome do Dependente"
          maxLength={45}
          className="ml-md-2 mb-2"
          onChange={handleInputChangeDependent}
          isDisabled={disabledDependentsSelect}
        />
      </Col>
      <Col md={12} className="d-md-flex flex-row p-0">
        <Input
          type="text"
          name="phone"
          label="Telefone"
          placeholder="Telefone"
          mask="(99) 9 9999-9999"
          fontSizeLabel="12px"
          fontSizeInput="12px"
          className="mr-md-2 mb-2 w-50"
        />
        <Input
          type="text"
          name="address"
          label="Endereço"
          placeholder="Endereço"
          fontSizeLabel="12px"
          fontSizeInput="12px"
          maxLength={150}
          className="mb-2"
        />
        <Input
          type="text"
          name="neighborhood"
          label="Bairro"
          placeholder="Bairro"
          fontSizeLabel="12px"
          fontSizeInput="12px"
          maxLength={150}
          className="ml-md-2 mb-2"
        />
      </Col>

      <Col md={12} className="d-md-flex flex-row p-0">
        <SelectWithListLocal
          name="payment_method_id_select"
          label="Forma de Pagamento"
          placeholder="Forma de Pagamento"
          fontSize="12px"
          sizeIcon={12}
          options={listPaymentMethods}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          aria-label="Forma de Pagamento"
          maxLength={45}
          className="mr-md-2 mb-2"
        />
        <SelectWithListLocal
          name="employee_id_select"
          label="Vendedor(A)"
          placeholder="Vendedor(A)"
          fontSize="12px"
          sizeIcon={12}
          options={listEmployees}
          getOptionLabel={option => option.nickname}
          getOptionValue={option => option.id}
          aria-label="Vendedor(A)"
          maxLength={45}
          className="mb-2"
          onChange={handleInputChangeEmployee}
        />
        <Input
          type="text"
          name="observation"
          label="Observação"
          placeholder="Observação"
          fontSizeLabel="12px"
          fontSizeInput="12px"
          className="ml-md-2 mb-2"
        />
      </Col>
    </Col>
  );
};

export default HeaderConditional;
