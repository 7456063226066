/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import fincalc from 'fincalc';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import SimpleCreateAndUpdateTemplate from '../../../../components/Template/SimpleCreateAndUpdateTemplate';

import convertDateToBr from '../../../../utils/convertDateToBr';
import convertNumberFloatToBr from '../../../../utils/convertNumberFloatToBr';

import Button from '../../../../components/Button';

import Modal from '../../../../components/Modal';

import { useToast } from '../../../../context/ToastContext';
import api from '../../../../services/api';

interface BillReceiveHeaderFormDTO {
  id: number;
  sale_header_id: number;
  analytical_account_id: number;
  payment_method_id: number;
  client_id: number;
  amount: string;
  amount_received: string;
  due_date: string;
  discount: string;
  addition: string;
  is_paid: boolean;
  bills_receive_body: Array<any>;

  amount_receiving: string;
  payment_methods?: any;
}

interface ModalDTO {
  idReceiveProp: number;
  title: string;
  loadModalProp: boolean;
  toggleLoadModalDetailsReceive: () => void;
  closeAndClearModalDetailsReceive: () => void;
}

const ModalDetailsReceive: React.FC<ModalDTO> = ({
  idReceiveProp,
  title,
  loadModalProp = false,
  closeAndClearModalDetailsReceive,
}) => {
  const { addToast } = useToast();
  const [billReceive, setBillReceive] = useState<BillReceiveHeaderFormDTO>(
    {} as BillReceiveHeaderFormDTO,
  );
  const [paymentMethodsComplete, setPaymentMethodsComplete] = useState<any[]>();

  const [loadModalWait, setLoadModalWait] = useState(false);

  useEffect(() => {
    if (idReceiveProp > 0) {
      const getBillReceive = async (): Promise<void> => {
        setLoadModalWait(true);
        try {
          const response = await api.get(`sales/receive/${idReceiveProp}`);

          const responsePaymentMethodsComplete = await api.get(
            'cash/payment-methods',
          );

          const newData = {
            ...response.data,
            amount_receiving: convertNumberFloatToBr(
              fincalc(
                parseFloat(response.data.amount) -
                  parseFloat(response.data.amount_received),
              ),
            ),
          };

          setBillReceive(newData);
          setPaymentMethodsComplete(responsePaymentMethodsComplete.data);
          setLoadModalWait(false);
        } catch (error) {
          setLoadModalWait(false);
          // eslint-disable-next-line no-shadow
          if (error.response) {
            const { data } = error.response; // Error vindo do back está em data dentro de response
            addToast({
              type: 'error',
              title: data.message,
            });
            return;
          }

          addToast({
            type: 'error',
            title: 'Ocorreu um erro interno',
          });
        }
      };
      getBillReceive();
    }
  }, [addToast, idReceiveProp]);

  return (
    <>
      <Modal visibleProp={loadModalWait}>
        <div className="d-flex flex-column align-items-center bg-branco-puro-5bits p-4">
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          <strong>Carregando...</strong>
        </div>
      </Modal>

      {Object.keys(billReceive).length !== 0 && (
        <Modal visibleProp={loadModalProp} className="w-80">
          <div
            style={{ maxHeight: '86vh' }}
            className="d-flex flex-column align-items-center w-100 bg-branco-puro-5bits p-4"
          >
            <h1>{title}</h1>
            <div className="overflow-auto w-100">
              <SimpleCreateAndUpdateTemplate
                title=""
                numberColContent={7}
                classNameProp="h-95"
              >
                <div className="h-100 mt-4">
                  <Row noGutters className="d-flex flex-row h-50">
                    <Col className="mr-5 border-right">
                      <Row className="d-flex flex-column">
                        <Col className="text-primary text-uppercase mb-2">
                          Cód. da Venda:
                          <span className="text-cinza-51-5bits text-uppercase ml-2">
                            <Link
                              to={`/sales/sell/read/${billReceive.sale_header_id}`}
                            >
                              {billReceive.sale_header_id}
                            </Link>
                          </span>
                        </Col>
                        <Col className="text-primary text-uppercase mb-2">
                          Valor:
                          <span className="text-cinza-51-5bits text-uppercase ml-2">
                            {convertNumberFloatToBr(billReceive.amount)}
                          </span>
                        </Col>
                        <Col className="text-primary text-uppercase mb-2">
                          Valor Recebido:
                          <span className="text-cinza-51-5bits text-uppercase ml-2">
                            {convertNumberFloatToBr(
                              billReceive.amount_received,
                            )}
                          </span>
                        </Col>
                        <Col className="text-primary text-uppercase mb-2">
                          Desconto:
                          <span className="text-cinza-51-5bits text-uppercase ml-2">
                            {convertNumberFloatToBr(billReceive.discount)}
                          </span>
                        </Col>
                        <Col className="d-flex flex-row align-items-center text-primary text-uppercase mb-2">
                          Acréscimo:
                          <span className="text-cinza-51-5bits text-uppercase ml-2">
                            {convertNumberFloatToBr(billReceive.addition)}
                          </span>
                        </Col>
                        <Col className="d-flex flex-row align-items-center text-primary text-uppercase mb-2">
                          Vencimento:
                          <span className="text-cinza-51-5bits text-uppercase ml-2">
                            {convertDateToBr(billReceive.due_date)}
                          </span>
                        </Col>
                        <Col className="d-flex flex-row align-items-center text-primary text-uppercase mb-2">
                          Tipo:
                          <span className="text-cinza-51-5bits text-uppercase ml-2">
                            {paymentMethodsComplete &&
                              paymentMethodsComplete.find(
                                item =>
                                  item.id === billReceive.payment_method_id,
                              ).name}
                          </span>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="d-flex flex-column align-items-center align-items-top text-primary text-uppercase h-100">
                      <div className="d-flex justify-content-around align-items-center w-100">
                        <span className="d-flex justify-content-center align-items-center w-50">
                          Data Pag.
                        </span>
                        <span className="d-flex justify-content-center align-items-center w-50">
                          Valor
                        </span>
                      </div>
                      <div className="w-100 overflow-auto">
                        <table
                          style={{ fontSize: '12px' }}
                          className="table-striped table-hover table-bordered w-100"
                        >
                          <tbody className="text-center">
                            {billReceive.bills_receive_body &&
                              billReceive.bills_receive_body.map(
                                (item: any, index) => (
                                  <tr key={index.toString()}>
                                    <td className="text-center p-1 w-50">
                                      {convertDateToBr(item.receive_date)}
                                    </td>

                                    <td className="text-right pr-3">
                                      {convertNumberFloatToBr(
                                        item.amount_received,
                                      )}
                                    </td>
                                  </tr>
                                ),
                              )}
                          </tbody>
                        </table>
                      </div>
                    </Col>
                  </Row>
                  <hr />
                  {billReceive.is_paid === true && (
                    <>
                      <Row
                        noGutters
                        className="d-flex flex-row justify-content-center align-items-center"
                      >
                        <Col
                          md={8}
                          className="d-flex flex-row justify-content-center align-items-center text-primary text-uppercase"
                        >
                          <div className="w-100 overflow-auto">
                            <table
                              style={{ fontSize: '12px' }}
                              className="table-striped table-hover table-bordered w-100"
                            >
                              <thead className="text-center text-primary text-uppercase">
                                <tr>
                                  <th
                                    className="font-weight-normal py-3"
                                    scope="col"
                                  >
                                    Forma de Pagamento
                                  </th>
                                  <th
                                    className="font-weight-normal py-3"
                                    scope="col"
                                  >
                                    Valor
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="text-center">
                                {billReceive.bills_receive_body &&
                                  billReceive.bills_receive_body.map(
                                    (item: any, index) => (
                                      <tr key={index.toString()}>
                                        <td className="text-center p-1 w-50">
                                          {
                                            paymentMethodsComplete?.find(
                                              option =>
                                                option.id ===
                                                item.payment_method_id,
                                            ).name
                                          }
                                        </td>

                                        <td className="text-right pr-3">
                                          {convertNumberFloatToBr(
                                            item.amount_received,
                                          )}
                                        </td>
                                      </tr>
                                    ),
                                  )}
                              </tbody>
                            </table>
                          </div>
                        </Col>
                      </Row>
                    </>
                  )}
                </div>
              </SimpleCreateAndUpdateTemplate>
            </div>

            <div className="d-flex flex-row justify-content-around mt-3 w-50">
              <Button
                name="closeModal"
                type="button"
                className="w-50 mr-4"
                fontSizeButton="12px"
                onClick={() => {
                  setBillReceive({} as BillReceiveHeaderFormDTO);
                  closeAndClearModalDetailsReceive();
                }}
              >
                Fechar
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ModalDetailsReceive;
