import React from 'react';
import SimpleCreateAndUpdateTemplate from '../../components/Template/SimpleCreateAndUpdateTemplate';

import { IPermission } from '../../interfaces/IPermission';

interface PermissionDTO {
  permissions: IPermission;
}

const InputProducts: React.FC<PermissionDTO> = ({ permissions }) => {
  return (
    <SimpleCreateAndUpdateTemplate title="Entrada de Produtos">
      <h1>Em desenvolvimento..</h1>
      {permissions.update && ''}
    </SimpleCreateAndUpdateTemplate>
  );
};

export default InputProducts;
