import styled from 'styled-components';

export const ContainerFooter = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 21vh;
  position: absolute;
  left: 0;
  bottom: 0;

  color: var(--cinza-51-5bits);

  > div {
    z-index: 5001;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-top: 1px solid var(--laranja-5bits);
    background: var(--branco-puro-5bits);

    width: 85%;
  }

  span {
    display: flex;
    justify-content: center;

    width: 100%;
    font-size: 12px;
    color: #6d6d6d;
    padding: 5px;

    background: var(--branco-gelo-5bits);
  }

  @media only screen and (max-width: 767px) {
    z-index: 5004;
    position: absolute;
    left: 0;
    height: 7vh;
    bottom: -88vh;

    div:first-child {
      display: none;
    }

    span {
      display: flex;
      align-items: center;
      height: 100%;
    }
    //755
  }

  @media only screen and (max-height: 767px) {
    z-index: 5004;
    position: absolute;
    left: 0;
    height: 7vh;

    div:first-child {
      display: none;
    }

    span {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }
`;

export const Content = styled.div`
  height: 100%;
`;

export const ImagensFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 12px 0 12px 0;
  padding: 0 12px 0 12px;

  width: 60%;

  img {
    width: 24px;
    height: 24px;
  }
`;

export const ContentFooter = styled.div`
  text-align: center;
  color: var(---cinza-51-5bits);
  font-size: 12px;
  font-weight: normal;
  margin-bottom: 6px;

  p {
    margin: 0;
    line-height: 15px;
  }

  p:last-child {
    margin-top: 9px;
  }
`;
