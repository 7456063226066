/* eslint-disable camelcase */
import React, { useCallback, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { FiEdit } from 'react-icons/fi';

import SimpleCreateAndUpdateTemplate from '../../../components/Template/SimpleCreateAndUpdateTemplate';
import api from '../../../services/api';
import getValidationErrors from '../../../utils/getValidationErrors';
import { useToast } from '../../../context/ToastContext';

import Input from '../../../components/Input';
import CurrencyInput from '../../../components/Input/InputCurrency';
import Checkbox from '../../../components/Checkbox';
import Button from '../../../components/Button';

interface FinancialAccountsFormDataDTO {
  name: string;
  balance: number;
  accept_negative: string;
}

const FinancialAccounts: React.FC = () => {
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const [disableButton, setDisableButton] = useState(false);

  // handleSubmit
  const handleSubmit = useCallback(
    async (dataForm: FinancialAccountsFormDataDTO, { reset }) => {
      try {
        setDisableButton(true);

        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          balance: Yup.string().required('Saldo obrigatório'),
        });

        await schema.validate(dataForm, {
          abortEarly: false,
        });

        const newData = {
          ...dataForm,
          accept_negative: dataForm.accept_negative === 'true',
        };

        await api.post('cash/financial-accounts', newData);

        addToast({
          type: 'success',
          title: 'Cadastro realizado com sucesso',
        });

        reset();
        setDisableButton(false);
        history.push('/cash/financial-accounts/list');
      } catch (error) {
        setDisableButton(false);

        // Verifica se o error é do Yup ou seja dos campos
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);

          return;
        }
        // eslint-disable-next-line no-shadow
        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: 'Erro no cadastro',
            description: data.message,
          });
          if (formRef.current?.getFieldRef(data.field)) {
            formRef.current?.getFieldRef(data.field).focus(); // Foca o campo que deu erro de acordo com o field retornado do back
          }

          return;
        }

        addToast({
          type: 'error',
          title: 'Ocorreu um erro interno',
        });
      }
    },
    [addToast, history],
  );
  // Fim handleSubmit

  return (
    <SimpleCreateAndUpdateTemplate title="Cadastro de Conta Financeira">
      <Form ref={formRef} className="mt-5" onSubmit={handleSubmit}>
        <Input
          type="text"
          name="name"
          label="Nome"
          icon={FiEdit}
          placeholder="Nome"
          aria-label="Nome"
          maxLength={45}
          className="mb-4"
        />
        <CurrencyInput
          type="text"
          name="balance"
          label="Saldo"
          icon="R$"
          placeholder="Saldo"
          className="mb-4"
        />

        <Checkbox name="accept_negative" propChecked={false} className="mb-4">
          Aceita Negativar
        </Checkbox>

        <Button
          name="submit"
          type="submit"
          className="mt-5 w-auto"
          disabled={disableButton}
        >
          Cadastrar
        </Button>
      </Form>
    </SimpleCreateAndUpdateTemplate>
  );
};

export default FinancialAccounts;
