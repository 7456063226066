import styled from 'styled-components';

export const ContainerPermissions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;

    details {
      flex: 1;
      width: 95%;

      & + details {
        margin-top: 10px;
      }

      summary {
        display: flex;
        align-content: center;
        align-items: center;

        padding: 4px;
        height: 56px;
        width: 100%;

        border: none;
        border-radius: 5px;
        font-weight: 500;
        color: var(--button-text-color);
        background-color: var(--on-focus-input-border-color);
        cursor: pointer;
      }
    }
  }
`;

export const ContentPermissions = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  width: 100%;

  strong {
    margin-right: 5px;
  }

  > div {
    flex-basis: 18%;
    border: none;
    margin: 0;
    padding: 0;
    border-radius: 0;
    background: none;
  }
`;
