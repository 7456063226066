function removeMaskCPFCNPJ(cpf: string): string {
  // Remove mask
  const regexp = new RegExp(/[^\d]+/g);

  const removeMask = cpf.replace(regexp, '');

  return removeMask;
}

export default removeMaskCPFCNPJ;
