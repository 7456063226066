import React from 'react';
import * as Styles from './styles';

import Button from '../../../../../../components/Button';
import Modal from '../../../../../../components/Modal';

interface ModalDTO {
  title: string;
  loadModalResponseReceiveListProp: boolean;
  closeModalResponseReceiveProp: () => void;
}

const ModalResponseReceiveList: React.FC<ModalDTO> = ({
  title,
  loadModalResponseReceiveListProp = false,
  closeModalResponseReceiveProp,
}) => {
  return (
    <Modal visibleProp={loadModalResponseReceiveListProp}>
      <div
        style={{ maxHeight: '70vh' }}
        className="d-flex flex-column align-items-center w-100 bg-branco-puro-5bits p-4"
      >
        <Styles.Header>
          <h1>{title}</h1>
        </Styles.Header>
        <Button
          name="closeModal"
          type="button"
          className="w-50 mr-4"
          fontSizeButton="12px"
          onClick={closeModalResponseReceiveProp}
        >
          Fechar
        </Button>
      </div>
    </Modal>
  );
};

export default ModalResponseReceiveList;
