import styled from 'styled-components';

export const ContainerLogo = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  background: var(--branco-puro-5bits);

  img {
    width: 75%;
    height: 54%;
  }

  img:nth-child(2) {
    display: none;
  }

  @media only screen and (max-width: 767px) {
    img:nth-child(1) {
      display: none;
    }
    img:nth-child(2) {
      display: block;
      width: 42px;
      height: 42px;
    }
  }
`;
