import { format } from 'date-fns';

/**
 * @param mask - Ex. dd/MM/yyyy HH:mm or yyyy-MM-dd, etc...
 * @returns Date string
 */
function returnDateNow(mask: string): string {
  const dNow = new Date();
  return format(dNow, mask);
}

export default returnDateNow;
