/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { useAuth } from '../context/AuthContext';

import Signin from '../pages/Signin';
import Signup from '../pages/Signup';
import Home from '../pages/Home';
// import NotFoundPage from '../pages/NotFoundPage';
import UsersRoutes from './UsersRoutes';
import ClientsRoutes from './ClientsRoutes';
import EmployeesRoutes from './EmployeesRoutes';
import ProductsRoutes from './ProductsRoutes';
import CashRoutes from './CashRoutes';
import SalesRoutes from './SalesRoutes';
import DiscountCoupon from './DiscountCoupon';
import ReportsRoutes from './ReportsRoutes';

import PrivateRoute from './PrivateRoutes';

const Routes: React.FC = () => {
  const { user }: any = useAuth();

  return (
    <Switch>
      {(!user && <Route path="/" exact component={Signin} />) || (
        <>
          <Route path="/signup" component={Signup} />

          <PrivateRoute path="/home" component={Home} />

          <PrivateRoute exact path="/users/*" component={UsersRoutes} />
          <PrivateRoute exact path="/clients/*" component={ClientsRoutes} />
          <PrivateRoute exact path="/employees/*" component={EmployeesRoutes} />
          <PrivateRoute exact path="/products/*" component={ProductsRoutes} />
          <PrivateRoute exact path="/cash/*" component={CashRoutes} />
          <PrivateRoute exact path="/sales/*" component={SalesRoutes} />
          <PrivateRoute
            exact
            path="/discount-coupons/*"
            component={DiscountCoupon}
          />
          <PrivateRoute exact path="/reports/*" component={ReportsRoutes} />
        </>
      )}
    </Switch>
  );
};

export default Routes;
