/* eslint-disable camelcase */
import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';

import Table from 'react-bootstrap/Table';

import ListingTemplate from '../../components/Template/ListingTemplate';
import Loader from '../../components/Loader';
import api from '../../services/api';

import { useToast } from '../../context/ToastContext';

import { IPermission } from '../../interfaces/IPermission';

interface PermissionDTO {
  permissions: IPermission;
}

interface EmployeeFormDataDTO {
  id: number;
  name: string;
  nickname: string;
  dt_birth: string;
  is_active: boolean | string;
}

/**
 * [X] Adicionar botão Editar
 * [ ] Corrigir a quebra de linha para texto muito grande
 */

const ReadsEmployees: React.FC<PermissionDTO> = ({ permissions }) => {
  const [loading, setLoading] = useState(false);
  const [listEmployees, setListEmployees] = useState<EmployeeFormDataDTO[]>([]);
  const [
    listEmployeesPaginatedItems,
    setListEmployeesPaginatedItems,
  ] = useState<any[]>([]);
  const { addToast } = useToast();

  // const { permission } = useAuth();
  // let employeePermissions: IPermission | undefined = {};
  // if (permission.length > 0) {
  //   employeePermissions = permission.find((item: any) => item.menus_id === 2); // menus_id employee == 2
  // }

  useEffect(() => {
    try {
      const getListEmployees = async (): Promise<void> => {
        try {
          setLoading(true);
          const response = await api.get('employees');
          setListEmployees(response.data);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          // eslint-disable-next-line no-shadow
          if (error.response) {
            const { data } = error.response; // Error vindo do back está em data dentro de response
            addToast({
              type: 'error',
              title: data.message,
            });
            return;
          }

          addToast({
            type: 'error',
            title: 'Ocorreu um erro interno',
          });
        }
      };
      getListEmployees();
    } catch (error) {
      setLoading(false);
      // eslint-disable-next-line no-shadow
      if (error.response.data) {
        const { data } = error.response; // Error vindo do back está em data dentro de response
        addToast({
          type: 'error',
          title: data.message,
        });
        return;
      }

      addToast({
        type: 'error',
        title: 'Ocorreu um erro interno',
      });
    }
  }, [addToast]);

  const handleListEmployeesPaginatedItems = useCallback(newList => {
    setListEmployeesPaginatedItems(newList);
  }, []);

  return (
    <ListingTemplate
      title="Funcionários"
      module="employees"
      listItemsProps={listEmployees}
      handleListItemsPaginatedItems={handleListEmployeesPaginatedItems}
      placeHolderProp="Cód. do Fornecedor"
      permissions={permissions}
    >
      {(loading && <Loader />) || (
        <>
          <Table striped bordered hover size="sm" responsive="md">
            <thead className="text-center text-primary text-uppercase">
              <tr>
                <th className="font-weight-normal py-3" scope="col">
                  ID
                </th>
                <th className="font-weight-normal py-3" scope="col">
                  Nome
                </th>
                <th className="font-weight-normal py-3" scope="col">
                  Data de Aniversário
                </th>
                <th className="font-weight-normal py-3" scope="col">
                  Funcionário Ativo
                </th>
                <th className="font-weight-normal py-3" scope="col">
                  Ações
                </th>
              </tr>
            </thead>
            <tbody className="text-center">
              {listEmployeesPaginatedItems.map((item, index) => (
                <tr key={index.toString()}>
                  <th scope="row">{item.id}</th>
                  <td className="text-left">{item.name}</td>
                  <td>{item.dt_birth}</td>
                  <td>{(item.is_active === true && ' SIM') || ' NÃO'}</td>
                  <td>
                    {(permissions.update && (
                      <Link to={`/employees/read/${item.id}`}>Alterar</Link>
                    )) || <span className="text-cinza-51-5bits">Alterar</span>}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
    </ListingTemplate>
  );
};

export default ReadsEmployees;
