import ConvertNumberFloatToDB from './convertNumberFloatToDB';

function ConvertPriceBRL(value: string): string {
  const newValue = ConvertNumberFloatToDB(value);

  return newValue.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
}

export default ConvertPriceBRL;
