import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;

  > a {
    margin-top: 100px;
    text-decoration: none;
  }
`;
