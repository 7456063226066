/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable camelcase */
import React, { useCallback, useState, useEffect, useRef } from 'react';
import { Form } from '@unform/web';
import { FormHandles, Scope } from '@unform/core';
import * as Yup from 'yup';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Cookie from 'js-cookie';
import Spinner from 'react-bootstrap/Spinner';
import SimpleCreateAndUpdateTemplate from '../../components/Template/SimpleCreateAndUpdateTemplate';
import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';
import convertDateToDB from '../../utils/convertDateToDB';
import { useToast } from '../../context/ToastContext';

import Button from '../../components/Button';
import DatePicker from '../../components/DatePicker';
import RadioButton from '../../components/RadioButton';

import { useAuth } from '../../context/AuthContext';
import removeMaskCPFCNPJ from '../../utils/removeMaskCPFCNPJ';
import CheckboxUnform from '../../components/CheckboxUnform';

interface ReportSalesFormDataDTO {
  start_date: string;
  end_date: string;
  format_paper: string;
  summary_only: boolean;
  scopeEmployees: object;
  scopePaymentMethods: object;
}

const ReportSales: React.FC = () => {
  const { configuration }: any = useAuth();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [disableButton, setDisableButton] = useState(false);

  const cookieName = `cookieFormatPaperInReportSales5Bits${removeMaskCPFCNPJ(
    configuration.cnpj,
  )}`;
  const cookieFormatPaper = Cookie.get(cookieName);

  const [listEmployees, setListEmployees] = useState<any[]>([]);
  const [paymentMethodsComplete, setPaymentMethodsComplete] = useState<any[]>(
    [],
  );

  useEffect(() => {
    if (cookieFormatPaper) {
      formRef.current?.setFieldValue('format_paper', cookieFormatPaper);
    } else {
      formRef.current?.setFieldValue('format_paper', 'a4');
    }
  }, [cookieFormatPaper]);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.setFieldValue('start_date', new Date());
      formRef.current.setFieldValue('end_date', new Date());
    }
  }, []);

  useEffect(() => {
    try {
      const getLists = async (): Promise<void> => {
        try {
          const responseEmployees = await api.get('employees/active');
          setListEmployees(responseEmployees.data);

          const responsePaymentMethodsComplete = await api.get(
            'cash/payment-methods/active',
          );
          setPaymentMethodsComplete(responsePaymentMethodsComplete.data);
        } catch (erro) {
          // eslint-disable-next-line no-shadow
          if (erro.response) {
            const { data } = erro.response; // Error vindo do back está em data dentro de response
            addToast({
              type: 'error',
              title: data.message,
            });
            return;
          }

          addToast({
            type: 'error',
            title: 'Ocorreu um erro interno',
          });
        }
      };
      getLists();
    } catch (erro) {
      // eslint-disable-next-line no-shadow
      if (erro.response.data) {
        const { data } = erro.response; // Error vindo do back está em data dentro de response
        addToast({
          type: 'error',
          title: data.message,
        });
        return;
      }

      addToast({
        type: 'error',
        title: 'Ocorreu um erro interno',
      });
    }
  }, [addToast]);

  // handleSubmit
  const handleSubmit = useCallback(
    async (dataForm: ReportSalesFormDataDTO) => {
      try {
        setDisableButton(true);

        if (cookieFormatPaper !== undefined) {
          Cookie.remove(cookieName);
        }
        Cookie.set(cookieName, dataForm.format_paper, { expires: 365 });

        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          start_date: Yup.string()
            .required('Data inicial obrigatória')
            .nullable(),
          end_date: Yup.string().required('Data final obrigatória').nullable(),
          format_paper: Yup.string().required(
            'Formato do papel para impressão obrigatória',
          ),
        });

        await schema.validate(dataForm, {
          abortEarly: false,
        });

        const { scopeEmployees } = dataForm;
        const { scopePaymentMethods } = dataForm;

        // Typescript exige uma key
        type KeyEmployee = keyof typeof scopeEmployees;
        const employeesWithTrue = Object.keys(scopeEmployees).filter(
          (item: string) => scopeEmployees[item as KeyEmployee] === true,
        );

        type KeyPaymentMethod = keyof typeof scopePaymentMethods;
        const paymentMethodsWithTrue = Object.keys(scopePaymentMethods).filter(
          (item: string) =>
            scopePaymentMethods[item as KeyPaymentMethod] === true,
        );

        // Expressão regular para retirar o id antes do número.
        // g é para verificar em toda string e i para ignorar a diferença
        // entre maiúsculas e minúsculas.
        const regx = /id/gi;
        const employeesString = employeesWithTrue.toString().replace(regx, '');
        const paymentMethodString = paymentMethodsWithTrue
          .toString()
          .replace(regx, '');

        const newData = {
          start_date: convertDateToDB(dataForm.start_date),
          end_date: convertDateToDB(dataForm.end_date),
          format_paper: dataForm.format_paper,
          summary_only: dataForm.summary_only,
          employees: employeesString,
          payment_methods: paymentMethodString,
        };

        const response = await api.post('reports/sales', newData, {
          responseType: 'arraybuffer',
        });

        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: 'application/pdf' }),
        );

        window.open(url);

        addToast({
          type: 'success',
          title: 'Relatório gerado com sucesso',
        });

        // reset();
        setDisableButton(false);
        // history.push('/home');
      } catch (error) {
        setDisableButton(false);

        // Verifica se o error é do Yup ou seja dos campos
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);

          return;
        }
        // eslint-disable-next-line no-shadow
        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: 'Erro na geração do relatório',
            description: data.message,
          });
          if (formRef.current?.getFieldRef(data.field)) {
            formRef.current?.getFieldRef(data.field).focus(); // Foca o campo que deu erro de acordo com o field retornado do back
          }

          return;
        }

        addToast({
          type: 'error',
          title: 'Ocorreu um erro interno',
        });
      }
    },
    [addToast, cookieFormatPaper, cookieName],
  );
  // Fim handleSubmit

  // checkbox selectAll
  const handleSelectAll = useCallback((scope: string) => {
    const selectAll = formRef.current?.getFieldValue(`selectAll${scope}`);
    const keysScopeEmployeesObject = Object.keys(
      formRef.current?.getData()[scope],
    );
    // eslint-disable-next-line array-callback-return
    keysScopeEmployeesObject.map((item: string): void => {
      formRef.current?.setFieldValue(`${scope}.${item}`, selectAll);
    });
  }, []);

  const handleCheckSelectAll = useCallback((scope: string) => {
    if (
      Object.values(formRef.current?.getData()[scope]).find(
        item => item === false,
      ) === undefined
    ) {
      formRef.current?.setFieldValue(`selectAll${scope}`, true);
    } else {
      formRef.current?.setFieldValue(`selectAll${scope}`, false);
    }
  }, []);
  // Fim checkbox selectAll

  return (
    <SimpleCreateAndUpdateTemplate
      numberColContent={12}
      title="Relatório de vendas"
    >
      <Form ref={formRef} className="mt-5" onSubmit={handleSubmit}>
        <Row
          noGutters
          className="d-flex
          flex-column
          justify-content-sm-center
          align-items-sm-center
          flex-md-row
          justify-content-md-center
          justify-content-xl-start
          py-2 px-3
          bg-branco-gelo-5bits
          rounded"
          style={{ fontSize: '14px', textTransform: 'initial' }}
        >
          <Col
            sm={8}
            md={8}
            xl={4}
            className="d-flex
            flex-column
            justify-content-center
            align-items-center
            flex-sm-row
            flex-sm-row
            flex-md-row
            justify-content-md-start
            align-items-md-end
            "
          >
            <span
              className="d-flex
               justify-content-center
               align-items-center
               mt-2 mb-1
               justify-content-md-end
               align-items-md-end
               mr-md-2 mb-md-0 text-nowrap"
              style={{ lineHeight: '95%' }}
            >
              Período de:
            </span>
            <DatePicker
              name="start_date"
              inputClassName="h-auto px-2 py-0"
              className="w-auto"
            />
            <span
              className="mt-2 mb-1 mx-2 mb-md-0"
              style={{ lineHeight: '95%' }}
            >
              até
            </span>
            <DatePicker
              name="end_date"
              inputClassName="h-auto px-2 py-0"
              className="mb-2 mb-md-0 w-auto"
            />
          </Col>
          <Col
            md={7}
            xl={4}
            className="d-flex
             flex-column align-items-center
             mt-sm-2
             flex-md-row justify-content-md-center align-items-md-end
             ml-md-0 mt-md-3
             justify-content-xl-start
             ml-xl-4 mt-xl-2"
          >
            <span
              className="text-nowrap
              mr-2 mb-2
              mb-md-0"
              style={{ lineHeight: '95%' }}
            >
              Formato em:
            </span>
            <RadioButton
              name="format_paper"
              className="mr-3"
              lineHeight="95%"
              style={{ lineHeight: '95%' }}
              options={[
                { id: 'a4', value: 'a4', option: 'A4' },
                {
                  id: 'bob80',
                  value: 'bob80',
                  option: 'Bobina 80',
                },
              ]}
            />
          </Col>
          <Col
            md={6}
            xl={2}
            className="d-flex
            justify-content-center
            align-items-center
            mt-3
            justify-content-xl-start
            mt-xl-2"
          >
            <CheckboxUnform
              name="summary_only"
              value="summary_only"
              label="Somente resumo"
              classNameLabel="text-initial"
            />
          </Col>
        </Row>

        <Row noGutters md={12} className="mt-4 mb-2">
          <Col
            md={12}
            className="text-initial"
            style={{
              fontWeight: 600,
            }}
          >
            Vendedor
          </Col>
        </Row>
        <Row
          noGutters
          className="d-flex flex-row
          justify-content-start border
          rounded py-3 text-cinza-51-5bits
          mr-5
          mr-md-0"
          style={{
            letterSpacing: '2%',
            maxHeight: '8.4375rem',
            overflowY: 'auto',
          }}
        >
          <Col md={12} className="pl-4 mb-4">
            <CheckboxUnform
              name="selectAllscopeEmployees"
              value="selectAllscopeEmployees"
              label="Selecionar todos"
              classNameLabel="text-capitalize"
              sizeCheckbox={1}
              fontSizeText="14px"
              onClick={() => handleSelectAll('scopeEmployees')}
            />
          </Col>
          <Scope path="scopeEmployees">
            {listEmployees.length > 0 &&
              listEmployees.map((item: any, index) => {
                return (
                  <Col
                    key={index.toString()}
                    md={7}
                    lg={5}
                    xl={4}
                    className="d-flex flex-column
                    justify-content-start border-left pl-4"
                  >
                    <CheckboxUnform
                      name={`id${item.id}`}
                      value={item.name}
                      label={item.name}
                      classNameLabel="text-capitalize"
                      sizeCheckbox={1}
                      onClick={() => handleCheckSelectAll('scopeEmployees')}
                    />
                  </Col>
                );
              })}
          </Scope>
        </Row>

        <Row noGutters md={12} className="mt-4 mb-2">
          <Col
            md={12}
            className="text-initial"
            style={{
              fontWeight: 600,
            }}
          >
            Forma de Pagamento
          </Col>
        </Row>
        <Row
          noGutters
          className="d-flex
          flex-row justify-content-start
          border rounded py-3
          text-cinza-51-5bits
          mr-5
          mr-md-0"
          style={{
            letterSpacing: '2%',
            maxHeight: '8.4375rem',
            overflowY: 'auto',
          }}
        >
          <Col md={12} className="pl-4 mb-4">
            <CheckboxUnform
              name="selectAllscopePaymentMethods"
              value="selectAllscopePaymentMethods"
              label="Selecionar todos"
              classNameLabel="text-capitalize"
              sizeCheckbox={1}
              fontSizeText="14px"
              onClick={() => handleSelectAll('scopePaymentMethods')}
            />
          </Col>
          <Scope path="scopePaymentMethods">
            {paymentMethodsComplete.length > 0 &&
              paymentMethodsComplete.map((item: any, index) => {
                return (
                  <Col
                    key={index.toString()}
                    md={4}
                    className="d-flex flex-column justify-content-start border-left pl-4"
                  >
                    <CheckboxUnform
                      name={item.id}
                      value={item.name}
                      label={item.name}
                      classNameLabel="text-capitalize"
                      sizeCheckbox={1}
                      onClick={() =>
                        handleCheckSelectAll('scopePaymentMethods')
                      }
                    />
                  </Col>
                );
              })}
          </Scope>
        </Row>
        <Row
          noGutters
          md={12}
          className="d-flex flex-row justify-content-center align-items-center"
        >
          <Col md={3} className="d-flex justify-content-center mt-4">
            {(disableButton && (
              <Spinner animation="border" variant="primary" />
            )) || (
              <Button
                name="submit"
                type="submit"
                className="w-auto"
                disabled={disableButton}
              >
                Gerar Relatório
              </Button>
            )}
          </Col>
        </Row>
      </Form>
    </SimpleCreateAndUpdateTemplate>
  );
};

export default ReportSales;
