import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

import ButtonBootstrap from '../Button';

import { Container } from './styles';

const ButtonLink: React.FC<LinkProps> = ({ children, to, className }) => {
  return (
    <Container className={className}>
      <Link id="link" to={to}>
        <ButtonBootstrap>{children}</ButtonBootstrap>
      </Link>
    </Container>
  );
};

export default ButtonLink;
