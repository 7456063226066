/* eslint-disable camelcase */
import React, { useCallback, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import SimpleCreateAndUpdateTemplate from '../../../components/Template/SimpleCreateAndUpdateTemplate';
import api from '../../../services/api';
import getValidationErrors from '../../../utils/getValidationErrors';
import convertNumberFloatToDB from '../../../utils/convertNumberFloatToDB';
import { useToast } from '../../../context/ToastContext';

import Input from '../../../components/Input';
import InputCurrency from '../../../components/Input/InputCurrency';
import SelectSimple from '../../../components/SelectSimple';
import Button from '../../../components/Button';

interface TransferFormDataDTO {
  outgoing_financial_account_id: number;
  incoming_financial_account_id: number;
  amount: number;
  observation: string;
}

const CreateTransfer: React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [disableButton, setDisableButton] = useState(false);

  // handleSubmit
  const handleSubmit = useCallback(
    async (dataForm: any, { reset }) => {
      try {
        setDisableButton(true);

        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          outgoing_financial_account_id: Yup.string().required(
            'Conta de Saída obrigatória',
          ),
          incoming_financial_account_id: Yup.string().required(
            'Conta de Entrada obrigatória',
          ),
          amount: Yup.string().required('Data de aniversário obrigatório'),
        });

        await schema.validate(dataForm, {
          abortEarly: false,
        });

        const newData: TransferFormDataDTO = {
          outgoing_financial_account_id: dataForm.outgoing_financial_account_id,
          incoming_financial_account_id: dataForm.incoming_financial_account_id,
          amount: convertNumberFloatToDB(dataForm.amount),
          observation: dataForm.observation,
        };

        await api.post('cash/financial-transfers', newData);

        addToast({
          type: 'success',
          title: 'Cadastro realizado com sucesso',
        });

        reset();
        setDisableButton(false);

        history.push('/cash/financial-transfers/list');
        // history.push('/home');
      } catch (error) {
        setDisableButton(false);

        // Verifica se o error é do Yup ou seja dos campos
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);

          return;
        }
        // eslint-disable-next-line no-shadow
        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: 'Erro no cadastro',
            description: data.message,
          });
          if (formRef.current?.getFieldRef(data.field)) {
            formRef.current?.getFieldRef(data.field).focus(); // Foca o campo que deu erro de acordo com o field retornado do back
          }

          return;
        }

        addToast({
          type: 'error',
          title: `Ocorreu um erro interno`,
        });
      }
    },
    [addToast, history],
  );
  // Fim handleSubmit

  return (
    <SimpleCreateAndUpdateTemplate title="Cadastro de Transferências">
      <Form ref={formRef} className="mt-5" onSubmit={handleSubmit}>
        <SelectSimple
          module="cash/financial-accounts/is-active"
          name="outgoing_financial_account_id"
          label="Saída"
          placeholder="Conta financeira de saída"
          aria-label="Conta financeira de saída"
          noOptionsMessage={() => 'Nenhuma conta encontrada'}
          className="mb-3"
        />
        <SelectSimple
          module="cash/financial-accounts/is-active"
          name="incoming_financial_account_id"
          label="Entrada"
          placeholder="Conta financeira de entrada"
          aria-label="Conta financeira de entrada"
          noOptionsMessage={() => 'Nenhuma conta encontrada'}
          className="mb-3"
        />
        <InputCurrency
          type="text"
          name="amount"
          label="Valor"
          placeholder="Valor"
          aria-label="Valor"
          className="mb-3"
        />
        <Input
          type="text"
          name="observation"
          label="Observação"
          placeholder="Observação"
          aria-label="Observação"
          className="mb-3"
        />

        <Button
          name="submit"
          type="submit"
          className="mt-5 w-auto"
          disabled={disableButton}
        >
          Cadastrar
        </Button>
      </Form>
    </SimpleCreateAndUpdateTemplate>
  );
};

export default CreateTransfer;
