import styled, { css } from 'styled-components';

/**
 * Se for botão de paginação não terá o hover
 */
interface ContainerProps {
  isPagination?: boolean;
}

/**
 * Quando for isPagination define o css para paginação, senão
 * o css é para os botões normais.
 */
export const Container = styled.div<ContainerProps>`
  width: 100%;

  button {
    text-transform: uppercase;
    ${props =>
      (props.isPagination &&
        css`
          &:hover {
            border-color: var(--azul-5bits);
            color: var(--azul-5bits);
            box-shadow: transparent;
            background-color: transparent;
          }
        `) ||
      css`
        &:hover {
          border-color: var(--laranja-5bits);
          background-color: var(--laranja-5bits);
        }
      `}
  }
`;
