/* eslint-disable camelcase */
import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';

import ListingTemplate from '../../../components/Template/ListingTemplate';
import Loader from '../../../components/Loader';
// import { BiPrinter } from 'react-icons/bi';
// import Button from '../../../components/Button';
// import ConvertPriceBRL from '../../../utils/convertPriceBRL';

import api from '../../../services/api';

import { useToast } from '../../../context/ToastContext';

import { IPermission } from '../../../interfaces/IPermission';
import Tooltip from '../../../components/Tooltip';

import { TR } from './styles';

interface PermissionDTO {
  permissions: IPermission;
}

interface SalesHeaderFormDTO {
  id: number;
  client_id: number;
  employee_id: number;
  amount: number;
  dependent_name: string;
  observation: string;
  is_canceled: boolean;
  canceled_date: string;
  canceled_user: string;
}

const ReadsSell: React.FC<PermissionDTO> = ({ permissions }) => {
  const [loading, setLoading] = useState(false);
  const [listSales, setListSales] = useState<SalesHeaderFormDTO[]>([]);
  const [listSalesPaginatedItems, setListSalesPaginatedItems] = useState<
    SalesHeaderFormDTO[]
  >([]);
  const { addToast } = useToast();

  useEffect(() => {
    try {
      const getListSales = async (): Promise<void> => {
        try {
          setLoading(true);
          const response = await api.get('sales/sell');
          setListSales(response.data);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          // eslint-disable-next-line no-shadow
          if (error.response) {
            const { data } = error.response; // Error vindo do back está em data dentro de response
            addToast({
              type: 'error',
              title: data.message,
            });
            return;
          }
          addToast({
            type: 'error',
            title: 'Ocorreu um erro interno',
          });
        }
      };
      getListSales();
    } catch (error) {
      setLoading(false);
      // eslint-disable-next-line no-shadow
      if (error.response.data) {
        const { data } = error.response; // Error vindo do back está em data dentro de response
        addToast({
          type: 'error',
          title: data.message,
        });
        return;
      }

      addToast({
        type: 'error',
        title: 'Ocorreu um erro interno',
      });
    }
  }, [addToast]);

  const handleListSalesPaginatedItems = useCallback(newList => {
    setListSalesPaginatedItems(newList);
  }, []);

  return (
    <ListingTemplate
      title="Vendas"
      module="sales/sell"
      listItemsProps={listSales}
      handleListItemsPaginatedItems={handleListSalesPaginatedItems}
      placeHolderProp="Cód. da Venda"
      permissions={permissions}
    >
      {(loading && <Loader />) || (
        <>
          <div className="text-primary text-uppercase font-weight-bold">
            Vendas Cadastradas:
            <span className="text-cinza-51-5bits text-uppercase ml-2 font-weight-bold">
              {listSales.length}
            </span>
          </div>
          <Table striped bordered hover size="sm" responsive="md">
            <thead className="text-center text-primary text-uppercase">
              <tr>
                <th className="font-weight-normal py-3" scope="col">
                  ID
                </th>
                <th className="font-weight-normal py-3" scope="col">
                  Cliente
                </th>
                <th className="font-weight-normal py-3" scope="col">
                  Dependente
                </th>
                <th className="font-weight-normal py-3" scope="col">
                  Total
                </th>
                <th className="font-weight-normal py-3" scope="col">
                  Vendedor(a)
                </th>
                <th className="font-weight-normal py-3" scope="col">
                  Ação
                </th>
              </tr>
            </thead>
            <tbody className="text-center">
              {listSalesPaginatedItems.map((item: any, index) => (
                <TR key={index.toString()}>
                  {(item.is_canceled && (
                    <>
                      <th scope="row">
                        <s>{item.id}</s>
                      </th>
                      <td className="text-left">
                        <s>{item.client.name}</s>
                      </td>
                      <td className="text-left">
                        <s>{item.dependent_name}</s>
                      </td>
                      <td className="text-right pr-3">
                        <s>{item.amount}</s>
                      </td>
                      <td>
                        <s>{item.employee.nickname}</s>
                      </td>
                      <td>
                        <Tooltip
                          style={{
                            textTransform: 'capitalize',
                          }}
                          className="position-relative text-primary"
                          classNameSpan="bg-primary border-primary"
                          colorBefore="#0F4C82"
                          title={`Cancelado por: ${item.canceled_user}`}
                        >
                          <div
                            style={{
                              cursor: 'pointer',
                            }}
                            className="text-danger text-uppercase font-weight-bold"
                          >
                            Cancelada
                          </div>
                        </Tooltip>
                      </td>
                    </>
                  )) || (
                    <>
                      <th scope="row">{item.id}</th>
                      <td className="text-left">{item.client.name}</td>
                      <td className="text-left">{item.dependent_name}</td>
                      <td className="text-right pr-3">{item.amount}</td>
                      <td>{item.employee.nickname}</td>
                      <td>
                        {(permissions.update && (
                          <Link to={`/sales/sell/read/${item.id}`}>
                            Detalhar
                          </Link>
                        )) || (
                          <span className="text-cinza-51-5bits">Detalhar</span>
                        )}
                      </td>
                    </>
                  )}
                </TR>
              ))}
            </tbody>
          </Table>
        </>
      )}
    </ListingTemplate>
  );
};

export default ReadsSell;
