/* eslint-disable camelcase */
import React, { useCallback, useRef, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { FiUser, FiEdit } from 'react-icons/fi';

import SimpleCreateAndUpdateTemplate from '../../components/Template/SimpleCreateAndUpdateTemplate';
import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';
import { useToast } from '../../context/ToastContext';

import Input from '../../components/Input';
import Checkbox from '../../components/Checkbox';
import Button from '../../components/Button';

interface EmployeeFormDataDTO {
  id: number;
  name: string;
  nickname: string;
  dt_birth: string;
  is_activeText?: string;
  is_active: boolean;
}

/**
 * [ ] Ver o history com o Daniel após alterar Employee
 * [ ] Ver campo sold_amount
 * [ ] Ver se apenas administrador poderá alterar o is_active
 */
const UpdateEmployee: React.FC<EmployeeFormDataDTO> = () => {
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { id }: any = useParams();
  const [employee, setEmployee] = useState<EmployeeFormDataDTO>(
    {} as EmployeeFormDataDTO,
  );
  const [disableButton, setDisableButton] = useState(false);

  const sessionStorageUser: any = sessionStorage.getItem('@Stock5b:user');
  const currentUserLogged = JSON.parse(sessionStorageUser);

  // eslint-disable-next-line no-bitwise

  useEffect(() => {
    const getEmployee = async (): Promise<void> => {
      try {
        const response = await api.get(`employees/${id}`);
        setEmployee({
          ...response.data,
          dt_birth: response.data.dt_birth,
        });

        /**
         * Preenche os campos do formulário. Sem esta linha os inputs com
         * máscara não são preenchidos.
         */
        formRef.current?.setData({
          ...response.data,
          dt_birth: response.data.dt_birth,
        });
      } catch (error) {
        // eslint-disable-next-line no-shadow
        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: data.message,
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Ocorreu um erro interno',
        });
      }
    };
    getEmployee();
  }, [addToast, id]);

  // handleSubmit
  const handleSubmit = useCallback(
    async (dataForm: EmployeeFormDataDTO, { reset }) => {
      try {
        setDisableButton(true);
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          nickname: Yup.string().required('Cognome obrigatório'),
          dt_birth: Yup.string().required('Data de aniversário obrigatório'),
        });

        await schema.validate(dataForm, {
          abortEarly: false,
        });

        /**
         * Cria um novo array com o is_admin com o
         * valor em booleano que será salvo no bd
         */
        let isActiveBoolean;

        if (currentUserLogged.is_admin) {
          isActiveBoolean = dataForm.is_activeText === 'true';
        } else {
          isActiveBoolean = employee.is_active;
        }

        const newData = {
          ...dataForm,
          is_active: isActiveBoolean,
        };

        delete newData.is_activeText;

        await api.put('employees', newData);

        addToast({
          type: 'success',
          title: 'Alteração realizada com sucesso',
        });

        reset();
        setDisableButton(false);
        history.push('/employees/list');
      } catch (error) {
        setDisableButton(false);

        // Verifica se o error é do Yup ou seja dos campos
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);

          return;
        }
        // eslint-disable-next-line no-shadow
        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: 'Erro na alteração do cadastro',
            description: data.message,
          });
          formRef.current?.getFieldRef(data.field).focus(); // Foca o campo que deu erro de acordo com o field retornado do back

          return;
        }
        addToast({
          type: 'error',
          title: 'Ocorreu um erro interno',
        });
      }
    },
    [addToast, currentUserLogged.is_admin, employee, history],
  );
  // Fim handleSubmit

  return (
    <SimpleCreateAndUpdateTemplate title="Atualização de Funcionários">
      <Form
        ref={formRef}
        initialData={employee}
        className="mt-5"
        onSubmit={handleSubmit}
      >
        <Input type="hidden" name="id" isHidden />
        <Input
          type="text"
          name="name"
          label="Nome Completo"
          icon={FiUser}
          placeholder="Nome Completo"
          maxLength={45}
          className="mb-4"
        />
        <Input
          type="text"
          name="nickname"
          label="Cognome"
          icon={FiUser}
          placeholder="Cognome"
          maxLength={15}
          className="mb-4"
        />
        <Input
          type="text"
          name="dt_birth"
          label="Data Aniversário"
          icon={FiEdit}
          placeholder="Data Aniversário"
          mask="99/99/9999"
          className="mb-4"
        />
        {currentUserLogged.is_admin === true && (
          <Checkbox name="is_activeText" propChecked={employee?.is_active}>
            Funcionário Ativo
          </Checkbox>
        )}

        <Button
          name="submit"
          type="submit"
          className="mt-5 w-auto"
          disabled={disableButton}
        >
          Salvar
        </Button>
      </Form>
    </SimpleCreateAndUpdateTemplate>
  );
};

export default UpdateEmployee;
