import React, { ButtonHTMLAttributes } from 'react';
import ButtonBootstrap from 'react-bootstrap/Button';
import { Container } from './styles';
/*
O isPagination é usado em botões como o da paginação que não
terão o as bordas com o sombreamento(bordas borradas).
O isSelected trás as className do componente pagination ou outro
componente que precisar passar classes css adicional. É usado na paginação
e define o fundo laranja e a cor do texto branco quando for selecionado e
estiver na página que corresponde ao seu número.
*/
interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isPagination?: boolean;
  isSelected?: string;
  fontSizeButton?: string;
  classNameButton?: string;
}

/*
Como está recebendo children que é o texto entre a tag Button e
rest que são o restante das props.
O container é um button no styles.ts
*/
const Button: React.FC<ButtonProps> = ({
  isPagination = false,
  isSelected = '',
  fontSizeButton = '',
  children,
  className,
  classNameButton = '',
  ...rest
}) => {
  return (
    <Container isPagination={isPagination} className={className}>
      <ButtonBootstrap
        style={{
          fontSize: `${fontSizeButton}`,
        }}
        variant="outline-primary"
        className={`btn-block ${classNameButton}
        ${isPagination ? `shadow-none ${isSelected}` : ''}`}
        {...rest}
      >
        {children}
      </ButtonBootstrap>
    </Container>
  );
};

export default Button;
