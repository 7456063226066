/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useRef, useEffect, useState, useCallback } from 'react';
import { OptionTypeBase } from 'react-select';
import Select, { Props as AsyncProps } from 'react-select/async';
import { useField } from '@unform/core';
import { IconBaseProps } from 'react-icons';

import InputGroup from 'react-bootstrap/InputGroup';
import { FiAlertCircle } from 'react-icons/fi';

import { Container, ErrorStyle } from './styles';

interface Props extends AsyncProps<OptionTypeBase, boolean> {
  name: string;
  label?: string;
  fontSize?: string;
  sizeIcon?: number;
  className?: string;
  classNameInputGroupTextAndFormControl?: string;

  icon?: React.ComponentType<IconBaseProps>;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export default function AsyncSelect({
  name,
  label,
  fontSize,
  sizeIcon,
  className,
  classNameInputGroupTextAndFormControl = '',

  icon: Icon,
  ...rest
}: Props) {
  const selectRef = useRef(null);
  const {
    fieldName,
    defaultValue,
    registerField,
    error,
    clearError,
  } = useField(name);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref: any) => {
        if (rest.isMulti) {
          if (!ref.select.state.value) {
            return [];
          }
          return ref.select.state.value.map(
            (option: OptionTypeBase) => option.value,
          );
        }
        if (!ref.select.state.value) {
          return '';
        }
        return ref.select.state.value;
      },
      clearValue: ref => {
        // eslint-disable-next-line no-param-reassign
        ref.select.state.value = '';
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  const handleInputFocus = useCallback((): void => {
    setIsFocused(true);
    // Função do unform para limpar os erros do campo
    clearError();
  }, [clearError]);

  const handleInputBlur = useCallback((): void => {
    setIsFocused(false);

    const valueSelect: any = selectRef.current;
    setIsFilled(!!valueSelect.state.value);
  }, []);

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      minHeight: 1,
      width: 200,
      letterSpacing: '0,09em',
      fontSize: `${fontSize}`,
    }),
    menu: (provided: any) => ({
      ...provided,
      width: 'auto',
      minWidth: 300,
      letterSpacing: '0,09em',
      fontSize: `${fontSize}`,
    }),
  };

  return (
    <Container
      isErrored={!!error}
      isFilled={isFilled}
      isFocused={isFocused}
      className={className}
    >
      {label && (
        <label
          htmlFor={name}
          style={{
            textTransform: 'uppercase',
            fontSize: `${fontSize}`,
            letterSpacing: '0,09em',
          }}
        >
          {label}
        </label>
      )}
      <InputGroup className="pt-0">
        {Icon && (
          <InputGroup.Prepend>
            <InputGroup.Text
              id="basic-addon1"
              className={classNameInputGroupTextAndFormControl}
            >
              <Icon size={sizeIcon} />
            </InputGroup.Text>
          </InputGroup.Prepend>
        )}
        <Select
          defaultValue={defaultValue}
          cacheOptions
          ref={selectRef}
          classNamePrefix="react-select"
          className="d-flex flex-fill border border-primary rounded text-uppercase"
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          styles={customStyles}
          loadingMessage={() => 'Carregando...'}
          {...rest}
        />
        {error && (
          <ErrorStyle title={error}>
            <FiAlertCircle size={20} />
          </ErrorStyle>
        )}
      </InputGroup>
    </Container>
  );
}
