/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useCallback } from 'react';

import { FaSignOutAlt } from 'react-icons/fa';
import { MdNotificationsActive } from 'react-icons/md';

import { useAuth } from '../../../context/AuthContext';

import { Container } from './styles';

const Header: React.FC = () => {
  /* const [currentUserLogged, setCurrentUserLogged] = useState<any>(
    JSON.parse(sessionStorage.getItem('@Stock5b:user')!),
  ); */
  const { configuration, user, signOut }: any = useAuth();

  const handleLogOut = useCallback(() => {
    signOut();
    window.location.href = '/';
  }, [signOut]);

  return (
    <Container>
      {user && (
        <div>
          <span>{user!.nickname}</span>
          <span> | </span>
          <span>{configuration.fantasy_name}</span>
        </div>
      )}
      <div>
        <MdNotificationsActive />
        <FaSignOutAlt onClick={handleLogOut} />
      </div>
    </Container>
  );
};

export default Header;
