import styled from 'styled-components';

export const Header = styled.div`
  width: 100%;
  text-transform: uppercase;
`;

export const AmountTotal = styled.div`
  width: 100%;
  margin-top: 20px;
  text-align: right;
  text-transform: uppercase;
  font-weight: bold;

  strong {
    color: var(--laranja-5bits);
  }
`;
