/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Form } from '@unform/web';
import { FormHandles, Scope } from '@unform/core';
import { useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import ComplexCreateAndUpdateTemplate from '../../../components/Template/ComplexCreateAndUpdateTemplate';

import api from '../../../services/api';
import convertDateToBr from '../../../utils/convertDateToBr';

import { useToast } from '../../../context/ToastContext';

import HeaderSalesUpdate from './PartialsUpdate/HeaderSellUpdate/headerSellUpdate';
import BodySalesUpdate from './PartialsUpdate/BodySellUpdate/bodySellUpdate';
import FooterSalesUpdate from './PartialsUpdate/FooterSellUpdate/footerSellUpdate';

interface ClientFormDTO {
  id: number;
  name: string;
  dependent_name: string;
}

interface EmployeeFormDTO {
  id: number;
  name: string;
}

interface SaleBodyItemFormDTO {
  id: number;
  sale_header_id: number;
  product_id: string;
  quantity: number;
  price: string;
  discount: string;
  name: string;
  size: string;
  color: string;
}

interface BillsReceiveItemFormDTO {
  id: number;
  name: string;
  sale_header_id: number;
  analytical_account_id: number;
  payment_method_id: number;
  amount: string;
  amount_received: string;
  due_date: string;
  discount: string;
  addition: string;
  is_paid: boolean;
}

const UpdateSell: React.FC = () => {
  const { id }: any = useParams();
  const formRef = useRef<FormHandles>(null);
  // const [listSales, setListSales] = useState<any>();
  const { addToast } = useToast();

  // eslint-disable-next-line no-unused-vars
  const [client, setClient] = useState<ClientFormDTO>({} as ClientFormDTO);
  // eslint-disable-next-line no-unused-vars
  const [employee, setEmployee] = useState<EmployeeFormDTO>(
    {} as EmployeeFormDTO,
  );

  const [sale, setSale] = useState<any>({} as any);

  useEffect(() => {
    try {
      const getListSales = async (): Promise<void> => {
        try {
          const response = await api.get(`sales/sell/${id}`);

          // Search details product
          const newSalesBodyItem: any = await response.data.sales_body.map(
            // eslint-disable-next-line consistent-return
            async (item: SaleBodyItemFormDTO) => {
              try {
                const responseProduct = await api.get(
                  `products/${item.product_id}`,
                );
                return {
                  ...item,
                  name: responseProduct.data.name,
                  size: responseProduct.data.size,
                  color: responseProduct.data.color,
                };
              } catch (error) {
                // eslint-disable-next-line no-shadow
                if (error.response) {
                  const { data } = error.response; // Error vindo do back está em data dentro de response
                  addToast({
                    type: 'error',
                    title: data.message,
                  });
                  // eslint-disable-next-line consistent-return
                  return;
                }
                addToast({
                  type: 'error',
                  title: `Ocorreu um erro interno${error}`,
                });
              }
            },
          );
          // End Search details product

          // Search name PaymentMethods
          const newBillsReceiveHeaderItem: any = await response.data.bills_receive_header.map(
            // eslint-disable-next-line consistent-return
            async (item: BillsReceiveItemFormDTO) => {
              try {
                const responsePaymentMethods = await api.get(
                  `cash/payment-methods/${item.payment_method_id}`,
                );
                return {
                  ...item,
                  name: responsePaymentMethods.data.name,
                };
              } catch (error) {
                // eslint-disable-next-line no-shadow
                if (error.response) {
                  const { data } = error.response; // Error vindo do back está em data dentro de response
                  addToast({
                    type: 'error',
                    title: data.message,
                  });
                  // eslint-disable-next-line consistent-return
                  return;
                }
                addToast({
                  type: 'error',
                  title: `Ocorreu um erro interno${error}`,
                });
              }
            },
          );
          // End Search name PaymentMethods

          setSale({
            ...response.data,
            created_at: convertDateToBr(response.data.created_at),
            sales_body: await Promise.all(newSalesBodyItem),
            bills_receive_header: await Promise.all(newBillsReceiveHeaderItem),
          });
        } catch (error) {
          // eslint-disable-next-line no-shadow
          if (error.response) {
            const { data } = error.response; // Error vindo do back está em data dentro de response
            addToast({
              type: 'error',
              title: data.message,
            });
            return;
          }
          addToast({
            type: 'error',
            title: 'Ocorreu um erro interno',
          });
        }
      };
      getListSales();
    } catch (error) {
      // eslint-disable-next-line no-shadow
      if (error.response.data) {
        const { data } = error.response; // Error vindo do back está em data dentro de response
        addToast({
          type: 'error',
          title: data.message,
        });
        return;
      }

      addToast({
        type: 'error',
        title: 'Ocorreu um erro interno',
      });
    }
  }, [addToast, id]);

  // Seta os valores no Form. Se não setar com o setData os campos como currency e mascara não recebem o valor
  useEffect(() => {
    formRef.current?.setData(sale);
  }, [sale]);

  const handleSub = useCallback(() => {
    // Não faz submit. Form apenas para exibição de dados
  }, []);

  return (
    <ComplexCreateAndUpdateTemplate title="Detalhe da Venda">
      <Form
        ref={formRef}
        onSubmit={handleSub}
        initialData={sale}
        className="h-100"
      >
        <Row noGutters className="w-100 h-100">
          <Col md={12} className="d-md-flex flex-row p-0 h-30">
            <HeaderSalesUpdate client={client} employee={employee} />
          </Col>
          <Col
            md={12}
            style={{ maxHeight: '60%' }}
            className="mt-2 mb-2 border border-right-0 border-left-0 border-cinza-claro-5bits overflow-auto"
          >
            <Row
              noGutters
              className="w-100 h-100 justify-content-center row no-gutters overflow-auto"
            >
              <Col md={12} className="p-md-2">
                {sale.sales_body && (
                  <>
                    {sale.sales_body.map(
                      (item: SaleBodyItemFormDTO, index: number) => (
                        <Row key={index.toString()} noGutters className="w-100">
                          <Scope
                            key={index.toString()}
                            path={`sales_body[${index}]`}
                          >
                            <BodySalesUpdate index={index} />
                          </Scope>
                        </Row>
                      ),
                    )}
                  </>
                )}
              </Col>
            </Row>
          </Col>
          <Col md={12} className="d-md-flex flex-row align-items-end">
            <Row noGutters className="w-100 h-auto">
              <FooterSalesUpdate
                saleId={sale.id}
                paymentMethodsComplete={sale.bills_receive_header}
              />
            </Row>
          </Col>
        </Row>
      </Form>
    </ComplexCreateAndUpdateTemplate>
  );
};

export default UpdateSell;
