/* eslint-disable camelcase */
import React, { useState, useEffect, useCallback } from 'react';
import fincalc from 'fincalc';
import { parseISO, isAfter, formatISO } from 'date-fns';
import Table from 'react-bootstrap/Table';

import ListingTemplateOfCashFlow from './ListingTemplateOfCashFlow';
import api from '../../../services/api';

import { useToast } from '../../../context/ToastContext';
import Loader from '../../../components/Loader';
import Modal from '../../../components/Modal';
import Button from '../../../components/Button';
import convertNumberFloatToBr from '../../../utils/convertNumberFloatToBr';
import convertDateToBr from '../../../utils/convertDateToBr';

import { IPermission } from '../../../interfaces/IPermission';

interface CashFlowClosingFormDTO {
  id: number;
  close_date: string;
  financialAccount: Array<{
    name: string;
    balance: string;
  }>;
}

interface PermissionDTO {
  permissions: IPermission;
}

const ListCashFlowClosing: React.FC<PermissionDTO> = ({ permissions }) => {
  const [loading, setLoading] = useState(false);
  const [listCashFlowClosing, setListCashFlowClosing] = useState<
    CashFlowClosingFormDTO[]
  >([]);
  const [
    listCashFlowClosingPaginatedItems,
    setListCashFlowClosingPaginatedItems,
  ] = useState<CashFlowClosingFormDTO[]>([]);
  const { addToast } = useToast();
  // const [lastCloseDate, setLastCloseDate] = useState('');
  const [configuration, setConfiguration] = useState<any>({});
  const [modalCashFlowOpen, setModalCashFlowOpen] = useState(false);
  const [modalCashFlowClose, setModalCashFlowClose] = useState(false);

  const groupCloseDays = useCallback((objOfReturn, objActual) => {
    // eslint-disable-next-line no-param-reassign
    objOfReturn[objActual.close_date] = objOfReturn[objActual.close_date] || [];
    objOfReturn[objActual.close_date].push(objActual);

    return objOfReturn;
  }, []);

  const groupFinancialAccounts = useCallback((objOfReturn, objActual) => {
    objOfReturn.push({
      ...objActual.financialAccount,
      balance: objActual.balance,
    });
    return objOfReturn;
  }, []);

  useEffect(() => {
    try {
      const getListCashFlowClosing = async (): Promise<void> => {
        try {
          setLoading(true);
          // Configuration
          const responseConfiguration = await api.get('/');

          setConfiguration(responseConfiguration.data);

          if (responseConfiguration.data.open_date) {
            const dateNowFormattedString = formatISO(new Date(), {
              representation: 'date',
            });
            const openDateFormattedString = formatISO(
              parseISO(responseConfiguration.data.open_date),
              {
                representation: 'date',
              },
            );

            isAfter(
              parseISO(dateNowFormattedString),
              parseISO(openDateFormattedString),
            )
              ? setModalCashFlowOpen(true)
              : setModalCashFlowOpen(false);
          }

          responseConfiguration.data.cash_flow_open
            ? setModalCashFlowClose(false)
            : setModalCashFlowClose(true);
          // Fim Configuration

          const response: any = await api.get('cash/cash-flow');

          const closeDaysGrouped = Object.values(
            response.data.reduce(groupCloseDays, {}),
          ).map((item: any) => {
            const financialAccounts = item.reduce(groupFinancialAccounts, []);
            return { ...item[0], financialAccount: financialAccounts };
          });

          setListCashFlowClosing(closeDaysGrouped);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          // eslint-disable-next-line no-shadow
          if (error.response) {
            const { data } = error.response; // Error vindo do back está em data dentro de response
            addToast({
              type: 'error',
              title: data.message,
            });
            return;
          }

          addToast({
            type: 'error',
            title: `Ocorreu um erro interno`,
          });
        }
      };
      getListCashFlowClosing();
    } catch (error) {
      setLoading(false);
      // eslint-disable-next-line no-shadow
      if (error.response.data) {
        const { data } = error.response; // Error vindo do back está em data dentro de response
        addToast({
          type: 'error',
          title: data.message,
        });
        return;
      }

      addToast({
        type: 'error',
        title: `Ocorreu um erro interno`,
      });
    }
  }, [addToast, groupCloseDays, groupFinancialAccounts]);

  const handleListCashFlowClosingPaginatedItems = useCallback(newList => {
    setListCashFlowClosingPaginatedItems(newList);
  }, []);

  const handleConfiguration = useCallback(newConfiguration => {
    setConfiguration(newConfiguration);
  }, []);

  return (
    <ListingTemplateOfCashFlow
      title="Fluxo de Caixa"
      module="cash/cash-flow"
      listItemsProps={listCashFlowClosing}
      configuration={configuration}
      handleListItemsPaginatedItems={handleListCashFlowClosingPaginatedItems}
      handleConfiguration={handleConfiguration}
      permissions={permissions}
    >
      {(loading && <Loader />) || (
        <>
          {configuration.open_date && (
            <div className="text-primary text-uppercase font-weight-bold">
              Data do caixa:
              <span className="text-cinza-51-5bits text-uppercase ml-2 font-weight-bold">
                {convertDateToBr(configuration.open_date)}
              </span>
              <span className="text-cinza-51-5bits text-uppercase ml-2 font-weight-bold">
                {configuration.open_hour}
              </span>
              <span className="mx-2">-</span>
              {(configuration.cash_flow_open && (
                <span className="text-success text-uppercase font-weight-bold">
                  Aberto
                </span>
              )) || (
                <span className="text-danger text-uppercase font-weight-bold">
                  Fechado
                </span>
              )}
            </div>
          )}
          <Table striped bordered hover size="sm" responsive="md">
            <thead className="text-center text-primary text-uppercase">
              <tr>
                <th className="font-weight-normal py-3 w-30" scope="col">
                  Data Fechamento
                </th>
                <th className="font-weight-normal py-3" scope="col">
                  Conta
                </th>
                <th className="font-weight-normal py-3" scope="col">
                  Saldo Atual
                </th>
              </tr>
            </thead>
            <tbody>
              {listCashFlowClosingPaginatedItems &&
                listCashFlowClosingPaginatedItems.length > 0 && (
                  <>
                    {listCashFlowClosingPaginatedItems.map(
                      (item, index: number) => (
                        <React.Fragment key={index.toString()}>
                          {item.financialAccount &&
                            item.financialAccount.length > 0 && (
                              <>
                                <tr>
                                  <td
                                    rowSpan={item.financialAccount.length + 2}
                                    style={{
                                      textAlign: 'center',
                                      verticalAlign: 'middle',
                                    }}
                                    className={`${
                                      index % 2 === 0
                                        ? 'bg-branco-puro-5bits'
                                        : 'bg-cinza-claro-5bits'
                                    }`}
                                  >
                                    {convertDateToBr(item.close_date)}
                                  </td>
                                </tr>
                                {item.financialAccount.map(
                                  (subItem, subIndex) => (
                                    <tr key={subIndex.toString()}>
                                      <td className="text-left">
                                        {subItem.name}
                                      </td>
                                      <td className="text-right pr-3">
                                        {convertNumberFloatToBr(
                                          subItem.balance,
                                        )}
                                      </td>
                                    </tr>
                                  ),
                                )}

                                <tr>
                                  <th colSpan={2} scope="row" className="pr-0">
                                    <div className="d-flex justify-content-around">
                                      <span className="w-50">Total</span>
                                      <span className="w-50 text-right pr-3">
                                        {convertNumberFloatToBr(
                                          fincalc(
                                            item.financialAccount.reduce(
                                              (sum: number, itemSum: any) => {
                                                return (
                                                  sum +
                                                  parseFloat(itemSum.balance)
                                                );
                                              },
                                              0,
                                            ),
                                          ),
                                        )}
                                      </span>
                                    </div>
                                  </th>
                                </tr>
                              </>
                            )}
                        </React.Fragment>
                      ),
                    )}
                  </>
                )}
            </tbody>
          </Table>
          <Modal visibleProp={modalCashFlowOpen}>
            <div className="d-flex flex-column align-items-center w-100 bg-branco-puro-5bits p-4">
              <h1>
                Caixa do dia {convertDateToBr(configuration.open_date)} não foi
                fechado
              </h1>
              <div className="d-flex flex-row align-items-center justify-content-center w-100">
                <Button
                  name="submit"
                  type="submit"
                  className="w-30 mr-2"
                  fontSizeButton="12px"
                  onClick={() => setModalCashFlowOpen(false)}
                >
                  OK
                </Button>
              </div>
            </div>
          </Modal>

          <Modal visibleProp={modalCashFlowClose}>
            <div className="d-flex flex-column align-items-center w-100 bg-branco-puro-5bits p-4">
              <h1>Caixa fechado</h1>
              <div className="d-flex flex-row align-items-center w-100">
                <Button
                  name="submit"
                  type="submit"
                  className="w-100 mr-2"
                  fontSizeButton="12px"
                  onClick={() => setModalCashFlowClose(false)}
                >
                  OK
                </Button>
              </div>
            </div>
          </Modal>
        </>
      )}
    </ListingTemplateOfCashFlow>
  );
};

export default ListCashFlowClosing;
