/* eslint-disable camelcase */
import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import fincalc from 'fincalc';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SimpleCreateAndUpdateTemplate from '../../../components/Template/SimpleCreateAndUpdateTemplate';
import api from '../../../services/api';
import getValidationErrors from '../../../utils/getValidationErrors';
import convertDateToBr from '../../../utils/convertDateToBr';
import convertNumberFloatToBr from '../../../utils/convertNumberFloatToBr';
import convertNumberFloatToDB from '../../../utils/convertNumberFloatToDB';
import { useToast } from '../../../context/ToastContext';

import Input from '../../../components/Input';
import InputCurrency from '../../../components/Input/InputCurrency';
import SelectWithListLocal from '../../../components/SelectWithListLocal';
import Button from '../../../components/Button';

interface BillReceiveHeaderFormDTO {
  id: number;
  sale_header_id: number;
  analytical_account_id: number;
  payment_method_id: number;
  client_id: number;
  amount: string;
  amount_received: string;
  due_date: string;
  discount: string;
  addition: string;
  is_paid: boolean;
  bills_receive_body: Array<any>;

  amount_receiving: string;
  amount_receiving_text: string;
  payment_methods?: any;
}

const UpdateReceive: React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();
  const { id }: any = useParams();
  const formRef = useRef<FormHandles>(null);
  const [billReceive, setBillReceive] = useState<BillReceiveHeaderFormDTO>(
    {} as BillReceiveHeaderFormDTO,
  );
  const [paymentMethodsComplete, setPaymentMethodsComplete] = useState<any[]>();
  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    const getBillReceive = async (): Promise<void> => {
      try {
        const response = await api.get(`sales/receive/${id}`);

        const responsePaymentMethodsComplete = await api.get(
          'cash/payment-methods',
        );

        const newData = {
          ...response.data,
          amount_receiving: convertNumberFloatToBr(
            fincalc(
              parseFloat(response.data.amount) -
                parseFloat(response.data.amount_received),
            ),
          ),
        };

        setBillReceive(newData);
        setPaymentMethodsComplete(responsePaymentMethodsComplete.data);

        /**
         * Preenche os campos do formulário. Sem esta linha os inputs com
         * máscara não são preenchidos.
         */
        formRef.current?.setData(newData);
      } catch (error) {
        // eslint-disable-next-line no-shadow
        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: data.message,
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Ocorreu um erro interno',
        });
      }
    };
    getBillReceive();
  }, [addToast, id]);

  // handleSubmit
  const handleSubmit = useCallback(
    async (dataForm: BillReceiveHeaderFormDTO, { reset }) => {
      try {
        setDisableButton(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          payment_methods: Yup.object()
            .shape({
              id: Yup.string().required('Forma de pagamento obrigatório'),
            })
            .nullable()
            .required('Forma de pagamento obrigatório'),
          amount_receiving_text: Yup.string()
            .test('amount_receiving_text', 'Valor inválido', () => {
              const valueAmountReceived = convertNumberFloatToDB(
                dataForm.amount_receiving_text,
              );
              const value = fincalc(
                valueAmountReceived + parseFloat(billReceive.amount_received),
              );
              if (
                valueAmountReceived <= 0 ||
                value > parseFloat(billReceive.amount)
              ) {
                return false;
              }
              return true;
            })
            .required('Valor obrigatório'),
        });

        await schema.validate(dataForm, {
          abortEarly: false,
        });

        const newData: any = [
          {
            id: dataForm.id,
            amount_received: dataForm.amount_receiving_text,
            payment_method_id: dataForm.payment_methods.id,
          },
        ];

        await api.post('sales/receive/pay/', newData);

        addToast({
          type: 'success',
          title: 'Cadastro realizado com sucesso',
        });

        reset();
        setDisableButton(false);

        history.push('/sales/receive/list');
      } catch (error) {
        setDisableButton(false);

        // Verifica se o error é do Yup ou seja dos campos
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);

          return;
        }
        // eslint-disable-next-line no-shadow
        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: 'Erro no cadastro',
            description: data.message,
          });
          if (formRef.current?.getFieldRef(data.field)) {
            formRef.current?.getFieldRef(data.field).focus(); // Foca o campo que deu erro de acordo com o field retornado do back
          }

          return;
        }

        addToast({
          type: 'error',
          title: `Ocorreu um erro interno${error}`,
        });
      }
    },
    [addToast, billReceive.amount, billReceive.amount_received, history],
  );
  // Fim handleSubmit

  const handleSugestionAmountReceiving = useCallback(
    e => {
      const amountReceivedFloat = parseFloat(billReceive.amount_received);
      const amountFloat = parseFloat(billReceive.amount);
      const valueReceiving = convertNumberFloatToDB(e.target.value);

      const value = fincalc(valueReceiving + amountReceivedFloat);

      if (valueReceiving <= 0 || value > amountFloat) {
        formRef.current?.setFieldError(
          'amount_receiving_text',
          'Valor inválido',
        );
      } else {
        formRef.current?.setFieldError('amount_receiving_text', '');
      }
    },
    [billReceive.amount, billReceive.amount_received],
  );

  return (
    <SimpleCreateAndUpdateTemplate
      title="Baixa Conta a Receber"
      numberColContent={7}
      classNameProp="h-95"
    >
      <Form
        ref={formRef}
        initialData={billReceive}
        className="h-100 mt-4"
        onSubmit={handleSubmit}
      >
        <Row noGutters className="d-flex flex-row h-50">
          <Input type="hidden" name="id" isHidden />
          <Col className="mr-5 border-right">
            <Row className="d-flex flex-column">
              <Col className="text-primary text-uppercase mb-2">
                Cód. da Venda:
                <span className="text-cinza-51-5bits text-uppercase ml-2">
                  <Link to={`/sales/sell/read/${billReceive.sale_header_id}`}>
                    {billReceive.sale_header_id}
                  </Link>
                </span>
              </Col>
              <Col className="text-primary text-uppercase mb-2">
                Valor:
                <span className="text-cinza-51-5bits text-uppercase ml-2">
                  {convertNumberFloatToBr(billReceive.amount)}
                </span>
              </Col>
              <Col className="text-primary text-uppercase mb-2">
                Valor Recebido:
                <span className="text-cinza-51-5bits text-uppercase ml-2">
                  {convertNumberFloatToBr(billReceive.amount_received)}
                </span>
              </Col>
              <Col className="text-primary text-uppercase mb-2">
                Valor À Receber:
                <span className="text-cinza-51-5bits text-uppercase ml-2">
                  {billReceive.amount_receiving}
                </span>
              </Col>
              <Col className="text-primary text-uppercase mb-2">
                Desconto:
                <span className="text-cinza-51-5bits text-uppercase ml-2">
                  {convertNumberFloatToBr(billReceive.discount)}
                </span>
              </Col>
              <Col className="d-flex flex-row align-items-center text-primary text-uppercase mb-2">
                Acréscimo:
                <span className="text-cinza-51-5bits text-uppercase ml-2">
                  {convertNumberFloatToBr(billReceive.addition)}
                </span>
              </Col>
              <Col className="d-flex flex-row align-items-center text-primary text-uppercase mb-2">
                Vencimento:
                <span className="text-cinza-51-5bits text-uppercase ml-2">
                  {convertDateToBr(billReceive.due_date)}
                </span>
              </Col>
              <Col className="d-flex flex-row align-items-center text-primary text-uppercase mb-2">
                Tipo:
                <span className="text-cinza-51-5bits text-uppercase ml-2">
                  {paymentMethodsComplete &&
                    paymentMethodsComplete.find(
                      item => item.id === billReceive.payment_method_id,
                    ).name}
                </span>
              </Col>
            </Row>
          </Col>

          <Col className="d-flex flex-column align-items-center align-items-top text-primary text-uppercase h-100">
            <div className="d-flex justify-content-around align-items-center w-100">
              <span className="d-flex justify-content-center align-items-center w-50">
                Data Pag.
              </span>
              <span className="d-flex justify-content-center align-items-center w-50">
                Valor
              </span>
            </div>
            <div className="w-100 overflow-auto">
              <table
                style={{ fontSize: '12px' }}
                className="table-striped table-hover table-bordered w-100"
              >
                <tbody className="text-center">
                  {billReceive.bills_receive_body &&
                    billReceive.bills_receive_body.map((item: any, index) => (
                      <tr key={index.toString()}>
                        <td className="text-center p-1 w-50">
                          {convertDateToBr(item.receive_date)}
                        </td>

                        <td className="text-right pr-3">
                          {convertNumberFloatToBr(item.amount_received)}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
        <hr />
        <Row noGutters>
          <Col className="d-flex flex-row align-items-center text-primary text-uppercase">
            <SelectWithListLocal
              name="payment_methods"
              label="Forma de Pagamento"
              placeholder="Forma de Pagamento"
              options={paymentMethodsComplete}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              aria-label="Forma de Pagamento"
              maxLength={45}
              className="mr-md-3"
            />
          </Col>
          <Col
            md={4}
            className="d-flex flex-row align-items-center text-primary text-uppercase"
          >
            <InputCurrency
              name="amount_receiving_text"
              label="Valor"
              onKeyUp={handleSugestionAmountReceiving}
            />
          </Col>
        </Row>
        <Row noGutters>
          <Col>
            <Button
              name="submit"
              type="submit"
              className="mt-5 w-auto"
              disabled={disableButton}
            >
              Salvar
            </Button>
          </Col>
        </Row>
      </Form>
    </SimpleCreateAndUpdateTemplate>
  );
};

export default UpdateReceive;
