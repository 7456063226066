/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Input from '../../../../../components/Input';
import CurrencyInput from '../../../../../components/Input/InputCurrency';

interface HeaderSalesFormDTO {
  index: number;
}

const BodySellUpdate: React.FC<HeaderSalesFormDTO> = ({ index }) => {
  return (
    <>
      <Row noGutters className="w-100">
        <Col md={4} className="d-flex align-items-end p-md-2">
          <Input
            name="name"
            label={`${(index === 0 && 'Nome do Produto') || ''}`}
            placeholder="Nome do Produto"
            aria-label="Nome do Produto"
            maxLength={45}
            className="mb-1"
            fontSizeInput="12px"
            fontSizeLabel="12px"
            disabled
          />
        </Col>
        <Col md={4} className="d-flex flex-row p-md-2">
          <Input
            type="text"
            name="product_id"
            label={`${(index === 0 && 'Código') || ''}`}
            className="mb-1 mr-2"
            fontSizeInput="12px"
            fontSizeLabel="12px"
            disabled
          />
          <Input
            type="text"
            name="size"
            label={`${(index === 0 && 'Tamanho') || ''}`}
            className="mb-1 mr-2"
            fontSizeInput="12px"
            fontSizeLabel="12px"
            disabled
          />
          <Input
            type="text"
            name="color"
            label={`${(index === 0 && 'Cor') || ''}`}
            className="mb-1 mr-2"
            fontSizeInput="12px"
            fontSizeLabel="12px"
            disabled
          />
          <Input
            type="text"
            name="price"
            label={`${(index === 0 && 'Preço UN') || ''}`}
            className="mb-1"
            classNameInputGroupTextAndFormControl="p-1 font-weight-bold"
            fontSizeInput="12px"
            fontSizeLabel="12px"
            disabled
          />
        </Col>
        <Col md={4} className="d-flex flex-row align-items-center p-md-2">
          <Input
            type="number"
            name="quantity"
            label={`${(index === 0 && 'Quantidade') || ''}`}
            className="mb-1 mr-2"
            fontSizeInput="12px"
            fontSizeLabel="12px"
            min={1}
            disabled
          />
          <CurrencyInput
            type="text"
            name="discount"
            label={`${(index === 0 && 'Desconto') || ''}`}
            className="mb-1 mr-2"
            fontSizeInput="12px"
            fontSizeLabel="12px"
            disabled
          />
        </Col>
      </Row>
    </>
  );
};

export default BodySellUpdate;
