/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useState, useEffect } from 'react';

import { FormHandles, Scope } from '@unform/core';
import fincalc from 'fincalc';

import * as Yup from 'yup';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Spinner from 'react-bootstrap/Spinner';
import getValidationErrors from '../../../../../utils/getValidationErrors';

import Input from '../../../../../components/Input';
import CurrencyInput from '../../../../../components/Input/InputCurrency';
import Checkbox from '../../../../../components/Checkbox';
import Button from '../../../../../components/Button';
import SelectWithListLocal from '../../../../../components/SelectWithListLocal';
import Modal from '../../../../../components/Modal';
import ModalSimulateSale from '../../Modal/modalSimulateSell';

import { useToast } from '../../../../../context/ToastContext';

import api from '../../../../../services/api';

import convertNumberFloatToDB from '../../../../../utils/convertNumberFloatToDB';
import convertNumberFloatToBr from '../../../../../utils/convertNumberFloatToBr';

interface DiscountCouponFormDataDTO {
  id: number;
  expiration_date: string;
  amount: string;
  client_name: string;
  is_active: boolean;
}
// const VALID_DIGIT = /^[0-9]{1}$/;

interface HeaderSalesFormDTO {
  formRefProps: any | FormHandles;
  paymentMethodsComplete: Array<any>[];
  amountReceivedOriginalProps: number;
  // eslint-disable-next-line no-unused-vars
  handleDiscountCoupon: (discountCouponProp: DiscountCouponFormDataDTO) => void;
}

const FooterSell: React.FC<HeaderSalesFormDTO> = ({
  formRefProps,
  paymentMethodsComplete,
  amountReceivedOriginalProps = 0,
  handleDiscountCoupon,
}) => {
  // Variáveis

  const [listPaymentMethods, setListPaymentMethods] = useState<any[]>([
    {} as any,
  ]);

  const [
    totalInstallmentsDisable,
    setTotalInstallmentsDisable,
  ] = useState<boolean>(true);

  // Second payment
  const [
    totalInstallmentsDisableSecondPayment,
    setTotalInstallmentsDisableSecondPayment,
  ] = useState<boolean>(true);

  const [isSecondPayment, setIsSecondPayment] = useState(false);

  const [listSimulator, setListSimulator] = useState<any[]>([]);
  const [loadModalSimulate, setLoadModalSimulate] = useState(false);
  const [loadModalLoadingSimulate, setLoadModalLoadingSimulate] = useState(
    false,
  );
  const [loadModalConfirmationSale, setLoadModalConfirmationSale] = useState(
    false,
  );

  const { addToast } = useToast();

  const [visibleDiscount, setVisibleDiscount] = useState(true); // Inicia o campo habilitado
  const [visibleDiscountSecond, setVisibleDiscountSecond] = useState(true);

  const [visibleAddition, setVisibleAddition] = useState(true); // Inicia o campo habilitado
  const [visibleAdditionSecond, setVisibleAdditionSecond] = useState(true);

  const [amountReceivedOriginal, setAmountReceivedOriginal] = useState(0);
  const [
    amountReceivedOriginalSecond,
    setAmountReceivedOriginalSecond,
  ] = useState(0);

  const [discountCoupon, setDiscountCoupon] = useState(
    {} as DiscountCouponFormDataDTO,
  );

  // Fim Variáveis
  const toggleLoadModalSimulate = useCallback(() => {
    setLoadModalSimulate(!loadModalSimulate);
  }, [loadModalSimulate]);

  const toggleLoadModalConfirmationSale = useCallback(() => {
    setLoadModalConfirmationSale(!loadModalConfirmationSale);
  }, [loadModalConfirmationSale]);

  /*
  Atualiza amountReceivedOriginal caso seja adicionado outro produto a em bodySale e já tiver valor
  no campo valor recebido. No caso é a soma do que o cliente digitou para pagar mais o valor do novo
  produto. Já vem com o valor correto de bodySale. Lembrando que passa no componente pai createSale.
  */
  useEffect(() => {
    setAmountReceivedOriginal(amountReceivedOriginalProps);
  }, [amountReceivedOriginalProps]);

  // handleSecondAmountReceived
  const handleSecondAmountReceived = useCallback(
    e => {
      if (isSecondPayment && e.key !== 'Tab') {
        // limpa mensagem de erro
        formRefProps.current?.setErrors({});
        const valueAmountReceivedPaymentMethodsPrimary = convertNumberFloatToDB(
          formRefProps.current.getFieldValue(
            'payment_methods[0].amount_received',
          ),
        );

        let valueAmountReceivedPaymentMethodsSecond = convertNumberFloatToDB(
          formRefProps.current.getFieldValue(
            'payment_methods[1].amount_received',
          ),
        );

        const valueAmount = convertNumberFloatToDB(
          formRefProps.current.getFieldValue('amount'),
        );

        if (
          valueAmountReceivedPaymentMethodsPrimary >= 0 &&
          valueAmountReceivedPaymentMethodsPrimary <= valueAmount
        ) {
          valueAmountReceivedPaymentMethodsSecond = fincalc(
            valueAmount - valueAmountReceivedPaymentMethodsPrimary,
          );

          setAmountReceivedOriginal(valueAmountReceivedPaymentMethodsPrimary);

          setAmountReceivedOriginalSecond(
            valueAmountReceivedPaymentMethodsSecond,
          );

          formRefProps.current?.setFieldValue(
            `payment_methods[1].amount_received`,
            valueAmountReceivedPaymentMethodsSecond.toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
            }),
          );
        } else {
          formRefProps.current.setFieldError(
            'payment_methods[0].amount_received',
            'Valor divergente do total da compra',
          );
        }
      }
    },
    [formRefProps, isSecondPayment],
  );
  // Fim handleSecondAmountReceived

  // handleUpdateAmount
  const handleUpdateAmount = useCallback(() => {
    const dataForm = formRefProps.current.getData();
    const salesBodyScope = dataForm.sales_body;
    const paymentMethods: any = dataForm.payment_methods;

    const amountDiscountCoupon: number = discountCoupon.amount
      ? parseFloat(discountCoupon.amount)
      : 0;

    let calcAmountItems = 0; // Tem o valor da soma dos totais de produtos
    let discount = 0;
    let addition = 0;
    let discountSecond = 0;
    let additionSecond = 0;

    let amountReceived = 0;
    let amountReceivedSecond = 0;

    let amount = 0;

    if (salesBodyScope) {
      // Soma os totais de todos os produtos
      calcAmountItems = fincalc(
        salesBodyScope.reduce((sum: number, item: any) => {
          return sum + convertNumberFloatToDB(item.amount);
        }, 0),
      );

      // Paga os descontos, acréscimos e valores pagos
      paymentMethods.map((item: any, index: number) => {
        if (index === 0) {
          discount = convertNumberFloatToDB(item.discount);
          addition = convertNumberFloatToDB(item.addition);
          amountReceived = convertNumberFloatToDB(item.amount_received);
        } else {
          discountSecond = convertNumberFloatToDB(item.discount);
          additionSecond = convertNumberFloatToDB(item.addition);
          amountReceivedSecond = convertNumberFloatToDB(item.amount_received);
        }

        return null;
      });

      /*
      Se for uma forma de pagamento o valor total recebe o desconto e acréscimo e o
      valor pago recebe o valor total já com o desconto e acréscimo da primeira forma calculados.
      */
      if (!isSecondPayment) {
        amount = calcAmountItems - (discount + amountDiscountCoupon);
        amount += addition;

        amount = fincalc(amount);

        formRefProps.current?.setFieldValue(
          'payment_methods[0].amount_received',
          amount.toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
          }),
        );

        formRefProps.current?.setFieldValue(
          'amount',
          amount.toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
          }),
        );

        // Duas formas de pagamento
      } else {
        amountReceived = amountReceivedOriginal - discount + addition;
        amountReceivedSecond =
          amountReceivedOriginalSecond - discountSecond + additionSecond;

        amountReceived = fincalc(amountReceived);
        amountReceivedSecond = fincalc(amountReceivedSecond);

        calcAmountItems =
          calcAmountItems -
          (discount + discountSecond) +
          (addition + additionSecond);

        calcAmountItems = fincalc(calcAmountItems);

        formRefProps.current?.setFieldValue(
          'payment_methods[0].amount_received',
          amountReceived.toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
          }),
        );

        formRefProps.current?.setFieldValue(
          'payment_methods[1].amount_received',
          amountReceivedSecond.toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
          }),
        );

        formRefProps.current?.setFieldValue(
          'amount',
          calcAmountItems.toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
          }),
        );
      }
    }
  }, [
    formRefProps,
    isSecondPayment,
    amountReceivedOriginal,
    amountReceivedOriginalSecond,
    discountCoupon,
  ]);
  // Fim handleUpdateAmount

  // submitSimular
  const submitSimular = useCallback(async () => {
    try {
      // setDisableButton(true);
      setLoadModalLoadingSimulate(true);
      const dataForm = formRefProps.current?.getData();
      formRefProps.current?.setErrors({});

      /** A função mista do Yup pode corresponder a todos os tipos */
      const schema = Yup.object().shape({
        payment_methods: Yup.array().of(
          Yup.object().shape({
            payment_method_id_select: Yup.object()
              .shape({
                id: Yup.string().required('Forma de pagamento obrigatório'),
              })
              .nullable()
              .required('Forma de pagamento obrigatório'),
          }),
        ),
      });

      await schema.validate(dataForm, {
        abortEarly: false,
      });

      const salesBody = dataForm.sales_body.map((item: any) => {
        const newItem = {
          product_id: item.id,
          quantity: item.quantity,
          price: item.price,
          discount: item.discount,
        };

        return newItem;
      });

      const paymentMethods = dataForm.payment_methods.map((item: any) => {
        const newItem = {
          ...item,
          payment_method_id: item.payment_method_id_select.id,
          name: item.payment_method_id_select.name,
        };

        return newItem;
      });

      const newDataForm: any = {
        ...dataForm,
        sales_body: salesBody,
        payment_methods: paymentMethods,
        discount_coupons: discountCoupon,
      };

      delete newDataForm.client_id_select;
      delete newDataForm.dependent_id;
      delete newDataForm.employee_id_select;
      delete newDataForm.payment_methods.payment_method_id_select;

      const response = await api.post('sales/sell/simulator', newDataForm);
      setListSimulator(response.data);

      addToast({
        type: 'success',
        title: 'Simulação realizada com sucesso',
      });

      toggleLoadModalSimulate();
      setLoadModalLoadingSimulate(false);
      // reset();
      // setDisableButton(false);

      // history.push('/sales/list');
    } catch (error) {
      // setDisableButton(false);
      setLoadModalLoadingSimulate(false);
      // Verifica se o error é do Yup ou seja dos campos
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRefProps.current?.setErrors(errors);

        return;
      }

      // eslint-disable-next-line no-shadow
      if (error.response) {
        const { data } = error.response; // Error vindo do back está em data dentro de response
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: data.message,
        });
        if (formRefProps.current?.getFieldRef(data.field)) {
          formRefProps.current?.getFieldRef(data.field).focus(); // Foca o campo que deu erro de acordo com o field retornado do back
        }

        return;
      }

      addToast({
        type: 'error',
        title: 'Ocorreu um erro interno',
      });
    }
  }, [addToast, formRefProps, toggleLoadModalSimulate, discountCoupon]);
  // Fim submitSimular

  // handlePaymentMethods
  const handlePaymentMethods = useCallback(
    (inputValue: any, indexPaymentMethods: number) => {
      if (inputValue) {
        const daysCompensat = inputValue.days_compensation;
        const allowInstallments = inputValue.allow_installments;

        // setNamePayment(inputValue.name);

        // Habilita os campos desconto e acréscimo
        setVisibleDiscount(false);
        setVisibleAddition(false);
        /*
      Tem que verificar pelo index, se verificar pelo isSecond os campos da segunda forma
      são liberados ao escolher a primeira forma.
      */
        if (indexPaymentMethods === 1) {
          setVisibleDiscountSecond(false);
          setVisibleAdditionSecond(false);
        }

        if (
          (daysCompensat === 0 && allowInstallments === false) ||
          (daysCompensat > 0 && allowInstallments === false)
        ) {
          if (indexPaymentMethods === 0) {
            setTotalInstallmentsDisable(true); // parcela
          } else {
            setTotalInstallmentsDisableSecondPayment(true);
          }

          formRefProps.current?.setFieldValue(
            `payment_methods[${indexPaymentMethods}].total_installments`,
            1,
          );
        } else if (daysCompensat > 0 && allowInstallments === true) {
          if (indexPaymentMethods === 0) {
            setTotalInstallmentsDisable(false); // parcela
          } else {
            setTotalInstallmentsDisableSecondPayment(false);
          }
        }
      }
      // updatePaymentMethodsPrimary(index);
    },
    [formRefProps],
  );
  // Fim handlePaymentMethods

  const removeSecondPaymentMethods = useCallback(() => {
    listPaymentMethods.splice(1, 1);
    const newListPaymentMethods = [...listPaymentMethods];
    setListPaymentMethods(newListPaymentMethods);
  }, [listPaymentMethods]);

  // handleAddItem
  const handleAddSecondPaymentMethods = useCallback(() => {
    if (isSecondPayment) {
      const dataForm = formRefProps.current.getData();
      let newListPaymentMethods: any = [];

      // Zera o valor pago da primeira forma de pagamento
      formRefProps.current?.setFieldValue(
        'payment_methods[0].amount_received',
        '0,0',
      );
      /*
      Não pode usar o spread senão é adicionada uma linha a mais.
      Por isso pego apenas a primeira posição.
      */
      if (listPaymentMethods) {
        newListPaymentMethods = [dataForm.payment_methods[0]];
        newListPaymentMethods.push({} as any);
      }

      setListPaymentMethods(newListPaymentMethods);
    } else {
      removeSecondPaymentMethods();
    }
  }, [
    formRefProps,
    listPaymentMethods,
    isSecondPayment,
    removeSecondPaymentMethods,
  ]);
  // Fim handleAddItem

  // handleToggleIsSecondPayment
  const handleToggleIsSecondPayment = useCallback(() => {
    setIsSecondPayment(!isSecondPayment);
    setDiscountCoupon({} as DiscountCouponFormDataDTO);

    if (isSecondPayment === true) {
      setAmountReceivedOriginal(0);
      setAmountReceivedOriginalSecond(0);

      formRefProps.current.setFieldValue('payment_methods[0].discount', 0);
      formRefProps.current.setFieldValue('payment_methods[0].addition', 0);

      formRefProps.current?.setFieldValue(
        'payment_methods[0].amount_received',
        '0,0',
      );
    } else {
      setAmountReceivedOriginal(0);
      setAmountReceivedOriginalSecond(0);

      formRefProps.current.setFieldValue('payment_methods[0].discount', 0);
      formRefProps.current.setFieldValue('payment_methods[0].addition', 0);
      formRefProps.current.clearField('payment_methods[1].discount');
      formRefProps.current.clearField('payment_methods[1].addition');
    }
  }, [isSecondPayment, formRefProps]);
  // Fim handleToggleIsSecondPayment

  // Discount Coupons
  const handleSearchDiscountCoupons = useCallback(
    async (ticketCode: string): Promise<void> => {
      const ticketCodeCorrect = ticketCode.trim();
      if (ticketCodeCorrect.length === 10) {
        try {
          const response = await api.get(
            `discount-coupons/ticket-code/${ticketCodeCorrect}`,
          );

          setDiscountCoupon(response.data);
          handleDiscountCoupon(response.data);
          formRefProps.current.setFieldValue(
            'amountCoupon',
            convertNumberFloatToBr(response.data.amount),
          );
        } catch (error) {
          setDiscountCoupon({} as DiscountCouponFormDataDTO);
          handleDiscountCoupon({} as DiscountCouponFormDataDTO);
          formRefProps.current.clearField('amountCoupon');

          // eslint-disable-next-line no-shadow
          if (error.response) {
            const { data } = error.response; // Error vindo do back está em data dentro de response
            addToast({
              type: 'error',
              title: data.message,
            });
            return;
          }

          addToast({
            type: 'error',
            title: `Ocorreu um erro interno`,
          });
        }
        // Verifica se discountCoupon está preenchido. Se estiver entra no bloco
      } else if (
        Object.keys(discountCoupon).length !== 0 ||
        ticketCodeCorrect.length !== 10
      ) {
        setDiscountCoupon({} as DiscountCouponFormDataDTO);
        handleDiscountCoupon({} as DiscountCouponFormDataDTO);
        formRefProps.current.clearField('amountCoupon');
      }

      // Retira espaços em branco do início e do fim do ticketCode
      formRefProps.current.setFieldValue('ticketCode', ticketCodeCorrect);
    },
    [addToast],
  );
  // End Discount Coupons

  useEffect(() => {
    handleUpdateAmount();
  }, [formRefProps, discountCoupon]);

  useEffect(() => {
    handleAddSecondPaymentMethods();
    handleUpdateAmount();
  }, [formRefProps, isSecondPayment]);

  return (
    <>
      <Col md={8} className="d-md-flex flex-row align-items-top">
        <Row noGutters className="w-100 h-auto">
          {listPaymentMethods && (
            <>
              {listPaymentMethods.map((item, index) => (
                <Col
                  key={index.toString()}
                  md={12}
                  className="d-md-flex flex-row align-items-top px-md-2"
                >
                  <Scope path={`payment_methods[${index}]`}>
                    <SelectWithListLocal
                      name="payment_method_id_select"
                      label={`${(index === 0 && 'Forma de Pagamento') || ''}`}
                      placeholder="Forma de Pagamento"
                      fontSize="12px"
                      sizeIcon={12}
                      options={paymentMethodsComplete}
                      getOptionLabel={option => option.name}
                      getOptionValue={option => option.id}
                      aria-label="Forma de Pagamento"
                      maxLength={45}
                      className="mr-md-2 mb-2"
                      onChange={e => handlePaymentMethods(e, index)}
                    />
                    <Input
                      type="number"
                      name="total_installments"
                      label={`${(index === 0 && 'Parcelas') || ''}`}
                      className="w-30 mr-md-2 mb-2"
                      classNameInputGroupTextAndFormControl={`p-1 ${
                        index === 0
                          ? !totalInstallmentsDisable && 'bg-branco-puro-5bits'
                          : !totalInstallmentsDisableSecondPayment &&
                            'bg-branco-puro-5bits'
                      }`}
                      fontSizeLabel="12px"
                      fontSizeInput="12px"
                      defaultValue={1}
                      disabled={
                        index === 0
                          ? totalInstallmentsDisable
                          : totalInstallmentsDisableSecondPayment
                      }
                    />
                    <CurrencyInput
                      type="text"
                      name="discount"
                      label={`${(index === 0 && 'Desconto') || ''}`}
                      className="w-30 mb-2 mr-md-2"
                      classNameInputGroupTextAndFormControl={`p-1 ${
                        index === 0
                          ? !visibleDiscount && 'bg-branco-puro-5bits'
                          : !visibleDiscountSecond && 'bg-branco-puro-5bits'
                      }`}
                      onKeyUp={handleUpdateAmount}
                      fontSizeLabel="12px"
                      fontSizeInput="12px"
                      defaultValue={0}
                      disabled={
                        index === 0 ? visibleDiscount : visibleDiscountSecond
                      }
                    />
                    <CurrencyInput
                      type="text"
                      name="addition"
                      label={`${(index === 0 && 'Acréscimo') || ''}`}
                      className="w-30 mb-2 mr-md-2"
                      classNameInputGroupTextAndFormControl={`p-1 ${
                        index === 0
                          ? !visibleAddition && 'bg-branco-puro-5bits'
                          : !visibleAdditionSecond && 'bg-branco-puro-5bits'
                      }`}
                      onKeyUp={handleUpdateAmount}
                      fontSizeLabel="12px"
                      fontSizeInput="12px"
                      defaultValue={0}
                      disabled={
                        index === 0 ? visibleAddition : visibleAdditionSecond
                      }
                    />

                    <CurrencyInput
                      type="text"
                      name="amount_received"
                      label={`${(index === 0 && 'Valor pago') || ''}`}
                      className="w-50 mb-2 mr-md-2"
                      classNameInputGroupTextAndFormControl={`p-1 font-weight-bold ${
                        listPaymentMethods.length > 1 &&
                        index === 0 &&
                        'bg-branco-puro-5bits'
                      }`}
                      fontSizeLabel="12px"
                      fontSizeInput="12px"
                      defaultValue={0}
                      onKeyUp={e => handleSecondAmountReceived(e)}
                      disabled={!(listPaymentMethods.length > 1 && index === 0)}
                    />
                  </Scope>
                </Col>
              ))}
            </>
          )}
        </Row>
      </Col>
      <Col md={4} className="d-flex flex-column align-items-start">
        <Input
          type="textarea"
          name="observation"
          label="Observações"
          maxLength={45}
          className="mb-2"
          classNameInputGroupTextAndFormControl="p-1 bg-branco-puro-5bits"
          fontSizeLabel="12px"
          fontSizeInput="12px"
        />
        {isSecondPayment === false && (
          <Row noGutters className="w-100">
            <Col md={8}>
              <Input
                type="text"
                name="ticketCode"
                style={{ fontSize: '12px', letterSpacing: '5px' }}
                placeholder="Cupom"
                maxLength={10}
                className="w-95 mb-2"
                classNameInputGroupTextAndFormControl="p-1 bg-branco-puro-5bits"
                fontSizeLabel="12px"
                fontSizeInput="12px"
                onChange={e => handleSearchDiscountCoupons(e.target.value)}
              />
            </Col>
            <Col md={4}>
              <Input
                type="text"
                name="amountCoupon"
                placeholder="Valor"
                className="mb-2"
                classNameInputGroupTextAndFormControl="p-1"
                fontSizeLabel="12px"
                fontSizeInput="12px"
                disabled
              />
            </Col>
          </Row>
        )}
      </Col>
      <Col
        md={12}
        className="d-md-flex flex-row justify-content-between align-items-center my-2"
      >
        <ModalSimulateSale
          title="Simulado da Venda"
          listSaleProp={listSimulator}
          loadModalProp={loadModalSimulate}
          toggleLoadModalSale={toggleLoadModalSimulate}
        />

        <Modal visibleProp={loadModalLoadingSimulate}>
          <div className="d-flex flex-column align-items-center bg-branco-puro-5bits p-4">
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            <strong>Carregando Simulação...</strong>
          </div>
        </Modal>

        <Modal visibleProp={loadModalConfirmationSale}>
          <div className="d-flex flex-column align-items-center w-100 bg-branco-puro-5bits p-4">
            <h1>Confirmar Venda</h1>
            <div className="d-flex flex-row align-items-center w-100">
              <Button
                name="submit"
                type="submit"
                className="w-100 mr-2"
                fontSizeButton="12px"
                onClick={() => {
                  setTimeout(() => {
                    toggleLoadModalConfirmationSale();
                  }, 500);
                }}
              >
                Confirmar Venda
              </Button>
              <Button
                name="closeModalSale"
                type="button"
                className="w-50"
                fontSizeButton="12px"
                onClick={toggleLoadModalConfirmationSale}
              >
                Cancelar
              </Button>
            </div>
          </div>
        </Modal>

        <Button
          name="simular"
          type="button"
          className="w-25 ml-1 mr-4"
          fontSizeButton="12px"
          onClick={submitSimular}
        >
          Simular
        </Button>
        <Button
          name="gerarVendaModal"
          type="button"
          className="w-25"
          fontSizeButton="12px"
          onClick={toggleLoadModalConfirmationSale}
        >
          Gerar Venda
        </Button>

        <Checkbox
          name="secondPaymentMethods"
          propChecked={false}
          onClick={handleToggleIsSecondPayment}
          fontSizeText="12px"
          className="border-0 bg-transparent p-0 w-30 mb-2 mx-md-2 my-md-0"
        >
          2ª forma de pagamento
        </Checkbox>
        <div className="d-flex flex-row align-items-center text-primary text-uppercase font-weight-bold">
          Total Geral:
          <Input
            type="text"
            name="amount"
            className="w-50 border-0 bg-transparent"
            classNameInputGroupTextAndFormControl="text-laranja-5bits text-uppercase font-weight-bold border-0 bg-transparent"
            disabled
          />
        </div>
      </Col>
    </>
  );
};

export default FooterSell;
