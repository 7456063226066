/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useState, useEffect, useCallback } from 'react';
import ReactSelect, {
  OptionTypeBase,
  Props as SelectProps,
} from 'react-select';
import { useField } from '@unform/core';
import InputGroup from 'react-bootstrap/InputGroup';
// Importa todas as propriedades que um ícone pode ter
import { IconBaseProps } from 'react-icons';
// Icone de erro que irá no lugar da menssagem de erro dentro do container
import { FiAlertCircle } from 'react-icons/fi';

// O error é um container que contém a imagem e o tooltip com a mensagem ao passar o mouse
import { Container, ErrorStyle } from './styles';

interface Props extends SelectProps<OptionTypeBase> {
  name: string;
  label?: string;
  fontSize?: string;
  sizeIcon?: number;
  className?: string;
  classNameInputGroupTextAndFormControl?: string;
  clearInputProp?: boolean;

  icon?: React.ComponentType<IconBaseProps>;
}

const SelectWithListLocal: React.FC<Props> = ({
  name,
  label,
  fontSize,
  sizeIcon,
  options,
  className,
  classNameInputGroupTextAndFormControl = '',
  clearInputProp = false,

  icon: Icon,
  ...rest
}) => {
  const selectRef = useRef(null);

  const {
    fieldName,
    defaultValue,
    error,
    clearError,
    registerField,
  } = useField(name);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: ref => ref.state.value,
      setValue: (ref, value) => {
        ref.select.setValue(value || null);
      },
      clearValue: ref => {
        ref.select.clearValue();
      },
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    if (clearInputProp) {
      const valueSelect: any = selectRef.current;
      valueSelect.select.clearValue();
    }
  }, [clearInputProp]);

  const handleInputFocus = useCallback((): void => {
    setIsFocused(true);
    // Função do unform para limpar os erros do campo
    clearError();
  }, [clearError]);

  const handleInputBlur = useCallback((): void => {
    setIsFocused(false);

    const valueSelect: any = selectRef.current;
    setIsFilled(!!valueSelect.state.value);
  }, []);

  /*
  Estilos personalizados. Recebe todos os estilos default por padrão na provider.
  O minHeight atrapalhava ao definir qualquer height pois era em 36px.
  Zera os padding dos indicadores caso utilize:
  dropdownIndicator: () => ({
      paddingTop: 0,
      paddingBottom: 0,
    }),
    clearIndicator: () => ({
      paddingTop: 0,
      paddingBottom: 0,
    }),
  */
  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      minHeight: 1,
      width: 200,
      letterSpacing: '0,09em',
      fontSize: `${fontSize}`,
    }),
    menu: (provided: any) => ({
      ...provided,
      width: 'auto',
      minWidth: 300,
      letterSpacing: '0,09em',
      fontSize: `${fontSize}`,
    }),
  };

  return (
    <Container
      isErrored={!!error}
      isFilled={isFilled}
      isFocused={isFocused}
      className={className}
    >
      {label && (
        <label
          htmlFor={name}
          style={{
            textTransform: 'uppercase',
            fontSize: `${fontSize}`,
            letterSpacing: '0,09em',
          }}
        >
          {label}
        </label>
      )}
      <InputGroup className="pt-0">
        {Icon && (
          <InputGroup.Prepend>
            <InputGroup.Text
              id="basic-addon1"
              className={classNameInputGroupTextAndFormControl}
            >
              <Icon size={sizeIcon} />
            </InputGroup.Text>
          </InputGroup.Prepend>
        )}
        <ReactSelect
          style={{ letterSpacing: '0,09em' }}
          defaultValue={
            defaultValue && options?.find(option => option.id === defaultValue)
          }
          options={options}
          ref={selectRef}
          className="d-flex flex-fill border border-primary rounded text-uppercase"
          classNamePrefix="react-select"
          aria-describedby="basic-addon1"
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          loadingMessage={() => 'Carregando...'}
          noOptionsMessage={() => 'Nenhum registro encontrado'}
          menuPlacement="auto"
          styles={customStyles}
          maxMenuHeight={150}
          menuPosition="fixed"
          {...rest}
        />
        {error && (
          <ErrorStyle title={error}>
            <FiAlertCircle size={20} />
          </ErrorStyle>
        )}
      </InputGroup>
    </Container>
  );
};

export default SelectWithListLocal;
