import React from 'react';
import { Switch } from 'react-router-dom';

import { useAuth } from '../context/AuthContext';
import findPermission from '../utils/findPermission';

import NotAuthorizedPage from '../pages/NotAuthorizedPage';

import CreateUsers from '../pages/Users/createUser';
import ListUsers from '../pages/Users/listUsers';
import UpdateUser from '../pages/Users/updateUser';

import PrivateRoute from './PrivateRoutes';

const UsersRoutes: React.FC = () => {
  const { permission } = useAuth();
  const usersPermissions = findPermission('users', permission); // menus_id users = 3

  return (
    <Switch>
      <PrivateRoute
        path="*/create"
        component={
          Object.keys(usersPermissions).length !== 0 && usersPermissions.create
            ? CreateUsers
            : NotAuthorizedPage
        }
      />
      <PrivateRoute
        path="*/list"
        render={props => (
          <ListUsers {...props} permissions={usersPermissions} />
        )}
      />
      <PrivateRoute
        path="*/read/:id"
        component={
          Object.keys(usersPermissions).length !== 0 && usersPermissions.update
            ? UpdateUser
            : NotAuthorizedPage
        }
      />
    </Switch>
  );
};

export default UsersRoutes;
