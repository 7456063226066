import React from 'react';
import fincalc from 'fincalc';
import { compareAsc, parseISO } from 'date-fns';

import Table from 'react-bootstrap/Table';
import * as Styles from './styles';

import Button from '../../../../../../components/Button';

import Modal from '../../../../../../components/Modal';

import returnDateNow from '../../../../../../utils/returnDateNow';
import convertDateToBr from '../../../../../../utils/convertDateToBr';
import convertNumberFloatToBr from '../../../../../../utils/convertNumberFloatToBr';
import { IPermission } from '../../../../../../interfaces/IPermission';
import { IReceive } from '../../../../../../interfaces/IReceive';
import Loader from '../../../../../../components/Loader';

interface PermissionDTO {
  permissions: IPermission;
}

type IPaymentMethodSelected = {
  id: number;
  name: string;
};

interface ModalDTO extends PermissionDTO {
  title: string;
  loadModalReceiveListProp: boolean;
  hiddenButtonsWhileSubmitOfTheReceives: boolean;
  listReceivesProp: IReceive[];
  paymentMethodSelectedInReceiveListProp: IPaymentMethodSelected;
  handleSubmitListReceivesProp: () => void;
  closeModalReceive: () => void;
}

const ModalReceiveList: React.FC<ModalDTO> = ({
  title,
  loadModalReceiveListProp = false,
  hiddenButtonsWhileSubmitOfTheReceives = false,
  listReceivesProp,
  paymentMethodSelectedInReceiveListProp,
  handleSubmitListReceivesProp,
  closeModalReceive,
  permissions,
}) => {
  return (
    <Modal visibleProp={loadModalReceiveListProp}>
      <div
        style={{ maxHeight: '70vh' }}
        className="d-flex flex-column align-items-center w-100 bg-branco-puro-5bits p-4"
      >
        <Styles.Header>
          <h1>{title}</h1>
          <span>
            Forma de pagamento Selecionada:{' '}
            <strong>{paymentMethodSelectedInReceiveListProp.name}</strong>
          </span>
        </Styles.Header>

        <div className="overflow-auto">
          <Table striped bordered hover size="sm" responsive="md">
            <thead className="text-left text-primary text-uppercase">
              <tr>
                <th className="font-weight-normal py-3" scope="col">
                  Cliente
                </th>
                <th className="font-weight-normal py-3" scope="col">
                  Venda
                </th>
                <th className="font-weight-normal py-3" scope="col">
                  Valor
                </th>
                <th className="font-weight-normal py-3" scope="col">
                  Vencimento
                </th>
              </tr>
            </thead>
            <tbody className="text-center">
              {listReceivesProp.map((item: IReceive, index: number) => (
                <tr key={index.toString()}>
                  <th scope="row" className="text-left">
                    {item.client.name}
                  </th>
                  <td className="text-center">{item.sale_header.id}</td>
                  <td className="text-right text-nowrap pr-3">
                    {convertNumberFloatToBr(
                      fincalc(
                        parseFloat(item.amount) -
                          parseFloat(item.amount_received),
                      ),
                    )}
                  </td>

                  {(compareAsc(
                    parseISO(returnDateNow('yyyy-MM-dd')),
                    parseISO(item.due_date),
                  ) === 1 &&
                    item.is_paid === false && (
                      <td className="bg-vermelho-suave-5bits font-weight-bold">
                        {convertDateToBr(item.due_date)}
                      </td>
                    )) || <td>{convertDateToBr(item.due_date)}</td>}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <Styles.AmountTotal>
          Total:{' '}
          <strong>
            {convertNumberFloatToBr(
              listReceivesProp.reduce(
                (previousValue, currentValue) =>
                  previousValue +
                  fincalc(
                    parseFloat(currentValue.amount) -
                      parseFloat(currentValue.amount_received),
                  ),
                0,
              ),
            )}
          </strong>
        </Styles.AmountTotal>

        <div className="d-flex flex-row justify-content-around align-items-center mt-3 w-50">
          {(hiddenButtonsWhileSubmitOfTheReceives && (
            <Loader size="32px" />
          )) || (
            <>
              {(permissions.update && (
                <Button
                  name="confirmRegisterReceives"
                  type="button"
                  className="w-50 mr-4"
                  fontSizeButton="12px"
                  onClick={handleSubmitListReceivesProp}
                >
                  Confirmar
                </Button>
              )) || (
                <Button
                  name="confirmRegisterReceivesDisabled"
                  type="button"
                  className="w-50 mr-4"
                  fontSizeButton="12px"
                  disabled
                >
                  Sem permissão
                </Button>
              )}
              <Button
                name="closeModal"
                type="button"
                className="w-50 mr-4"
                fontSizeButton="12px"
                onClick={closeModalReceive}
              >
                Cancelar
              </Button>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ModalReceiveList;
