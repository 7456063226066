import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  right: 0;
  top: 95px;
  padding: 0 10px 0 0;
  overflow: hidden;
  z-index: 2000;
`;
