import React from 'react';
import packageVersion from '../../../../package.json';

import IconFacebook from '../../../assets/Facebook.svg';
import IconInstagram from '../../../assets/Instagram.svg';
import IconYoutube from '../../../assets/Youtube.svg';

import {
  ContainerFooter,
  Content,
  ImagensFooter,
  ContentFooter,
} from './styles';

const Footer: React.FC = () => {
  const { version } = packageVersion;

  return (
    <ContainerFooter>
      <Content>
        <ImagensFooter>
          <img src={IconFacebook} alt="Facebook" />
          <img src={IconInstagram} alt="Instagram" />
          <img src={IconYoutube} alt="Youtube" />
        </ImagensFooter>

        <ContentFooter>
          <p>Rua Joaquim Cândido, 249</p>
          <p> Centro - Sete Lagoas - MG</p>
          <p>TEL: 31 3772-3162</p>
          <p>
            © 2013 - 2021 | <b>5Bits</b> Soluções Web
          </p>
        </ContentFooter>
      </Content>
      <span> Versão do sistema: {version}</span>
    </ContainerFooter>
  );
};

export default Footer;
