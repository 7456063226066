/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useCallback, useState, useEffect } from 'react';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import ComplexCreateAndUpdateTemplate from '../../../components/Template/ComplexCreateAndUpdateTemplate';
import Modal from '../../../components/Modal';

import ModalSell from './Modal/modalSell';
import ModalPrintTicket from './Modal/modalPrintTicket';

import api from '../../../services/api';
import getValidationErrors from '../../../utils/getValidationErrors';
import { useToast } from '../../../context/ToastContext';

import HeaderSell from './Partials/HeaderSell/headerSell';
import BodySell from './Partials/BodySell/bodySell';
import FooterSell from './Partials/FooterSell/footerSell';

interface SalesHeaderFormDTO {
  client_id: number;
  employee_id: number;
  amount: number;
  dependent_name: string;
  observation: string;
  payment_methods: Array<any>[any];
  sales_body: Array<any>[any];
}

interface DiscountCouponFormDataDTO {
  id: number;
  expiration_date: string;
  amount: string;
  client_name: string;
  is_active: boolean;
}

const CreateSell: React.FC = () => {
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);

  const [paymentMethodsComplete, setPaymentMethodsComplete] = useState<any[]>(
    [],
  );
  const [listClients, setListClients] = useState<any[]>([]);
  const [listEmployees, setListEmployees] = useState<any[]>([]);
  const [listProducts, setListProducts] = useState<any[]>([]);

  const [listSaleReturn, setListSaleReturn] = useState<any[]>([] as any);
  const [loadModalReturn, setLoadModalReturn] = useState(false);
  const [loadModalTicket, setLoadModalTicket] = useState(false);
  const [loadModalConfirmationSale, setLoadModalConfirmationSale] = useState(
    false,
  );
  const [loadModalLoadingNewSale, setLoadModalLoadingNewSale] = useState(false);

  const toggleLoadModalSale = useCallback(() => {
    setLoadModalReturn(!loadModalReturn);
  }, [loadModalReturn]);

  const closeAndClearModalSale = useCallback(() => {
    setLoadModalReturn(false);
    setLoadModalTicket(true);
  }, []);

  const closeAndClearModalTicket = useCallback(() => {
    setLoadModalTicket(false);
    setLoadModalLoadingNewSale(true);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }, []);

  const [discountCoupon, setDiscountCoupon] = useState(
    {} as DiscountCouponFormDataDTO,
  );

  useEffect(() => {
    try {
      const getListsAllSales = async (): Promise<void> => {
        try {
          const responseClients = await api.get('clients/active');
          setListClients(responseClients.data);

          const responseEmployees = await api.get('employees/active');
          setListEmployees(responseEmployees.data);

          const responseProducts = await api.get('products/with-stock');
          setListProducts(responseProducts.data);
          const responsePaymentMethodsComplete = await api.get(
            'cash/payment-methods/active',
          );
          setPaymentMethodsComplete(responsePaymentMethodsComplete.data);
        } catch (erro) {
          // eslint-disable-next-line no-shadow
          if (erro.response) {
            const { data } = erro.response; // Error vindo do back está em data dentro de response
            addToast({
              type: 'error',
              title: data.message,
            });
            return;
          }

          addToast({
            type: 'error',
            title: 'Ocorreu um erro interno',
          });
        }
      };
      getListsAllSales();
    } catch (erro) {
      // eslint-disable-next-line no-shadow
      if (erro.response.data) {
        const { data } = erro.response; // Error vindo do back está em data dentro de response
        addToast({
          type: 'error',
          title: data.message,
        });
        return;
      }

      addToast({
        type: 'error',
        title: 'Ocorreu um erro interno',
      });
    }
  }, [addToast]);

  // handleSubmit
  const handleSubmit = useCallback(
    async (dataForm: SalesHeaderFormDTO) => {
      try {
        formRef.current?.setErrors({});
        setLoadModalConfirmationSale(true);

        /** A função mista do Yup pode corresponder a todos os tipos */
        const schema = Yup.object().shape({
          client_id_select: Yup.object()
            .shape({
              id: Yup.string().required('Cliente é obrigatório'),
            })
            .nullable()
            .required('Cliente é obrigatório'),
          employee_id_select: Yup.object().shape({
            id: Yup.string().required('Vendedor é obrigatório'),
          }),
          sales_body: Yup.array().of(
            Yup.object().shape({
              product_idItem: Yup.object()
                .shape({
                  id: Yup.string().required('Produto é obrigatório'),
                })
                .nullable()
                .required('Produto é obrigatório'),
            }),
          ),
          payment_methods: Yup.array().of(
            Yup.object().shape({
              payment_method_id_select: Yup.object()
                .shape({
                  id: Yup.string().required('Forma de pagamento obrigatório'),
                })
                .nullable()
                .required('Forma de pagamento obrigatório'),
            }),
          ),
        });

        await schema.validate(dataForm, {
          abortEarly: false,
        });

        const salesBody = dataForm.sales_body.map((item: any) => {
          const newItem = {
            product_id: item.id,
            quantity: item.quantity,
            price: item.price,
            discount: item.discount,
          };

          return newItem;
        });

        const paymentMethods = dataForm.payment_methods.map((item: any) => {
          const newItem = {
            ...item,
            payment_method_id: item.payment_method_id_select.id,
            name: item.payment_method_id_select.name,
          };

          return newItem;
        });

        const newDataForm: any = {
          ...dataForm,
          sales_body: salesBody,
          payment_methods: paymentMethods,
          discount_coupons: discountCoupon,
        };

        delete newDataForm.client_id_select;
        delete newDataForm.dependent_id;
        delete newDataForm.employee_id_select;
        delete newDataForm.payment_methods.payment_method_id_select;

        const response = await api.post('sales/sell', newDataForm);
        setListSaleReturn(response.data);

        addToast({
          type: 'success',
          title: 'Cadastro realizado com sucesso',
        });

        setLoadModalConfirmationSale(false);

        toggleLoadModalSale();

        // reset();

        // history.push('/sales/sell/create');
      } catch (error) {
        setLoadModalConfirmationSale(false);

        // Verifica se o error é do Yup ou seja dos campos
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);

          return;
        }
        // eslint-disable-next-line no-shadow
        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: 'Erro no cadastro',
            description: data.message,
          });
          if (formRef.current?.getFieldRef(data.field)) {
            formRef.current?.getFieldRef(data.field).focus(); // Foca o campo que deu erro de acordo com o field retornado do back
          }

          return;
        }

        addToast({
          type: 'error',
          title: 'Ocorreu um erro interno',
          description: error,
        });
      }
    },
    [addToast, discountCoupon, toggleLoadModalSale],
  );
  // Fim handleSubmit

  const [amountPrimaryOriginal, setAmountPrimaryOriginal] = useState(0);
  const handleValueChange = useCallback(val => {
    setAmountPrimaryOriginal(val);
  }, []);

  const handleDiscountCouponSell = useCallback(
    (newDiscountCoupon: DiscountCouponFormDataDTO) => {
      setDiscountCoupon(newDiscountCoupon);
    },
    [],
  );

  return (
    <ComplexCreateAndUpdateTemplate title="Cadastro de Vendas">
      <Form ref={formRef} onSubmit={handleSubmit} className="h-100">
        <Row noGutters className="w-100 h-100">
          <HeaderSell
            listClients={listClients}
            listEmployees={listEmployees}
            formRef={formRef}
          />

          <BodySell
            formRefProps={formRef}
            listProducts={listProducts}
            onValueChangeAmountPrimaryOriginal={handleValueChange}
          />

          <FooterSell
            formRefProps={formRef}
            paymentMethodsComplete={paymentMethodsComplete}
            amountReceivedOriginalProps={amountPrimaryOriginal}
            handleDiscountCoupon={handleDiscountCouponSell}
          />
        </Row>
      </Form>

      <ModalSell
        title="Venda"
        listSaleProp={listSaleReturn}
        loadModalProp={loadModalReturn}
        toggleLoadModalSale={toggleLoadModalSale}
        closeAndClearModalSale={closeAndClearModalSale}
      />

      <ModalPrintTicket
        title="Imprimir Ticket de Venda"
        saleId={listSaleReturn.length > 0 && listSaleReturn[0].sale_header.id}
        loadModalProp={loadModalTicket}
        toggleLoadModalSale={toggleLoadModalSale}
        closeAndClearModalTicket={closeAndClearModalTicket}
      />

      <Modal visibleProp={loadModalConfirmationSale}>
        <div className="d-flex flex-column align-items-center bg-branco-puro-5bits p-4">
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          <strong>Salvando a venda</strong>
        </div>
      </Modal>
      <Modal visibleProp={loadModalLoadingNewSale}>
        <div className="d-flex flex-column align-items-center bg-branco-puro-5bits p-4">
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          <strong>Preparando para nova a venda</strong>
        </div>
      </Modal>
    </ComplexCreateAndUpdateTemplate>
  );
};

export default CreateSell;
