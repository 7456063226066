import React from 'react';

import { ContainerLogo } from './styles';
import logoImg from '../../assets/Logo-5bits.svg';
import logoImgSmall from '../../assets/Logo-5bits-Small.svg';

const Logo: React.FC = () => {
  return (
    <ContainerLogo>
      <img src={logoImg} alt="5bits" />
      <img src={logoImgSmall} alt="5bits" />
    </ContainerLogo>
  );
};

export default Logo;
