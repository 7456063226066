/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import React, { useState, useCallback, useRef, useEffect } from 'react';

import { BsSearch } from 'react-icons/bs';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import ButtonBootstrap from '../../ButtonBootstrap';
import DatePicker from '../../DatePicker';
import { useToast } from '../../../context/ToastContext';
import Input from '../../Input';
import InputSearchWithButton from '../../Input/InputSearchWithButton';
import SelectSimple from '../../SelectSimple';
import ButtonLink from '../../ButtonLink';
import Button from '../../Button';
import Checkbox from '../../Checkbox';
import Pagination from '../../Pagination';

import Modal from '../../Modal';

import getValidationErrors from '../../../utils/getValidationErrors';

import convertDateToDB from '../../../utils/convertDateToDB';

import api from '../../../services/api';

import { IPermission } from '../../../interfaces/IPermission';

interface ListingTemplateProps {
  title: string;
  module: string;
  moduleButtonNew?: string;
  moduleForSelectFind?: string;
  moduleSearchPerDate?: string;
  listItemsProps: Array<any>;
  // eslint-disable-next-line no-unused-vars
  handleListItemsPaginatedItems: (newList: Array<any>) => void;
  filterDate?: boolean;
  searchTheLocalObject?: boolean;
  searchPerId?: boolean;
  displayButtonNew?: boolean;
  placeHolderProp?: string;
  displaySearchArea?: boolean;
  permissions: IPermission;
}

const ListingTemplate: React.FC<ListingTemplateProps> = ({
  title,
  module,
  moduleButtonNew,
  moduleForSelectFind = '',
  moduleSearchPerDate = '',
  listItemsProps,
  handleListItemsPaginatedItems,
  filterDate = false,
  searchTheLocalObject = false,
  searchPerId = false,
  displayButtonNew = true,
  placeHolderProp = 'Pesquisar',
  displaySearchArea = true,
  children,
  permissions = {
    create: false,
    read: false,
    update: false,
    delete: false,
  } as IPermission,
}) => {
  const formSearchListItemRef = useRef<FormHandles>(null);
  const formSearchDateListItemRef = useRef<FormHandles>(null);
  const [listAllItems, setListAllItems] = useState<any[]>([]);
  const [listItemsOriginal, setListItemsOriginal] = useState<any[]>([]);

  const [loadModalWait, setLoadModalWait] = useState(false);
  const [isCheckIsPaid, setIsCheckIsPaid] = useState(false);
  const [visibleButtonListAll, setVisibleButtonListAll] = useState(false);
  const { addToast } = useToast();
  // // Filter Per IsPaid
  // const filterPerIsPaid = useCallback((obj, isPaid) => {
  //   return obj.is_paid === isPaid;
  // }, []);
  // // End Filter Per IsPaid

  // toggleAddIsCheckIsPaid
  const toggleAddIsCheckIsPaid = useCallback(() => {
    // if (listItemsOriginal.length > 0) {
    //   const newList = listItemsOriginal.filter(obj =>
    //     filterPerIsPaid(obj, !isCheckIsPaid),
    //   );
    //   handleListItemsPaginatedItems(newList);
    //   setListAllItems(newList);
    // }

    setIsCheckIsPaid(!isCheckIsPaid);
  }, [isCheckIsPaid]);
  // End toggleAddIsCheckIsPaid

  useEffect(() => {
    const getListAllItems = (): void => {
      setListAllItems(listItemsProps);
      setListItemsOriginal(listItemsProps);
    };
    getListAllItems();
  }, [listItemsProps]);

  const filterPerId = useCallback(
    (obj: any, find) => {
      if (module === 'discount-coupons') {
        return obj.ticket_code.includes(find.toUpperCase());
      }
      return obj.id === parseInt(find, 10);
    },
    [module],
  );

  const filterPerItem = useCallback(
    (obj: any, find) => {
      if (module === 'sales/receive') {
        return obj.client.name.includes(find.toUpperCase());
      }
      if (module === 'sales/conditional/open') {
        return obj.client_name.includes(find.toUpperCase());
      }
      if (module === 'discount-coupons') {
        return obj.client_name.includes(find.toUpperCase());
      }
      return obj.name.includes(find.toUpperCase());
    },
    [module],
  );

  // handleListAll
  const handleListAll = useCallback(async () => {
    try {
      setLoadModalWait(true);
      formSearchListItemRef.current?.setErrors({});

      /**
       * Apenas listagem de clientes está pesquisando por name e id.
       * O retorno é um array de objetos, e não um objeto quando se pesquisa
       * por id apenas.
       * Quando todo o sistema passar a ter a pesquisa por name e id, terá que
       * retirar o if e deixar apenas o código do bloco if e retirar o do else.
       */
      let newModule: string = module;

      if (module === 'clients/search') {
        newModule = 'clients';
      }

      let response: any = [];
      response = await api.get(`${newModule}`);
      handleListItemsPaginatedItems(response.data);
      setListAllItems(response.data);
      setListItemsOriginal(response.data);

      if (formSearchDateListItemRef.current)
        formSearchDateListItemRef.current.reset();

      setLoadModalWait(false);
      setVisibleButtonListAll(false);
    } catch (error) {
      setLoadModalWait(false);
      setVisibleButtonListAll(true);
      // Verifica se o error é do Yup ou seja dos campos
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formSearchListItemRef.current?.setErrors(errors);

        return;
      }

      // eslint-disable-next-line no-shadow
      if (error.response) {
        const { data } = error.response; // Error vindo do back está em data dentro de response
        addToast({
          type: 'error',
          title: data.message,
        });
        if (formSearchListItemRef.current?.getFieldRef(data.field)) {
          formSearchListItemRef.current?.getFieldRef(data.field).focus(); // Foca o campo que deu erro de acordo com o field retornado do back
        }

        return;
      }

      addToast({
        type: 'error',
        title: `Ocorreu um erro internoAA${error}`,
      });
    }
  }, [module, handleListItemsPaginatedItems, addToast]);
  // End handleListAll

  // handleSearchSubmit
  const handleSearchSubmit = useCallback(
    async (dataForm: any, { reset }) => {
      try {
        setLoadModalWait(true);
        formSearchListItemRef.current?.setErrors({});

        let response: any = [];
        if (dataForm.find === '') {
          response = await api.get(`${module}`);
          handleListItemsPaginatedItems(response.data);
          setListAllItems(response.data);
          setListItemsOriginal(response.data);

          if (filterDate) {
            formSearchDateListItemRef.current?.setErrors({});
            formSearchDateListItemRef.current?.reset();
            setIsCheckIsPaid(false);
          }
        } else if (searchTheLocalObject) {
          if (searchPerId) {
            response = listItemsOriginal.filter(obj =>
              filterPerId(obj, dataForm.find),
            );
          } else {
            response = listItemsOriginal.filter(obj =>
              filterPerItem(obj, dataForm.find),
            );
          }
          handleListItemsPaginatedItems(response);
          setListAllItems(response);
        } else {
          response = await api.get(`${module}/${dataForm.find}`);
          /**
           * Apenas listagem de clientes está pesquisando por name e id.
           * O retorno é um array de objetos, e não um objeto quando se pesquisa
           * por id apenas.
           * Quando todo o sistema passar a ter a pesquisa por name e id, terá que
           * retirar o if e deixar apenas o código do bloco if e retirar o do else.
           */
          if (module === 'clients/search') {
            handleListItemsPaginatedItems([...response.data]);
            setListAllItems([...response.data]);
            setListItemsOriginal([...response.data]);
          } else {
            handleListItemsPaginatedItems([{ ...response.data }]);
            setListAllItems([{ ...response.data }]);
            setListItemsOriginal([{ ...response.data }]);
          }
        }

        reset();

        setLoadModalWait(false);
        setVisibleButtonListAll(true);
      } catch (error) {
        setLoadModalWait(false);
        setVisibleButtonListAll(false);
        // Verifica se o error é do Yup ou seja dos campos
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formSearchListItemRef.current?.setErrors(errors);

          return;
        }

        // eslint-disable-next-line no-shadow
        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: data.message,
          });
          if (formSearchListItemRef.current?.getFieldRef(data.field)) {
            formSearchListItemRef.current?.getFieldRef(data.field).focus(); // Foca o campo que deu erro de acordo com o field retornado do back
          }

          return;
        }

        addToast({
          type: 'error',
          title: `Ocorreu um erro internoAA${error}`,
        });
      }
    },
    [
      searchTheLocalObject,
      module,
      handleListItemsPaginatedItems,
      filterDate,
      searchPerId,
      listItemsOriginal,
      filterPerId,
      filterPerItem,
      addToast,
    ],
  );
  // Fim handleSearchSubmit

  // handleSearchDateSubmit
  const handleSearchDateSubmit = useCallback(
    async (dataFormFilter: any) => {
      try {
        setLoadModalWait(true);
        formSearchDateListItemRef.current?.setErrors({});

        const schema = Yup.object().shape({
          start_date: Yup.string()
            .required('Data inicial obrigatória')
            .nullable(),
          end_date: Yup.string().required('Data final obrigatória').nullable(),
        });

        await schema.validate(dataFormFilter, {
          abortEarly: false,
        });

        const newData = {
          ...dataFormFilter,
          start_date: convertDateToDB(dataFormFilter.start_date),
          end_date: convertDateToDB(dataFormFilter.end_date),
          is_paid: dataFormFilter.isPaid === 'true',
        };

        delete newData.isPaid;

        const response = await api.get(
          `${moduleSearchPerDate}/${newData.start_date}/${newData.end_date}/${newData.is_paid}`,
        );

        handleListItemsPaginatedItems(response.data);
        setListAllItems(response.data);
        setListItemsOriginal(response.data);

        setLoadModalWait(false);
        setVisibleButtonListAll(true);
        // reset();
      } catch (error) {
        setLoadModalWait(false);
        setVisibleButtonListAll(false);
        // Verifica se o error é do Yup ou seja dos campos
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formSearchDateListItemRef.current?.setErrors(errors);

          return;
        }

        // eslint-disable-next-line no-shadow
        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: data.message,
          });

          return;
        }

        addToast({
          type: 'error',
          title: `Ocorreu um erro interno`,
        });
      }
    },
    [addToast, handleListItemsPaginatedItems, moduleSearchPerDate],
  );
  // Fim handleSearchDateSubmit

  const handleSearchOnListLocal = useCallback(
    value => {
      if (value && listItemsOriginal) {
        const newListAllItems = listItemsOriginal.filter(
          item =>
            item.outgoingFinancialAccount.name.toLowerCase() ===
              value.label.toLowerCase() ||
            item.incomingFinancialAccount.name.toLowerCase() ===
              value.label.toLowerCase(),
        );
        if (
          !newListAllItems ||
          (newListAllItems && newListAllItems.length < 1)
        ) {
          addToast({
            type: 'error',
            title: 'Nenhum registro encontrado',
          });
          setVisibleButtonListAll(false);
        } else {
          handleListItemsPaginatedItems(newListAllItems);
          setListAllItems(newListAllItems);
          setVisibleButtonListAll(true);
        }
      } else {
        formSearchListItemRef.current?.clearField('find');
        formSearchListItemRef.current?.submitForm();
        setVisibleButtonListAll(false);
      }
    },
    [addToast, handleListItemsPaginatedItems, listItemsOriginal],
  );

  return (
    <>
      <Col
        md={12}
        style={{ height: '100%' }}
        className="d-flex flex-column bg-branco-puro-5bits p-4"
      >
        <Row className="d-flex flex-row justify-content-between no-gutters">
          <Col md={4}>
            <h1 className="text-left">{title}</h1>
          </Col>
          <Col
            md={7}
            className="d-flex flex-column justify-content-end align-items-top"
          >
            <Form
              ref={formSearchListItemRef}
              className="mr-3 mb-3"
              onSubmit={handleSearchSubmit}
            >
              {displaySearchArea && (
                <>
                  {(!moduleForSelectFind && (
                    <>
                      {(permissions.read && (
                        <div className="d-flex justify-content-end">
                          <div className="w-35">
                            {visibleButtonListAll && (
                              <ButtonBootstrap
                                type="button"
                                name="listAll"
                                className="text-uppercase"
                                onClick={handleListAll}
                              >
                                Listar Todos
                              </ButtonBootstrap>
                            )}
                          </div>
                          <InputSearchWithButton
                            type="text"
                            name="find"
                            className="w-90"
                            classNameInputGroupTextAndFormControl="bg-transparent"
                            icon={BsSearch}
                            placeholder={placeHolderProp}
                            aria-label={`Pesquisar por ${placeHolderProp}`}
                            maxLength={45}
                          />
                        </div>
                      )) || (
                        <InputSearchWithButton
                          type="text"
                          name="findDisabled"
                          classNameInputGroupTextAndFormControl="bg-transparent"
                          icon={BsSearch}
                          placeholder={placeHolderProp}
                          aria-label={`Pesquisar por ${placeHolderProp}`}
                          maxLength={45}
                          disabled
                          disabledButtonProp
                        />
                      )}
                    </>
                  )) || (
                    <>
                      {(permissions.read && (
                        <div className="d-flex justify-content-end">
                          <Input type="hidden" name="find" isHidden />
                          <SelectSimple
                            module={moduleForSelectFind}
                            name="findSelect"
                            icon={BsSearch}
                            placeholder={placeHolderProp}
                            aria-label={`Pesquisar por ${placeHolderProp}`}
                            className="w-80"
                            isClearable
                            onChange={e => handleSearchOnListLocal(e)}
                          />
                        </div>
                      )) || (
                        <SelectSimple
                          module=" "
                          name="findSelectDisabled"
                          icon={BsSearch}
                          placeholder={placeHolderProp}
                          aria-label={`Pesquisar por ${placeHolderProp}`}
                          isClearable
                          isDisabled
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </Form>
          </Col>
          {displayButtonNew && (
            <Col
              md={1}
              className="d-flex flex-row justify-content-end align-items-top"
            >
              {(permissions.create && (
                <ButtonLink
                  className="w-auto"
                  to={`/${moduleButtonNew || module}/create`}
                >
                  Novo
                </ButtonLink>
              )) || (
                <Button className="w-auto" disabled>
                  Novo
                </Button>
              )}
            </Col>
          )}
        </Row>

        {filterDate && (
          <Row className="no-gutters">
            <Col
              md={12}
              className="d-flex flex-row justify-content-end align-items-end"
            >
              <Form
                ref={formSearchDateListItemRef}
                className="d-flex flex-row justify-content-end align-items-center mr-3"
                onSubmit={handleSearchDateSubmit}
              >
                {(permissions.read && (
                  <div className="d-flex justify-content-center align-items-end">
                    <span className="d-flex justify-content-end text-uppercase w-100 mr-2">
                      Vencimento de:
                    </span>
                    <DatePicker name="start_date" className="w-50" />
                    <span className="text-uppercase mx-2">até</span>
                    <DatePicker name="end_date" className="w-50 mr-2" />
                    <Checkbox
                      name="isPaid"
                      propChecked={isCheckIsPaid}
                      onClick={toggleAddIsCheckIsPaid}
                      className="p-0 mr-2 w-auto text-nowrap"
                    >
                      Incluir Quitados
                    </Checkbox>

                    <ButtonBootstrap type="submit" className="text-uppercase">
                      Filtrar
                    </ButtonBootstrap>

                    {visibleButtonListAll && filterDate && (
                      <div className="w-35">
                        <ButtonBootstrap
                          type="button"
                          name="listAll"
                          className="text-uppercase"
                          onClick={handleListAll}
                        >
                          Limpar Filtragem
                        </ButtonBootstrap>
                      </div>
                    )}
                  </div>
                )) || (
                  <>
                    <span className="d-flex justify-content-end text-uppercase w-100 mr-2">
                      Vencimento de:
                    </span>
                    <Input name="startInput" className="w-50" disabled />
                    <span className="text-uppercase mx-2">até</span>
                    <Input name="endInput" className="w-50 mr-2" disabled />
                    <Checkbox
                      name="isPaid"
                      propChecked={false}
                      className="p-0 mr-2 w-auto text-nowrap"
                      disabled
                    >
                      Incluir Quitados
                    </Checkbox>

                    <ButtonBootstrap
                      type="button"
                      className="text-uppercase"
                      onClick={e => e.preventDefault()}
                      disabled
                    >
                      Filtrar
                    </ButtonBootstrap>
                  </>
                )}
              </Form>
            </Col>
          </Row>
        )}

        <Row className="h-85 justify-content-center no-gutters overflow-auto mt-4">
          <Col className="h-100 overflow-auto" md={12}>
            {children}
          </Col>
        </Row>

        <Pagination
          listItems={listAllItems}
          onChangeListPaginatedItems={handleListItemsPaginatedItems}
        />
      </Col>

      <Modal visibleProp={loadModalWait}>
        <div className="d-flex flex-column align-items-center bg-branco-puro-5bits p-4">
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          <strong>Pesquisando...</strong>
        </div>
      </Modal>
    </>
  );
};

export default ListingTemplate;
