/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import React, { useCallback, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { FiUser, FiEdit } from 'react-icons/fi';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ComplexCreateAndUpdateTemplate from '../../components/Template/ComplexCreateAndUpdateTemplate';

import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';
import convertToNumber from '../../utils/convertToNumber';
import validationCPF from '../../utils/validationCPF';
import { useToast } from '../../context/ToastContext';

import Dependents from './createDependents';

import Input from '../../components/Input';
import Button from '../../components/Button';

interface ClientFormDataDTO {
  name: string;
  cpf: string;
  dt_birth: string;
  address: string;
  phone: string;
  email: string;

  // Estes dois campos são retirados do objeto no submit
  nameDependentFormAdd?: string;
  phoneDependentFormAdd?: string;
}

// interface DependentsFormDataDTO {
//   name: string;
//   phone: string;
// }

/**
 * [ ] Ver o history com o Daniel após cadastrar user
 * [X] Validação do form dependents e do form update dependents
 * [X] Retirar os console.log
 * [X] Corrigir flex box da listagem de dependents
 * [X] ignorar campos no form para não irem no submit
 *
 */

const Client: React.FC = () => {
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const [disableButton, setDisableButton] = useState(false);

  // handleSubmitOutsideTheForm
  /**
   * Função chamada no botão Salvar fora do form de clientes.
   * É o último botão depois de todos os forms.
   */
  const handleSubmitOutsideTheForm = useCallback(() => {
    formRef.current?.submitForm();
  }, []);
  // Fim handleSubmitOutsideTheForm

  // handleSubmit
  const handleSubmit = useCallback(
    async (dataForm: ClientFormDataDTO, { reset }) => {
      try {
        setDisableButton(true);

        // Remove os campos que não devem ir para o banco de dados
        delete dataForm.nameDependentFormAdd;
        delete dataForm.phoneDependentFormAdd;

        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          cpf: Yup.string()
            .min(11)
            .test('cpf', 'CPF inválido', () => {
              return validationCPF(dataForm.cpf);
            })
            .required('CPF obrigatório'),
          phone: Yup.string().test('phone', 'Telefone inválido', () => {
            return (
              convertToNumber(dataForm.phone).length === 11 ||
              convertToNumber(dataForm.phone).length === 10
            );
          }),
          dependents: Yup.array().of(
            Yup.object().shape({
              name: Yup.string().required('Nome obrigatório'),
              phone: Yup.string()
                .test('phone', 'Telefone inválido', (v: any) => {
                  return (
                    convertToNumber(v).length === 11 ||
                    convertToNumber(v).length === 10
                  );
                })
                .required('Telefone obrigatório'),
            }),
          ),
        });

        await schema.validate(dataForm, {
          abortEarly: false,
        });

        await api.post('clients', dataForm);

        addToast({
          type: 'success',
          title: 'Cadastro realizado com sucesso',
        });

        reset();

        setDisableButton(false);

        history.push('/clients/list');
        // window.location.href = '/clients/create';
      } catch (error) {
        setDisableButton(false);

        // Verifica se o error é do Yup ou seja dos campos
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);

          return;
        }
        // eslint-disable-next-line no-shadow
        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response

          addToast({
            type: 'error',
            title: 'Erro no cadastro',
            description: data.message,
          });
          if (
            formRef.current?.getFieldRef(data.field) &&
            data.field.toString().indexOf('_id') === -1
          ) {
            formRef.current?.getFieldRef(data.field).focus(); // Foca o campo que deu erro de acordo com o field retornado do back
          }

          return;
        }

        addToast({
          type: 'error',
          title: 'Ocorreu um erro interno',
        });
      }
    },
    [addToast, history],
  );
  // Fim handleSubmit
  // initialData={{ dependents: listDependents }}
  return (
    <ComplexCreateAndUpdateTemplate title="Cadastro de Clientes">
      <Form ref={formRef} onSubmit={handleSubmit} className="h-100">
        <Row noGutters className="w-100 h-100">
          <Col md={6} className=" h-100 overflow-auto">
            <Input
              type="text"
              name="name"
              label="Nome Completo"
              icon={FiUser}
              placeholder="Nome Completo"
              maxLength={45}
              className="mb-2"
            />

            <Input
              type="text"
              name="cpf"
              label="CPF"
              icon={FiEdit}
              placeholder="CPF"
              mask="999.999.999-99"
              className="mb-2"
            />
            <Input
              type="text"
              name="dt_birth"
              label="Data Aniversário"
              icon={FiEdit}
              placeholder="Data Aniversário"
              mask="99/99/9999"
              className="mb-2"
            />
            <Input
              type="text"
              name="address"
              label="Endereço"
              icon={FiEdit}
              placeholder="Endereço"
              maxLength={150}
              className="mb-2"
            />
            <Input
              type="text"
              name="neighborhood"
              label="Bairro"
              icon={FiEdit}
              placeholder="Bairro"
              maxLength={150}
              className="mb-2"
            />
            <Input
              type="text"
              name="phone"
              label="Telefone"
              icon={FiEdit}
              placeholder="Telefone"
              mask="(99) 9 9999-9999"
              className="mb-2"
            />
            <Input
              type="text"
              name="email"
              label="Email"
              icon={FiEdit}
              placeholder="Email"
              maxLength={255}
              className="mb-2"
            />
          </Col>
          <Col md={6} className="overflow-auto h-100 pl-3">
            <Dependents refProps={formRef} />
          </Col>
        </Row>
      </Form>
      <Row noGutters className="w-100 mt-5">
        <Col md={6} className="m-auto">
          <Button
            name="submit"
            type="button"
            onClick={handleSubmitOutsideTheForm}
            disabled={disableButton}
          >
            Cadastrar
          </Button>
        </Col>
      </Row>
    </ComplexCreateAndUpdateTemplate>
  );
};

export default Client;
