import styled from 'styled-components';

/**
 * Se for botão de paginação não terá o hover
 */
interface ContainerProps {
  isPagination?: boolean;
}

/**
 * Quando for isPagination define o css para paginação, senão
 * o css é para os botões normais.
 */
export const Container = styled.div<ContainerProps>`
  button {
    &:hover {
      border-color: var(--laranja-5bits);
      background-color: var(--laranja-5bits);
    }
  }
`;
