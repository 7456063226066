import React from 'react';

import { ModalBackground, ModalArea } from './styles';

interface ModalDTO {
  visibleProp: boolean;
  className?: string;
}

const Modal: React.FC<ModalDTO> = ({
  visibleProp,
  className = '',
  children,
}) => {
  return (
    <>
      {visibleProp === true && (
        <ModalBackground>
          <ModalArea className={className}>{children}</ModalArea>
        </ModalBackground>
      )}
    </>
  );
};

export default Modal;
