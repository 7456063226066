import React from 'react';
import { Switch } from 'react-router-dom';

import PrivateRoute from './PrivateRoutes';
import { useAuth } from '../context/AuthContext';
import findPermission from '../utils/findPermission';

import NotAuthorizedPage from '../pages/NotAuthorizedPage';

import CreateFinancialAccounts from '../pages/Cash/FinancialAccounts/createFinancialAccounts';
import ListFinancialAccounts from '../pages/Cash/FinancialAccounts/listFinancialAccounts';
import UpdateFinancialAccounts from '../pages/Cash/FinancialAccounts/updateFinancialAccounts';

import CreatePaymentMethods from '../pages/Cash/PaymentMethods/createPaymentMethods';
import ListPaymentMethods from '../pages/Cash/PaymentMethods/listPaymentMethods';
import UpdatePaymentMethods from '../pages/Cash/PaymentMethods/updatePaymentMethods';

import CreateTransfer from '../pages/Cash/Transfer/createTransfer';
import ListTransfer from '../pages/Cash/Transfer/listTransfer';

import CreateCashFlowClosing from '../pages/Cash/CashFlow/createCashFlowClosing';
import ListCashFlowClosing from '../pages/Cash/CashFlow/listCashFlowClosing';

const CashRoutes: React.FC = () => {
  const { permission } = useAuth();
  const cashFinancialAccountsPermissions = findPermission(
    'cash/financial-accounts',
    permission,
  ); // menus_id cash/financial-accounts = 11
  const cashPaymentMethodsPermissions = findPermission(
    'cash/payment-methods',
    permission,
  ); // menus_id cash/payment-methods = 12
  const cashFinancialTransfersPermissions = findPermission(
    'cash/financial-transfers',
    permission,
  ); // menus_id cash/financial-transfers = 16
  const cashCashFlowPermissions = findPermission('cash/cash-flow', permission); // menus_id cash/financial-transfers = 17

  return (
    <Switch>
      <PrivateRoute
        path="*/financial-accounts/create"
        component={
          Object.keys(cashFinancialAccountsPermissions).length !== 0 &&
          cashFinancialAccountsPermissions.create
            ? CreateFinancialAccounts
            : NotAuthorizedPage
        }
      />
      <PrivateRoute
        path="*/financial-accounts/list"
        render={props => (
          <ListFinancialAccounts
            {...props}
            permissions={cashFinancialAccountsPermissions}
          />
        )}
      />
      <PrivateRoute
        path="*/financial-accounts/read/:id"
        component={
          Object.keys(cashFinancialAccountsPermissions).length !== 0 &&
          cashFinancialAccountsPermissions.update
            ? UpdateFinancialAccounts
            : NotAuthorizedPage
        }
      />
      <PrivateRoute
        path="*/payment-methods/create"
        component={
          Object.keys(cashPaymentMethodsPermissions).length !== 0 &&
          cashPaymentMethodsPermissions.create
            ? CreatePaymentMethods
            : NotAuthorizedPage
        }
      />
      <PrivateRoute
        path="*/payment-methods/list"
        render={props => (
          <ListPaymentMethods
            {...props}
            permissions={cashPaymentMethodsPermissions}
          />
        )}
      />
      <PrivateRoute
        path="*/payment-methods/read/:id"
        component={
          Object.keys(cashPaymentMethodsPermissions).length !== 0 &&
          cashPaymentMethodsPermissions.update
            ? UpdatePaymentMethods
            : NotAuthorizedPage
        }
      />
      <PrivateRoute
        path="*/financial-transfers/list"
        render={props => (
          <ListTransfer
            {...props}
            permissions={cashFinancialTransfersPermissions}
          />
        )}
      />
      <PrivateRoute
        path="*/financial-transfers/create"
        component={
          Object.keys(cashFinancialTransfersPermissions).length !== 0 &&
          cashFinancialTransfersPermissions.create
            ? CreateTransfer
            : NotAuthorizedPage
        }
      />
      <PrivateRoute
        path="*/cash-flow/list"
        render={props => (
          <ListCashFlowClosing
            {...props}
            permissions={cashCashFlowPermissions}
          />
        )}
      />
      <PrivateRoute
        path="*/cash-flow/create"
        component={
          Object.keys(cashCashFlowPermissions).length !== 0 &&
          cashCashFlowPermissions.create
            ? CreateCashFlowClosing
            : NotAuthorizedPage
        }
      />
    </Switch>
  );
};

export default CashRoutes;
