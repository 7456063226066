/* eslint-disable camelcase */
import React, { useCallback, useRef, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { FiEdit } from 'react-icons/fi';

import SimpleCreateAndUpdateTemplate from '../../../components/Template/SimpleCreateAndUpdateTemplate';
import api from '../../../services/api';
import getValidationErrors from '../../../utils/getValidationErrors';
import { useToast } from '../../../context/ToastContext';

import Input from '../../../components/Input';

import CurrencyInput from '../../../components/Input/InputCurrency';
import Checkbox from '../../../components/Checkbox';
import Button from '../../../components/Button';

interface FinancialAccountsFormDataDTO {
  id: number;
  name: string;
  balance?: number;
  accept_negative: boolean;
  accept_negativeText?: string;
  is_activeText?: string;
  is_active: boolean;
}

const UpdateFinancialAccounts: React.FC<FinancialAccountsFormDataDTO> = () => {
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { id }: any = useParams();
  const [
    financialAccounts,
    setFinancialAccounts,
  ] = useState<FinancialAccountsFormDataDTO>(
    {} as FinancialAccountsFormDataDTO,
  );
  const [disableButton, setDisableButton] = useState(false);
  const sessionStorageUser: any = sessionStorage.getItem('@Stock5b:user');
  const currentUserLogged = JSON.parse(sessionStorageUser);

  useEffect(() => {
    const getFinancialAccounts = async (): Promise<void> => {
      try {
        const response = await api.get(`cash/financial-accounts/${id}`);
        setFinancialAccounts(response.data);

        /**
         * Preenche os campos do formulário. Sem esta linha os inputs com
         * máscara não são preenchidos.
         */
        formRef.current?.setData(response.data);
      } catch (error) {
        // eslint-disable-next-line no-shadow
        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: data.message,
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Ocorreu um erro interno',
        });
      }
    };
    getFinancialAccounts();
  }, [addToast, id]);

  // handleSubmit
  const handleSubmit = useCallback(
    async (dataForm: FinancialAccountsFormDataDTO, { reset }) => {
      try {
        setDisableButton(true);
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
        });

        await schema.validate(dataForm, {
          abortEarly: false,
        });

        /**
         * Cria um novo array com o is_admin com o
         * valor em booleano que será salvo no bd
         */
        let isActiveBoolean;

        if (currentUserLogged.is_admin) {
          isActiveBoolean = dataForm.is_activeText === 'true';
        } else {
          isActiveBoolean = financialAccounts.is_active;
        }

        const newData = {
          ...dataForm,
          accept_negative: dataForm.accept_negativeText === 'true',
          is_active: isActiveBoolean,
        };

        delete newData.accept_negativeText;
        delete newData.is_activeText;
        delete newData.balance;

        await api.put('cash/financial-accounts', newData);

        addToast({
          type: 'success',
          title: 'Alteração realizada com sucesso',
        });

        reset();
        setDisableButton(false);
        history.push('/cash/financial-accounts/list');
      } catch (error) {
        setDisableButton(false);

        // Verifica se o error é do Yup ou seja dos campos
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);

          return;
        }
        // eslint-disable-next-line no-shadow
        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: 'Erro na alteração do cadastro',
            description: data.message,
          });
          formRef.current?.getFieldRef(data.field).focus(); // Foca o campo que deu erro de acordo com o field retornado do back

          return;
        }
        addToast({
          type: 'error',
          title: 'Ocorreu um erro interno',
        });
      }
    },
    [addToast, financialAccounts, currentUserLogged.is_admin, history],
  );
  // Fim handleSubmit

  return (
    <SimpleCreateAndUpdateTemplate title="Atualização de Conta Financeira">
      <Form
        ref={formRef}
        initialData={financialAccounts}
        className="mt-5"
        onSubmit={handleSubmit}
      >
        <Input type="hidden" name="id" isHidden />
        <Input
          type="text"
          name="name"
          label="Nome"
          icon={FiEdit}
          placeholder="Nome"
          aria-label="Nome"
          maxLength={45}
          className="mb-4"
        />
        <CurrencyInput
          type="text"
          name="balance"
          label="Saldo"
          icon="R$"
          placeholder="Saldo"
          className="mb-4"
          disabled
        />

        <Checkbox
          name="accept_negativeText"
          propChecked={financialAccounts?.accept_negative}
          className="mb-4"
        >
          Aceita Negativar
        </Checkbox>

        {currentUserLogged.is_admin === true && (
          <Checkbox
            name="is_activeText"
            propChecked={financialAccounts?.is_active}
          >
            Conta Ativa
          </Checkbox>
        )}

        <Button
          name="submit"
          type="submit"
          className="mt-5 w-auto"
          disabled={disableButton}
        >
          Salvar
        </Button>
      </Form>
    </SimpleCreateAndUpdateTemplate>
  );
};

export default UpdateFinancialAccounts;
