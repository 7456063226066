/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState, useCallback } from 'react';

import { Link } from 'react-router-dom';
// import { RiAccountCircleLine } from 'react-icons/ri';
import { useAuth } from '../../context/AuthContext';
import Footer from '../Partials/Footer';
// Importa todas as propriedades que um ícone pode ter

// import ImgMenuMobile from '../../assets/Menu-Mobile.svg';

import { Container, NavCustom, Menu, BtnMenuOpen } from './styles';

import SubMenuUL from './SubMenuUL';

/**
 * [ ] colocar ou retirar barra / de routes e action
 * [ ] a sequência do array determina a sequência de exibição
 */

const MenuDropDown: React.FC = () => {
  const { menus } = useAuth();
  const [menuOpen, setMenuOpen] = useState(false);
  const [visibleClose, setVisibleClose] = useState(false);

  const toggleMenuOpen = useCallback(() => {
    setMenuOpen(!menuOpen);
  }, [menuOpen]);

  const toggleSubMenuClose = useCallback(visible => {
    setVisibleClose(visible);
    setTimeout(() => {
      setVisibleClose(false);
    }, 150);
  }, []);

  return (
    <>
      <BtnMenuOpen onClick={toggleMenuOpen} />
      <Container className="shadow col-md-3 col-lg-2" menuOpen={menuOpen}>
        <NavCustom>
          <Menu id="menu" menuOpen={menuOpen}>
            {menus.map((item, index) => (
              <li key={index.toString()}>
                {/* Menu sem submenu */}
                {((!item.submenus || item.submenus.length < 1) && (
                  <Link
                    style={{ zIndex: 1500 }}
                    to={`/${item.route}${item.action}`}
                    onClick={() => {
                      toggleMenuOpen();
                      toggleSubMenuClose(true);
                    }}
                  >
                    <label>
                      <span>{item.label}</span>
                    </label>
                  </Link>
                )) || (
                  <>
                    {/* Menu com submenu */}
                    <SubMenuUL
                      label={item.label}
                      visibleClose={visibleClose}
                      toggleClose={toggleSubMenuClose}
                    >
                      {(item.submenus as Array<any>).map(
                        (subItem, subIndex) => (
                          <li key={subIndex.toString()}>
                            <Link
                              style={{ zIndex: 1500 }}
                              to={`/${subItem.route}${subItem.action}`}
                              onClick={toggleMenuOpen}
                            >
                              <label>{subItem.label}</label>
                            </Link>
                          </li>
                        ),
                      )}
                    </SubMenuUL>
                  </>
                )}
              </li>
            ))}
          </Menu>
          <Footer />
        </NavCustom>
      </Container>
    </>
  );
};

export default MenuDropDown;
