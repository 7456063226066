import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  InputHTMLAttributes,
} from 'react';
import { useField } from '@unform/core';

import FormControl from 'react-bootstrap/Form';
import bsCustomFileInput from 'bs-custom-file-input';

import InputGroup from 'react-bootstrap/InputGroup';

// Icone de erro que irá no lugar da menssagem de erro dentro do container
import { FiAlertCircle } from 'react-icons/fi';

import { Container, ErrorStyle } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  className?: string;
}
const InputFile: React.FC<InputProps> = ({ name, className, ...rest }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);

  const { fieldName, error, clearError, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'files[0]',
      clearValue(ref: HTMLInputElement) {
        // eslint-disable-next-line no-param-reassign
        ref.value = '';
      },
    });

    /*
    Biblioteca utilizada pelo bootstrap para colocar o nome
    do arquivo no input após selecioná-lo
    */
    bsCustomFileInput.init();
  }, [fieldName, registerField]);

  const handleInputFocus = useCallback((): void => {
    setIsFocused(true);
    // Função do unform para limpar os erros do campo
    clearError();
  }, [clearError]);

  return (
    <Container className={className} isErrored={!!error} isFocused={isFocused}>
      <InputGroup>
        <FormControl.File
          id="custom-file"
          label="Escolha o arquivo"
          custom
          className={`text-uppercase ${className}`}
          onFocus={handleInputFocus}
          ref={inputRef}
          {...rest}
        />
        {error && (
          <ErrorStyle title={error}>
            <FiAlertCircle size={20} />
          </ErrorStyle>
        )}
      </InputGroup>
    </Container>
  );
};
export default InputFile;
