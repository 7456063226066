/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useRef,
  useState,
  useEffect,
  FC,
  InputHTMLAttributes,
  KeyboardEvent,
  useCallback,
} from 'react';

import { useField } from '@unform/core';
import fincalc from 'fincalc';

import { FiAlertCircle } from 'react-icons/fi';

import InputGroup from 'react-bootstrap/InputGroup';

import convertNumberFloatToDB from '../../../utils/convertNumberFloatToDB';

import { Container, ErrorStyle } from '../styles';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  fontSizeInput?: string;
  fontSizeLabel?: string;
  className?: string;
  classNameInputGroupTextAndFormControl?: string;
  icon?: string;
}

const VALID_FIRST = /^[1-9]{1}$/;
const VALID_NEXT = /^[0-9]{1}$/;
const DELETE_KEY_CODE = 'Backspace';
const TAB_KEY_CODE = 'Tab';

const CurrencyInput: FC<Props> = ({
  name,
  label,
  fontSizeInput,
  fontSizeLabel,
  className,
  classNameInputGroupTextAndFormControl = '',
  icon: Icon,

  max = Number.MAX_SAFE_INTEGER,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const {
    fieldName,
    defaultValue = '0,00',
    error,
    clearError,
    registerField,
  } = useField(name);

  // Registra input no Form
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      // setValue(ref: any, value: string) {
      //   //   // ref.value = value;
      //   setValueCurrency(
      //     parseInt((convertNumberFloatToDB(value) * 100).toString(), 10),
      //   );
      // },
    });
  }, [fieldName, registerField]);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
    // Função do unform para limpar os erros do campo
    clearError();
  }, [clearError]);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!inputRef.current?.value);
  }, []);

  const handleKeyDown = useCallback(
    // eslint-disable-next-line consistent-return
    (e: KeyboardEvent<HTMLDivElement>) => {
      const { key } = e;
      let valueActual = 0;

      if (key === TAB_KEY_CODE) {
        return;
      }

      e.preventDefault(); // Evita que o campo receba o valor digitado (0,011 evita duplicar o digito)

      if (inputRef.current) {
        valueActual = parseInt(
          fincalc(
            convertNumberFloatToDB(inputRef.current.value.toString()) * 100,
          ).toString(),
          10,
        );

        if (
          (valueActual === 0 && !VALID_FIRST.test(key)) ||
          (valueActual !== 0 &&
            !VALID_NEXT.test(key) &&
            key !== DELETE_KEY_CODE)
        ) {
          e.preventDefault();
          return;
        }

        const valueString = valueActual.toString();
        let nextValue: number;
        if (key !== DELETE_KEY_CODE) {
          const nextValueString: string =
            valueActual === 0 ? key : `${valueString}${key}`;
          nextValue = Number.parseInt(nextValueString, 10);
        } else {
          const nextValueString = valueString.slice(0, -1);
          nextValue =
            nextValueString === '' ? 0 : Number.parseInt(nextValueString, 10);
        }
        if (nextValue > max) {
          return;
        }

        const valueDisplay = fincalc(nextValue / 100).toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
        });

        inputRef.current.value = `${valueDisplay}`;
      }
    },
    [max],
  );

  return (
    <Container
      isErrored={!!error}
      isFilled={isFilled}
      isFocused={isFocused}
      className={className}
    >
      {label && (
        <label
          htmlFor={name}
          style={{
            textTransform: 'uppercase',
            fontSize: `${fontSizeLabel}`,
            letterSpacing: '0,09em',
          }}
        >
          {label}
        </label>
      )}
      <InputGroup>
        {Icon && (
          <InputGroup.Prepend>
            <InputGroup.Text
              id="basic-addon1"
              className={classNameInputGroupTextAndFormControl}
            >
              {Icon}
            </InputGroup.Text>
          </InputGroup.Prepend>
        )}
        <input
          id={name}
          type="text"
          style={{ fontSize: `${fontSizeInput}`, letterSpacing: '0,09em' }}
          className={`form-control text-uppercase ${classNameInputGroupTextAndFormControl}`}
          aria-describedby="basic-addon1"
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          onKeyDown={handleKeyDown}
          defaultValue={defaultValue}
          ref={inputRef}
          {...rest}
        />
        {error && (
          <ErrorStyle title={error}>
            <FiAlertCircle size={20} />
          </ErrorStyle>
        )}
      </InputGroup>
    </Container>
  );
};

export default CurrencyInput;
