/* eslint-disable camelcase */
import React, { useCallback, useState, useEffect } from 'react';

import Button from '../../../../components/Button';

import Modal from '../../../../components/Modal';

import { useToast } from '../../../../context/ToastContext';
import api from '../../../../services/api';

interface ModalDTO {
  title: string;
  loadModalProp: boolean;
  saleConditionalIdProp: number;
  handleListSalesConditionalModal: () => void;
}

interface CloseConditional {
  id: number;
  client_name: string;
}

const ModalCloseConditional: React.FC<ModalDTO> = ({
  title,
  loadModalProp = false,
  saleConditionalIdProp,
  handleListSalesConditionalModal,
}) => {
  const [
    returnCloseConditional,
    setReturnCloseConditional,
  ] = useState<CloseConditional>({} as CloseConditional);
  const [
    loadModalReturnCloseConditional,
    setLoadModalReturnCloseConditional,
  ] = useState(false);
  const [loadModalConfirmation, setLoadModalConfirmation] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const { addToast } = useToast();

  useEffect(() => {
    setLoadModalConfirmation(loadModalProp);
  }, [loadModalProp]);

  const handleBaixa = useCallback(async (): Promise<void> => {
    try {
      setDisabledButton(true);
      const response = await api.get(
        `sales/conditional/close/${saleConditionalIdProp}`,
      );

      handleListSalesConditionalModal();
      setReturnCloseConditional(response.data);

      setLoadModalConfirmation(false);
      setLoadModalReturnCloseConditional(true);
      setDisabledButton(false);
    } catch (error) {
      setLoadModalConfirmation(false);
      setLoadModalReturnCloseConditional(false);
      setDisabledButton(false);
      // eslint-disable-next-line no-shadow
      if (error.response) {
        const { data } = error.response; // Error vindo do back está em data dentro de response
        addToast({
          type: 'error',
          title: data.message,
        });
        return;
      }

      addToast({
        type: 'error',
        title: 'Ocorreu um erro interno',
        description: `${error}`,
      });
    }
  }, [addToast, handleListSalesConditionalModal, saleConditionalIdProp]);

  return (
    <>
      <Modal visibleProp={loadModalConfirmation}>
        <div className="d-flex flex-column align-items-center w-100 bg-branco-puro-5bits p-4">
          <h1>{title}</h1>
          <div className="d-flex flex-row align-items-center justify-content-center w-100">
            <Button
              className="w-auto mr-3"
              onClick={handleBaixa}
              disabled={disabledButton}
            >
              Confirmar
            </Button>
            <Button
              name="closeModal"
              type="button"
              className="w-auto"
              onClick={() => setLoadModalConfirmation(false)}
            >
              Cancelar
            </Button>
          </div>
        </div>
      </Modal>

      <Modal visibleProp={loadModalReturnCloseConditional}>
        <div className="d-flex flex-column align-items-center w-100 bg-branco-puro-5bits p-4">
          <h1>Baixa da Venda Condicional</h1>
          <div className="d-flex flex-column align-items-center justify-content-center w-100">
            <span className="text-uppercase">
              <h5>
                Baixa da venda <strong>cód. {returnCloseConditional.id}</strong>{' '}
                - cliente:{' '}
                <strong> {returnCloseConditional.client_name}</strong> realizada
                com sucesso!
              </h5>
            </span>
            <Button
              name="closeModal"
              type="button"
              className="w-auto"
              onClick={() => {
                setLoadModalReturnCloseConditional(false);
              }}
            >
              Fechar
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ModalCloseConditional;
