import React, { useCallback, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

// import Button from 'react-bootstrap/Button';
import { RiAccountCircleLine, RiShoppingBagLine } from 'react-icons/ri';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Input from '../../components/Input';
import Button from '../../components/Button';
import { useAuth } from '../../context/AuthContext';
import getValidationErrors from '../../utils/getValidationErrors';
import { useToast } from '../../context/ToastContext';

import logoImg from '../../assets/Logo-5bits.svg';

interface SignInFormData {
  username: string;
  password: string;
}

const SignIn: React.FC = () => {
  const { signIn } = useAuth();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [disableButton, setDisableButton] = useState(false);
  const history = useHistory();

  // handleSubmit
  const handleSubmit = useCallback(
    async (dataForm: SignInFormData) => {
      try {
        setDisableButton(true);
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          username: Yup.string().required('Usuário obrigatório'),
          password: Yup.string().required('Senha obrigatória'),
        });

        await schema.validate(dataForm, {
          abortEarly: false,
        });

        await signIn({
          username: dataForm.username,
          password: dataForm.password,
        });

        setDisableButton(false);
        history.push('/home');
      } catch (error) {
        setDisableButton(false);

        // Verifica se o error é do Yup ou seja dos campos
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);

          return;
        }

        // eslint-disable-next-line no-shadow
        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: data.message,
          });

          return;
        }

        addToast({
          type: 'error',
          title: 'Ocorreu um erro interno',
        });
      }
    },
    [addToast, history, signIn],
  );
  // Fim handleSubmit

  return (
    <Row
      className="h-100 d-flex align-content-center justify-content-center align-items-center"
      noGutters
    >
      <Col
        md={12}
        className="d-flex align-items-center justify-content-center mb-5"
      >
        <img src={logoImg} alt="5bits" />
      </Col>
      <Col
        xs={12}
        sm={8}
        md={8}
        lg={7}
        xl={6}
        className="d-flex
        flex-column align-items-center
        justify-content-center bg-white
        shadow bg-white rounded"
      >
        <Row className="w-100 flex-column align-items-center">
          <Col sm={9} md={9}>
            <h1 className="mt-5 mb-5">Sistema de gerenciamento</h1>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <Input
                type="text"
                textTransform="text-none"
                name="username"
                className="mb-4"
                icon={RiAccountCircleLine}
                placeholder="Usuário"
              />
              <Input
                type="password"
                textTransform="text-none"
                name="password"
                className="mb-5"
                icon={RiShoppingBagLine}
                placeholder="Senha"
                autoComplete="current-password"
              />

              <Button type="submit" className="mb-5" disabled={disableButton}>
                ENTRAR
              </Button>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default SignIn;
