/* eslint-disable camelcase */
import React, { useCallback, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { FiEdit } from 'react-icons/fi';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ComplexCreateAndUpdateTemplate from '../../../components/Template/ComplexCreateAndUpdateTemplate';

import api from '../../../services/api';
import getValidationErrors from '../../../utils/getValidationErrors';
import { useToast } from '../../../context/ToastContext';

import Input from '../../../components/Input';
import CurrencyInput from '../../../components/Input/InputCurrency';
import Checkbox from '../../../components/Checkbox';
import SelectSimple from '../../../components/SelectSimple';
import Button from '../../../components/Button';

interface PaymentMethodsFormDataDTO {
  financial_account_id: number;
  name: string;
  days_compensation: number;
  allow_installments: boolean;
  allow_installmentsText?: string;
  fee: number;
  print_booklet_format: boolean;
  print_booklet_formatText?: string;
}

const PaymentMethods: React.FC = () => {
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const [disableButton, setDisableButton] = useState(false);

  // const [clearIsFixedDays, setClearIsFixedDays] = useState(false);

  // handleSubmit
  const handleSubmit = useCallback(
    async (dataForm: PaymentMethodsFormDataDTO, { reset }) => {
      try {
        setDisableButton(true);

        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          financial_account_id: Yup.string().required(
            'Conta Financeira obrigatória',
          ),
        });

        await schema.validate(dataForm, {
          abortEarly: false,
        });

        const newData = {
          ...dataForm,
          allow_installments: dataForm.allow_installmentsText === 'true',
          print_booklet_format: dataForm.print_booklet_formatText === 'true',
        };

        delete newData.allow_installmentsText;
        delete newData.print_booklet_formatText;

        await api.post('cash/payment-methods', newData);

        addToast({
          type: 'success',
          title: 'Cadastro realizado com sucesso',
        });

        reset();
        setDisableButton(false);
        history.push('/cash/payment-methods/list');
      } catch (error) {
        setDisableButton(false);

        // Verifica se o error é do Yup ou seja dos campos
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);

          return;
        }
        // eslint-disable-next-line no-shadow
        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: 'Erro no cadastro',
            description: data.message,
          });
          if (formRef.current?.getFieldRef(data.field)) {
            formRef.current?.getFieldRef(data.field).focus(); // Foca o campo que deu erro de acordo com o field retornado do back
          }

          return;
        }

        addToast({
          type: 'error',
          title: 'Ocorreu um erro interno',
        });
      }
    },
    [addToast, history],
  );
  // Fim handleSubmit
  /*
  const handleClearDaysCompensation = useCallback(() => {
    formRef.current?.clearField('days_compensation');
    setClearIsFixedDays(!clearIsFixedDays);
  }, [clearIsFixedDays]);

  const handleClearIsFixedDays = useCallback(
    (e: KeyboardEvent<HTMLDivElement>): void => {
      const { key, keyCode } = e;

      const VALID_FIRST = /^[0-9]{1}$/;
      const daysCompensationValue = formRef.current?.getFieldRef(
        'days_compensation',
      ).value;

      if (VALID_FIRST.test(key) && daysCompensationValue >= 0) {
        setClearIsFixedDays(false);
      } else {
        formRef.current?.clearField('days_compensation');
      }
    },
    [],
  );
*/
  return (
    <ComplexCreateAndUpdateTemplate title="Cadastro de Formas de Pagamento">
      <Form ref={formRef} className="mt-4" onSubmit={handleSubmit}>
        <Row noGutters className="w-100 h-100">
          <Col md={6} className=" h-100">
            <Input
              type="text"
              name="name"
              label="Nome"
              icon={FiEdit}
              placeholder="Nome"
              aria-label="Nome"
              maxLength={45}
              className="mb-4"
            />

            <Input
              type="number"
              name="days_compensation"
              label="Dias de compensação"
              icon={FiEdit}
              placeholder="Dias de compensação"
              aria-label="Dias de compensação"
              min="0"
              step="1"
              className="mb-4"
            />
          </Col>
          <Col md={6} className=" h-100 pl-3">
            <SelectSimple
              module="cash/financial-accounts"
              name="financial_account_id"
              label="Conta Financeira"
              icon={FiEdit}
              placeholder="Conta Financeira"
              aria-label="Conta Financeira"
              className="mb-4"
            />

            <CurrencyInput
              type="text"
              name="fee"
              label="Taxa"
              icon="R$"
              placeholder="Taxa"
              className="mb-2"
            />
          </Col>
          <Col md={12} className=" h-100 pl-3">
            <Checkbox name="allow_installmentsText" propChecked={false}>
              Permitir Parcelamento
            </Checkbox>

            <Checkbox name="print_booklet_formatText" propChecked={false}>
              Imprimir em formato de carnê
            </Checkbox>
          </Col>
          <Col
            md={12}
            className=" h-100 d-flex align-items-center justify-content-center mb-3 overflow-auto"
          >
            <Button
              name="submit"
              type="submit"
              className="mt-2 w-50"
              disabled={disableButton}
            >
              Cadastrar
            </Button>
          </Col>
        </Row>
      </Form>
    </ComplexCreateAndUpdateTemplate>
  );
};

export default PaymentMethods;
