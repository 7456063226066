/* eslint-disable camelcase */
import React, { useRef, useState, useEffect, useCallback } from 'react';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { BsSearch } from 'react-icons/bs';

import Spinner from 'react-bootstrap/Spinner';
import getValidationErrors from '../../../../../../utils/getValidationErrors';

import api from '../../../../../../services/api';
import convertDateToBr from '../../../../../../utils/convertDateToBr';
import { useToast } from '../../../../../../context/ToastContext';
import InputSearchWithButton from '../../../../../../components/Input/InputSearchWithButton';

// interface ClientFormDTO {
//   id: number;
//   name: string;
//   dependent_name: string;
// }

// interface EmployeeFormDTO {
//   id: number;
//   name: string;
// }

interface SaleBodyItemFormDTO {
  id: number;
  sale_header_id: number;
  product_id: string;
  quantity: number;
  price: string;
  discount: string;
  name: string;
  size: string;
  color: string;
}

interface BillsReceiveItemFormDTO {
  id: number;
  name: string;
  sale_header_id: number;
  analytical_account_id: number;
  payment_method_id: number;
  amount: string;
  amount_received: string;
  due_date: string;
  discount: string;
  addition: string;
  is_paid: boolean;
}

interface ComplexCancelSaleUpdateTemplateProps {
  title: string;
  // eslint-disable-next-line no-unused-vars
  handleSearch: (newItem: any) => void;
}

const ComplexCancelSaleUpdateTemplate: React.FC<ComplexCancelSaleUpdateTemplateProps> = ({
  title,
  handleSearch,
  children,
}) => {
  const formRefSearch = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const [sale, setSale] = useState<any>({} as any);
  const [loading, setLoading] = useState(false);

  const handleFindSale = useCallback(
    async (dataForm: any, { reset }) => {
      try {
        const getListSales = async (): Promise<void> => {
          try {
            setLoading(true);
            setSale({} as any);

            formRefSearch.current?.setErrors({});
            const schema = Yup.object().shape({
              find: Yup.string().required('Código da venda obrigatório'),
            });

            await schema.validate(dataForm, {
              abortEarly: false,
            });

            const response = await api.get(`sales/sell/${dataForm.find}`);

            // Search details product
            const newSalesBodyItem: any = await response.data.sales_body.map(
              // eslint-disable-next-line consistent-return
              async (item: SaleBodyItemFormDTO) => {
                try {
                  const responseProduct = await api.get(
                    `products/${item.product_id}`,
                  );
                  return {
                    ...item,
                    name: responseProduct.data.name,
                    size: responseProduct.data.size,
                    color: responseProduct.data.color,
                  };
                } catch (error) {
                  // eslint-disable-next-line no-shadow
                  if (error.response) {
                    const { data } = error.response; // Error vindo do back está em data dentro de response
                    addToast({
                      type: 'error',
                      title: data.message,
                    });
                    // eslint-disable-next-line consistent-return
                    return;
                  }
                  addToast({
                    type: 'error',
                    title: `Ocorreu um erro interno${error}`,
                  });
                }
              },
            );
            // End Search details product

            // Search name PaymentMethods
            const newBillsReceiveHeaderItem: any = await response.data.bills_receive_header.map(
              // eslint-disable-next-line consistent-return
              async (item: BillsReceiveItemFormDTO) => {
                try {
                  const responsePaymentMethods = await api.get(
                    `cash/payment-methods/${item.payment_method_id}`,
                  );
                  return {
                    ...item,
                    name: responsePaymentMethods.data.name,
                  };
                } catch (error) {
                  // eslint-disable-next-line no-shadow
                  if (error.response) {
                    const { data } = error.response; // Error vindo do back está em data dentro de response
                    addToast({
                      type: 'error',
                      title: data.message,
                    });
                    // eslint-disable-next-line consistent-return
                    return;
                  }
                  addToast({
                    type: 'error',
                    title: `Ocorreu um erro interno${error}`,
                  });
                }
              },
            );
            // End Search name PaymentMethods

            setSale({
              ...response.data,
              created_at: convertDateToBr(response.data.created_at),
              sales_body: await Promise.all(newSalesBodyItem),
              bills_receive_header: await Promise.all(
                newBillsReceiveHeaderItem,
              ),
            });

            reset();
            setLoading(false);
          } catch (error) {
            setLoading(false);
            setSale({} as any);

            // Verifica se o error é do Yup ou seja dos campos
            if (error instanceof Yup.ValidationError) {
              const errors = getValidationErrors(error);
              // formRefSearch.current?.setErrors(errors);
              //  find é o nome do campo de pesquisa
              addToast({
                type: 'error',
                title: `${errors.find}`,
              });

              return;
            }
            // eslint-disable-next-line no-shadow
            if (error.response) {
              const { data } = error.response; // Error vindo do back está em data dentro de response
              addToast({
                type: 'error',
                title: data.message,
              });
              return;
            }
            addToast({
              type: 'error',
              title: `Ocorreu um erro interno${error}`,
              description: error.response,
            });
          }
        };
        getListSales();
      } catch (error) {
        // eslint-disable-next-line no-shadow
        if (error.response.data) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: data.message,
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Ocorreu um erro interno',
          description: error.response,
        });
      }
    },
    [addToast],
  );

  useEffect(() => {
    handleSearch(sale);
  }, [handleSearch, sale]);

  return (
    <Col
      md={12}
      className="d-flex flex-column h-100
      overflow-auto bg-branco-puro-5bits shadow p-2"
    >
      <Row
        noGutters
        className="d-flex flex-row justify-content-between no-gutters p-2 border border-right-0 border-left-0 border-top-0 border-cinza-claro-5bits"
      >
        <Col md={5} className="d-flex justify-content-start align-items-start">
          <h1>{title}</h1>
        </Col>
        <Col
          md={7}
          className="d-flex flex-column justify-content-end align-items-top mb-4"
        >
          <Form
            ref={formRefSearch}
            onSubmit={handleFindSale}
            className="d-flex flex-row justify-content-end h-100"
          >
            <InputSearchWithButton
              type="text"
              name="find"
              className="w-90"
              classNameInputGroupTextAndFormControl="bg-transparent"
              icon={BsSearch}
              placeholder="Cancelar pelo código"
              aria-label="Cancelar pelo código"
              maxLength={45}
            />
          </Form>
        </Col>
      </Row>
      <Row noGutters className="d-block h-75 w-100">
        {(loading && (
          <Col className="h-100 w-100 p-2 d-flex justify-content-center align-items-center">
            <Spinner animation="border" variant="primary" />
          </Col>
        )) || <Col className="h-100 p-2">{children}</Col>}
      </Row>
    </Col>
  );
};

export default ComplexCancelSaleUpdateTemplate;
