import React, { useState, useEffect, useCallback, useMemo } from 'react';

import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';
import { FiPrinter } from 'react-icons/fi';
import ConvertPriceBRL from '../../../../utils/convertPriceBRL';

import Button from '../../../../components/Button';

import Modal from '../../../../components/Modal';

import { useToast } from '../../../../context/ToastContext';
import api from '../../../../services/api';

interface ModalDTO {
  title: string;
  loadModalProp: boolean;
  listSaleProp: Array<any>;
  toggleLoadModalSale: () => void;
  closeAndClearModalSale: () => void;
}

const ModalSell: React.FC<ModalDTO> = ({
  title,
  loadModalProp = false,
  listSaleProp,
  closeAndClearModalSale,
}) => {
  const { addToast } = useToast();
  const [loadingPDF, setLoadingPDF] = useState(false);
  const [listSale, setListSale] = useState<Array<any>>([]);
  const listSaleSecondPaymentMethod: any = useMemo(() => [], []);

  const handlePrintPDF = useCallback(
    async (id): Promise<void> => {
      try {
        setLoadingPDF(true);
        const response = await api.get(`sales/sell/receipt-pdf/${id}`, {
          responseType: 'arraybuffer',
        });

        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: 'application/pdf' }),
        );
        setLoadingPDF(false);
        window.open(url);
      } catch (error) {
        setLoadingPDF(false);
        // eslint-disable-next-line no-shadow
        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: data.message,
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Ocorreu um erro interno',
          description: `${error}`,
        });
      }
    },
    [addToast],
  );

  const filterListSale = useCallback(
    obj => {
      if (
        listSaleProp &&
        obj.payment_method.id === listSaleProp[0].payment_method.id
      ) {
        return true;
      }

      listSaleSecondPaymentMethod.push(obj);
      return false;
    },
    [listSaleProp, listSaleSecondPaymentMethod],
  );

  useEffect(() => {
    if (loadModalProp === true) {
      const newListSale = [
        listSaleProp.filter(filterListSale),
        listSaleSecondPaymentMethod,
      ];
      setListSale(newListSale);
    }
  }, [
    filterListSale,
    listSaleProp,
    listSaleSecondPaymentMethod,
    loadModalProp,
  ]);

  return (
    <Modal visibleProp={loadModalProp}>
      <div
        style={{ maxHeight: '70vh' }}
        className="d-flex flex-column align-items-center w-100 bg-branco-puro-5bits p-4"
      >
        <h1>{title}</h1>
        <div className="overflow-auto">
          <Table striped bordered hover size="sm" responsive="md">
            <thead className="text-center text-primary text-uppercase">
              <tr>
                <th className="font-weight-normal py-3" scope="col">
                  Imprimir
                </th>
                <th className="font-weight-normal py-3" scope="col">
                  Forma de Pagamento
                </th>
                <th className="font-weight-normal py-3" scope="col">
                  Desconto
                </th>
                <th className="font-weight-normal py-3" scope="col">
                  Acréscimo
                </th>
                <th className="font-weight-normal py-3" scope="col">
                  Valor
                </th>
                <th className="font-weight-normal py-3" scope="col">
                  Vencimento
                </th>
              </tr>
            </thead>
            <tbody className="text-center">
              {listSale.map((item, index) => {
                return item.map((subItem: any, indexSubItem: number) => (
                  <tr key={indexSubItem.toString()}>
                    {indexSubItem === 0 && (
                      <th
                        scope="row"
                        rowSpan={item.length}
                        style={{
                          textAlign: 'center',
                          verticalAlign: 'middle',
                        }}
                        className={`${index === 1 && 'bg-branco-puro-5bits'}`}
                      >
                        {subItem.payment_method.print_booklet_format && (
                          <>
                            {(loadingPDF && (
                              <Spinner animation="border" variant="primary" />
                            )) || (
                              <Button
                                name="print"
                                type="button"
                                fontSizeButton="12px"
                                onClick={() => {
                                  handlePrintPDF(subItem.sale_header.id);
                                }}
                              >
                                <FiPrinter size={26} />
                              </Button>
                            )}
                          </>
                        )}
                      </th>
                    )}
                    <th scope="row">{subItem.payment_method.name}</th>
                    <td className="text-right pr-3">
                      {ConvertPriceBRL(subItem.discount)}
                    </td>
                    <td className="text-right pr-3">
                      {ConvertPriceBRL(subItem.addition)}
                    </td>
                    <td className="text-right pr-3">
                      {ConvertPriceBRL(subItem.amount)}
                    </td>
                    <td>{subItem.due_date}</td>
                  </tr>
                ));
              })}
            </tbody>
          </Table>
        </div>

        <div className="d-flex flex-row justify-content-around mt-3 w-50">
          <Button
            name="closeModal"
            type="button"
            className="w-50 mr-4"
            fontSizeButton="12px"
            onClick={closeAndClearModalSale}
          >
            Fechar
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalSell;
