/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable camelcase */
interface UserFormDataDTO {
  id: number;
  name: string;
  nickname: string;
  username: string;
  is_adminText?: string;
  is_activeText?: string;
  is_admin: boolean | string;
  is_active: boolean;
  password?: string;
  usersHasMenus?: Array<Object>;
  usersHasMainMenus?: Array<Object>;
  usersHasMainSubMenus?: Array<Object>;
  usersHasSubMenus?: Array<Object>;
}

interface PermissionDTO {
  menus_id?: number;
  action?: string | null;
  read: boolean;
  create: boolean;
  update: boolean;
  delete: boolean;
  // cancel: boolean;
}

interface MenuDTO {
  id: number;
  group_menu: number;
  label: string;
  route: string;
  action: string | null;
  is_submenu: boolean;
  submenus: Array<MenuDTO>;
  permission: {
    menus_id?: number;
    action?: string | null;
    read: boolean;
    create: boolean;
    update: boolean;
    delete: boolean;
    // cancel: boolean;
  };
  usersHasMainSubMenus_id?: number;
  usersHasSubMenus?: Array<MenuDTO>;
  usersHasMainSubMenus?: Array<MenuDTO>;
  usersHasMainMenus?: Array<MenuDTO>;
}

/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-param-reassign */
export function handleListMenus(dataForm: UserFormDataDTO): Array<Object> {
  /**
   * Converte o 'true' string em true boolean
   * Retira do array de objetos as permissões que
   * forem false.
   * Retira do array todos os objetos que ficaram sem
   * nenhuma permissão.
   */

  const finalSubMenu: any = dataForm.usersHasSubMenus?.filter((item: any) => {
    Object.keys(item).map(key => {
      if (item[key.toString()] === 'true') {
        item[key.toString()] = true;
      } else if (item[key.toString()] === 'false') {
        delete item[key.toString()];
      }
      return null;
    }); // Fim map keys

    /**
     * Se não existir nenhuma permissão o retorno é false e o item
     * não é salvo no novo array
     * */
    return Object.values(item).some(value => value === true);
  }); // Fim Filter

  /**
   * Verificar se existe ao menos um submenu com
   * permissão para o menu principal. Se existir
   * o menu principal é adicionado. Lembrando que ele tem
   * apenas duas propriedades o menus_id e a permissão read: "true".
   */
  const mainMenuIfSubMenu: any = dataForm.usersHasMainSubMenus?.filter(
    (item: any) => {
      if (
        finalSubMenu?.find(
          (itemFind: any) => itemFind.usersHasMainSubMenus_id === item.menus_id,
        )
      ) {
        // Adiciona a propriedade read
        item.read = true;
        return item;
      }
      return null;
    },
  ); // Fim filter
  /**
   * Remove dos objetos subMenu a
   * propriedade usersHasMainSubMenus_id que não
   * deve ser enviada para cadastro.
   */
  finalSubMenu?.map((item: any) => {
    delete item.usersHasMainSubMenus_id;
    return null;
  });

  /**
   * Converte o 'true' string em true boolean
   * Retira do array de objetos as permissões que
   * forem false.
   * Retira do array todos os objetos que ficaram sem
   * nenhuma permissão
   */
  const mainMenu: any = dataForm.usersHasMainMenus?.filter((item: any) => {
    Object.keys(item).map(key => {
      if (item[key.toString()] === 'true') {
        item[key.toString()] = true;
      } else if (item[key.toString()] === 'false') {
        delete item[key.toString()];
      }
      return null;
    });
    // Se não existir nenhuma permissão o retorno é false e o item é salvo no novo array
    return Object.values(item).some(value => value === true);
  });

  const newUsersHasMenus = [...mainMenu, ...mainMenuIfSubMenu, ...finalSubMenu];

  return newUsersHasMenus;
}

function addPermission(obj: MenuDTO): void {
  // O item que não tiver permissões recebe todas em false
  if (!Object.keys(obj).find(key => key === 'permission')) {
    obj.permission = {
      read: false,
      create: false,
      update: false,
      delete: false,
      // cancel: false,
    };
  }
}

/**
 * Junta os menus com suas respectivas permissões para
 * o update de permissões. Os menus que não tiverem permissões
 * as recebem na function addPermission com o valor em false.
 *
 */

export function joinMenusWithPermission(
  listMenusWithoutPermission: Array<MenuDTO> = [] as Array<MenuDTO>,
  ObjPermission: Array<PermissionDTO> = [] as Array<PermissionDTO>,
): Array<MenuDTO> {
  const newMenusWithPermission = listMenusWithoutPermission.map(
    (item: MenuDTO) => {
      if (item.submenus!.length > 0) {
        item.submenus!.map(subItem => {
          ObjPermission.filter((element: PermissionDTO) => {
            if (element.menus_id === subItem.id) {
              subItem.permission = element;
            }
            return null;
          });
          // O item que não tiver permissões recebe todas em false
          addPermission(subItem);
          return null;
        });
      } else {
        ObjPermission.filter((element: PermissionDTO) => {
          if (element.menus_id === item.id) {
            item.permission = element;
          }
          return null;
        });
      }
      // O item que não tiver permissões recebe todas em false
      addPermission(item);

      return item;
    },
  );

  return newMenusWithPermission;
}

/**
 * A lista de menus vinda do banco para o cadastro
 * não vem com as permissions. Para o cadastro todas
 * devem estar em false.
 */
export function joinMenusWithoutPermission(
  listMenusWithoutPermission: Array<MenuDTO>,
): Array<MenuDTO> {
  const newMenusWithPermission: Array<MenuDTO> = listMenusWithoutPermission.map(
    (item: MenuDTO) => {
      if (item.submenus!.length > 0) {
        item.submenus!.map(subItem => {
          subItem.permission = {
            read: false,
            create: false,
            update: false,
            delete: false,
            // cancel: false,
          };
          return null;
        });
      } else {
        item.permission = {
          read: false,
          create: false,
          update: false,
          delete: false,
          // cancel: false,
        };
      }

      return item;
    },
  );

  return newMenusWithPermission;
}
