/* eslint-disable camelcase */
import React, { useCallback, useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import typy from 'typy';
import { FiEdit } from 'react-icons/fi';

import { Row, Col } from 'react-bootstrap';
import SimpleCreateAndUpdateTemplate from '../../components/Template/SimpleCreateAndUpdateTemplate';
import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';
import convertNumberFloatToDB from '../../utils/convertNumberFloatToDB';
import convertDateToDB from '../../utils/convertDateToDB';
import { useToast } from '../../context/ToastContext';

import Button from '../../components/Button';
import SelectAsyncCreatable from '../../components/SelectAsyncCreatable';
import CurrencyInput from '../../components/Input/InputCurrency';
import DatePicker from '../../components/DatePicker';
import ModalCoupon from './Modal/modalPrintCoupon';

interface DiscountCouponFormDataDTO {
  expiration_date: string;
  amount: string;
  client_name: string;
  client_id_select: Array<{ value: number; label: string }>[any];
}

const DiscountCoupon: React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [disableButton, setDisableButton] = useState(false);

  const [listClients, setListClients] = useState<any[]>([]);

  const [loadModalPrint, setLoadModalPrint] = useState(false);
  const [idCouponModal, setIdCouponModal] = useState(0);

  useEffect(() => {
    const getListClients = async (): Promise<void> => {
      try {
        const responseClients = await api.get('clients');
        setListClients(
          responseClients.data.map((item: any) => {
            return { label: item.name, value: item.id };
          }),
        );
      } catch (erro) {
        // eslint-disable-next-line no-shadow
        if (erro.response) {
          const { data } = erro.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: data.message,
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Ocorreu um erro interno',
        });
      }
    };
    getListClients();
  }, [addToast]);

  // handleSubmit
  const handleSubmit = useCallback(
    async (dataForm: DiscountCouponFormDataDTO, { reset }) => {
      try {
        setDisableButton(true);
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          amount: Yup.string().required('Cognome obrigatório'),
          client_id_select: Yup.object()
            .shape({
              label: Yup.string().required('Cliente é obrigatório'),
            })
            .nullable()
            .required('Cliente é obrigatório'),
        });

        await schema.validate(dataForm, {
          abortEarly: false,
        });

        const newData = {
          expiration_date: convertDateToDB(dataForm.expiration_date),
          amount: convertNumberFloatToDB(dataForm.amount),
          client_id: typy(dataForm.client_id_select.value).isNumber
            ? dataForm.client_id_select.value
            : null,
          client_name: dataForm.client_id_select.label,
        };

        const response = await api.post('discount-coupons', newData);

        setIdCouponModal(response.data.id);
        setLoadModalPrint(true);

        addToast({
          type: 'success',
          title: 'Cadastro realizado com sucesso',
        });

        reset();
        setDisableButton(false);
      } catch (error) {
        setLoadModalPrint(false);
        setIdCouponModal(0);
        setDisableButton(false);

        // Verifica se o error é do Yup ou seja dos campos
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);

          return;
        }
        // eslint-disable-next-line no-shadow
        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: 'Erro no cadastro',
            description: data.message,
          });
          if (formRef.current?.getFieldRef(data.field)) {
            formRef.current?.getFieldRef(data.field).focus(); // Foca o campo que deu erro de acordo com o field retornado do back
          }

          return;
        }

        addToast({
          type: 'error',
          title: 'Ocorreu um erro interno',
        });
      }
    },
    [addToast],
  );
  // Fim handleSubmit

  const handleLoadingClient = useCallback(
    // eslint-disable-next-line consistent-return
    async (inputValue: any) => {
      try {
        if (inputValue) {
          const response = await api.get(`clients/search/${inputValue}`);

          return response.data.map((item: any) => {
            return { label: item.name, value: item.id };
          });
        }
        return [];
      } catch (erro) {
        // eslint-disable-next-line no-shadow
        if (erro.response) {
          const { data } = erro.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: data.message,
          });
          // eslint-disable-next-line consistent-return
          return;
        }

        addToast({
          type: 'error',
          title: 'Ocorreu um erro interno',
        });
      }
    },
    [addToast],
  );

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const loadOptions = async (inputValue: any, callback: any) => {
    if (!inputValue) return;
    const response = await handleLoadingClient(inputValue);

    // eslint-disable-next-line consistent-return
    return callback(response);
  };

  // For Modal
  const toggleLoadModalPrint = useCallback(() => {
    setLoadModalPrint(!loadModalPrint);
  }, [loadModalPrint]);

  const closeAndClearModalPrint = useCallback(() => {
    setLoadModalPrint(false);
    history.push('/discount-coupons/list');
  }, [history]);
  // End for Modal

  return (
    <SimpleCreateAndUpdateTemplate title="Cadastro de Cupons de Desconto">
      <Form ref={formRef} className="mt-5" onSubmit={handleSubmit}>
        <Row noGutters>
          <Col md={6}>
            <DatePicker
              name="expiration_date"
              label="Data de Expiração"
              icon={FiEdit}
              className="mb-2"
              wrapperClassName="w-70"
            />
          </Col>
          <Col md={6}>
            <CurrencyInput
              type="text"
              name="amount"
              label="Valor"
              icon="R$"
              placeholder="Valor"
              className="mb-4"
            />
          </Col>
          <Col md={12}>
            <SelectAsyncCreatable
              name="client_id_select"
              label="Nome do Cliente"
              placeholder="Nome do Cliente"
              defaultOptions={listClients}
              loadOptions={loadOptions}
              noOptionsMessage={() => 'Nenhum cliente encontrado'}
              maxLength={45}
              className="mb-2"
              allowCreateWhileLoading
              isClearable
            />
          </Col>
        </Row>
        <Button
          name="submit"
          type="submit"
          className="mt-5 w-auto"
          disabled={disableButton}
        >
          Cadastrar
        </Button>
      </Form>

      <ModalCoupon
        title="Imprimir Cupom de Desconto"
        couponIdProp={idCouponModal}
        loadModalProp={loadModalPrint}
        toggleLoadModalPrint={toggleLoadModalPrint}
        closeAndClearModalPrint={closeAndClearModalPrint}
      />
    </SimpleCreateAndUpdateTemplate>
  );
};

export default DiscountCoupon;
