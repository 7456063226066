/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useState } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Button from '../../../../../components/Button';
import Modal from '../../../../../components/Modal';

// const VALID_DIGIT = /^[0-9]{1}$/;

const FooterConditional: React.FC = () => {
  // Variáveis
  const [loadModalConfirmationSale, setLoadModalConfirmationSale] = useState(
    false,
  );
  // Fim Variáveis

  const toggleLoadModalConfirmationSale = useCallback(() => {
    setLoadModalConfirmationSale(!loadModalConfirmationSale);
  }, [loadModalConfirmationSale]);

  return (
    <>
      <Col md={12}>
        <Row
          noGutters
          className="d-md-flex flex-row justify-content-center align-items-center-100 h-auto"
        >
          <Col md={3} className=" my-2">
            <Button
              name="gerarVendaModal"
              type="button"
              className="w-100"
              fontSizeButton="12px"
              onClick={toggleLoadModalConfirmationSale}
            >
              Gerar Venda Condicional
            </Button>
          </Col>
        </Row>
      </Col>

      <Modal visibleProp={loadModalConfirmationSale}>
        <div className="d-flex flex-column align-items-center w-100 bg-branco-puro-5bits p-4">
          <h1>Confirmar Venda</h1>
          <div className="d-flex flex-row align-items-center w-100">
            <Button
              name="submit"
              type="submit"
              className="w-100 mr-2"
              fontSizeButton="12px"
              onClick={() => {
                setTimeout(() => {
                  toggleLoadModalConfirmationSale();
                }, 500);
              }}
            >
              Confirmar Venda
            </Button>
            <Button
              name="closeModalSale"
              type="button"
              className="w-50"
              fontSizeButton="12px"
              onClick={toggleLoadModalConfirmationSale}
            >
              Cancelar
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default FooterConditional;
