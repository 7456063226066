import { cpf as cpfValidator } from 'cpf-cnpj-validator';

export default function validationCPF(cpfForm: string): boolean {
  if (cpfForm) {
    const regexp = new RegExp(/[^\d]+/g);
    const removeMaskCPF = cpfForm.replace(regexp, '');
    if (!cpfValidator.isValid(removeMaskCPF)) {
      return false;
    }
    return true;
  }
  return false;
}
