import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import AppProvider from './context';

import Template from './components/Template';

import GlobalStyles from './styles/global';

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <AppProvider>
        <Template />
      </AppProvider>

      <GlobalStyles />
    </BrowserRouter>
  );
};

export default App;
