/* eslint-disable camelcase */
import React, { useCallback, useState, useEffect } from 'react';

import Button from '../../../components/Button';

import Modal from '../../../components/Modal';

import { useToast } from '../../../context/ToastContext';
import api from '../../../services/api';

interface DiscountCouponFormDTO {
  id: number;
  ticket_code: string;
  client_name: string;
  sale_header_id: number;
  amount: string;
  user: { name: string };
  is_active: boolean;
  expiration_date: string;
}

interface ModalDTO {
  title: string;
  loadModalProp: boolean;
  discountCouponProp: DiscountCouponFormDTO;
  handleListDiscountCouponModal: () => void;
  toggleLoadModalExcludeCoupon: () => void;
}

const ModalCloseDiscountCoupon: React.FC<ModalDTO> = ({
  title,
  loadModalProp = false,
  discountCouponProp,
  handleListDiscountCouponModal,
  toggleLoadModalExcludeCoupon,
}) => {
  const [
    loadModalReturnCloseDiscountCoupon,
    setLoadModalReturnCloseDiscountCoupon,
  ] = useState(false);
  const [loadModalConfirmation, setLoadModalConfirmation] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const { addToast } = useToast();

  useEffect(() => {
    setLoadModalConfirmation(loadModalProp);
  }, [loadModalProp]);

  const handleDeleteCoupon = useCallback(async (): Promise<void> => {
    try {
      setDisabledButton(true);
      await api.delete(`discount-coupons/${discountCouponProp.id}`);

      setLoadModalReturnCloseDiscountCoupon(true);

      handleListDiscountCouponModal();
      setLoadModalConfirmation(false);
      setDisabledButton(false);
    } catch (error) {
      setLoadModalConfirmation(false);
      setLoadModalReturnCloseDiscountCoupon(false);
      toggleLoadModalExcludeCoupon();
      setDisabledButton(false);
      // eslint-disable-next-line no-shadow
      if (error.response) {
        const { data } = error.response; // Error vindo do back está em data dentro de response
        addToast({
          type: 'error',
          title: data.message,
        });
        return;
      }

      addToast({
        type: 'error',
        title: 'Ocorreu um erro interno',
        description: `${error}`,
      });
    }
  }, [
    discountCouponProp,
    handleListDiscountCouponModal,
    toggleLoadModalExcludeCoupon,
    addToast,
  ]);

  return (
    <>
      <Modal visibleProp={loadModalConfirmation}>
        <div className="d-flex flex-column align-items-center w-100 bg-branco-puro-5bits p-4">
          <h1>{title}</h1>
          <div className="d-flex flex-row align-items-center justify-content-center w-100">
            <Button
              className="w-auto mr-3"
              onClick={handleDeleteCoupon}
              disabled={disabledButton}
            >
              Confirmar
            </Button>
            <Button
              name="closeModal"
              type="button"
              className="w-auto"
              onClick={() => {
                setLoadModalConfirmation(false);
                toggleLoadModalExcludeCoupon();
              }}
            >
              Cancelar
            </Button>
          </div>
        </div>
      </Modal>

      <Modal visibleProp={loadModalReturnCloseDiscountCoupon}>
        <div className="d-flex flex-column align-items-center w-100 bg-branco-puro-5bits p-4">
          <h1>Exclusão do Cupom de Desconto</h1>
          <div className="d-flex flex-column align-items-center justify-content-center w-100">
            <span className="text-uppercase">
              <h5 className="text-center">
                cód.{' '}
                <strong>
                  {discountCouponProp && discountCouponProp.ticket_code}
                </strong>{' '}
                - cliente:{' '}
                <strong>
                  {' '}
                  {discountCouponProp && discountCouponProp.client_name}
                </strong>{' '}
                <br />
                realizada com sucesso!
              </h5>
            </span>
            <Button
              name="closeModal"
              type="button"
              className="w-auto"
              onClick={() => {
                setLoadModalReturnCloseDiscountCoupon(false);
                toggleLoadModalExcludeCoupon();
              }}
            >
              Fechar
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ModalCloseDiscountCoupon;
