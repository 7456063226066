import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

interface SimpleCreateAndUpdateTemplateProps {
  title: string;
  numberColContent?: number;
  classNameProp?: string;
}

const SimpleCreateAndUpdateTemplate: React.FC<SimpleCreateAndUpdateTemplateProps> = ({
  title,
  numberColContent = 5,
  classNameProp = '',
  children,
}) => {
  return (
    <Col
      md={12}
      className="d-flex md-justify-content-center flex-column h-100
      overflow-auto bg-branco-puro-5bits shadow p-4"
    >
      <Row className="no-gutters">
        <Col md={12}>
          <h1 className="m-0">{title}</h1>
        </Col>
      </Row>
      <Row className="h-100 overflow-auto justify-content-center no-gutters">
        <Col className={classNameProp} md={numberColContent}>
          {children}
        </Col>
      </Row>
    </Col>
  );
};

export default SimpleCreateAndUpdateTemplate;
