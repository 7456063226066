/* eslint-disable camelcase */
import React from 'react';
import { Switch } from 'react-router-dom';

import { useAuth } from '../context/AuthContext';
import PrivateRoute from './PrivateRoutes';

import CreateClients from '../pages/Clients/createClients';
import ListClients from '../pages/Clients/listClients';
import UpdateClient from '../pages/Clients/updateClient';
import NotAuthorizedPage from '../pages/NotAuthorizedPage';

import findPermission from '../utils/findPermission';

const ClientsRoutes: React.FC = () => {
  const { permission } = useAuth();
  const clientPermissions = findPermission('clients', permission); // menus_id client = 1

  return (
    <Switch>
      <PrivateRoute
        path="*/create"
        component={
          Object.keys(clientPermissions).length !== 0 &&
          clientPermissions.create
            ? CreateClients
            : NotAuthorizedPage
        }
      />
      <PrivateRoute
        path="*/list"
        render={props => (
          <ListClients {...props} permissions={clientPermissions} />
        )}
      />
      <PrivateRoute
        path="*/read/:id"
        component={
          Object.keys(clientPermissions).length !== 0 &&
          clientPermissions.update
            ? UpdateClient
            : NotAuthorizedPage
        }
      />
    </Switch>
  );
};

export default ClientsRoutes;
