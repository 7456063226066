import React, { useState, useCallback } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { FiPrinter } from 'react-icons/fi';

import Button from '../../../../components/Button';

import Modal from '../../../../components/Modal';

import { useToast } from '../../../../context/ToastContext';
import api from '../../../../services/api';

interface ModalDTO {
  title: string;
  loadModalProp: boolean;
  saleConditionalIdProp: number;
  toggleLoadModalPrint: () => void;
  closeAndClearModalPrint: () => void;
}

const ModalConditional: React.FC<ModalDTO> = ({
  title,
  loadModalProp = false,
  saleConditionalIdProp,
  closeAndClearModalPrint,
}) => {
  const { addToast } = useToast();
  const [loadingPDF, setLoadingPDF] = useState(false);

  const handlePrintPDF = useCallback(
    async (id): Promise<void> => {
      try {
        setLoadingPDF(true);

        const response = await api.get(`sales/conditional/document-pdf/${id}`, {
          responseType: 'arraybuffer',
        });

        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: 'application/pdf' }),
        );
        setLoadingPDF(false);
        window.open(url);
      } catch (error) {
        setLoadingPDF(false);
        // eslint-disable-next-line no-shadow
        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: data.message,
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Ocorreu um erro interno',
          description: `${error}`,
        });
      }
    },
    [addToast],
  );

  return (
    <Modal visibleProp={loadModalProp}>
      <div
        style={{ maxHeight: '70vh' }}
        className="d-flex flex-column justify-content-center align-items-center w-100 bg-branco-puro-5bits p-4"
      >
        <h1>{title}</h1>
        <div className="d-flex flex-row justify-content-center mt-3 w-50">
          {(loadingPDF && <Spinner animation="border" variant="primary" />) || (
            <Button
              name="print"
              type="button"
              fontSizeButton="12px"
              onClick={() => {
                handlePrintPDF(saleConditionalIdProp);
              }}
            >
              <FiPrinter size={26} />
            </Button>
          )}
        </div>
        <div className="d-flex flex-row justify-content-center mt-3 w-50">
          <Button
            name="closeModal"
            type="button"
            className="w-50"
            fontSizeButton="12px"
            onClick={closeAndClearModalPrint}
          >
            Fechar
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalConditional;
