import styled from 'styled-components';

interface SpanProps {
  colorBefore: string;
}

export const Container = styled.div<SpanProps>`
  position: absolute;
  right: 0;
  margin-right: 2%;
  bottom: calc(100% + -25px);

  span {
    width: 160px;
    background: var(--message-tooltip-span-bg-color);
    padding: 8px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    opacity: 0;
    transition: opacity 0.4s;
    visibility: hidden;
    z-index: 1998;
    text-align: center;

    position: absolute;
    bottom: calc(100% + 8px);
    left: 50%;
    transform: translateX(-50%);

    color: var(--message-tooltip-span-text-color);

    &::before {
      content: '';

      border-color: ${props => props.colorBefore || '#FF8441'};

      border-style: solid;
      border-width: 0px 6px 6px 0px;
      top: 100%;
      position: absolute;
      padding: 3px;
      left: 50%;
      transform: rotate(45deg) translateX(-76%);
    }
  }

  &:hover span {
    opacity: 1;
    visibility: visible;
  }
`;
