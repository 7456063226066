/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/ban-types */
import React, { useEffect, useCallback, useState } from 'react';

import * as Yup from 'yup';
import { FormHandles, Scope } from '@unform/core';
import { FiUser, FiEdit } from 'react-icons/fi';

import convertToNumber from '../../utils/convertToNumber';
import getValidationErrors from '../../utils/getValidationErrors';

import Input from '../../components/Input';
import Checkbox from '../../components/Checkbox';
import Button from '../../components/Button';

import {
  DependentsContent,
  DependentsContainerActions,
  ListDependents,
} from './styles';

interface DependentsFormDataDTO {
  id?: number | string;
  name: string;
  phone: string;
  is_active: boolean;
}

interface DependentsProps {
  refProps: any | FormHandles;
  dependentsProps: Array<any>;
}

interface ClientFormDataDTO {
  dependents?: Array<DependentsFormDataDTO>;
  // Estes dois campos são retirados do objeto no submit
  nameDependentFormAdd?: string;
  phoneDependentFormAdd?: string;
}

const Dependents: React.FC<DependentsProps> = ({
  refProps,
  dependentsProps,
}) => {
  const [dependentsUp, setDependentsUp] = useState<DependentsFormDataDTO[]>([]);

  const [isVisibleFormDependents, setIsVisibleFormDependents] = useState(
    dependentsProps?.length > 0,
  );

  /** Inicia o scope dependents, com a lista atual recebida do banco */
  useEffect(() => {
    setDependentsUp(dependentsProps);
  }, [dependentsProps]);

  /** Adiciona ao scope dependents novos itens */
  useEffect(() => {
    refProps.current?.setData({ dependents: dependentsUp });
  }, [dependentsUp, refProps]);

  // handleAddDependent
  const handleAddDependent = useCallback(async () => {
    try {
      // Limpa as mensagens de erro dos campos
      refProps.current?.setFieldError('nameDependentFormAdd', '');
      refProps.current?.setFieldError('phoneDependentFormAdd', '');

      // Cria este objeto para validação no YUP e já pega os valores dos campos
      const dataDependentAdd = {
        nameDependentFormAdd: refProps.current?.getFieldValue(
          'nameDependentFormAdd',
        ),
        phoneDependentFormAdd: refProps.current?.getFieldValue(
          'phoneDependentFormAdd',
        ),
      };

      const schema = Yup.object().shape({
        nameDependentFormAdd: Yup.string().required('Nome obrigatório'),
        phoneDependentFormAdd: Yup.string()
          .test('phoneDependentFormAdd', 'Telefone inválido', () => {
            return (
              convertToNumber(dataDependentAdd.phoneDependentFormAdd).length ===
              11
            );
          })
          .required('Telefone obrigatório'),
      });

      await schema.validate(dataDependentAdd, {
        abortEarly: false,
      });

      /*
        Pega os dados atuais do form, não utilizei o ...client pois perderia
        alterações do cliente ao adicionar um novo dependente. Usando o formRef.current?.getData()
        do unform, os dados são recuperados como se estivesse fazendo um submit, assim todos os dados
        que estiverem nos campos serão mantidos.
        */
      const dataForm = refProps.current?.getData() as ClientFormDataDTO;

      let newListDependents = [];

      /*
      Pega as lista atual de dependentes e adiciona o novo item
      */
      if (dependentsUp.length < 1) {
        newListDependents.push({
          name: dataDependentAdd.nameDependentFormAdd,
          phone: dataDependentAdd.phoneDependentFormAdd,
          is_active: true,
        });
      } else {
        newListDependents = [...dataForm.dependents!];
        newListDependents.push({
          name: dataDependentAdd.nameDependentFormAdd,
          phone: dataDependentAdd.phoneDependentFormAdd,
          is_active: true,
        });
      }

      setDependentsUp(newListDependents);
      /** Seta os valores no scope dependents no form */
      refProps.current?.setData({ dependents: newListDependents });

      // Limpa os campos
      refProps.current?.clearField('nameDependentFormAdd');
      refProps.current?.clearField('phoneDependentFormAdd');
    } catch (error) {
      // Verifica se o error é do Yup ou seja dos campos
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        refProps.current?.setErrors(errors);
      }
    }
  }, [dependentsUp, refProps]);
  // Fim handleAddDependent

  // handleVisibleFormDependent
  const handleVisibleFormDependent = useCallback(() => {
    /*
    Pega os dados atuais do form, pois perderia
    alterações do cliente ao clicar para mostrar os campos de dependentes. Usando o formRef.current?.getData()
    do unform, os dados são recuperados como se estivesse fazendo um submit, assim todos os dados
    que estiverem nos campos serão mantidos.
    setClient({
      ...(refProps.current?.getData() as ClientFormDataDTO),
    });
 */

    setIsVisibleFormDependents(!isVisibleFormDependents);
  }, [isVisibleFormDependents]);
  // Fim handleVisibleFormDependent

  return (
    <>
      <DependentsContent>
        {(!isVisibleFormDependents && (
          <Button
            type="button"
            name="activeFormDependents"
            onClick={handleVisibleFormDependent}
          >
            Adicionar Dependentes
          </Button>
        )) || (
          <>
            <h1>Cadastro de Dependentes</h1>

            <Input
              type="text"
              name="nameDependentFormAdd"
              icon={FiUser}
              placeholder="Nome Completo"
              maxLength={45}
            />
            <Input
              type="text"
              name="phoneDependentFormAdd"
              icon={FiEdit}
              placeholder="Telefone"
              mask="(99) 9 9999-9999"
            />

            <DependentsContainerActions>
              <Button
                type="button"
                name="addDependent"
                onClick={handleAddDependent}
              >
                +
              </Button>

              <Button
                type="button"
                name="activeFormDependents"
                onClick={handleVisibleFormDependent}
              >
                Cancelar
              </Button>
            </DependentsContainerActions>
          </>
        )}
      </DependentsContent>
      {dependentsUp?.length > 0 && (
        <DependentsContent>
          <h2 className="text-md-center">Lista de Dependentes</h2>
          {dependentsUp?.map((item, index) => (
            <ListDependents key={index.toString()}>
              <Scope path={`dependents[${index}]`}>
                <Input type="hidden" name="id" isHidden />
                <Input
                  type="text"
                  name="name"
                  icon={FiUser}
                  placeholder="Nome Completo"
                  maxLength={45}
                />
                <Input
                  type="text"
                  name="phone"
                  icon={FiEdit}
                  placeholder="Telefone"
                  mask="(99) 9 9999-9999"
                  required
                />
                <Checkbox name="is_active" propChecked={item.is_active}>
                  Ativo
                </Checkbox>
              </Scope>
            </ListDependents>
          ))}
        </DependentsContent>
      )}
    </>
  );
};

export default Dependents;
