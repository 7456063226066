/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import React, { useState, useCallback, useRef, useEffect } from 'react';

import { BsSearch } from 'react-icons/bs';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import { compareAsc as compareDateAsc, parseISO } from 'date-fns';
import ButtonBootstrap from '../../../../components/ButtonBootstrap';
import DatePicker from '../../../../components/DatePicker';
import { useToast } from '../../../../context/ToastContext';

import ButtonLink from '../../../../components/ButtonLink';
import Button from '../../../../components/Button';
import Pagination from '../../../../components/Pagination';

import Modal from '../../../../components/Modal';

import getValidationErrors from '../../../../utils/getValidationErrors';

// import convertDateToBr from '../../../../utils/convertDateToBr';
import convertDateToDB from '../../../../utils/convertDateToDB';

import api from '../../../../services/api';

import { IPermission } from '../../../../interfaces/IPermission';
import Input from '../../../../components/Input';

interface ListingTemplateOfCashFlowProps {
  title: string;
  module: string;
  listItemsProps: Array<any>;
  configuration: any;
  handleListItemsPaginatedItems: (newList: Array<any>) => void;
  handleConfiguration: (newConfiguration: object) => void;
  placeHolderProp?: string;
  permissions: IPermission;
}

const ListingTemplate: React.FC<ListingTemplateOfCashFlowProps> = ({
  title,
  module,
  listItemsProps,
  configuration,
  handleListItemsPaginatedItems,
  handleConfiguration,
  children,
  permissions = {
    create: false,
    read: false,
    update: false,
    delete: false,
  } as IPermission,
}) => {
  const formSearchDateListItemRef = useRef<FormHandles>(null);
  const [listAllItems, setListAllItems] = useState<any[]>([]);
  const [listItemsOriginal, setListItemsOriginal] = useState<any[]>([]);

  const [loadModalWait, setLoadModalWait] = useState(false);
  const [loadModalWaitOpenCash, setLoadModalWaitOpenCash] = useState(false);
  const [
    loadModalConfirmationOpenCashFlow,
    setLoadModalConfirmationOpenCashFlow,
  ] = useState(false);

  const { addToast } = useToast();

  useEffect(() => {
    const getListAllItems = (): void => {
      setListAllItems(listItemsProps);
      setListItemsOriginal(listItemsProps);
    };
    getListAllItems();
  }, [listItemsProps]);

  const groupCloseDays = useCallback((objOfReturn, objActual) => {
    // eslint-disable-next-line no-param-reassign
    objOfReturn[objActual.close_date] = objOfReturn[objActual.close_date] || [];
    objOfReturn[objActual.close_date].push(objActual);

    return objOfReturn;
  }, []);

  const groupFinancialAccounts = useCallback((objOfReturn, objActual) => {
    objOfReturn.push({
      ...objActual.financialAccount,
      balance: objActual.balance,
    });
    return objOfReturn;
  }, []);

  // handleSearchSubmit
  const handleSearchAllItems = useCallback(async () => {
    try {
      setLoadModalWait(true);
      formSearchDateListItemRef.current?.setErrors({});
      formSearchDateListItemRef.current?.reset();

      const response = await api.get(`${module}`);
      const responseData = response.data;

      const closeDaysGrouped = Object.values(
        responseData.reduce(groupCloseDays, {}),
      ).map((item: any) => {
        const financialAccounts = item.reduce(groupFinancialAccounts, []);
        return { ...item[0], financialAccount: financialAccounts };
      });

      handleListItemsPaginatedItems(closeDaysGrouped);
      setListAllItems(closeDaysGrouped);
      setListItemsOriginal(closeDaysGrouped);

      setLoadModalWait(false);
    } catch (error) {
      setLoadModalWait(false);
      // Verifica se o error é do Yup ou seja dos campos
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formSearchDateListItemRef.current?.setErrors(errors);

        return;
      }

      // eslint-disable-next-line no-shadow
      if (error.response) {
        const { data } = error.response; // Error vindo do back está em data dentro de response
        addToast({
          type: 'error',
          title: data.message,
        });
        if (formSearchDateListItemRef.current?.getFieldRef(data.field)) {
          formSearchDateListItemRef.current?.getFieldRef(data.field).focus(); // Foca o campo que deu erro de acordo com o field retornado do back
        }

        return;
      }

      addToast({
        type: 'error',
        title: 'Ocorreu um erro interno',
      });
    }
  }, [
    addToast,
    handleListItemsPaginatedItems,
    groupCloseDays,
    groupFinancialAccounts,
    module,
  ]);
  // Fim handleSearchSubmit

  // handleSearchOnListLocal
  const handleSearchOnListLocal = useCallback(
    value => {
      if (value.find && listItemsOriginal) {
        const newListAllItems = listItemsOriginal.filter(
          item =>
            compareDateAsc(
              parseISO(convertDateToDB(value.find)),
              parseISO(convertDateToDB(item.close_date)),
            ) === 0,
        );
        if (
          !newListAllItems ||
          (newListAllItems && newListAllItems.length < 1)
        ) {
          handleListItemsPaginatedItems([]);
          setListAllItems([]);
          addToast({
            type: 'error',
            title: 'Nenhum registro encontrado',
          });
        } else {
          handleListItemsPaginatedItems(newListAllItems);
          setListAllItems(newListAllItems);
        }
      } else {
        handleSearchAllItems();
      }
    },
    [
      addToast,
      handleListItemsPaginatedItems,
      handleSearchAllItems,
      listItemsOriginal,
    ],
  );
  // Fim handleSearchOnListLocal

  const openCash = useCallback(async () => {
    try {
      setLoadModalConfirmationOpenCashFlow(false);
      setLoadModalWaitOpenCash(true);

      const response = await api.get('cash/cash-flow/open');
      const responseConfiguration = await api.get('/');

      handleConfiguration(responseConfiguration.data);

      addToast({
        type: 'success',
        title: `${response.data}`,
      });

      setLoadModalWaitOpenCash(false);
    } catch (error) {
      setLoadModalWaitOpenCash(false);

      // eslint-disable-next-line no-shadow
      if (error.response) {
        const { data } = error.response; // Error vindo do back está em data dentro de response
        addToast({
          type: 'error',
          title: data.message,
        });
        if (formSearchDateListItemRef.current?.getFieldRef(data.field)) {
          formSearchDateListItemRef.current?.getFieldRef(data.field).focus(); // Foca o campo que deu erro de acordo com o field retornado do back
        }

        return;
      }

      addToast({
        type: 'error',
        title: 'Ocorreu um erro interno',
      });
    }
  }, [addToast, handleConfiguration]);

  return (
    <>
      <Col
        md={12}
        style={{ height: '100%' }}
        className="d-flex flex-column bg-branco-puro-5bits p-4"
      >
        <Row className="d-flex flex-row justify-content-between no-gutters">
          <Col md={6}>
            <h1 className="text-left">{title}</h1>
          </Col>
          <Col
            md={4}
            className="d-flex flex-column justify-content-end align-items-top"
          >
            <Form
              ref={formSearchDateListItemRef}
              className="d-flex flex-row justify-content-end align-items-center"
              onSubmit={handleSearchOnListLocal}
            >
              {(permissions.read && (
                <>
                  <DatePicker
                    name="find"
                    placeholderText="Data Fechamento"
                    maxDate={new Date()}
                    className="w-auto"
                  />

                  <ButtonBootstrap type="submit" className="text-uppercase">
                    <BsSearch />
                  </ButtonBootstrap>
                </>
              )) || (
                <>
                  <Input
                    name="inputDisabled"
                    placeholder="Data Fechamento"
                    className="w-auto"
                    disabled
                  />

                  <ButtonBootstrap
                    type="button"
                    disabled
                    className="text-uppercase"
                    onClick={e => e.preventDefault()}
                  >
                    <BsSearch />
                  </ButtonBootstrap>
                </>
              )}
            </Form>
          </Col>

          <Col
            md={2}
            className="d-flex flex-row justify-content-end align-items-top"
          >
            {(configuration.cash_flow_open && (
              <>
                {(permissions.create && (
                  <ButtonLink className="w-auto" to={`/${module}/create`}>
                    Fechar Caixa
                  </ButtonLink>
                )) || (
                  <Button className="w-auto" disabled>
                    Fechar Caixa
                  </Button>
                )}
              </>
            )) || (
              <>
                {(permissions.create && (
                  <Button
                    className="w-auto"
                    onClick={() => setLoadModalConfirmationOpenCashFlow(true)}
                  >
                    Abrir Caixa
                  </Button>
                )) || (
                  <Button className="w-auto" onClick={e => e.preventDefault()}>
                    Abrir Caixa
                  </Button>
                )}
              </>
            )}
          </Col>
        </Row>

        <Row className="h-85 justify-content-center no-gutters overflow-auto mt-4">
          <Col className="h-100 overflow-auto" md={12}>
            {children}
          </Col>
        </Row>

        <Pagination
          listItems={listAllItems}
          onChangeListPaginatedItems={handleListItemsPaginatedItems}
        />
      </Col>

      <Modal visibleProp={loadModalConfirmationOpenCashFlow}>
        <div className="d-flex flex-column align-items-center w-100 bg-branco-puro-5bits p-4">
          <h1>Confirma Abertura de Caixa?</h1>
          <div className="d-flex flex-row align-items-center justify-content-center w-100">
            <Button className="w-auto mr-3" onClick={openCash}>
              Confirmar
            </Button>
            <Button
              name="closeModalOpenCashFlow"
              type="button"
              className="w-auto"
              onClick={() => setLoadModalConfirmationOpenCashFlow(false)}
            >
              Cancelar
            </Button>
          </div>
        </div>
      </Modal>

      <Modal visibleProp={loadModalWaitOpenCash}>
        <div className="d-flex flex-column align-items-center bg-branco-puro-5bits p-4">
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          <strong>Abrindo o Caixa...</strong>
        </div>
      </Modal>

      <Modal visibleProp={loadModalWait}>
        <div className="d-flex flex-column align-items-center bg-branco-puro-5bits p-4">
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          <strong>Pesquisando...</strong>
        </div>
      </Modal>
    </>
  );
};

export default ListingTemplate;
