/* eslint-disable no-param-reassign */
/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useEffect, useRef, InputHTMLAttributes } from 'react';

import { useField } from '@unform/core';

interface Props {
  name: string;
  label?: string;
  value?: string;
  classNameLabel?: string;
  fontSizeText?: string;
  sizeCheckbox?: number;
}

type InputProps = InputHTMLAttributes<HTMLInputElement> & Props;

export default function Checkbox({
  name,
  value,
  label,
  classNameLabel = 'text-uppercase',
  fontSizeText = '',
  sizeCheckbox,
  ...rest
}: InputProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  const defaultChecked = defaultValue === value;

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: ref => {
        return ref.current.checked;
      },
      clearValue: ref => {
        /**
         * If you want to change the default checked for false or true,
         * you can do so here. In this example, when resetting the form,
         * the checkbox goes back to its initial state.
         */
        ref.current.checked = defaultChecked;
      },
      setValue: (ref, val) => {
        ref.current.checked = val;
      },
    });
  }, [defaultValue, fieldName, registerField, defaultChecked]);

  return (
    <div
      className="d-flex align-items-center"
      style={{
        fontSize: `${fontSizeText}`,
      }}
    >
      <input
        style={{ transform: `scale(${sizeCheckbox})` }}
        defaultChecked={defaultChecked}
        ref={inputRef}
        value={value}
        type="checkbox"
        className="mr-2"
        id={fieldName}
        {...rest}
      />

      <label
        htmlFor={fieldName}
        key={fieldName}
        className={`my-0 ${classNameLabel}`}
      >
        {label}
      </label>

      {error && <span>{error}</span>}
    </div>
  );
}
