/* eslint-disable camelcase */
import React, { useCallback, useState, useRef } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { RiAccountCircleLine, RiCake2Line } from 'react-icons/ri';

import SimpleCreateAndUpdateTemplate from '../../components/Template/SimpleCreateAndUpdateTemplate';
import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';
import { useToast } from '../../context/ToastContext';

import Input from '../../components/Input';
import Button from '../../components/Button';

interface EmployeeFormDataDTO {
  name: string;
  nickname: string;
  dt_birth: string;
}

/**
 * [ ] Ver o history com o Daniel após cadastrar employee
 * [ ] Ver campo sold_amount
 */

const Employee: React.FC = () => {
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [disableButton, setDisableButton] = useState(false);

  // handleSubmit
  const handleSubmit = useCallback(
    async (dataForm: EmployeeFormDataDTO, { reset }) => {
      try {
        setDisableButton(true);

        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          nickname: Yup.string().required('Cognome obrigatório'),
          dt_birth: Yup.string().required('Data de aniversário obrigatório'),
        });

        await schema.validate(dataForm, {
          abortEarly: false,
        });

        await api.post('employees', dataForm);

        addToast({
          type: 'success',
          title: 'Cadastro realizado com sucesso',
        });

        reset();
        setDisableButton(false);
        // history.push('/home');
      } catch (error) {
        setDisableButton(false);

        // Verifica se o error é do Yup ou seja dos campos
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);

          return;
        }
        // eslint-disable-next-line no-shadow
        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: 'Erro no cadastro',
            description: data.message,
          });
          if (formRef.current?.getFieldRef(data.field)) {
            formRef.current?.getFieldRef(data.field).focus(); // Foca o campo que deu erro de acordo com o field retornado do back
          }

          return;
        }

        addToast({
          type: 'error',
          title: 'Ocorreu um erro interno',
        });
      }
    },
    [addToast],
  );
  // Fim handleSubmit

  return (
    <SimpleCreateAndUpdateTemplate title="Cadastro de Funcionários">
      <Form ref={formRef} className="mt-5" onSubmit={handleSubmit}>
        <Input
          type="text"
          name="name"
          label="Nome Completo"
          icon={RiAccountCircleLine}
          placeholder="Nome Completo"
          aria-label="Nome Completo"
          maxLength={45}
          className="mb-4"
        />
        <Input
          type="text"
          name="nickname"
          label="Cognome"
          icon={RiAccountCircleLine}
          placeholder="Cognome"
          aria-label="Cognome"
          maxLength={15}
          className="mb-4"
        />
        <Input
          type="text"
          name="dt_birth"
          label="Data Aniversário"
          icon={RiCake2Line}
          placeholder="Data Aniversário"
          aria-label="Data Aniversário"
          mask="99/99/9999"
          className="mb-4"
        />

        <Button
          name="submit"
          type="submit"
          className="mt-5 w-auto"
          disabled={disableButton}
        >
          Cadastrar
        </Button>
      </Form>
    </SimpleCreateAndUpdateTemplate>
  );
};

export default Employee;
