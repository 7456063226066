/* eslint-disable camelcase */
import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { parseISO, isAfter } from 'date-fns';

import ListingTemplate from '../../components/Template/ListingTemplate';
import Loader from '../../components/Loader';

import api from '../../services/api';

import { useToast } from '../../context/ToastContext';
import convertDateToBr from '../../utils/convertDateToBr';
import returnDateNow from '../../utils/returnDateNow';
import convertNumberFloatToBr from '../../utils/convertNumberFloatToBr';

import ModalCloseDiscountCoupon from './Modal/modalCloseDiscountCoupon';

import { IPermission } from '../../interfaces/IPermission';

interface PermissionDTO {
  permissions: IPermission;
}

interface DiscountCouponFormDTO {
  id: number;
  ticket_code: string;
  client_name: string;
  sale_header_id: number;
  amount: string;
  user: { name: string };
  is_active: boolean;
  expiration_date: string;
}

const ReadsDiscountCoupon: React.FC<PermissionDTO> = ({ permissions }) => {
  const [loading, setLoading] = useState(false);
  const [listDiscountCoupon, setListDiscountCoupon] = useState<
    DiscountCouponFormDTO[]
  >([]);

  const [
    listDiscountCouponPaginatedItems,
    setListDiscountCouponPaginatedItems,
  ] = useState<DiscountCouponFormDTO[]>([]);

  const [discountCouponModal, setDiscountCouponModal] = useState(
    {} as DiscountCouponFormDTO,
  );
  const [loadModalExcludCoupon, setLoadModalExcludeCoupon] = useState(false);

  const { addToast } = useToast();

  const handleListDiscountCoupon = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);
      const response = await api.get('discount-coupons');
      setListDiscountCoupon(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      // eslint-disable-next-line no-shadow
      if (error.response) {
        const { data } = error.response; // Error vindo do back está em data dentro de response
        addToast({
          type: 'error',
          title: data.message,
        });
        return;
      }
      addToast({
        type: 'error',
        title: 'Ocorreu um erro interno',
      });
    }
  }, [addToast]);

  useEffect(() => {
    handleListDiscountCoupon();
  }, [handleListDiscountCoupon]);

  const handleListDiscountCouponPaginatedItems = useCallback(newList => {
    setListDiscountCouponPaginatedItems(newList);
  }, []);

  const toggleLoadModalExcludeCoupon = useCallback(() => {
    setLoadModalExcludeCoupon(!loadModalExcludCoupon);
  }, [loadModalExcludCoupon]);

  return (
    <ListingTemplate
      title="Cupons de Desconto"
      module="discount-coupons"
      moduleButtonNew="discount-coupons"
      searchTheLocalObject
      listItemsProps={listDiscountCoupon}
      handleListItemsPaginatedItems={handleListDiscountCouponPaginatedItems}
      placeHolderProp="Nome do Cliente"
      permissions={permissions}
    >
      {(loading && <Loader />) || (
        <>
          <div className="text-primary text-uppercase font-weight-bold">
            Cupons Cadastrados:
            <span className="text-cinza-51-5bits text-uppercase ml-2 font-weight-bold">
              {listDiscountCoupon.length}
            </span>
          </div>
          <Table striped bordered hover size="sm" responsive="md">
            <thead className="text-center text-primary text-uppercase">
              <tr>
                <th className="font-weight-normal py-3" scope="col">
                  Cliente
                </th>
                <th className="font-weight-normal py-3" scope="col">
                  Cupom
                </th>
                <th className="font-weight-normal py-3" scope="col">
                  Venda
                </th>
                <th className="font-weight-normal py-3" scope="col">
                  Valor
                </th>
                <th className="font-weight-normal py-3" scope="col">
                  Usuário
                </th>
                <th className="font-weight-normal py-3" scope="col">
                  Expiração
                </th>
                <th className="font-weight-normal py-3" scope="col">
                  Status
                </th>
                <th className="font-weight-normal py-3" scope="col">
                  Ação
                </th>
              </tr>
            </thead>
            <tbody className="text-center">
              {listDiscountCouponPaginatedItems.map(
                (item: DiscountCouponFormDTO, index) => (
                  <tr key={index.toString()}>
                    <th scope="row" className="text-left">
                      {item.client_name}
                    </th>
                    <td>{item.ticket_code}</td>
                    <td>
                      <Link to={`/sales/sell/read/${item.sale_header_id}`}>
                        {item.sale_header_id}
                      </Link>
                    </td>
                    <td className="text-right">
                      {convertNumberFloatToBr(item.amount)}
                    </td>
                    <td>{item.user.name}</td>

                    {(item.expiration_date &&
                      isAfter(
                        parseISO(returnDateNow('yyyy-MM-dd')),
                        parseISO(item.expiration_date),
                      ) && (
                        <td className="bg-vermelho-suave-5bits">
                          {convertDateToBr(item.expiration_date)}
                        </td>
                      )) || (
                        <td>
                          {item.expiration_date &&
                            convertDateToBr(item.expiration_date)}
                        </td>
                      ) || <td />}

                    {(item.is_active === false && (
                      <td className="text-danger text-uppercase font-weight-bold">
                        Usado
                      </td>
                    )) || <td />}

                    <td>
                      {item.is_active === true && (
                        <>
                          {(permissions.update && (
                            <Link to={`/discount-coupons/read/${item.id}`}>
                              Detalhes
                            </Link>
                          )) || (
                            <span className="text-cinza-51-5bits">
                              Detalhes
                            </span>
                          )}
                          {' | '}
                        </>
                      )}
                      {(permissions.delete && (
                        <Link
                          to="/"
                          onClick={e => {
                            e.preventDefault();
                            toggleLoadModalExcludeCoupon();
                            setDiscountCouponModal(item);
                          }}
                        >
                          Excluir
                        </Link>
                      )) || (
                        <span className="text-cinza-51-5bits">Excluir</span>
                      )}
                    </td>
                  </tr>
                ),
              )}
            </tbody>
          </Table>

          <ModalCloseDiscountCoupon
            title="Excluir Este Cupom de Desconto?"
            discountCouponProp={discountCouponModal}
            loadModalProp={loadModalExcludCoupon}
            toggleLoadModalExcludeCoupon={toggleLoadModalExcludeCoupon}
            handleListDiscountCouponModal={handleListDiscountCoupon}
          />
        </>
      )}
    </ListingTemplate>
  );
};

export default ReadsDiscountCoupon;
