import styled, { css } from 'styled-components';

import Tooltip from '../../Tooltip';

interface ContainerProps {
  isFocused: boolean;
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  width: 100%;

  label {
    margin: 0;
  }

  & + div {
    color: var(--azul-5bits);
  }

  > div {
    input {
      font-style: italic;
    }

    span,
    label,
    input {
      ${props =>
        props.isFocused &&
        css`
          color: var(--laranja-5bits);
          border-color: var(--laranja-5bits);
          box-shadow: 0 0 0 0.2rem rgba(255, 132, 65, 0.1);
          background-color: var(--branco-puro-5bits);
        `}
      ${props =>
        props.isErrored &&
        css`
          border-color: var(--on-errored-input-border-color);
        `}
    }
  }
`;

export const ErrorStyle = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;
  margin-right: 24%;
  z-index: 99;

  svg {
    margin: 0;
    color: var(--on-errored-icon-color);
  }

  span {
    background: var(--on-errored-message-bg-color);
    color: var(--on-errored-message-text-color);

    &::before {
      border-color: var(--on-errored-message-border-color);
    }
  }
`;
