/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import React, { useState, useCallback, useRef, useEffect } from 'react';

import { BsSearch } from 'react-icons/bs';
import { TiFilter } from 'react-icons/ti';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import ButtonBootstrap from '../../../../components/ButtonBootstrap';
import DatePicker from '../../../../components/DatePicker';
import { useToast } from '../../../../context/ToastContext';
import InputSearchWithButton from '../../../../components/Input/InputSearchWithButton';
import ButtonLink from '../../../../components/ButtonLink';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import Checkbox from '../../../../components/Checkbox';
import Pagination from '../../../../components/Pagination';

import Modal from '../../../../components/Modal';

import getValidationErrors from '../../../../utils/getValidationErrors';

import convertDateToDB from '../../../../utils/convertDateToDB';

import api from '../../../../services/api';

import { IPermission } from '../../../../interfaces/IPermission';

import PayOff from '../components/PayOff';
// import ModalConfirmReceiveList from '../components/Modal/modalConfirmReceiveList';
import * as Styles from './styles';

// interface ReceiveFormDataDTO {
//   sale_header_id: string;
//   analytical_account_id: string;
//   payment_method_id: any;
//   client_id: any;
//   amount: string;
//   due_date: string;
// }

type IPaymentMethodSelected = {
  id: number;
  name: string;
};

interface ListingTemplateProps {
  title: string;
  module: string;
  moduleButtonNew?: string;
  moduleForSelectFind?: string;
  moduleSearchPerDate?: string;
  listItemsProps: Array<any>;
  // eslint-disable-next-line no-unused-vars
  handleListItemsPaginatedItems: (newList: Array<any>) => void;
  handleShowModalReceivesListProp: (
    paymentMethodSelected: IPaymentMethodSelected,
  ) => void;
  // handleSubmitListReceivesProp: () => void;
  // receiveListForSubmitProp: ReceiveHeaderFormDTO;
  selectedDebtAmountToBePaidOffProp: number;
  filterDateProp?: boolean;
  searchTheLocalObject?: boolean;
  searchPerId?: boolean;
  displayButtonNew?: boolean;
  placeHolderProp?: string;
  displaySearchArea?: boolean;
  permissions: IPermission;
}

const ListingTemplate: React.FC<ListingTemplateProps> = ({
  title,
  module,
  moduleButtonNew,
  moduleForSelectFind = '',
  moduleSearchPerDate = '',
  listItemsProps,
  handleListItemsPaginatedItems,
  handleShowModalReceivesListProp,
  // handleListReceivesSubmit,
  // handleSubmitListReceivesProp,
  // receiveListForSubmitProp,
  selectedDebtAmountToBePaidOffProp,
  filterDateProp = false,
  searchTheLocalObject = false,
  searchPerId = false,
  displayButtonNew = true,
  placeHolderProp = 'Pesquisar',
  displaySearchArea = true,
  children,
  permissions = {
    create: false,
    read: false,
    update: false,
    delete: false,
  } as IPermission,
}) => {
  const formSearchListItemRef = useRef<FormHandles>(null);
  const formSearchDateListItemRef = useRef<FormHandles>(null);
  const [listAllItems, setListAllItems] = useState<any[]>([]);
  const [listItemsOriginal, setListItemsOriginal] = useState<any[]>([]);

  const [filterDate, setFilterDate] = useState(false);
  const [loadModalWait, setLoadModalWait] = useState(false);
  const [isCheckIncludePaidToo, setIsCheckIncludePaidToo] = useState(false);
  const [visibleButtonListAll, setVisibleButtonListAll] = useState(false);
  const { addToast } = useToast();

  // toggleAddIsCheckIncludePaidToo
  const toggleAddIsCheckIncludePaidToo = useCallback(() => {
    setIsCheckIncludePaidToo(!isCheckIncludePaidToo);
  }, [isCheckIncludePaidToo]);
  // End toggleAddIsCheckIncludePaidToo

  useEffect(() => {
    const getListAllItems = (): void => {
      setListAllItems(listItemsProps);
      setListItemsOriginal(listItemsProps);
      setFilterDate(filterDateProp);
    };
    getListAllItems();
  }, [filterDateProp, listItemsProps]);

  const toggleFilterDate = useCallback(() => {
    setFilterDate(!filterDate);
    setIsCheckIncludePaidToo(false);
    if (formSearchDateListItemRef.current)
      formSearchDateListItemRef.current.reset();
  }, [filterDate]);

  const filterPerId = useCallback((obj: any, find) => {
    return obj.id === parseInt(find, 10);
  }, []);

  const filterPerItem = useCallback((obj: any, find) => {
    return obj.client.name.includes(find.toUpperCase());
  }, []);

  // handleListAll
  const handleListAll = useCallback(async () => {
    try {
      setLoadModalWait(true);
      formSearchListItemRef.current?.setErrors({});

      let response: any = [];
      response = await api.get('sales/receive');
      handleListItemsPaginatedItems(response.data);
      setListAllItems(response.data);
      setListItemsOriginal(response.data);

      if (formSearchDateListItemRef.current)
        formSearchDateListItemRef.current.reset();

      setLoadModalWait(false);
      setVisibleButtonListAll(false);
    } catch (error) {
      setLoadModalWait(false);
      setVisibleButtonListAll(true);
      // Verifica se o error é do Yup ou seja dos campos
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formSearchListItemRef.current?.setErrors(errors);

        return;
      }

      // eslint-disable-next-line no-shadow
      if (error.response) {
        const { data } = error.response; // Error vindo do back está em data dentro de response
        addToast({
          type: 'error',
          title: data.message,
        });
        if (formSearchListItemRef.current?.getFieldRef(data.field)) {
          formSearchListItemRef.current?.getFieldRef(data.field).focus(); // Foca o campo que deu erro de acordo com o field retornado do back
        }

        return;
      }

      addToast({
        type: 'error',
        title: `Ocorreu um erro internoAA${error}`,
      });
    }
  }, [handleListItemsPaginatedItems, addToast]);
  // End handleListAll

  // handleSearchSubmit
  const handleSearchSubmit = useCallback(
    async (dataForm: any, { reset }) => {
      try {
        setLoadModalWait(true);
        formSearchListItemRef.current?.setErrors({});

        let response: any = [];
        if (dataForm.find === '') {
          response = await api.get(`${module}/undefined`);
          handleListItemsPaginatedItems(response.data);
          setListAllItems(response.data);
          setListItemsOriginal(response.data);

          if (filterDate) {
            formSearchDateListItemRef.current?.setErrors({});
            formSearchDateListItemRef.current?.reset();
            setIsCheckIncludePaidToo(false);
          }
        } else if (searchTheLocalObject) {
          if (searchPerId) {
            response = listItemsOriginal.filter(obj =>
              filterPerId(obj, dataForm.find),
            );
          } else {
            response = listItemsOriginal.filter(obj =>
              filterPerItem(obj, dataForm.find),
            );
          }
          handleListItemsPaginatedItems(response);
          setListAllItems(response);
        } else {
          response = await api.get(`${module}/${dataForm.find}`);

          handleListItemsPaginatedItems(response.data);
          setListAllItems(response.data);
          setListItemsOriginal(response.data);
        }

        reset();

        setLoadModalWait(false);
        setVisibleButtonListAll(true);
      } catch (error) {
        setLoadModalWait(false);
        setVisibleButtonListAll(false);
        // Verifica se o error é do Yup ou seja dos campos
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formSearchListItemRef.current?.setErrors(errors);

          return;
        }

        // eslint-disable-next-line no-shadow
        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: data.message,
          });
          if (formSearchListItemRef.current?.getFieldRef(data.field)) {
            formSearchListItemRef.current?.getFieldRef(data.field).focus(); // Foca o campo que deu erro de acordo com o field retornado do back
          }

          return;
        }

        addToast({
          type: 'error',
          title: `Ocorreu um erro internoAA${error}`,
        });
      }
    },
    [
      searchTheLocalObject,
      module,
      handleListItemsPaginatedItems,
      filterDate,
      searchPerId,
      listItemsOriginal,
      filterPerId,
      filterPerItem,
      addToast,
    ],
  );
  // Fim handleSearchSubmit

  // handleSearchDateSubmit
  const handleSearchDateSubmit = useCallback(
    async (dataFormFilter: any) => {
      try {
        setLoadModalWait(true);
        formSearchDateListItemRef.current?.setErrors({});

        const schema = Yup.object().shape({
          start_date: Yup.string()
            .required('Data inicial obrigatória')
            .nullable(),
          end_date: Yup.string().required('Data final obrigatória').nullable(),
        });

        await schema.validate(dataFormFilter, {
          abortEarly: false,
        });

        const newData = {
          ...dataFormFilter,
          start_date: convertDateToDB(dataFormFilter.start_date),
          end_date: convertDateToDB(dataFormFilter.end_date),
          include_paid_too: dataFormFilter.includePaidToo === 'true',
        };

        delete newData.includePaidToo;

        const clientSearch = formSearchListItemRef.current
          ? formSearchListItemRef.current.getFieldValue('find')
          : '';

        let urlString = '';
        if (clientSearch === '') {
          urlString = `${moduleSearchPerDate}/undefined/${newData.start_date}/${newData.end_date}/${newData.include_paid_too}`;
        } else {
          urlString = `${moduleSearchPerDate}/${clientSearch}/${newData.start_date}/${newData.end_date}/${newData.include_paid_too}`;
        }

        const response = await api.get(urlString);

        handleListItemsPaginatedItems(response.data);
        setListAllItems(response.data);
        setListItemsOriginal(response.data);

        setLoadModalWait(false);
        setVisibleButtonListAll(true);
        // reset();
      } catch (error) {
        setLoadModalWait(false);
        setVisibleButtonListAll(false);
        // Verifica se o error é do Yup ou seja dos campos
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formSearchDateListItemRef.current?.setErrors(errors);

          return;
        }

        // eslint-disable-next-line no-shadow
        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: data.message,
          });

          return;
        }

        addToast({
          type: 'error',
          title: `Ocorreu um erro interno`,
        });
      }
    },
    [addToast, handleListItemsPaginatedItems, moduleSearchPerDate],
  );
  // Fim handleSearchDateSubmit

  return (
    <>
      <Col
        md={12}
        style={{ height: '100%' }}
        className="d-flex flex-column bg-branco-puro-5bits p-4"
      >
        <Row className="d-flex flex-row justify-content-between no-gutters">
          <Col md={3}>
            <h1 className="text-left">{title}</h1>
          </Col>
          <Col
            md={8}
            className="d-flex flex-row justify-content-end align-items-top"
          >
            <div className="w-35">
              {visibleButtonListAll && filterDate === false && (
                <ButtonBootstrap
                  type="button"
                  name="listAll"
                  className="text-uppercase"
                  onClick={handleListAll}
                >
                  Listar Todos
                </ButtonBootstrap>
              )}
            </div>
            <div className="mr-3">
              <TiFilter size={36} onClick={toggleFilterDate} />
            </div>
            <Form
              ref={formSearchListItemRef}
              className="w-100 mb-3"
              onSubmit={handleSearchSubmit}
            >
              {displaySearchArea && (
                <>
                  {!moduleForSelectFind && (
                    <>
                      {(permissions.read && (
                        <div className="d-flex justify-content-end">
                          <InputSearchWithButton
                            type="text"
                            name="find"
                            classNameInputGroupTextAndFormControl="bg-transparent"
                            icon={BsSearch}
                            placeholder={placeHolderProp}
                            aria-label={`Pesquisar por ${placeHolderProp}`}
                            maxLength={45}
                            disabledButtonProp={filterDate}
                          />
                        </div>
                      )) || (
                        <InputSearchWithButton
                          type="text"
                          name="findDisabled"
                          classNameInputGroupTextAndFormControl="bg-transparent"
                          icon={BsSearch}
                          placeholder={placeHolderProp}
                          aria-label={`Pesquisar por ${placeHolderProp}`}
                          maxLength={45}
                          disabled
                          disabledButtonProp
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </Form>
          </Col>
          {displayButtonNew && (
            <Col
              md={1}
              className="d-flex flex-row justify-content-end align-items-top"
            >
              {(permissions.create && (
                <ButtonLink
                  className="w-auto"
                  to={`/${moduleButtonNew || module}/create`}
                >
                  Novo
                </ButtonLink>
              )) || (
                <Button className="w-auto" disabled>
                  Novo
                </Button>
              )}
            </Col>
          )}
        </Row>

        {filterDate && (
          <Row className="no-gutters">
            <Col
              md={12}
              className="d-flex flex-row justify-content-center align-items-center"
            >
              <Form
                ref={formSearchDateListItemRef}
                className="d-flex flex-row justify-content-center align-items-end px-0 py-2 bg-branco-gelo-5bits w-100"
                onSubmit={handleSearchDateSubmit}
              >
                {(permissions.read && (
                  <div className="d-flex justify-content-center align-items-end">
                    <span className="d-flex justify-content-end text-uppercase w-35 mr-2">
                      Vencimento de:
                    </span>
                    <DatePicker name="start_date" className="w-30" />
                    <span className="text-uppercase mx-2">até</span>
                    <DatePicker name="end_date" className="w-30 mr-2" />
                    <Checkbox
                      name="includePaidToo"
                      propChecked={isCheckIncludePaidToo}
                      onClick={toggleAddIsCheckIncludePaidToo}
                      className="p-0 mr-3 w-auto text-nowrap"
                    >
                      Incluir Quitados
                    </Checkbox>

                    <ButtonBootstrap
                      type="submit"
                      className="text-uppercase mr-3"
                    >
                      Filtrar
                    </ButtonBootstrap>

                    {visibleButtonListAll && filterDate && (
                      <div className="w-35">
                        <ButtonBootstrap
                          type="button"
                          name="listAll"
                          className="text-uppercase"
                          onClick={handleListAll}
                        >
                          Limpar Filtragem
                        </ButtonBootstrap>
                      </div>
                    )}
                  </div>
                )) || (
                  <>
                    <span className="d-flex justify-content-end text-uppercase w-35 mr-2">
                      Vencimento de:
                    </span>
                    <Input name="inputStart" className="w-50" disabled />
                    <span className="text-uppercase mx-2">até</span>
                    <Input name="inputEnd" className="w-50 mr-2" disabled />
                    <Checkbox
                      name="include"
                      propChecked={false}
                      className="p-0 mr-3 w-auto text-nowrap"
                      disabled
                    >
                      Incluir Quitados
                    </Checkbox>

                    <ButtonBootstrap
                      type="button"
                      className="text-uppercase"
                      disabled
                      onClick={e => e.preventDefault()}
                    >
                      Filtrar
                    </ButtonBootstrap>
                  </>
                )}
              </Form>
            </Col>
          </Row>
        )}

        <Row className="h-85 justify-content-center no-gutters overflow-auto mt-4">
          <Col className="h-100 overflow-auto" md={12}>
            {children}
          </Col>
        </Row>
        <Styles.TogglePaginationAndPayOff
          showPagination={selectedDebtAmountToBePaidOffProp <= 0}
        >
          <Pagination
            listItems={listAllItems}
            onChangeListPaginatedItems={handleListItemsPaginatedItems}
          />
          <Form
            onSubmit={() => {
              console.log('sub');
            }}
          >
            <PayOff
              selectedDebtAmountToBePaidOffProp={
                selectedDebtAmountToBePaidOffProp
              }
              handleShowModalReceivesListProp={handleShowModalReceivesListProp}
            />
          </Form>
        </Styles.TogglePaginationAndPayOff>
      </Col>

      <Modal visibleProp={loadModalWait}>
        <div className="d-flex flex-column align-items-center bg-branco-puro-5bits p-4">
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          <strong>Pesquisando...</strong>
        </div>
      </Modal>
    </>
  );
};

export default ListingTemplate;
