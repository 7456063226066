import React from 'react';
import { Switch } from 'react-router-dom';

import PrivateRoute from './PrivateRoutes';

import { useAuth } from '../context/AuthContext';
import findPermission from '../utils/findPermission';

import NotAuthorizedPage from '../pages/NotAuthorizedPage';
import CreateDiscountCoupon from '../pages/DiscountCoupon/createDiscountCoupon';
import ListDiscountCoupon from '../pages/DiscountCoupon/listDiscountCoupon';
import UpdateDiscountCoupon from '../pages/DiscountCoupon/updateDiscountCoupon';

const DiscountCouponRoutes: React.FC = () => {
  const { permission } = useAuth();
  const discountCouponsPermissions = findPermission(
    'discount-coupons',
    permission,
  ); // menus_id discount-coupons = 19

  return (
    <Switch>
      <PrivateRoute
        path="*/create"
        component={
          Object.keys(discountCouponsPermissions).length !== 0 &&
          discountCouponsPermissions.create
            ? CreateDiscountCoupon
            : NotAuthorizedPage
        }
      />
      <PrivateRoute
        path="*/list"
        render={props => (
          <ListDiscountCoupon
            {...props}
            permissions={discountCouponsPermissions}
          />
        )}
      />
      <PrivateRoute
        path="*/read/:id"
        component={
          Object.keys(discountCouponsPermissions).length !== 0 &&
          discountCouponsPermissions.create
            ? UpdateDiscountCoupon
            : NotAuthorizedPage
        }
      />
    </Switch>
  );
};

export default DiscountCouponRoutes;
