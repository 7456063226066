/* eslint-disable camelcase */
import React, { useCallback, useRef, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { FiEdit } from 'react-icons/fi';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import SimpleCreateAndUpdateTemplate from '../../components/Template/SimpleCreateAndUpdateTemplate';
import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';
import { useToast } from '../../context/ToastContext';

import convertNumberFloatToDB from '../../utils/convertNumberFloatToDB';

import Input from '../../components/Input';
import CurrencyInput from '../../components/Input/InputCurrency';
import Checkbox from '../../components/Checkbox';
import Button from '../../components/Button';
import { useAuth } from '../../context/AuthContext';

interface ProductFormDataDTO {
  id: string;
  name: string;
  price: string;
  size: string;
  color: string;
  stock?: string;
  min_stock: string;
  is_active: boolean;
  is_activeText?: string;
  barcode: string;
}

/**
 * [ ] Ver o history com o Daniel após alterar Product
 * [ ] Ver campo sold_amount
 * [ ] Ver se apenas administrador poderá alterar o is_active
 */
const UpdateProduct: React.FC<ProductFormDataDTO> = () => {
  const { configuration }: any = useAuth();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { id }: any = useParams();
  const [product, setProduct] = useState<ProductFormDataDTO>(
    {} as ProductFormDataDTO,
  );
  const [disableButton, setDisableButton] = useState(false);
  const currentUserLogged = JSON.parse(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    sessionStorage.getItem('@Stock5b:user')!,
  );

  // eslint-disable-next-line no-bitwise

  useEffect(() => {
    const getProduct = async (): Promise<void> => {
      try {
        const response = await api.get(`products/${id}`);
        setProduct(response.data);
        /**
         * Preenche os campos do formulário. Sem esta linha os inputs com
         * máscara não são preenchidos.
         */
        formRef.current?.setData(response.data);
      } catch (error) {
        // eslint-disable-next-line no-shadow
        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: data.message,
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Ocorreu um erro interno',
        });
      }
    };
    getProduct();
  }, [addToast, id]);

  // handleSubmit
  const handleSubmit = useCallback(
    async (dataForm: ProductFormDataDTO, { reset }) => {
      try {
        setDisableButton(true);
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          price: Yup.string()
            .test('price', 'Preço obrigatório', () => {
              return convertNumberFloatToDB(dataForm.price.toString()) > 0;
            })
            .required('Preço obrigatório'),
          size: Yup.string().required('Tamanho obrigatório'),
          color: Yup.string().required('Cor obrigatório'),
        });

        await schema.validate(dataForm, {
          abortEarly: false,
        });

        /**
         * Cria um novo array com o is_admin com o
         * valor em booleano que será salvo no bd
         */
        let isActiveBoolean;

        if (currentUserLogged.is_admin) {
          isActiveBoolean = dataForm.is_activeText === 'true';
        } else {
          isActiveBoolean = product.is_active;
        }

        const newData = {
          ...dataForm,
          price: convertNumberFloatToDB(dataForm.price.toString()),
          is_active: isActiveBoolean,
        };

        delete newData.is_activeText;

        await api.put('products', newData);

        addToast({
          type: 'success',
          title: 'Alteração realizada com sucesso',
        });

        reset();
        setDisableButton(false);
        history.push('/products/list');
      } catch (error) {
        setDisableButton(false);

        // Verifica se o error é do Yup ou seja dos campos
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);

          return;
        }
        // eslint-disable-next-line no-shadow
        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: 'Erro na alteração do cadastro',
            description: data.message,
          });
          formRef.current?.getFieldRef(data.field).focus(); // Foca o campo que deu erro de acordo com o field retornado do back

          return;
        }
        addToast({
          type: 'error',
          title: 'Ocorreu um erro interno',
        });
      }
    },
    [addToast, product, currentUserLogged.is_admin, history],
  );
  // Fim handleSubmit

  return (
    <SimpleCreateAndUpdateTemplate
      title="Atualização de Produtos"
      numberColContent={12}
    >
      <Form
        ref={formRef}
        initialData={product}
        className="mt-5"
        onSubmit={handleSubmit}
      >
        <Row className="flex-row overflow-auto m-0">
          <Col md={12}>
            <Input type="hidden" name="id" isHidden />
            <div className="text-primary text-uppercase font-weight-bold">
              Código do produto:
              <span className="text-cinza-51-5bits text-uppercase ml-2 font-weight-bold">
                {product.id}
              </span>
            </div>
          </Col>
          <Col md={6}>
            <Input
              type="text"
              name="name"
              label="Nome"
              icon={FiEdit}
              placeholder="Nome"
              maxLength={60}
              className="mb-2"
            />
          </Col>
          <Col md={6}>
            <CurrencyInput
              type="text"
              name="price"
              label="Preço"
              icon="R$"
              placeholder="Preço"
              className="mb-2"
            />
          </Col>
          <Col md={6}>
            <Input
              type="text"
              name="size"
              label="Tamanho"
              icon={FiEdit}
              placeholder="Tamanho"
              maxLength={45}
              className="mb-2"
            />
          </Col>
          <Col md={6}>
            <Input
              type="text"
              name="color"
              label="Cor"
              icon={FiEdit}
              placeholder="Cor"
              maxLength={30}
              className="mb-2"
            />
          </Col>
          {configuration.is_update_stock && (
            <Col md={6}>
              <Input
                type="number"
                name="stock"
                label="Estoque"
                icon={FiEdit}
                placeholder="Estoque"
                className="mb-2"
              />
            </Col>
          )}
          <Col md={6}>
            <Input
              type="number"
              name="min_stock"
              label="Estoque Mínimo"
              icon={FiEdit}
              placeholder="Estoque Mínimo"
              className="mb-2"
            />
          </Col>
          <Col md={6}>
            <Input
              type="text"
              name="barcode"
              label="Código de Barras"
              icon={FiEdit}
              placeholder="Código de Barras"
              maxLength={60}
              className="mb-2"
            />
          </Col>
          <Col md={6}>
            {currentUserLogged.is_admin === true && (
              <Checkbox
                name="is_activeText"
                className="mb-2"
                propChecked={product?.is_active}
              >
                Produto Ativo
              </Checkbox>
            )}
          </Col>
        </Row>
        <Row className="m-0">
          <Col md={6} className="m-auto">
            <Button
              name="submit"
              type="submit"
              className="mt-5 w-auto"
              disabled={disableButton}
            >
              Salvar
            </Button>
          </Col>
        </Row>
      </Form>
    </SimpleCreateAndUpdateTemplate>
  );
};

export default UpdateProduct;
