/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { useAuth } from '../../context/AuthContext';

import Routes from '../../routes';
import Header from '../Partials/Header';
import MenuSide from '../MenuDropDown';

import Logo from '../Logo';

// Chama o css para os componentes do react-Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../App.scss';

const Template: React.FC = () => {
  const { user }: any = useAuth();

  return (
    <Container fluid className="vh-100 p-0 no-gutters">
      {(!user && <Routes />) || (
        <>
          <Row noGutters style={{ height: '12vh' }}>
            <Col xs={10} sm={10} md={9} lg={10} className="order-md-2">
              <Header />
            </Col>
            <Col
              xs={2}
              sm={2}
              md={3}
              lg={2}
              className="order-md-1"
              style={{ zIndex: 5001 }}
            >
              <Logo />
            </Col>
          </Row>
          <Row noGutters style={{ height: '88vh' }}>
            <MenuSide />
            <Col
              md={9}
              lg={10}
              className="d-flex align-items-center justify-content-center h-100"
            >
              <Row noGutters className="d-block overflow-auto w-100 h-100">
                <Routes />
              </Row>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default Template;
