/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles, Scope } from '@unform/core';

import * as Yup from 'yup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import api from '../../../services/api';
import getValidationErrors from '../../../utils/getValidationErrors';
import { useToast } from '../../../context/ToastContext';

import ComplexCancelSaleUpdateTemplate from './components/Template/ComplexCancelSaleUpdateTemplate';

import HeaderSalesUpdate from './PartialsUpdate/HeaderSellUpdate/headerSellUpdate';
import BodySalesUpdate from './PartialsUpdate/BodySellUpdate/bodySellUpdate';
import FooterSalesUpdate from './PartialsUpdate/FooterSellUpdate/footerSellUpdate';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import Input from '../../../components/Input';

interface ClientFormDTO {
  id: number;
  name: string;
  dependent_name: string;
}

interface EmployeeFormDTO {
  id: number;
  name: string;
}

interface SaleBodyItemFormDTO {
  id: number;
  sale_header_id: number;
  product_id: string;
  quantity: number;
  price: string;
  discount: string;
  name: string;
  size: string;
  color: string;
}

interface IDataForm {
  id: string;
}

const CreateCancelSale: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { addToast } = useToast();

  const [sale, setSale] = useState<any>({} as any);
  // eslint-disable-next-line no-unused-vars
  const [client, setClient] = useState<ClientFormDTO>({} as ClientFormDTO);
  // eslint-disable-next-line no-unused-vars
  const [employee, setEmployee] = useState<EmployeeFormDTO>(
    {} as EmployeeFormDTO,
  );

  const [loadModalConfirmationSale, setLoadModalConfirmationSale] = useState(
    false,
  );

  const toggleLoadModalConfirmationSale = useCallback(() => {
    setLoadModalConfirmationSale(!loadModalConfirmationSale);
  }, [loadModalConfirmationSale]);

  // handleSubmit
  const handleSubmit = useCallback(
    async (dataForm: IDataForm, { reset }) => {
      try {
        formRef.current?.setErrors({});

        /** A função mista do Yup pode corresponder a todos os tipos */
        const schema = Yup.object().shape({
          id: Yup.string().required('Venda é obrigatória'),
        });

        await schema.validate(dataForm, {
          abortEarly: false,
        });

        const newItem = {
          id_sale: parseInt(dataForm.id, 10),
        };

        await api.post('sales/cancel', newItem);

        addToast({
          type: 'success',
          title: 'Cancelamento realizado com sucesso',
        });

        reset();

        history.push('/sales/cancel/list');
      } catch (error) {
        // Verifica se o error é do Yup ou seja dos campos
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);

          return;
        }
        // eslint-disable-next-line no-shadow
        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: 'Erro no cancelamento',
            description: data.message,
          });
          if (formRef.current?.getFieldRef(data.field)) {
            formRef.current?.getFieldRef(data.field).focus(); // Foca o campo que deu erro de acordo com o field retornado do back
          }

          return;
        }

        addToast({
          type: 'error',
          title: 'Ocorreu um erro interno',
          description: error,
        });
      }
    },
    [addToast, history],
  );
  // Fim handleSubmit

  const handleUpdateSale = useCallback(newSale => {
    setSale(newSale);
  }, []);

  // Seta os valores no Form. Se não setar com o setData os campos como currency e mascara não recebem o valor
  useEffect(() => {
    formRef.current?.setData(sale);
  }, [sale]);

  return (
    <ComplexCancelSaleUpdateTemplate
      title={`Cancelamento de venda${
        sale && Object.keys(sale).length > 0 ? `: ${sale.id}` : ''
      }`}
      handleSearch={handleUpdateSale}
    >
      {sale && Object.keys(sale).length > 0 && (
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={sale}
          className="h-100"
        >
          <Input type="hidden" name="id" isHidden />
          <Row noGutters className="w-100 h-100">
            <Col md={12} className="d-md-flex flex-row mt-3 p-0 h-30">
              <HeaderSalesUpdate />
            </Col>
            <Col
              md={12}
              style={{ maxHeight: '60%' }}
              className="mt-2 mb-2 border border-right-0 border-left-0 border-cinza-claro-5bits overflow-auto"
            >
              <Row
                noGutters
                className="w-100 h-100 justify-content-center row no-gutters overflow-auto"
              >
                <Col md={12} className="p-md-2">
                  {sale.sales_body && (
                    <>
                      {sale.sales_body.map(
                        (item: SaleBodyItemFormDTO, index: number) => (
                          <Row
                            key={index.toString()}
                            noGutters
                            className="w-100"
                          >
                            <Scope
                              key={index.toString()}
                              path={`sales_body[${index}]`}
                            >
                              <BodySalesUpdate index={index} />
                            </Scope>
                          </Row>
                        ),
                      )}
                    </>
                  )}
                </Col>
              </Row>
            </Col>
            <Col md={12} className="d-md-flex flex-row align-items-end">
              <Row noGutters className="w-100 h-auto">
                <FooterSalesUpdate
                  saleId={sale.id}
                  paymentMethodsComplete={sale.bills_receive_header}
                />
              </Row>
            </Col>
            <Col md={12} className="d-md-flex flex-row align-items-end">
              <Row noGutters className="w-100 h-auto">
                <Button
                  name="cancelarVendaModal"
                  type="button"
                  className="w-25"
                  fontSizeButton="12px"
                  onClick={toggleLoadModalConfirmationSale}
                >
                  Cancelar Venda
                </Button>
              </Row>
            </Col>
          </Row>

          <Modal visibleProp={loadModalConfirmationSale}>
            <div className="d-flex flex-column align-items-center w-100 bg-branco-puro-5bits p-4">
              <h1>Confirmar Cancelamento da Venda</h1>
              <div className="d-flex flex-row align-items-center w-100">
                <Button
                  name="submit"
                  type="submit"
                  className="w-100 mr-2"
                  fontSizeButton="12px"
                  onClick={() => {
                    setTimeout(() => {
                      toggleLoadModalConfirmationSale();
                    }, 500);
                  }}
                >
                  Confirmar Cancelamento
                </Button>
                <Button
                  name="closeModalSale"
                  type="button"
                  className="w-50"
                  fontSizeButton="12px"
                  onClick={toggleLoadModalConfirmationSale}
                >
                  Cancelar
                </Button>
              </div>
            </div>
          </Modal>
        </Form>
      )}
    </ComplexCancelSaleUpdateTemplate>
  );
};

export default CreateCancelSale;
