import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin-top: 15px;
`;

export const Content = styled.div`
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  gap: 28px;
`;

export const SelectContainer = styled.div`
  width: 196px;
  display: flex;
`;

export const PayOffAmountText = styled.div`
  display: flex;
  align-items: center;
  span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.02em;
    text-transform: uppercase;

    color: #0f4c82;

    strong {
      font-size: 18px;
      line-height: 24px;
      color: #ff8441;
    }
  }
`;

type TProps = {
  buttonDisabledProp: boolean;
};

export const ButtonContainer = styled.div<TProps>`
  button {
    width: 100px;
    height: 38px;
    border-radius: 4px;

    border-radius: 4px;

    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 13px;
    letter-spacing: 0.05em;
    text-transform: uppercase;

    color: var(--azul-5bits);

    ${props =>
      (props.buttonDisabledProp &&
        css`
          cursor: not-allowed;
          color: var(--branco-puro-5bits);
          background: var(--cinza-claro-5bits);
          border: 1px solid var(--cinza-claro-5bits);

          &:hover {
            color: var(--branco-puro-5bits);
            border-color: var(--cinza-51-5bits);
            background-color: rgba(81, 81, 81, 0.5);
          }
        `) ||
      css`
        cursor: pointer;
        background: var(--branco-puro-5bits);
        border: 1px solid var(--azul-5bits);

        &:hover {
          color: var(--branco-puro-5bits);
          border-color: var(--laranja-5bits);
          background-color: var(--laranja-5bits);
        }
      `}
  }
`;
