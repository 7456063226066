/* eslint-disable camelcase */
import React, { useCallback, useState, useRef } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
// import { RiAccountCircleLine, RiCake2Line } from 'react-icons/ri';

import SimpleCreateAndUpdateTemplate from '../../components/Template/SimpleCreateAndUpdateTemplate';
import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';
import { useToast } from '../../context/ToastContext';

import Input from '../../components/Input';
import Button from '../../components/Button';

import { IPermission } from '../../interfaces/IPermission';

interface PermissionDTO {
  permissions: IPermission;
}

interface InputFormDataDTO {
  id: string;
  stock_start: string;
}

interface ProductFormDataDTO {
  id: string;
  name: string;
  price: string;
  size: string;
  color: string;
  stock: string;
  min_stock: string;
  is_active: boolean;
  is_activeText: string;
  barcode: string;
}

const InputInitialStock: React.FC<PermissionDTO> = ({ permissions }) => {
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const formSearchRef = useRef<FormHandles>(null);
  const [disableButton, setDisableButton] = useState(false);

  const [product, setProduct] = useState<ProductFormDataDTO>(
    {} as ProductFormDataDTO,
  );

  // handleSubmit
  const handleSearchSubmit = useCallback(
    async (dataForm: InputFormDataDTO, { reset }) => {
      try {
        setDisableButton(true);

        formSearchRef.current?.setErrors({});
        const schema = Yup.object().shape({
          id: Yup.string().required(' '),
        });

        await schema.validate(dataForm, {
          abortEarly: false,
        });

        const response = await api.get(`products/stock-start/${dataForm.id}`);
        setProduct(response.data);
        /*
        addToast({
          type: 'success',
          title: 'Cadastro realizado com sucesso',
        });
        */
        reset();
        setDisableButton(false);
        // history.push('/home');
      } catch (error) {
        setDisableButton(false);

        // Verifica se o error é do Yup ou seja dos campos
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formSearchRef.current?.setErrors(errors);

          return;
        }
        // eslint-disable-next-line no-shadow
        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: 'Erro na pesquisa',
            description: data.message,
          });
          if (formSearchRef.current?.getFieldRef(data.field)) {
            formSearchRef.current?.getFieldRef(data.field).focus(); // Foca o campo que deu erro de acordo com o field retornado do back
          }

          return;
        }

        addToast({
          type: 'error',
          title: 'Ocorreu um erro interno',
        });
      }
    },
    [addToast],
  );
  // Fim handleSubmit

  // handleSubmit
  const handleSubmit = useCallback(
    async (dataForm: InputFormDataDTO, { reset }) => {
      try {
        setDisableButton(true);

        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          id: Yup.string().required('Código obrigatório!'),
          stock_start: Yup.string().required('Estoque obrigatório'),
        });

        await schema.validate(dataForm, {
          abortEarly: false,
        });

        await api.put('products/stock-start', dataForm);

        addToast({
          type: 'success',
          title: 'Cadastro realizado com sucesso',
        });

        reset();
        setProduct({} as ProductFormDataDTO);
        setDisableButton(false);
        // history.push('/home');
      } catch (error) {
        setDisableButton(false);

        // Verifica se o error é do Yup ou seja dos campos
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);

          return;
        }
        // eslint-disable-next-line no-shadow
        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: 'Erro no cadastro',
            description: data.message,
          });
          if (formRef.current?.getFieldRef(data.field)) {
            formRef.current?.getFieldRef(data.field).focus(); // Foca o campo que deu erro de acordo com o field retornado do back
          }

          return;
        }

        addToast({
          type: 'error',
          title: 'Ocorreu um erro interno',
        });
      }
    },
    [addToast],
  );
  // Fim handleSubmit

  return (
    <SimpleCreateAndUpdateTemplate title="Lançamento de estoque inicial">
      <Form
        ref={formSearchRef}
        className="mt-4 mb-4 d-flex flex-row"
        onSubmit={handleSearchSubmit}
      >
        <Input
          type="text"
          name="id"
          className="w-100 mr-2"
          aria-label="Código do Produto"
          disabled={permissions.read === false}
        />
        {(permissions.read && (
          <Button
            name="submit"
            type="submit"
            className="w-50"
            disabled={disableButton}
          >
            Buscar
          </Button>
        )) || (
          <Button
            name="btDisabled"
            type="button"
            className="w-50"
            onClick={e => e.preventDefault()}
            disabled
          >
            Buscar
          </Button>
        )}
      </Form>
      <hr />
      <Form
        ref={formRef}
        initialData={product}
        className="mt-2"
        onSubmit={handleSubmit}
      >
        {(permissions.read && (
          <>
            <Input type="hidden" name="id" isHidden />

            <div className="text-primary text-uppercase mb-2">
              Código do produto:
              <span className="text-cinza-51-5bits text-uppercase ml-2">
                {product.id}
              </span>
            </div>
            <div className="text-primary text-uppercase mb-2">
              Nome:
              <span className="text-cinza-51-5bits text-uppercase ml-2">
                {product.name}
              </span>
            </div>
            <div className="text-primary text-uppercase mb-2">
              Tamanho:
              <span className="text-cinza-51-5bits text-uppercase ml-2">
                {product.size}
              </span>
            </div>
            <div className="text-primary text-uppercase mb-2">
              Cor:
              <span className="text-cinza-51-5bits text-uppercase ml-2">
                {product.color}
              </span>
            </div>
            <div className="d-flex flex-row align-items-center text-primary text-uppercase mb-2">
              Estoque:
              <Input
                type="number"
                name="stock_start"
                aria-label="Estoque"
                className="w-25 ml-3"
                disabled={permissions.update === false}
              />
            </div>
          </>
        )) || (
          <>
            <div className="text-primary text-uppercase mb-2">
              Código do produto:
            </div>
            <div className="text-primary text-uppercase mb-2">Nome:</div>
            <div className="text-primary text-uppercase mb-2">Tamanho:</div>
            <div className="text-primary text-uppercase mb-2">Cor:</div>
            <div className="d-flex flex-row align-items-center text-primary text-uppercase mb-2">
              Estoque:
              <Input
                type="number"
                name="inputDisabled"
                aria-label="Estoque"
                className="w-25 ml-3"
                disabled
              />
            </div>
          </>
        )}

        {(permissions.read && permissions.update && (
          <Button
            name="submit"
            type="submit"
            className="mt-5 w-auto"
            disabled={disableButton}
          >
            Salvar
          </Button>
        )) || (
          <Button
            name="btDisabled"
            type="button"
            onClick={e => e.preventDefault()}
            className="mt-5 w-auto"
            disabled
          >
            Salvar
          </Button>
        )}
      </Form>
    </SimpleCreateAndUpdateTemplate>
  );
};

export default InputInitialStock;
