/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import React, { useEffect, useCallback, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { FiUser, FiLock } from 'react-icons/fi';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ComplexCreateAndUpdateTemplate from '../../components/Template/ComplexCreateAndUpdateTemplate';

import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';
import {
  handleListMenus,
  joinMenusWithoutPermission,
} from '../../utils/handleListMenus';
import { useToast } from '../../context/ToastContext';

import Input from '../../components/Input';
import Checkbox from '../../components/Checkbox';
import Button from '../../components/Button';
import MenuPermissions from '../../components/MenuPermissions';

interface UserFormDataDTO {
  id: number;
  name: string;
  nickname: string;
  username: string;
  is_adminText: string;
  is_activeText: string;
  is_admin: boolean | string;
  is_active: boolean;
  password?: string;
  usersHasMenus?: Array<Object>;
  usersHasMainMenus?: Array<Object>;
  usersHasMainSubMenus?: Array<Object>;
  usersHasSubMenus?: Array<Object>;
}

interface MenuDTO {
  id: number;
  group_menu: number;
  label: string;
  route: string;
  action: string | null;
  is_submenu: boolean;
  submenus: Array<MenuDTO>;
  permission: {
    menus_id?: number;
    action?: string | null;
    read: boolean;
    create: boolean;
    update: boolean;
    delete: boolean;
    // cancel: boolean;
  };
}

/**
 * [X] Retirar o link na Sigin que leva a User
 * [ ] Ver o history com o Daniel após cadastrar user
 * [X] Buscar mensagens de retorno do backend e exibir no tooltip
 * [X] Algoritimo sugerir nome de login (yfernandes) converter para minuscula
 * [X] Focar o campo com erro de acordo com o status: field retornado do backend
 * [X] Dasbilitar botão submite ao enviar
 * [X] Mostrar outras mensagens de error como as Network error
 * [X] Corrigir o onFocus do username
 */

const User: React.FC = () => {
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const [disableButton, setDisableButton] = useState(false);
  const [listMenus, setListMenus] = useState<MenuDTO[]>([]);

  useEffect(() => {
    try {
      const getListMenus = async (): Promise<void> => {
        try {
          const response = await api.get('menus');

          setListMenus(
            joinMenusWithoutPermission(response.data as Array<MenuDTO>),
          );
        } catch (error) {
          // eslint-disable-next-line no-shadow
          if (error.response) {
            const { data } = error.response; // Error vindo do back está em data dentro de response
            addToast({
              type: 'error',
              title: data.message,
            });
            return;
          }

          addToast({
            type: 'error',
            title: 'Ocorreu um erro interno',
          });
        }
      };
      getListMenus();
    } catch (error) {
      // eslint-disable-next-line no-shadow
      if (error.response.data) {
        const { data } = error.response; // Error vindo do back está em data dentro de response
        addToast({
          type: 'error',
          title: data.message,
        });
        return;
      }

      addToast({
        type: 'error',
        title: 'Ocorreu um erro interno',
      });
    }
  }, [addToast]);

  // handleSubmit
  const handleSubmit = useCallback(
    async (dataForm: UserFormDataDTO) => {
      try {
        setDisableButton(true);

        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          nickname: Yup.string().required('Cognome obrigatório'),
          username: Yup.string().required('Login obrigatório'),
          password: Yup.string().min(6, 'No mínimo 6 dígitos'),
        });

        await schema.validate(dataForm, {
          abortEarly: false,
        });

        /**
         * handleListMenus monta o array de objetos com os
         * menus e permissões
         */
        const usersHasMenus = handleListMenus(dataForm);
        /**
         * Remove as lista que não são enviadas para o banco de dados
         */
        delete dataForm.usersHasMainMenus;
        delete dataForm.usersHasMainSubMenus;
        delete dataForm.usersHasSubMenus;

        /**
         * Cria um novo array com o is_admin com o
         * valor em booleano que será salvo no bd
         */
        const newData = {
          ...dataForm,
          is_admin: dataForm.is_admin === 'true',
          usersHasMenus,
        };

        await api.post('users', newData);

        addToast({
          type: 'success',
          title: 'Cadastro realizado com sucesso',
          description: 'Já pode realizar o logon.',
        });

        setDisableButton(false);
        history.push('/home');
      } catch (error) {
        setDisableButton(false);

        // Verifica se o error é do Yup ou seja dos campos
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);

          return;
        }
        // eslint-disable-next-line no-shadow
        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: 'Erro no cadastro',
            description: data.message,
          });
          if (formRef.current?.getFieldRef(data.field)) {
            formRef.current?.getFieldRef(data.field).focus(); // Foca o campo que deu erro de acordo com o field retornado do back
          }

          return;
        }

        addToast({
          type: 'error',
          title: 'Ocorreu um erro interno',
        });
      }
    },
    [history, addToast],
  );
  // Fim handleSubmit

  const handleSugestionUserName = (): void => {
    if (formRef.current?.getFieldValue('name')) {
      const valueInputName = formRef.current?.getFieldValue('name').trim();
      const firstLetter = valueInputName[0][0];

      if (valueInputName.length > 1) {
        let lastName = valueInputName.split(' ');
        lastName = lastName[lastName.length - 1];
        const susgestionNickname = (firstLetter + lastName).toLowerCase();

        formRef.current?.setFieldValue('username', susgestionNickname);
      } else {
        formRef.current?.setFieldValue('username', '');
      }
    }
  };

  return (
    <ComplexCreateAndUpdateTemplate title="Cadastro de Usuário">
      <Form ref={formRef} onSubmit={handleSubmit} className="h-100">
        <Row noGutters className="w-100 h-100">
          <Col md={6} className=" h-100 overflow-auto">
            <Input
              type="text"
              name="name"
              icon={FiUser}
              label="Nome Completo"
              placeholder="Nome Completo"
              onInput={handleSugestionUserName}
              maxLength={45}
              className="mb-2"
            />
            <Input
              type="text"
              name="nickname"
              label="Cognome"
              icon={FiUser}
              placeholder="Cognome"
              maxLength={15}
              className="mb-2"
            />
            <Input
              type="text"
              name="username"
              label="Login"
              icon={FiUser}
              placeholder="Login"
              maxLength={45}
              className="mb-2"
            />
            <Input
              type="password"
              name="password"
              label="Senha"
              icon={FiLock}
              placeholder="Senha"
              maxLength={60}
              className="mb-2"
              autoComplete="new-password"
            />
            <Checkbox name="is_admin" propChecked={false} className="mb-2">
              Nível Administrador
            </Checkbox>
          </Col>
          <Col md={6} className="overflow-auto h-100 pl-3">
            {listMenus.length > 0 && (
              <MenuPermissions propListMenus={listMenus} />
            )}
          </Col>
          <Col md={6} className="mt-5 ml-auto mr-auto">
            <Button name="submit" type="submit" disabled={disableButton}>
              Cadastrar
            </Button>
          </Col>
        </Row>
      </Form>
    </ComplexCreateAndUpdateTemplate>
  );
};

export default User;
