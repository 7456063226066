import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
// Importa todas as propriedades que um ícone pode ter
import { IconBaseProps } from 'react-icons';

// Icone de erro que irá no lugar da menssagem de erro dentro do container
import { FiAlertCircle } from 'react-icons/fi';

// Importa da core pois é igual tanto para web quanto para mobile
import { useField } from '@unform/core';

// import InputMask from './inputMask';
import InputMask from 'react-input-mask';

import InputGroup from 'react-bootstrap/InputGroup';

/**
 * O Form.Control foi substituído por apenas <input> após atualizar as dependências, pois
 * o Form.Control não aceitou o {...rest}, sendo assim o types (como password) passados
 * não surtiam efeito.
 * ....
 * </InputGroup.Prepend>
      <input
        id={nam ....

import Form from 'react-bootstrap/Form';
 */

// O error é um container que contém a imagem e o tooltip com a mensagem ao passar o mouse
import { Container, ErrorStyle } from './styles';

/*
Pega todas as propriedades que um input html pode receber
e faz com que o atributo name seja obrigatório.
O icon é o componente de icone da Fi do react-icons passados
como props na index do Signin. Não é obrigatório e tem o
tipo React.ComponentType que recebe todas as proos que um icone
pode ter
*/
interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  className?: string;
  classNameInputGroupTextAndFormControl?: string;
  fontSizeInput?: string;
  fontSizeLabel?: string;
  textTransform?: string;
  icon?: React.ComponentType<IconBaseProps>;
  isHidden?: boolean;
  mask?: string;
}
/*
Como está recebendo as props tem que repassar para o elemento
no caso o input. Aqui pega o nome do campo que é passado para o hook
useField, o icon e o restante das props em rest.
O icon tem que ser renomeado com Icon pois se trata de um component
e o react não entende componentes com letras minúsculas pois componentes
tem que ser definidos em formato de tags <Icon /> e o react não entende
ser for minúsculo.
Faz uma verificação se existe icones enviados como props, se
existir define o tamanho como 20px e exibe
*/
const Input: React.FC<InputProps> = ({
  name,
  label,
  className = '',
  classNameInputGroupTextAndFormControl = '',
  fontSizeInput = '',
  fontSizeLabel = '',
  textTransform = 'text-uppercase',
  icon: Icon,
  isHidden = false,
  mask,
  ...rest
}) => {
  /*
  É usada abaixo no input para fazer a referencia do campo input
  */
  const inputRef = useRef<HTMLInputElement>(null);
  /*
  É usada abaixo no input com mascara para fazer a referencia do campo input
  */
  const inputMaskRef = useRef(null);

  /*
  Armazena no state se o campo está com focus ou não.
  O isFilled armazena se o campo está preenchido ou não.
  */
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  /*
  useField retorna todas as propriedades que estão dentro das chaves
  de const
  */
  const {
    fieldName,
    defaultValue,
    error,
    clearError,
    registerField,
  } = useField(name);

  /*
  O registro é feito assim quem o elemento é exibdo em tela.
  O registerField recebe alguns parametros obrigatórios.
  O name que é o nome do campo, neste caso recebe o fieldName pois o unform
  altera o nome dos campos em algumas condições.
  O ref é uma referencia para acessar o elemento de forma direta na DOM
  sem ter que armazenar em um estado, seria o getElement do javascript.
  O input estará dentro do current (inputRef.current) esse é o padrão do
  react.
  A path indica o caminho para quando se que pegar o valor. A ref já retorna
  o input e a path indica o valor do campo. Seria querySelecto(input).value
  */
  useEffect(() => {
    if (mask) {
      registerField({
        name: fieldName,
        ref: inputMaskRef.current,
        path: 'value',
      });
    } else {
      registerField({
        name: fieldName,
        ref: inputRef.current,
        path: 'value',
      });
    }
  }, [fieldName, mask, registerField]);

  const handleInputFocus = useCallback((): void => {
    setIsFocused(true);
    // Função do unform para limpar os erros do campo
    clearError();
  }, [clearError]);

  const handleInputBlur = useCallback((): void => {
    setIsFocused(false);
    /*
    if(inputRef.current?.value){
      setIsFilled(true);
    }else {
      setIsFilled(false);
    }
    As linhas acima podem ser substituídas pela linha abaixo
    O !! converte para booleano. Ou seja, se o valor estiver preenchido
    converte para true senão para false.
    */
    setIsFilled(!!inputRef.current?.value);
  }, []);

  return (
    <>
      {(isHidden && (
        <input defaultValue={defaultValue} ref={inputRef} {...rest} />
      )) || (
        <Container
          isErrored={!!error}
          isFilled={isFilled}
          isFocused={isFocused}
          className={className}
        >
          {(!mask && (
            <>
              {label && (
                <label
                  htmlFor={name}
                  style={{
                    textTransform: 'uppercase',
                    fontSize: `${fontSizeLabel}`,
                  }}
                >
                  {label}
                </label>
              )}
              <InputGroup>
                {Icon && (
                  <InputGroup.Prepend>
                    <InputGroup.Text
                      id="basic-addon1"
                      className={classNameInputGroupTextAndFormControl}
                    >
                      <Icon size={20} />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                )}
                <input
                  id={name}
                  className={`form-control ${textTransform} ${classNameInputGroupTextAndFormControl}`}
                  style={{
                    fontSize: `${fontSizeInput}`,
                  }}
                  aria-describedby="basic-addon1"
                  onFocus={handleInputFocus}
                  onBlur={handleInputBlur}
                  defaultValue={defaultValue}
                  ref={inputRef}
                  {...rest}
                />
                {error && (
                  <ErrorStyle title={error}>
                    <FiAlertCircle size={20} />
                  </ErrorStyle>
                )}
              </InputGroup>
            </>
          )) || (
            <>
              {label && (
                <label
                  htmlFor={name}
                  style={{
                    textTransform: 'uppercase',
                    fontSize: `${fontSizeLabel}`,
                  }}
                >
                  {label}
                </label>
              )}
              <InputGroup>
                {Icon && (
                  <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">
                      <Icon size={20} />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                )}
                <InputMask
                  id={name}
                  mask={mask || ''}
                  className={`form-control ${textTransform}`}
                  style={{
                    fontSize: `${fontSizeInput}`,
                  }}
                  aria-describedby="basic-addon1"
                  onFocus={handleInputFocus}
                  onBlur={handleInputBlur}
                  defaultValue={defaultValue}
                  ref={inputMaskRef}
                  {...rest}
                />
                {error && (
                  <ErrorStyle title={error}>
                    <FiAlertCircle size={20} />
                  </ErrorStyle>
                )}
              </InputGroup>
            </>
          )}
        </Container>
      )}
    </>
  );
};

export default Input;
