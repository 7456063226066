import React from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';

import { useAuth } from '../context/AuthContext';

const PrivateRoute: React.FC<RouteProps> = ({ children, ...res }) => {
  const user = useAuth();

  return <Route {...res}>{user.user ? children : <Redirect to="/" />}</Route>;
};

export default PrivateRoute;
