/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useCallback, useState, useEffect } from 'react';
import fincalc from 'fincalc';

import { FiEdit } from 'react-icons/fi';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import CreateWithListingTemplate from '../../../components/Template/CreateWithListingTemplate';

import api from '../../../services/api';
import getValidationErrors from '../../../utils/getValidationErrors';
import convertNumberFloatToDB from '../../../utils/convertNumberFloatToDB';
import convertNumberFloatToBr from '../../../utils/convertNumberFloatToBr';

import convertDateToDB from '../../../utils/convertDateToDB';
import { useToast } from '../../../context/ToastContext';

import Button from '../../../components/Button';
import DatePicker from '../../../components/DatePicker';
import Modal from '../../../components/Modal';
import ModalReturnCloseCashFlow from './Modal/modalReturnCloseCashFlow';

interface CashFlowClosingFormDTO {
  close_date: string;
}

interface FinancialAccountsFormDataDTO {
  id: number;
  name: string;
  balance: number;
}

const CashFlowClosing: React.FC = () => {
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [
    loadModalConfirmationCloseCashFlow,
    setLoadModalConfirmationCloseCashFlow,
  ] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [loadModalWait, setLoadModalWait] = useState(false);

  const [
    listFinancialAccountsAfterCloseCasFlow,
    setListFinancialAccountsAfterCloseCasFlow,
  ] = useState<FinancialAccountsFormDataDTO[]>([]);

  // Para paginação
  const [listFinancialAccounts, setListFinancialAccounts] = useState<
    FinancialAccountsFormDataDTO[]
  >([]);

  const [
    listFinancialAccountsPaginatedItems,
    setListFinancialAccountsPaginatedItems,
  ] = useState<any[]>([]);
  // Fim Para paginação

  useEffect(() => {
    try {
      const getListFinancialAccounts = async (): Promise<void> => {
        try {
          const response = await api.get('cash/financial-accounts');
          setListFinancialAccounts(response.data);
        } catch (error) {
          // eslint-disable-next-line no-shadow
          if (error.response) {
            const { data } = error.response; // Error vindo do back está em data dentro de response
            addToast({
              type: 'error',
              title: data.message,
            });
            return;
          }

          addToast({
            type: 'error',
            title: 'Ocorreu um erro interno',
          });
        }
      };
      getListFinancialAccounts();
    } catch (error) {
      // eslint-disable-next-line no-shadow
      if (error.response.data) {
        const { data } = error.response; // Error vindo do back está em data dentro de response
        addToast({
          type: 'error',
          title: data.message,
        });
        return;
      }

      addToast({
        type: 'error',
        title: 'Ocorreu um erro interno',
      });
    }
  }, [addToast]);

  // handleSubmit
  const handleSubmit = useCallback(
    async (dataForm: CashFlowClosingFormDTO, { reset }) => {
      try {
        setLoadModalConfirmationCloseCashFlow(false);
        setDisableButton(true);
        setLoadModalWait(true);

        formRef.current?.setErrors({});

        /** A função mista do Yup pode corresponder a todos os tipos */
        const schema = Yup.object().shape({
          close_date: Yup.string().required('Data obrigatória').nullable(),
        });

        await schema.validate(dataForm, {
          abortEarly: false,
        });

        const newData = {
          close_date: convertDateToDB(dataForm.close_date),
        };

        const response = await api.post('cash/cash-flow', newData);

        setListFinancialAccountsAfterCloseCasFlow(response.data);

        addToast({
          type: 'success',
          title: 'Cadastro realizado com sucesso',
        });

        reset();
        setDisableButton(false);
        setLoadModalWait(false);
      } catch (error) {
        setLoadModalConfirmationCloseCashFlow(false);
        setDisableButton(false);
        setLoadModalWait(false);

        // Verifica se o error é do Yup ou seja dos campos
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);

          return;
        }
        // eslint-disable-next-line no-shadow
        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: 'Erro no cadastro',
            description: data.message,
          });
          // if (formRef.current?.getFieldRef(data.field)) {
          //   formRef.current?.getFieldRef(data.field).focus(); // Foca o campo que deu erro de acordo com o field retornado do back
          // }

          return;
        }

        addToast({
          type: 'error',
          title: 'Ocorreu um erro interno',
        });
      }
    },
    [addToast],
  );
  // Fim handleSubmit

  const handleListFinancialAccountsPaginatedItems = useCallback(newList => {
    setListFinancialAccountsPaginatedItems(newList);
  }, []);

  return (
    <CreateWithListingTemplate
      title="Fechamento de Caixa"
      numberColContent={12}
      listItemsProps={listFinancialAccounts}
      handleListItemsPaginatedItems={handleListFinancialAccountsPaginatedItems}
    >
      <Form
        ref={formRef}
        className="mt-4 mb-4 d-flex flex-row justify-content-center"
        onSubmit={handleSubmit}
        initialData={{ close_date: new Date() }}
      >
        <DatePicker
          name="close_date"
          label="Data de Fechamento"
          maxDate={new Date()}
          icon={FiEdit}
          className="w-auto mr-3"
        />

        <Button
          name="openModalConfirmation"
          type="button"
          className="w-auto d-flex align-items-end ml-3"
          onClick={() => setLoadModalConfirmationCloseCashFlow(true)}
          disabled={disableButton}
        >
          Fechar Caixa
        </Button>

        <Modal visibleProp={loadModalConfirmationCloseCashFlow}>
          <div className="d-flex flex-column align-items-center w-100 bg-branco-puro-5bits p-4">
            <h1>Confirma Fechamento de Caixa?</h1>
            <div className="d-flex flex-row align-items-center justify-content-center w-100">
              <Button name="submit" type="submit" className="w-auto mr-3">
                Confirmar
              </Button>
              <Button
                name="closeModalOpenCashFlow"
                type="button"
                className="w-auto"
                onClick={() => setLoadModalConfirmationCloseCashFlow(false)}
              >
                Cancelar
              </Button>
            </div>
          </div>
        </Modal>
      </Form>
      {listFinancialAccounts && (
        <Row className="h-auto justify-content-center no-gutters overflow-hide">
          <Col className="h-100 overflow-auto" md={12}>
            <div className="text-primary text-uppercase font-weight-bold">
              Contas Financeiras:
              <span className="text-cinza-51-5bits text-uppercase ml-2 font-weight-bold">
                {listFinancialAccounts.length}
              </span>
            </div>
            <Table striped bordered hover size="sm" responsive="md">
              <thead className="text-center text-primary text-uppercase">
                <tr>
                  <th className="font-weight-normal py-3" scope="col">
                    Conta
                  </th>
                  <th className="font-weight-normal py-3" scope="col">
                    Saldo Atual
                  </th>
                </tr>
              </thead>
              <tbody>
                {listFinancialAccountsPaginatedItems.map((item, index) => (
                  <tr key={index.toString()}>
                    <td className="text-left">{item.name}</td>
                    <td className="text-right pr-3">{item.balance}</td>
                  </tr>
                ))}
                <tr>
                  <th colSpan={2} scope="row" className="pr-0">
                    <div className="d-flex justify-content-around">
                      <span className="w-50">Total</span>
                      <span className="w-50 text-right pr-3">
                        {convertNumberFloatToBr(
                          fincalc(
                            listFinancialAccountsPaginatedItems.reduce(
                              (sum: number, item: any) => {
                                return (
                                  sum + convertNumberFloatToDB(item.balance)
                                );
                              },
                              0,
                            ),
                          ),
                        )}
                      </span>
                    </div>
                  </th>
                </tr>
              </tbody>
            </Table>

            <ModalReturnCloseCashFlow
              listProp={listFinancialAccountsAfterCloseCasFlow}
              loadModalProp={listFinancialAccountsAfterCloseCasFlow.length > 0}
              title="Contas Fechadas"
            />

            <Modal visibleProp={loadModalWait}>
              <div className="d-flex flex-column align-items-center bg-branco-puro-5bits p-4">
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <strong>Salvando...</strong>
              </div>
            </Modal>
          </Col>
        </Row>
      )}
    </CreateWithListingTemplate>
  );
};

export default CashFlowClosing;
