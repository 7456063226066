/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useCallback, useMemo } from 'react';

import Table from 'react-bootstrap/Table';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import { FiPrinter } from 'react-icons/fi';

import { compareAsc, parseISO } from 'date-fns';

import Button from '../../../../../components/Button';

import ConvertPriceBRL from '../../../../../utils/convertPriceBRL';
import convertDateToDB from '../../../../../utils/convertDateToDB';
import returnDateNow from '../../../../../utils/returnDateNow';

import { useToast } from '../../../../../context/ToastContext';
import api from '../../../../../services/api';

interface HeaderSalesFormDTO {
  paymentMethodsComplete: Array<any>[any];
  saleId: number;
}

const FooterSellUpdate: React.FC<HeaderSalesFormDTO> = ({
  paymentMethodsComplete,
  saleId,
}) => {
  const { addToast } = useToast();
  const [listPaymentMethods, setListPaymentMethods] = useState<Array<any>>([]);
  const listSecondPaymentMethod: any = useMemo(() => [], []);
  const [loadingPDF, setLoadingPDF] = useState(false);
  const [loadingPDFTicket, setLoadingPDFTicket] = useState(false);

  // handlePrintPDF
  const handlePrintPDF = useCallback(
    async (id): Promise<void> => {
      try {
        setLoadingPDF(true);
        const response = await api.get(`sales/sell/receipt-pdf/${id}`, {
          responseType: 'arraybuffer',
        });

        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: 'application/pdf' }),
        );
        setLoadingPDF(false);
        window.open(url);
      } catch (error) {
        setLoadingPDF(false);
        // eslint-disable-next-line no-shadow
        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: data.message,
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Ocorreu um erro interno',
          description: `${error}`,
        });
      }
    },
    [addToast],
  );
  // Fim handleProntPDF

  // handlePrintPDFTicket
  const handlePrintPDFTicket = useCallback(async (): Promise<void> => {
    try {
      setLoadingPDFTicket(true);
      const response = await api.get(`sales/sell/ticket-pdf/${saleId}`, {
        responseType: 'arraybuffer',
      });

      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: 'application/pdf' }),
      );
      setLoadingPDFTicket(false);
      window.open(url);
    } catch (error) {
      setLoadingPDFTicket(false);
      // eslint-disable-next-line no-shadow
      if (error.response) {
        const { data } = error.response; // Error vindo do back está em data dentro de response
        addToast({
          type: 'error',
          title: data.message,
        });
        return;
      }

      addToast({
        type: 'error',
        title: 'Ocorreu um erro interno',
        description: `${error}`,
      });
    }
  }, [addToast, saleId]);
  // Fim handlePrintPDFTicket

  const filterListPaymentMethods = useCallback(
    obj => {
      if (
        paymentMethodsComplete &&
        obj.payment_method_id === paymentMethodsComplete[0].payment_method_id
      ) {
        return true;
      }
      listSecondPaymentMethod.push(obj);
      return false;
    },
    [paymentMethodsComplete, listSecondPaymentMethod],
  );

  useEffect(() => {
    if (paymentMethodsComplete && paymentMethodsComplete.length > 0) {
      const newListPaymentMethods = [
        paymentMethodsComplete.filter(filterListPaymentMethods),
        listSecondPaymentMethod,
      ];
      setListPaymentMethods(newListPaymentMethods);
    }
  }, [paymentMethodsComplete]);

  return (
    <Col className="overflow-auto">
      <Table
        style={{ fontSize: '12px' }}
        striped
        bordered
        hover
        size="sm"
        responsive="md"
      >
        <thead className="text-center text-primary text-uppercase">
          <tr>
            <th className="font-weight-normal py-3" scope="col">
              Imprimir
            </th>
            <th className="font-weight-normal py-3" scope="col">
              Forma de Pagamento
            </th>
            <th className="font-weight-normal py-3" scope="col">
              Desconto
            </th>
            <th className="font-weight-normal py-3" scope="col">
              Acréscimo
            </th>
            <th className="font-weight-normal py-3" scope="col">
              Valor
            </th>
            <th className="font-weight-normal py-3" scope="col">
              Vencimento
            </th>
            <th className="font-weight-normal py-3" scope="col">
              Status
            </th>
            <th className="font-weight-normal py-3" scope="col">
              Ticket de Venda
            </th>
          </tr>
        </thead>
        <tbody className="text-center">
          {listPaymentMethods.map((item, index) => {
            return item.map((subItem: any, indexSubItem: number) => (
              <tr key={indexSubItem.toString()}>
                {indexSubItem === 0 && (
                  <th
                    scope="row"
                    rowSpan={item.length}
                    style={{
                      textAlign: 'center',
                      verticalAlign: 'middle',
                    }}
                    className={`${index === 1 && 'bg-branco-puro-5bits'}`}
                  >
                    {subItem.print_booklet_format && (
                      <>
                        {(loadingPDF && (
                          <Spinner animation="border" variant="primary" />
                        )) || (
                          <Button
                            name="print"
                            type="button"
                            fontSizeButton="12px"
                            onClick={() => {
                              handlePrintPDF(subItem.sale_header_id);
                            }}
                          >
                            <FiPrinter size={26} />
                          </Button>
                        )}
                      </>
                    )}
                  </th>
                )}
                <th scope="row">{subItem.name}</th>
                <td>{ConvertPriceBRL(subItem.discount)}</td>
                <td>{ConvertPriceBRL(subItem.addition)}</td>
                <td>{ConvertPriceBRL(subItem.amount)}</td>
                {(compareAsc(
                  parseISO(returnDateNow('yyyy-MM-dd')),
                  parseISO(convertDateToDB(subItem.due_date)),
                ) === 1 &&
                  subItem.is_paid === false && (
                    <td className="bg-vermelho-suave-5bits font-weight-bold">
                      {subItem.due_date}
                    </td>
                  )) || <td>{subItem.due_date}</td>}

                <td className="text-success font-weight-bold">
                  {subItem.is_paid && 'Pago'}
                </td>
                {index === 0 && indexSubItem === 0 && (
                  <th
                    style={{ verticalAlign: 'middle' }}
                    scope="row"
                    rowSpan={2}
                  >
                    <div className="d-flex justify-content-center w-50 m-auto p-0">
                      {(loadingPDFTicket && (
                        <Spinner animation="border" variant="primary" />
                      )) || (
                        <Button
                          name="print"
                          type="button"
                          fontSizeButton="12px"
                          onClick={() => {
                            handlePrintPDFTicket();
                          }}
                        >
                          <FiPrinter size={26} />
                        </Button>
                      )}
                    </div>
                  </th>
                )}
              </tr>
            ));
          })}
        </tbody>
      </Table>
    </Col>
  );
};

export default FooterSellUpdate;
