/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';

import Table from 'react-bootstrap/Table';

import ListingTemplate from '../../../components/Template/ListingTemplate';
import Loader from '../../../components/Loader';
import api from '../../../services/api';

import { useToast } from '../../../context/ToastContext';

import { IPermission } from '../../../interfaces/IPermission';

interface FinancialAccountsFormDataDTO {
  id: number;
  name: string;
  balance: number;
  accept_negative: boolean;
  is_active: boolean;
}

interface PermissionDTO {
  permissions: IPermission;
}

const ReadsFinancialAccounts: React.FC<PermissionDTO> = ({ permissions }) => {
  const [loading, setLoading] = useState(false);
  const [listFinancialAccounts, setListFinancialAccounts] = useState<
    FinancialAccountsFormDataDTO[]
  >([]);
  const [
    listFinancialAccountsPaginatedItems,
    setListFinancialAccountsPaginatedItems,
  ] = useState<any[]>([]);
  const { addToast } = useToast();

  useEffect(() => {
    try {
      const getListFinancialAccounts = async (): Promise<void> => {
        try {
          setLoading(true);
          const response = await api.get('cash/financial-accounts');
          setListFinancialAccounts(response.data);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          // eslint-disable-next-line no-shadow
          if (error.response) {
            const { data } = error.response; // Error vindo do back está em data dentro de response
            addToast({
              type: 'error',
              title: data.message,
            });
            return;
          }

          addToast({
            type: 'error',
            title: 'Ocorreu um erro interno',
          });
        }
      };
      getListFinancialAccounts();
    } catch (error) {
      setLoading(false);
      // eslint-disable-next-line no-shadow
      if (error.response.data) {
        const { data } = error.response; // Error vindo do back está em data dentro de response
        addToast({
          type: 'error',
          title: data.message,
        });
        return;
      }

      addToast({
        type: 'error',
        title: 'Ocorreu um erro interno',
      });
    }
  }, [addToast]);

  const handleListFinancialAccountsPaginatedItems = useCallback(newList => {
    setListFinancialAccountsPaginatedItems(newList);
  }, []);

  return (
    <ListingTemplate
      title="Contas Financeiras"
      module="cash/financial-accounts"
      listItemsProps={listFinancialAccounts}
      handleListItemsPaginatedItems={handleListFinancialAccountsPaginatedItems}
      displaySearchArea={false}
      permissions={permissions}
    >
      {(loading && <Loader />) || (
        <>
          <Table striped bordered hover size="sm" responsive="md">
            <thead className="text-center text-primary text-uppercase">
              <tr>
                <th className="font-weight-normal py-3" scope="col">
                  ID
                </th>
                <th className="font-weight-normal py-3" scope="col">
                  Nome
                </th>
                <th className="font-weight-normal py-3" scope="col">
                  Saldo
                </th>
                <th className="font-weight-normal py-3" scope="col">
                  Aceita Negativar
                </th>
                <th className="font-weight-normal py-3" scope="col">
                  Conta Ativa
                </th>
                <th className="font-weight-normal py-3" scope="col">
                  Ações
                </th>
              </tr>
            </thead>
            <tbody className="text-center">
              {listFinancialAccountsPaginatedItems.map((item, index) => (
                <tr key={index.toString()}>
                  <th scope="row">{item.id}</th>
                  <td className="text-left">{item.name}</td>
                  <td className="text-right pr-3">{item.balance}</td>
                  <td>{(item.accept_negative === true && ' SIM') || ' NÃO'}</td>
                  <td>{(item.is_active === true && ' SIM') || ' NÃO'}</td>
                  <td>
                    {(permissions.update && (
                      <Link to={`/cash/financial-accounts/read/${item.id}`}>
                        Alterar
                      </Link>
                    )) || <span className="text-cinza-51-5bits">Alterar</span>}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
    </ListingTemplate>
  );
};

export default ReadsFinancialAccounts;
