import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import fincalc from 'fincalc';
import Table from 'react-bootstrap/Table';
import convertNumberFloatToBr from '../../../../utils/convertNumberFloatToBr';
import convertNumberFloatToDB from '../../../../utils/convertNumberFloatToDB';

import Button from '../../../../components/Button';

import Modal from '../../../../components/Modal';

interface ModalDTO {
  title: string;
  loadModalProp: boolean;
  listProp: Array<any>;
}

const ModalReturnCloseCashFlow: React.FC<ModalDTO> = ({
  title,
  loadModalProp = false,
  listProp,
}) => {
  const history = useHistory();
  const [listCloseCashFlow, setListCloseCashFlow] = useState<Array<any>>([]);
  const [loadModal, setLoadModal] = useState(false);

  useEffect(() => {
    if (loadModalProp === true && listProp) {
      setListCloseCashFlow(listProp);
      setLoadModal(loadModalProp);
    }
  }, [loadModalProp, listProp]);

  return (
    <Modal visibleProp={loadModal} className="w-50">
      <div
        style={{ maxHeight: '70vh' }}
        className="d-flex flex-column align-items-center w-100 bg-branco-puro-5bits p-4"
      >
        <h1>{title}</h1>
        <div className="overflow-auto w-100">
          <Table striped bordered hover size="sm" responsive="md">
            <thead className="text-center text-primary text-uppercase">
              <tr>
                <th className="font-weight-normal py-3" scope="col">
                  Conta
                </th>
                <th className="font-weight-normal py-3" scope="col">
                  Saldo Atual
                </th>
              </tr>
            </thead>
            <tbody>
              {listCloseCashFlow.length > 0 &&
                listCloseCashFlow.map((item, index) => (
                  <tr key={index.toString()}>
                    <td className="text-left">{item.financialAccount.name}</td>
                    <td className="text-right pr-3">
                      {convertNumberFloatToBr(item.balance)}
                    </td>
                  </tr>
                ))}
              <tr>
                <th colSpan={2} scope="row" className="pr-0">
                  <div className="d-flex justify-content-around">
                    <span className="w-50">Total</span>
                    <span className="w-50 text-right pr-3">
                      {convertNumberFloatToBr(
                        fincalc(
                          listCloseCashFlow.reduce((sum: number, item: any) => {
                            return sum + convertNumberFloatToDB(item.balance);
                          }, 0),
                        ),
                      )}
                    </span>
                  </div>
                </th>
              </tr>
            </tbody>
          </Table>
        </div>

        <div className="d-flex flex-row justify-content-around mt-3 w-50">
          <Button
            name="closeModal"
            type="button"
            className="w-auto"
            onClick={() => {
              setLoadModal(false);
              history.push('/cash/cash-flow/list');
            }}
          >
            Fechar
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalReturnCloseCashFlow;
