/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable camelcase */
import React, { useCallback, useRef, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { FiEdit } from 'react-icons/fi';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import ComplexCreateAndUpdateTemplate from '../../../components/Template/ComplexCreateAndUpdateTemplate';

import api from '../../../services/api';
import getValidationErrors from '../../../utils/getValidationErrors';
import { useToast } from '../../../context/ToastContext';

import Input from '../../../components/Input';
import CurrencyInput from '../../../components/Input/InputCurrency';
import Checkbox from '../../../components/Checkbox';
import SelectSimple from '../../../components/SelectSimple';
import Button from '../../../components/Button';

interface PaymentMethodsFormDataDTO {
  id: number;
  financial_account_id: number;
  name: string;
  days_compensation: number;
  allow_installments: boolean;
  allow_installmentsText?: string;
  fee: number;
  is_activeText?: string;
  is_active: boolean;
  financialAccount: any;
  print_booklet_format: boolean;
  print_booklet_formatText?: string;
}

const UpdateFinancialAccounts: React.FC<PaymentMethodsFormDataDTO> = () => {
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { id }: any = useParams();
  const [
    paymentMethods,
    setPaymentMethods,
  ] = useState<PaymentMethodsFormDataDTO>({} as PaymentMethodsFormDataDTO);
  const [disableButton, setDisableButton] = useState(false);

  const sessionStorageUser: any = sessionStorage.getItem('@Stock5b:user');
  const currentUserLogged = JSON.parse(sessionStorageUser);

  // const [clearIsFixedDays, setClearIsFixedDays] = useState(false);

  // eslint-disable-next-line no-bitwise

  useEffect(() => {
    const getPaymentMethods = async (): Promise<void> => {
      try {
        const response = await api.get(`cash/payment-methods/${id}`);
        setPaymentMethods(response.data);
        // setClearIsFixedDays(response.data.is_fixed_days);
        /**
         * Preenche os campos do formulário. Sem esta linha os inputs com
         * máscara não são preenchidos.
         */
        formRef.current?.setData(response.data);
        /*
        Preenche o campo select de acordo com o valor salvo no banco.
        Sem isso não funciona no unform.
        */
        formRef.current?.setFieldValue('financial_account_id', {
          label: response.data.financialAccount.name,
          value: response.data.financialAccount.id,
        });
      } catch (error) {
        // eslint-disable-next-line no-shadow
        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: data.message,
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Ocorreu um erro interno',
        });
      }
    };
    getPaymentMethods();
  }, [addToast, id]);

  // handleSubmit
  const handleSubmit = useCallback(
    async (dataForm: PaymentMethodsFormDataDTO, { reset }) => {
      try {
        setDisableButton(true);
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          financial_account_id: Yup.string().required(
            'Conta Financeira obrigatória',
          ),
        });

        await schema.validate(dataForm, {
          abortEarly: false,
        });

        /**
         * Cria um novo array com o is_admin com o
         * valor em booleano que será salvo no bd
         */
        let isActiveBoolean;

        if (currentUserLogged.is_admin) {
          isActiveBoolean = dataForm.is_activeText === 'true';
        } else {
          isActiveBoolean = paymentMethods.is_active;
        }

        const newData = {
          ...dataForm,
          allow_installments: dataForm.allow_installmentsText === 'true',
          print_booklet_format: dataForm.print_booklet_formatText === 'true',
          is_active: isActiveBoolean,
        };

        delete newData.allow_installmentsText;
        delete newData.print_booklet_formatText;
        delete newData.is_activeText;

        await api.put('cash/payment-methods', newData);

        addToast({
          type: 'success',
          title: 'Alteração realizada com sucesso',
        });

        reset();
        setDisableButton(false);
        history.push('/cash/payment-methods/list');
      } catch (error) {
        setDisableButton(false);

        // Verifica se o error é do Yup ou seja dos campos
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);

          return;
        }
        // eslint-disable-next-line no-shadow
        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: 'Erro na alteração do cadastro',
            description: data.message,
          });
          formRef.current?.getFieldRef(data.field).focus(); // Foca o campo que deu erro de acordo com o field retornado do back

          return;
        }
        addToast({
          type: 'error',
          title: 'Ocorreu um erro interno',
        });
      }
    },
    [addToast, currentUserLogged.is_admin, paymentMethods, history],
  );
  // Fim handleSubmit

  return (
    <ComplexCreateAndUpdateTemplate title="Atualização de Formas de Pagamento">
      <Form
        ref={formRef}
        initialData={paymentMethods}
        className="mt-4"
        onSubmit={handleSubmit}
      >
        <Row noGutters className="w-100 h-100">
          <Col md={6} className=" h-100">
            <Input type="hidden" name="id" isHidden />
            <Input
              type="text"
              name="name"
              label="Nome"
              icon={FiEdit}
              placeholder="Nome"
              aria-label="Nome"
              maxLength={45}
              className="mb-4"
            />

            <Input
              type="number"
              name="days_compensation"
              label="Dias de compensação"
              icon={FiEdit}
              placeholder="Dias de compensação"
              aria-label="Dias de compensação"
              min="0"
              step="1"
              className="mb-4"
            />
          </Col>

          <Col md={6} className=" h-100 pl-3">
            <SelectSimple
              module="cash/financial-accounts"
              name="financial_account_id"
              label="Conta Financeira"
              icon={FiEdit}
              placeholder="Conta Financeira"
              aria-label="Conta Financeira"
              className="mb-4"
            />

            <CurrencyInput
              type="text"
              name="fee"
              label="Taxa"
              icon="R$"
              placeholder="Taxa"
              className="mb-4"
            />
          </Col>
          <Col md={12} className="h-100 pl-3">
            {currentUserLogged.is_admin === true && (
              <Checkbox
                name="is_activeText"
                propChecked={paymentMethods?.is_active}
              >
                Forma de Pagamento Ativa
              </Checkbox>
            )}

            <Checkbox
              name="allow_installmentsText"
              propChecked={paymentMethods?.allow_installments}
            >
              Permitir Parcelamento
            </Checkbox>

            <Checkbox
              name="print_booklet_formatText"
              propChecked={paymentMethods?.print_booklet_format}
            >
              Imprimir em formato de carnê
            </Checkbox>
          </Col>
          <Col
            md={12}
            className=" h-100 d-flex align-items-center justify-content-center mb-3 overflow-auto"
          >
            <Button
              name="submit"
              type="submit"
              className="mt-5 w-50"
              disabled={disableButton}
            >
              Salvar
            </Button>
          </Col>
        </Row>
      </Form>
    </ComplexCreateAndUpdateTemplate>
  );
};

export default UpdateFinancialAccounts;
