import React from 'react';
import { Switch } from 'react-router-dom';

import PrivateRoute from './PrivateRoutes';

import { useAuth } from '../context/AuthContext';
import findPermission from '../utils/findPermission';

import NotAuthorizedPage from '../pages/NotAuthorizedPage';
import ReportBillsReceived from '../pages/reports/reportBillsReceived';
import ReportSales from '../pages/reports/reportSales';

const ReportsRoutes: React.FC = () => {
  const { permission } = useAuth();
  const reportsBillsReceivedPermissions = findPermission(
    'reports/bills-received',
    permission,
  );
  // const reportsSalesPermissions = findPermission('reports/sales', permission);

  return (
    <Switch>
      <PrivateRoute
        path="*/bills-received"
        component={
          Object.keys(reportsBillsReceivedPermissions).length !== 0 &&
          reportsBillsReceivedPermissions.create
            ? ReportBillsReceived
            : NotAuthorizedPage
        }
      />
      <PrivateRoute path="*/sales" component={ReportSales} />
      {/* Object.keys(reportsSalesPermissions).length !== 0 &&
          reportsSalesPermissions.create
            ? ReportSales
            : NotAuthorizedPage
        } */}
    </Switch>
  );
};

export default ReportsRoutes;
