/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Input from '../../../../../components/Input';
import CurrencyInput from '../../../../../components/Input/InputCurrency';

// interface ClientFormDTO {
//   id: number;
//   name: string;
//   dependent_name: string;
// }

// interface EmployeeFormDTO {
//   id: number;
//   name: string;
// }

// interface HeaderSalesFormDTO {
//   client: ClientFormDTO;
//   employee: EmployeeFormDTO;
// }

const HeaderSellUpdate: React.FC = () => {
  return (
    <Row noGutters className="w-100 h-100">
      <Col md={12} className="d-md-flex flex-row p-0">
        <Input
          type="text"
          name="created_at"
          label="Data da Venda"
          className="bg-transparent p-0 font-weight-bold mb-1 mr-2 w-30"
          classNameInputGroupTextAndFormControl="text-center text-laranja-5bits text-uppercase font-weight-bold bg-transparent p-0"
          fontSizeInput="12px"
          fontSizeLabel="12px"
          disabled
        />
        <Input
          type="text"
          name="client.id"
          label="CÓD."
          placeholder="CÓD."
          fontSizeInput="12px"
          fontSizeLabel="12px"
          className="mr-md-2 mb-2 w-25"
          disabled
        />
        <Input
          type="text"
          name="client.name"
          label="Nome do Cliente"
          placeholder="Nome do Cliente"
          fontSizeInput="12px"
          fontSizeLabel="12px"
          className="mb-2"
          disabled
        />
        <Input
          type="text"
          name="dependent_name"
          label="Nome do Dependente"
          placeholder="Nome do Dependente"
          fontSizeInput="12px"
          fontSizeLabel="12px"
          className="ml-md-2 mb-2"
          disabled
        />
      </Col>
      <Col md={12} className="d-md-flex flex-row">
        <Input
          type="text"
          name="employee.name"
          label="Nome do Vendedor(A)"
          placeholder="Nome do Vendedor(A)"
          fontSizeInput="12px"
          fontSizeLabel="12px"
          className="mb-1 mr-2 w-60"
          disabled
        />
        <CurrencyInput
          type="text"
          name="discount"
          label="Desconto"
          className="mb-1 mr-2 w-25"
          classNameInputGroupTextAndFormControl="p-1 font-weight-bold"
          fontSizeInput="12px"
          fontSizeLabel="12px"
          disabled
        />
        <CurrencyInput
          type="text"
          name="addition"
          label="Acréscimo"
          className="mb-1 mr-2 w-25"
          classNameInputGroupTextAndFormControl="p-1 font-weight-bold"
          fontSizeInput="12px"
          fontSizeLabel="12px"
          disabled
        />

        <Input
          type="text"
          name="amount"
          label="Total Geral"
          className="bg-transparent p-0 font-weight-bold mb-1 mr-2 w-40"
          classNameInputGroupTextAndFormControl="text-center text-laranja-5bits text-uppercase font-weight-bold bg-transparent p-0"
          fontSizeInput="12px"
          fontSizeLabel="12px"
          disabled
        />

        <Input
          type="textarea"
          name="observation"
          label="Observações"
          maxLength={45}
          className="mb-2"
          classNameInputGroupTextAndFormControl="p-1"
          fontSizeLabel="12px"
          fontSizeInput="12px"
          disabled
        />
      </Col>
    </Row>
  );
};

export default HeaderSellUpdate;
