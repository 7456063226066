import styled, { css } from 'styled-components';
import ArrowDown from '../../../assets/Arrow_down.svg';

interface ULProps {
  visible: boolean;
}

export const Container = styled.div<ULProps>`
  label {
    padding: 5px;
  }

  > a {
    label {
      ${props =>
        props.visible &&
        css`
          color: var(--laranja-5bits);
        `}
    }
  }
`;

export const SubMenu = styled.ul<ULProps>`
  list-style: none;

  li {
    label {
      display: block;
      &:before {
        content: '';
      }

      &:hover {
        cursor: pointer;
        font-weight: normal;
        color: var(--laranja-5bits);
      }
    }
  }

  li {
    margin-top: 4px;
    margin-left: 20px;

    font-size: 14px;
    font-weight: normal;
    line-height: 14px;
  }

  ${props =>
    !props.visible &&
    css`
      display: none;
    `}
`;
export const Arrow = styled.span<ULProps>`
  float: right;
  content: url(${ArrowDown});
  ${props =>
    props.visible &&
    css`
      transform: rotate(-180deg);
    `}
`;
