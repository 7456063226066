/* eslint-disable camelcase */
import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';

import Table from 'react-bootstrap/Table';

import ListingTemplate from '../../components/Template/ListingTemplate';
import Loader from '../../components/Loader';

import api from '../../services/api';

import { useToast } from '../../context/ToastContext';

import { IPermission } from '../../interfaces/IPermission';

interface ClientFormDataDTO {
  id: number;
  name: string;
  cpf: string;
  dt_birth: string;
  address: string;
  phone: string;
  email: string;
}

interface PermissionDTO {
  permissions: IPermission;
}

/**
 * [X] Adicionar botão Editar
 * [X] Corrigir a quebra de linha para texto muito grande
 */

const ReadsClients: React.FC<PermissionDTO> = ({ permissions }) => {
  const [loading, setLoading] = useState(false);
  const [listClients, setListClients] = useState<ClientFormDataDTO[]>([]);
  const [listClientsPaginatedItems, setListClientsPaginatedItems] = useState<
    any[]
  >([]);
  const { addToast } = useToast();

  useEffect(() => {
    try {
      const getListClients = async (): Promise<void> => {
        try {
          setLoading(true);
          const response = await api.get('clients');
          setListClients(response.data);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          // eslint-disable-next-line no-shadow
          if (error.response) {
            const { data } = error.response; // Error vindo do back está em data dentro de response
            addToast({
              type: 'error',
              title: data.message,
            });
            return;
          }

          addToast({
            type: 'error',
            title: 'Ocorreu um erro interno',
          });
        }
      };
      getListClients();
    } catch (error) {
      setLoading(false);
      // eslint-disable-next-line no-shadow
      if (error.response.data) {
        const { data } = error.response; // Error vindo do back está em data dentro de response
        addToast({
          type: 'error',
          title: data.message,
        });
        return;
      }

      addToast({
        type: 'error',
        title: `Ocorreu um erro interno${error}`,
      });
    }
  }, [addToast]);

  const handleListClientsPaginatedItems = useCallback(newList => {
    setListClientsPaginatedItems(newList);
  }, []);

  return (
    <ListingTemplate
      title="Clientes"
      module="clients/search"
      listItemsProps={listClients}
      handleListItemsPaginatedItems={handleListClientsPaginatedItems}
      placeHolderProp="Cód. ou Nome do Cliente"
      permissions={permissions}
    >
      {(loading && <Loader />) || (
        <>
          <Table striped bordered hover size="sm" responsive="md">
            <thead className="text-center text-primary text-uppercase">
              <tr>
                <th className="font-weight-normal py-3" scope="col">
                  Código
                </th>
                <th className="font-weight-normal py-3" scope="col">
                  Nome
                </th>
                <th className="font-weight-normal py-3" scope="col">
                  CPF
                </th>
                <th className="font-weight-normal py-3" scope="col">
                  Endereço
                </th>
                <th className="font-weight-normal py-3" scope="col">
                  Ações
                </th>
              </tr>
            </thead>
            <tbody className="text-center">
              {listClientsPaginatedItems.map((item, index) => (
                <tr key={index.toString()}>
                  <th scope="row">{item.id}</th>
                  <td className="text-left">{item.name}</td>
                  <td>{item.cpf}</td>
                  <td className="text-left">{item.address}</td>
                  <td>
                    {(permissions.update && (
                      <Link to={`/clients/read/${item.id}`}>Alterar</Link>
                    )) || <span className="text-cinza-51-5bits">Alterar</span>}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
    </ListingTemplate>
  );
};

export default ReadsClients;
