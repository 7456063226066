/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useCallback, useState } from 'react';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CreateWithListingTemplate from '../../components/Template/CreateWithListingTemplate';

import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';
import { useToast } from '../../context/ToastContext';

import InputFile from '../../components/Input/InputFile';
import Button from '../../components/Button';

import Modal from '../../components/Modal';

import { IPermission } from '../../interfaces/IPermission';

interface PermissionDTO {
  permissions: IPermission;
}

const InputNFe: React.FC<PermissionDTO> = ({ permissions }) => {
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [disableButton, setDisableButton] = useState(false);
  const [load, setLoad] = useState(false);
  const [percentCompleted, setPercentCompleted] = useState(0);

  // Para paginação
  const [listResponse, setListResponse] = useState<any[]>([]);
  const [listNFePaginatedItems, setListNFePaginatedItems] = useState<any[]>([]);
  // Fim Para paginação

  interface ImportNFeDTO {
    file: any;
  }

  // handleSubmit
  const handleSubmit = useCallback(
    async (dataForm: ImportNFeDTO, { reset }) => {
      try {
        setDisableButton(true);
        setLoad(true);

        formRef.current?.setErrors({});

        /** A função mista do Yup pode corresponder a todos os tipos */
        const schema = Yup.object().shape({
          file: Yup.mixed().test('file', 'Arquivo obrigatório!', () => {
            if (dataForm.file) {
              return true;
            }
            return false;
          }),
        });

        await schema.validate(dataForm, {
          abortEarly: false,
        });

        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress(progressEvent: any) {
            // Indica a porcentagem já enviada
            setPercentCompleted(
              Math.round((progressEvent.loaded * 100) / progressEvent.total),
            );
          },
        };

        const formData = new FormData();
        formData.append('file', dataForm.file);

        const response = await api.post('products/nfe', formData, config);

        // Para Paginação
        setListResponse(response.data);
        // Fim Para Paginação

        addToast({
          type: 'success',
          title: 'Importação realizada com sucesso',
        });

        reset();
        setDisableButton(false);
        setPercentCompleted(0);
        setLoad(false);
        // history.push('/home');
      } catch (error) {
        setDisableButton(false);
        setPercentCompleted(0);
        setLoad(false);

        // Verifica se o error é do Yup ou seja dos campos
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);

          return;
        }
        // eslint-disable-next-line no-shadow
        if (error.response) {
          const { data } = error.response; // Error vindo do back está em data dentro de response
          addToast({
            type: 'error',
            title: 'Erro na importação',
            description: data.message,
          });
          if (formRef.current?.getFieldRef(data.field)) {
            formRef.current?.getFieldRef(data.field).focus(); // Foca o campo que deu erro de acordo com o field retornado do back
          }

          return;
        }

        addToast({
          type: 'error',
          title: 'Ocorreu um erro interno',
        });
      }
    },
    [addToast],
  );
  // Fim handleSubmit

  const handleListNFePaginatedItems = useCallback(newList => {
    setListNFePaginatedItems(newList);
  }, []);

  return (
    <CreateWithListingTemplate
      title="Entrada de NFe"
      numberColContent={12}
      listItemsProps={listResponse}
      handleListItemsPaginatedItems={handleListNFePaginatedItems}
    >
      {(load && (
        <Modal visibleProp={load}>
          <div className="w-100 bg-branco-puro-5bits p-4">
            <h1>Carregando NFe</h1>
            <ProgressBar
              animated
              now={percentCompleted}
              label={`${percentCompleted}%`}
            />
          </div>
        </Modal>
      )) || (
        <Form
          ref={formRef}
          className="mt-2 mb-4 d-md-flex flex-md-row align-items-md-end justify-content-md-center"
          onSubmit={handleSubmit}
        >
          {(permissions.create && (
            <>
              <InputFile name="file" className="w-50 mb-4 mb-md-0 mr-md-3" />

              <Button
                name="submit"
                type="submit"
                className="mt-5 mt-md-0 w-auto"
                disabled={disableButton}
              >
                Enviar
              </Button>
            </>
          )) || (
            <>
              <InputFile
                name="fileDisabled"
                className="w-50 mb-4 mb-md-0 mr-md-3"
                disabled
              />

              <Button
                name="button"
                type="button"
                className="mt-5 mt-md-0 w-auto"
                onClick={e => e.preventDefault()}
                disabled
              >
                Enviar
              </Button>
            </>
          )}
        </Form>
      )}
      {listResponse && (
        <Row className="h-auto justify-content-center no-gutters overflow-hide">
          <Col className="h-100 overflow-auto" md={12}>
            <div className="text-primary text-uppercase font-weight-bold">
              Produtos Atualizados:
              <span className="text-cinza-51-5bits text-uppercase ml-2 font-weight-bold">
                {listResponse.length}
              </span>
            </div>
            <Table striped bordered hover size="sm" responsive="md">
              <thead className="text-center text-primary text-uppercase">
                <tr>
                  <th className="font-weight-normal py-3" scope="col">
                    Nome do produto
                  </th>
                  <th className="font-weight-normal py-3" scope="col">
                    Quantidade
                  </th>
                  <th className="font-weight-normal py-3" scope="col">
                    Código de barras
                  </th>
                </tr>
              </thead>
              <tbody>
                {listNFePaginatedItems.map((item, index) => (
                  <tr key={index.toString()}>
                    <td className="text-left">{item.name}</td>
                    <td>{item.quantity}</td>
                    <td>{item.barcode}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      )}
    </CreateWithListingTemplate>
  );
};

export default InputNFe;
